import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";

const RStep2 = () => {

  const nav = useNavigate();

  // iniatize state variables...
  const [inputData, setInputData] = useState({
    email: "",
  });

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  }

  // onContinue function
  async function onContinue(event) {
    nav('/GetOtp');
  }
  return (
    <div className="flex items-center w-full h-[100vh] justify-center border ">
      <Row className="py-8 w-[46%]">
        <div className="flex items-center flex-col justify-center h-[30rem]">

          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[80%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Signup to LorryCare" />
                <span className="text-xs">Sign up / Register for FREE</span>
              </div>
              {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
            </div>

          </div>

          <div className="w-[80%]">
            <div className="w-[100%] ">
              <Input
                label="Email address"
                onChange={(e) => {
                  inputChange(e);
                }}
                type="text"
                name="email"
                placeholder="Example alex@gmail.com"
              />
            </div>
            <div className="w-full mt-5">
              <Button style={{ "color": "white", "background": "#232323", "width": "100%" }}
                onClick={(e) => {
                  onContinue(e);
                }}
              >Get Verification code</Button>
            </div>
            <div className="mt-1">
              <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-sm text-center">
                Already have an account?
                <span
                  style={{ color: "black", fontWeight: "600", cursor: "pointer" }}
                >
                  <Link to="/login" className="ml-1 underline">Login</Link>
                </span>{" "}
              </p>
            </div>
          </div>

        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`

`;

const Input = styled.input`
  margin-top: 0.75rem;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  outline:none !important;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  font-family: "Inter", sans-serif;
`;
export default RStep2;
