import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const filterData = createSlice({
    name: 'filterData',
    initialState,
    reducers: {
        filterItem: (state, action) => {
            state.push(action.payload);
        },
        removeItem: (state, action) => {
            return state.filter(item => 
                item !== action.payload
            )
        }
    },
})

// Action creators are generated for each case reducer function
export const { filterItem, removeItem } = filterData.actions

export default filterData.reducer