import React from "react";

function TruckIcon() {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M29.9984 35H32.4984C35.2484 35 37.4984 32.75 37.4984 30V5H14.9984C11.2484 5 7.97344 7.075 6.27344 10.125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 42.5C5 46.65 8.35 50 12.5 50H15C15 47.25 17.25 45 20 45C22.75 45 25 47.25 25 50H35C35 47.25 37.25 45 40 45C42.75 45 45 47.25 45 50H47.5C51.65 50 55 46.65 55 42.5V35H47.5C46.125 35 45 33.875 45 32.5V25C45 23.625 46.125 22.5 47.5 22.5H50.725L46.45 15.025C46.0095 14.2601 45.3759 13.6242 44.6125 13.1811C43.8491 12.738 42.9827 12.5031 42.1 12.5H37.5V30C37.5 32.75 35.25 35 32.5 35H30"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 20H20M5 27.5H15M5 35H10M20 55C21.3261 55 22.5979 54.4732 23.5355 53.5355C24.4732 52.5979 25 51.3261 25 50C25 48.6739 24.4732 47.4021 23.5355 46.4645C22.5979 45.5268 21.3261 45 20 45C18.6739 45 17.4021 45.5268 16.4645 46.4645C15.5268 47.4021 15 48.6739 15 50C15 51.3261 15.5268 52.5979 16.4645 53.5355C17.4021 54.4732 18.6739 55 20 55ZM40 55C41.3261 55 42.5979 54.4732 43.5355 53.5355C44.4732 52.5979 45 51.3261 45 50C45 48.6739 44.4732 47.4021 43.5355 46.4645C42.5979 45.5268 41.3261 45 40 45C38.6739 45 37.4021 45.5268 36.4645 46.4645C35.5268 47.4021 35 48.6739 35 50C35 51.3261 35.5268 52.5979 36.4645 53.5355C37.4021 54.4732 38.6739 55 40 55ZM55 30V35H47.5C46.125 35 45 33.875 45 32.5V25C45 23.625 46.125 22.5 47.5 22.5H50.725L55 30Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}

export default TruckIcon;
