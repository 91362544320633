import { configureStore } from '@reduxjs/toolkit'
import tableDatas from '../slices/tableDatas'
import historyTableDatas from '../slices/historyTableDatas'
import vehicleTabledata from '../slices/vehicleTabledata'
import pastJourneyTable from '../slices/pastJourneyTable'
import filterData from '../slices/filterData'
import vehicleGetResponse from '../slices/vehicleGetResponse'
import vehicleTrackData from '../slices/vehicleTrackData'
import mouseOnVehicle from '../slices/mouseOnVehicle'
import share_pop_up from '../slices/share_pop_up'
import isFilterClick from '../slices/isFilterClick'
import isFilterClick2 from '../slices/isFilterClick2'
import filterDataSource from '../slices/filterDataSource'
import HistoryVSTrack from '../slices/HistoryVSTrack'
import isShowFeed from '../slices/isShowFeed';
import tableLegnth from '../slices/tableLegnth'
import journeyTollData from '../slices/journeyTollData'
import closeJourneyPostData from '../slices/closeJourneyPostData'
import hasWalletMoney from "../slices/hasWalletMoney"
import choosePlan from "../slices/choosePlan"
import five_sec_popup from "../slices/five_sec_popup";
import WalletBalance from "../slices/WalletBalance"
import AllNotification from "../slices/AllNotification";
import translate from "../slices/translate"
import Vehicle_Number_from_vehicleList from '../slices/Vehicle_Number_from_vehicleList'
import cPA from '../slices/cPA'
import InvoiceAmount from '../slices/InvoiceAmount'
import hoverMenu from '../slices/hoverMenu'
import ewayTrackData from '../slices/ewayTrackData'

export const store = configureStore({
  reducer: {
    tableDatas,
    historyTableDatas,
    vehicleTabledata,
    pastJourneyTable,
    filterData,
    vehicleGetResponse,
    vehicleTrackData,
    mouseOnVehicle,
    share_pop_up,
    isFilterClick,
    isFilterClick2,
    filterDataSource,
    HistoryVSTrack,
    isShowFeed,
    tableLegnth,
    journeyTollData,
    closeJourneyPostData,
    hasWalletMoney,
    choosePlan,
    five_sec_popup,
    WalletBalance,
    AllNotification,
    translate,
    Vehicle_Number_from_vehicleList,
    cPA,
    InvoiceAmount,
    hoverMenu,
    ewayTrackData,
  },
})