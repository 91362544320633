import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import Footer from "../../components/layouts/Footer/Footer";

const AboutUs = () => {
  let c1 = [
    "When you use our website, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you with a safe, efficient, smooth, and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.o in general, you can browse the website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based on your behavior on our website. We use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect and serve our users.Use of Demographic / Profile Data / Your Information",
    "We use personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt out of such uses. We use your personal information to resolve disputes, troubleshoot problems, help promote a safe service, collect money, measure consumer interest in our products and services, inform you about online and offline offers, products, services, and updates, customize your experience, detect and protect us against error, fraud, and other criminal activity, enforce our terms and conditions, and as otherwise described to you at the time of collection.",
    "In our efforts to continually improve our product and service offerings, we collect and analyze demographic and profile data about our users' activity on our Website.",
    "We identify and use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information.",
    "We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, or income level). We use this data to tailor your experience at our Website, providing you with content that we think you might be interested in and displaying the content according to your preferences.",
  ];

  let c2 = [
    "Cookies are pieces of information that are automatically stored in a person's hard drive upon his use of the website. Lorrycare.com uses cookies to enable the browser to track the information provided to us by a user. The cookies do not contain any sort of viruses and are used for security and customization purposes. If a user rejects cookies, he may not be able to have access to some of the features or services offered by the website.",
  ];

  let c3 = [
    "We may share personal information with our other corporate entities and affiliates to help detect and prevent identity theft, fraud, and other potentially illegal acts, correlate related or multiple accounts to prevent abuse of our services, and to facilitate joint or co-branded services that you request where such services are provided by more than one corporate entity. Those entities and affiliates may not market to you as a result of such sharing unless you explicitly opt-in",
    "We disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court",
    "Orders or other legal processes. We may disclose personal information to law enforcement offices, third-party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy, respond to claims that an advertisement, posting, or other content violates the rights of a third party, or protect the rights, property or personal safety of our users or the general public.",
    "We and our affiliates will share/sell some or all of your personal information with another business entity we (or our assets) plan to merge with or be acquired by that business entity, or re-organization, amalgamation, or restructuring of business. If such a transaction occurs the other business entity (or the new combined entity) will be required to follow this privacy policy concerning your personal information Security",
    "Our website has stringent security measures in place to protect against the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.",
  ];

  return (
    <>
      {/* navbar START*/}
      <SecondNavBar translationHide={"true"} />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection bigi={"Privacy Policy"} small={""}></TopSection>
      {/* top section Component END*/}

      <div className="my-5">
        {/* about us Main Card START */}
        <Card>
          <HeadingWithText
            // head={"Effective 1 April, 2023"}
            desc={[
              "We respect and are committed towards protecting your privacy. Publishing, selling or renting any personal data or information to any third party, without your consent, is against our ethics.",
              "The privacy practices of this statement apply to our services available under the domain and subdomains of the Site. By visiting this Site you agree to be bound by the terms and conditions of this privacy policy. If you do not agree, please do not use or access our site.",
              "This privacy policy does not apply to sites maintained by other companies or organizations to which we link and we are not responsible for any personal information you submit to third parties via our website. Please ensure that you read the privacy policy of such other companies or organizations before submitting your details.",
              "This privacy policy describes the information, as part of the normal operation of our services, we collect from you and what may happen to that information. This policy is inter alia formulated and displayed, to inform you about our information collection/retention policies and practices so that you can make an informed decision, in relation to the sharing of your personal information with us. By accepting the privacy policy and the user agreement or the use of the site in general, you give your consent to our use and disclosure of your personal information in accordance with this privacy policy. This Privacy Policy is incorporated into and subject to the terms of the User Agreement. This privacy policy is effective upon acceptance of access by you to the site.",
            ]}
          />
          <HeadingWithText
            head={"Privacy Guarantee"}
            desc={[
              "We agree that we will not sell or rent your personal information to third parties for their marketing purposes without your explicit consent. From time to time, we may reveal general statistical information about our Site and visitors, such as number of visitors, number and type of goods and services purchased, etc. Only those of our employees who need access to your information in order to perform their duties, are allowed such access. Any employee who violates our privacy and/or security policies is subjected to disciplinary action, including possible termination and civil and/or criminal prosecution.",
            ]}
          />
          <HeadingWithText
            head={"Information We Collect"}
            desc={[
              "The Personal Information is used for two general purposes: to process your order, and to provide you with the best possible services. Unless otherwise stated explicitly, this policy applies to personal information as disclosed on any of the media.",
              "In furtherance of the confidentiality with which we treat Personal Information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online. We use data collection devices such as cookies on certain pages of the Site to help and analyze our web page flow, measure promotional effectiveness, and promote trust and safety.",
              "Cookies are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a cookie. Cookies can also help us provide information that is targeted to your interests. Most cookies are session cookies, which means that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits. Additionally, you may encounter cookies or other similar devices on certain pages of the Site that are placed by third parties. For example, if you view a web page created by a user, there may be a cookie placed within that web page. We do not control the use of cookies by third parties.You acknowledge that you are disclosing Personal Information voluntarily. We identify and use your IP address to help diagnose problems with our server and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information.",
            ]}
          />

          <HeadingWithText
            head={"Cookie Policy"}
            desc={[
              "ONEWHISTLE MARKETPLACE PVT.LTD. operates a strict privacy policy and we are committed to being transparent about how we use cookies on our website.",
              "Why are cookies important?",
              "Cookies help you make your online experience more efficient and relevant to your interests. For instance, they are used to remember your preferences on sites you visit often, to remember your user ID and the contents of your shopping baskets, and to help you navigate between pages efficiently.",
              "What is a Cookie?",
              "A cookie is a small file, or files on your computer, phone, or other device with a browser to save snippets of text for reference by the website you are visiting. All cookies have expiration dates in them that determine how long they stay in your browser:",
              "Session cookies - these are temporary cookies that expire (and are automatically erased) whenever you close your browser.",
              "Persistent cookies - these usually have an expiration date and so stay in your browser until they expire, or until you manually delete them. For example we use persistent cookies to better understand usage patterns so that we can improve the site for our customers.",
              "Cookies are grouped into the following categories:",
              "Essential - these are cookies that are required for the regular operation of our websites.",
              "Functional - these remember your preferences, and are intended to make your experience on our websites better for you.",
              "Analytics – these cookies are used for performance measurement to understand things including how many people visit our websites, how they navigate our sites, and what content is popular. This allows us to improve your experience with us. Additionally, you can see how Google Analytics (one of our analytics tools) uses cookie information when you use our partners' sites by visiting www.google.com/policies/privacy/partners, or any other URL Google may provide from time to time",
              "Advertising - these cookies enable us and our advertising partners to serve you with relevant advertisements that we think will interest you. You might see these advertisements on our sites on other sites you visit. These cookies record your visit to our website and the content you interact with. They may be placed by us, or by advertising partners with our permission.",
              "To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners.We also use third party information from third party sources to enable us deliver advertising. These sources are validated by Third party, and not by us.",
              "To delete cookies from your browser, please note the following simple steps. The following steps are indicative for Google chrome and might vary of the different browsers that you may use. Please refer Settings on your browser for further details.",
              "To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners.You may also change other setting related to Privacy and Security under the same section.",
            ]}
          />

          {/* <HeadingWithText
            head={"Effective 1 April, 2023"}
            desc={[
              "We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information-gathering and dissemination practices.Note: Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website. By mere use of the Website, you expressly consent to our use and disclosure of your personal information following this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.",
            ]}
          /> */}

          {/* <HeadingWithText head={"Cancellation and Refunds"} desc={["There shall be no cancellation of payments made via online payment (except for payments through Cheque/ Demand Draft). Cancellation requests for orders placed via Cheque/ Demand Draft can be made only before such payment is realized by lorrycare.com. In case lorrycare.com receives a payment for a failed transaction, lorrycare.com shall refund the same. However, lorrycare.com offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Customer's card/bank accounts. Such refunds involve multiple organizations over which lorrycare.com does not have any control; lorrycare.com shall reserve the exclusive right to cancel any content whatsoever from being published or reflected on its website or in any other mode. The payment of refunds for this shall be at the sole discretion of lorrycare.com. Lorrycare.com is not obliged to make any refund under whatsoever circumstances."]} /> */}

          {/* <HeadingWithText head={"Maintenance and System Failures"} desc={["While lorrycare.com shall take all reasonable efforts for data backup and business resumption, you will be solely responsible for retaining backup copies of all information, photographs, and other materials furnished/submitted by/to www.lorrycare.com. Lorrycare may also suspend the operation of lorrycare.com for support or technical upgradation, or maintenance work, to update the content, or for any other reason. Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authorities."]} /> */}

          {/* <HeadingWithText head={"Disclaimer"} desc={["Lorrycare.com disclaims all warranties, express or implied, including, but not limited to, the warranties of merchantability, fitness for a particular purpose, non-infringement, title, custom, trade, quiet enjoyment, system integration, and freedom from computer virus. Lorrycare.com does not represent or warrant that the functions contained in this website will be error-free or uninterrupted, that defects will be corrected, or that the website or the server that makes the website available is free from any harmful components including viruses. Lorrycare.com does not make any representations or warranties that the information on this website is accurate, complete, correct, adequate, useful, timely, reliable, or otherwise. You acknowledge, by your use of this website, that your use is at your sole risk."]} /> */}

          {/* <HeadingWithText head={"Information Collection"} desc={c1} /> */}

          {/* <HeadingWithText head={"Cookies"} desc={c2} /> */}

          {/* <HeadingWithText head={"Sharing of Personal Information"} desc={c3} /> */}

          {/* <HeadingWithText
            head={"Links to other websites"}
            desc={[
              "Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites, and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Posting Your Information to Public Areas of lorrycare.com"}
            desc={[
              "Please remember that if you post any of your personal information in public areas of lorrycare.com, such as in online forums or chat rooms, or the searchable database, such information may be collected and used by others over whom One whistle Marketplace Pvt. Ltd. has no control. We cannot control the use made by third parties of information you post or otherwise make available in public areas of One whistle Marketplace Pvt. Ltd.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Controlling and Managing your personal information"}
            desc={[
              "We retain all the information we gather about you to make your repeat use with us more efficient, practical, and relevant until you change or remove your data as described below. You may have access to review, correct, update, or delete your account profile at any time. Simply log into your account, go to your profile, and make the necessary changes. Before providing you with a copy of your personal information or correcting, updating, or deleting such information, we reserve the right to verify and authenticate your identity and the personal information to which you have requested access. Access to or correction, updating, or deletion of your personal information may be denied or limited by One whistle Marketplace Pvt. Ltd. if it would violate another person(s) rights and/or as otherwise permitted by applicable law.If you wish to delete or close your account or account profile information altogether, please contact us at info@lorrycare.com. An email will be sent to you to confirm that your personal information has been deleted (save for an archival copy that is not accessible by you or third parties on the Internet). The archival copy is retained as long as One whistle Marketplace Pvt. Ltd. reasonably considers it necessary for audit and record purposes. We will also retain logs, and demographic, indirect, and statistical information that may relate to you but do not identify you personally. If your personal information was previously accessed by others using our Sites, we are not able to delete the information or copies thereof from their systems.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Your Consent"}
            desc={[
              "o By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website under this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Grievance Officer"}
            desc={[
              "If there are any questions regarding this privacy policy you may contact us using the information below or drop us a mail with the subject line-PRIVACY POLICY:Address: A-514, Joyos Hubtown, S. T. Bus Stand, Geeta Mandir Road, Raipur Darwaza, Ahmedabad – 380002 Email: info@lorrycare.com ",
            ]}
          /> */}
        </Card>
        {/* about us Main Card END */}
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
