import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const isFilterClick = createSlice({
    name: 'isFilterClick',
    initialState,
    reducers: {
        setIsFilter: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIsFilter } = isFilterClick.actions

export default isFilterClick.reducer