import { Button, Card, Switch } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
// import { makeStyles } from "@material-ui/core/styles";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import PlansHeader from "../../components/layouts/Plans/PlansHeader";
import "./PremiumPlans.css";
import { Link, useNavigate } from 'react-router-dom'
import Footer from "../../components/layouts/Footer/Footer";

function PremiumPlans() {
  const nav = useNavigate();

  return (
    <>
      <div className="nav">
        <SecondNavbar />
      </div>
      <div className="w-[90%] m-auto flex flex-col gap-4 lg:w-[80%]">
        <PlansHeader
          style={{
            backgroundColor: "#fafafa",
            color: "black",
            border: "1px solid black",
          }}
          secondBtnStyle={{
            backgroundColor: "black",
            color: "#fafafa",
          }}
        />

        {/* SahalTarunDarshan */}

        <div className="flex w-full text-sm items-center justify-center md:hidden ">
          <div className="ppu">Pay Per Use</div>
          <Switch />
          <div className="monthlySubscription">Monthly Subscription</div>
        </div>
        <hr className="border-[#00000087] mb-4" />
        <div className="lg:hidden">
          <div className="component-essentials">
            <hr className="border-2 border-gray-700" />
          </div>
          <Carousel>
            <div className="one">
              <div className="card">
                <Card className="First-Child w-full ">
                  <div className="flex flex-col gap-1 py-1 px-12">
                    <div className="flex justify-start gap-1">
                      <div className="font-Inter font-bold text-4xl">₹ 100</div>
                      <span className="flex justify-center items-center font-Inter text-sm font-medium">
                        /month
                      </span>
                    </div>
                    <div className="heading">
                      <h1 className="text-2xl font-Inter font-normal">Basic</h1>
                      <p className="text-xs font-Inter font-medium">
                        Provides an affordable and easy-to-use vehicle tracking
                        solution
                      </p>
                    </div>
                    <div className="flex justify-center ml-4">
                      <ul className="flex flex-col gap-1 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track up to 10 vehicles</li>
                        </div>
                        <div>
                          <li>Automatic subscription renewal available</li>
                        </div>
                        <div>
                          <li>
                            Unlimited toll crossings for subscribed vehicles
                          </li>
                        </div>
                        <div className="">
                          <li>
                            Real-time alerts through push notifications on the
                            mobile app
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div className="flex justify-center items-center">
                      <Button onClick={() => nav('/checkout')}>
                        <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                          Choose Plan
                        </span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="two">
              <div className="card">
                <Card className="Second-Child w-full">
                  <div className="flex flex-col gap-1 py-1  px-12">
                    <div className="flex justify-start gap-1">
                      <div className="font-Inter font-bold text-4xl">₹ 95</div>
                      <span className="flex justify-center items-center font-Inter text-sm font-medium">
                        /month
                      </span>
                    </div>
                    <div className="heading">
                      <h1 className="text-2xl font-Inter font-normal">
                        Standard
                      </h1>
                      <p className="text-xs font-Inter font-medium">
                        Provides an affordable and easy-to-use vehicle tracking
                        solution
                      </p>
                    </div>
                    <div className="flex justify-center ml-4">
                      <ul className="flex flex-col gap-1 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track upto 10-15 vehicles</li>
                        </div>
                        <div>
                          <li>Automatic subscription renewal available</li>
                        </div>
                        <div>
                          <li>
                            Unlimited toll crossings for subscribed vehicles
                          </li>
                        </div>
                        <div className="">
                          <li>
                            Real-time alerts through push notifications on the
                            mobile app
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div className="flex justify-center items-center">
                      <Button onClick={() => nav('/checkout')}>
                        <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                          Choose Plan
                        </span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="three">
              <div className="card">
                <Card className="Third-Child w-full">
                  <div className="flex flex-col gap-1 py-1  px-12">
                    <div className="flex justify-start gap-1">
                      <div className="font-Inter font-bold text-4xl">₹ 90</div>
                      <span className="flex justify-center items-center font-Inter text-sm font-medium">
                        /month
                      </span>
                    </div>
                    <div className="heading">
                      <h1 className="text-2xl font-Inter font-normal">Pro</h1>
                      <p className="text-xs font-Inter font-medium">
                        Provides an affordable and easy-to-use vehicle tracking
                        solution
                      </p>
                    </div>
                    <div className="flex justify-center ml-4">
                      <ul className="flex flex-col gap-1 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track upto 51-100 vehicles</li>
                        </div>
                        <div>
                          <li>Automatic subscription renewal available</li>
                        </div>
                        <div>
                          <li>
                            Unlimited toll crossings for subscribed vehicles
                          </li>
                        </div>
                        <div className="">
                          <li>
                            Real-time alerts through push notifications on the
                            mobile app
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div className="flex justify-center items-center">
                      <Button onClick={() => nav('/checkout')}>
                        <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                          Choose Plan
                        </span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div className="four">
              <div className="card">
                <Card className="Fourth-Child w-full">
                  <div className="flex flex-col gap-1 py-1  px-12">
                    <div className="flex justify-start gap-1">
                      <div className="font-Inter font-bold text-4xl">₹ 80</div>
                      <span className="flex justify-center items-center font-Inter text-sm font-medium">
                        /month
                      </span>
                    </div>
                    <div className="heading">
                      <h1 className="text-2xl font-Inter font-normal">
                        Enterprise
                      </h1>
                      <p className="text-xs font-Inter font-medium">
                        Provides an affordable and easy-to-use vehicle tracking
                        solution
                      </p>
                    </div>
                    <div className="flex justify-center ml-4">
                      <ul className="flex flex-col gap-1 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track above 100 vehicles</li>
                        </div>
                        <div>
                          <li>Automatic subscription renewal available</li>
                        </div>
                        <div>
                          <li>
                            Unlimited toll crossings for subscribed vehicles
                          </li>
                        </div>
                        <div className="">
                          <li>
                            Real-time alerts through push notifications on the
                            mobile app
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div className="flex justify-center items-center">
                      <Button onClick={() => nav('/checkout')}>
                        <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                          Choose Plan
                        </span>
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="hidden lg:block">
          <div className="flex justify-center items-center gap-16">
            <div className="parent w-full flex relative gap-4">
              <Card className="First-Child w-[25%] ">
                <div className="flex flex-col gap-4 py-4 px-12">
                  <div className="flex justify-start gap-1">
                    <div className="font-Inter font-bold text-4xl">₹ 100</div>
                    <span className="flex justify-center items-center font-Inter text-sm font-medium">
                      /month
                    </span>
                  </div>
                  <div className="heading">
                    <h1 className="text-2xl font-Inter font-normal">Basic</h1>
                    <p className="text-sm font-Inter font-medium">
                      Provides an affordable and easy-to-use vehicle tracking
                      solution
                    </p>
                  </div>
                  <div className="flex justify-center ml-4">
                    <ul className="flex flex-col gap-2 text-[0.8rem] font-Inter list-disc">
                      <div>
                        <li>Track up to 10 vehicles</li>
                      </div>
                      <div>
                        <li>Automatic subscription renewal available</li>
                      </div>
                      <div>
                        <li>
                          Unlimited toll crossings for subscribed vehicles
                        </li>
                      </div>
                      <div className="">
                        <li>
                          Real-time alerts through push notifications on the
                          mobile app
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button onClick={() => nav('/checkout')}>
                      <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                        Choose Plan
                      </span>
                    </Button>
                  </div>
                </div>
              </Card>
              <Card className="Second-Child w-[25%]">
                <div className="flex flex-col gap-4 py-4 px-12">
                  <div className="flex justify-start gap-1">
                    <div className="font-Inter font-bold text-4xl">₹ 95</div>
                    <span className="flex justify-center items-center font-Inter text-sm font-medium">
                      /month
                    </span>
                  </div>
                  <div className="heading">
                    <h1 className="text-2xl font-Inter font-normal">
                      Standard
                    </h1>
                    <p className="text-sm font-Inter font-medium">
                      Provides an affordable and easy-to-use vehicle tracking
                      solution
                    </p>
                  </div>
                  <div className="flex justify-center ml-4">
                    <ul className="flex flex-col gap-2 text-[0.8rem] font-Inter list-disc">
                      <div>
                        <li>Track upto 10-15 vehicles</li>
                      </div>
                      <div>
                        <li>Automatic subscription renewal available</li>
                      </div>
                      <div>
                        <li>
                          Unlimited toll crossings for subscribed vehicles
                        </li>
                      </div>
                      <div className="">
                        <li>
                          Real-time alerts through push notifications on the
                          mobile app
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button onClick={() => nav('/checkout')}>
                      <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                        Choose Plan
                      </span>
                    </Button>
                  </div>
                </div>
              </Card>
              <Card className="Third-Child w-[25%]">
                <div className="flex flex-col gap-4 py-4 px-12">
                  <div className="flex justify-start gap-1">
                    <div className="font-Inter font-bold text-4xl">₹ 90</div>
                    <span className="flex justify-center items-center font-Inter text-sm font-medium">
                      /month
                    </span>
                  </div>
                  <div className="heading">
                    <h1 className="text-2xl font-Inter font-normal">Pro</h1>
                    <p className="text-sm font-Inter font-medium">
                      Provides an affordable and easy-to-use vehicle tracking
                      solution
                    </p>
                  </div>
                  <div className="flex justify-center ml-4">
                    <ul className="flex flex-col gap-2 text-[0.8rem] font-Inter list-disc">
                      <div>
                        <li>Track upto 51-100 vehicles</li>
                      </div>
                      <div>
                        <li>Automatic subscription renewal available</li>
                      </div>
                      <div>
                        <li>
                          Unlimited toll crossings for subscribed vehicles
                        </li>
                      </div>
                      <div className="">
                        <li>
                          Real-time alerts through push notifications on the
                          mobile app
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button onClick={() => nav('/checkout')}>
                      <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                        Choose Plan
                      </span>
                    </Button>
                  </div>
                </div>
              </Card>
              <Card className="Fourth-Child w-[25%]">
                <div className="flex flex-col gap-4 py-4 px-12">
                  <div className="flex justify-start gap-1">
                    <div className="font-Inter font-bold text-4xl">₹ 80</div>
                    <span className="flex justify-center items-center font-Inter text-sm font-medium">
                      /month
                    </span>
                  </div>
                  <div className="heading">
                    <h1 className="text-2xl font-Inter font-normal">
                      Enterprise
                    </h1>
                    <p className="text-sm font-Inter font-medium">
                      Provides an affordable and easy-to-use vehicle tracking
                      solution
                    </p>
                  </div>
                  <div className="flex justify-center ml-4">
                    <ul className="flex flex-col gap-2 text-[0.8rem] font-Inter list-disc">
                      <div>
                        <li>Track above 100 vehicles</li>
                      </div>
                      <div>
                        <li>Automatic subscription renewal available</li>
                      </div>
                      <div>
                        <li>
                          Unlimited toll crossings for subscribed vehicles
                        </li>
                      </div>
                      <div className="">
                        <li>
                          Real-time alerts through push notifications on the
                          mobile app
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div className="flex justify-center items-center">
                    <Button onClick={() => nav('/checkout')}>
                      <span className="bg-black text-white px-8 py-2 rounded-md border-2  btn-Hover-Onpoint font-Inter font-semibold">
                        Choose Plan
                      </span>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PremiumPlans;
