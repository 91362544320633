import { Box, Button, Chip, Divider, Paper, Radio } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv';
import { useNavigate, useParams } from 'react-router-dom';

const MobileTrack_Public = () => {
  const navigate = useNavigate();
  let [apiData, setApiData] = useState([]);
  let [apiData2, setApiData2] = useState([]);
  let [isFetched, setIsFetched] = useState(false);
  let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);
  const param = useParams();
  const nav = useNavigate();

  useEffect(() => {
    axios
      .get(
        `https://lorrycare.com:5000/v1/search-vehicle/${param._id}/shared/${param.random}`
      )
      .then((res) => {
        setIsFetched(true);
        setApiData(res.data.data.txn);
        console.log(res.data.data);
        setApiData2(res.data.data);
      })
      .catch((err) => {
        setIsFetched(false);
        console.log(err);
      });
  }, []);

  return (
    <section className="px-2">

      {/* top */}
      <div className="flex justify-between items-center">
        {/* left start */}
        <div className="flex py-6">
          {/* <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="material-symbols-outlined texl-sm">
              keyboard_backspace
            </span>
          </button> */}
          <div className="ml-2">
            <h1 className="text-xl font-Cardo font-bold">{apiData2.vehicle_no}</h1>
            <p style={{ fontSize: "0.8rem", color: "grey", fontWeight: "400" }}>
              View Last 72 Hour Journey
            </p>
          </div>
        </div>
        {/* left end */}
      </div>

      {/* divider */}
      <Divider />

      <Chip label={apiData2.tracking_no} className="mt-6" variant="outlined" />

      {/* main tracking list */}
      <section>
        {apiData.map((elm, ind) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 0.4,
                  width: "100%",
                },
              }}
            >
              <Paper
                style={{
                  display: "flex",
                  alignItems: "start",
                  paddingBottom: "0.5rem",
                }}
              >
                {/* icon */}
                <div>
                  <Radio color="success" checked />
                </div>

                {/* main start */}
                <div>
                  <h1 className="mt-2" style={{ fontWeight: "bold" }}>
                    {elm.plaza}
                  </h1>

                  <section>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Date -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(0, 10)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Time -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.readerReadTime.slice(11, 19)}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        State -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.state}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        City -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {elm.city}
                      </span>
                    </div>
                    <div className="mt-2">
                      <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                        Tracking No. -
                      </b>
                      <span style={{ color: "grey", fontSize: "0.9rem" }}>
                        {" "}
                        {apiData2.tracking_no}
                      </span>
                    </div>
                  </section>
                </div>
                {/* main end */}
              </Paper>
            </Box>
          );
        })}
      </section>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <CSVLink
          className="text-sm inline-block m-auto"
          data={apiData}
          style={{
            background: "black",
            color: "white",
            padding: "0.5rem",
            borderRadius: "0.3rem",
          }}
          filename={`Tracking-History-Of ${apiData2.vehicle_no}`}
        >
          Download
        </CSVLink>
      </div>
    </section>
  )
}

export default MobileTrack_Public