import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import HeadWithUnderline from "../../components/layouts/HeadingUnderline/HeadWithUnderline";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import ls from 'localstorage-slim'
import Tick from "../../components/assets/svg/Tick";
import { useNavigate } from 'react-router-dom';
import './CheckoutTwo.css';
import Footer from "../../components/layouts/Footer/Footer";
import TopSection from '../../TopSection'
import { states } from "./states";

function CheckoutTwo() {
  const nav = useNavigate();
  const [inputData, setInputData] = useState({
    "address": "",
    "states": "",
    "country": "INDIA",
    "pincode": ""
  })

  // inputChange function
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData);
  }

  // proceed function...
  function proceedFunction(e) {
    if ((inputData.address).length < 10 && (inputData.address).length < 50) {
      window.alert("address must be include characters between 10 to 50")
    } else if ((inputData.states).length === 0) {
      window.alert("please select your state")
    } else if ((inputData.pincode).length < 6) {
      window.alert("pincode must be include 6 characters")
    } else {
      ls.set('checkoutData2', inputData);
      nav('/Wallet')
    }
  }

  return (
    <>
      <div className="">
        <div className="nav">
          <SecondNavbar />
        </div>
        <TopSection
          bigi={"Checkout"}
          small={"lorem lorem lorem jhfge xuhfd gf"}
        >
        </TopSection>
        <div className="parent-elem relative my-16">
          <div>
            <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                  <h1>Please fill your details</h1>
                </div>
                <div className="second-Section flex flex-col gap-7 w-[85%]">
                  <div className="inputItem flex flex-col gap-2">
                    <label>Street Address <span style={{ "color": "red" }}>*</span></label>
                    <input
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2"
                      type="text" name="address"
                      placeholder="Example 417-419, Midas Shahar Plaza Cplx, Mathuradas Vasanji Road, Andheri"
                      onChange={(e) => { inputChange(e) }}
                    />
                  </div>
                  <div className="inputItem flex flex-col gap-2">
                    <label>State <span style={{ "color": "red" }}>*</span></label>
                    <select
                      id="state"
                      name="states"
                      onChange={(e) => { inputChange(e) }}
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2 select-btn"
                      aria-label="Default select example" >
                      {
                        states.map(elm => {
                          return <option>{elm}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="inputItem flex flex-col gap-2">
                    <label>Country <span style={{ "color": "red" }}>*</span></label>
                    <select
                      id="country"
                      onChange={(e) => { inputChange(e) }}
                      name="country"
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2 select-btn"
                      aria-label="Default select example">
                      <option default selected value={"india"}>
                        India
                      </option>
                    </select>
                  </div>
                  <div className="inputItem flex flex-col gap-2">
                    <label> Pincode <span style={{ "color": "red" }}>*</span></label>
                    <input
                      type="number"
                      className=" border-2 border-black rounded-md w-[100%] p-1 pl-2"
                      onChange={(e) => { inputChange(e) }}
                      placeholder="306705"
                      name="pincode"
                    />
                  </div>


                  <div className="flex gap-between">
                    <div className="flex bg-black text-white rounded-md px-10 py-1">


                      <button className="w-[100%] text-white " onClick={(e) => { proceedFunction(e) }}>
                        <span className="text-white">Proceed</span>
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CheckoutTwo;
