import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Checkbox from "@mui/material/Checkbox";
import "./toll.css";
import Footer from "../../components/layouts/Footer/Footer";
import ls from "localstorage-slim";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { trackVehicle } from "../../apis/api";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData"
import {isMobile} from 'react-device-detect';
import Mobile_tollplazaActive from './Mobile_tollplazaActive'

import {
  searchTollPlaza,
  AddTollPlaza,
  RemoveTollPlaza,
  closeJourney,
  preAlertCheck,
  postTripSummary,
} from "../../apis/api";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isShowFiveSec } from "../../redux/slices/five_sec_popup";
import { addTollData } from "../../redux/slices/journeyTollData";

const TollPlazaActive = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  // add toll plaza and delete toll plaza dropdown
  const [addTollDDL, setAddTollDDL] = useState(false);
  const [removeTollDDL, setRemoveTollDDL] = useState(false);
  const [showInputPop, setShowInputPop] = useState(false);
  const [confirmTollDeletePop, setconfirmTollDeletePop] = useState(false);
  const [tollIndex, setTollIndex] = useState({
    index: "",
  });
  const [tollInputData, setTollInputData] = useState({
    index: "",
    toll: "",
  });
  const [showInputDDL, setShowInputDDL] = useState(false);
  const [tollAddData, setTollAddData] = useState({
    toll: "",
  });
  const [conditioner, setConditioner] = useState(true);
  const [suggestData, setSuggestData] = useState([]);

  // toll planjourney form data get from localstorage
  const journeyPlan = ls.get("journeyPlan");
  const addButtonToggle = ls.get("addButtonToggle");

  // from localStorage Data START
  let tollMainData = useSelector((state) => state.journeyTollData.value);
  const iLogin = ls.get("iLogin");
  let ParametersOfRemoveTollPlaza;
  let AllObjectData, tollDatas, lastIndexOfTollPlaza;
  let login_response_object, token_user, user_id;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;

    if (tollMainData) {
      AllObjectData = tollMainData;
      console.log("AllObjectData" + JSON.stringify(AllObjectData));
      console.log("AllObjectData2" ,AllObjectData);
      tollDatas = AllObjectData.allPlaza;
      console.log('tollDatas',tollDatas);
      lastIndexOfTollPlaza = tollDatas.length - 1;
    } else {
      AllObjectData = ls.get("journeyTollData", { decrypt: true });
      console.log("AllObjectData" + JSON.stringify(AllObjectData));
      tollDatas = AllObjectData.allPlaza;
      lastIndexOfTollPlaza = tollDatas.length - 1;
    }
  }
  // from localStorage Data END

  // handleTollNameChange(e)
  function handleTollNameChange(e) {
    setTollAddData({ ...tollAddData, [e.target.name]: e.target.value });
  }

  // clickSuggestData(e,elm)
  function clickSuggestData(e, elm) {
    setTollAddData({ ...tollAddData, toll: elm });
    setShowInputDDL(false);
  }

  // ADD TOLL ALL FUNCTION START .....

  // addTollPlazeFunction(e,ind)
  function addTollPlazeFunction(e, ind, id) {
    setShowInputPop(true);
    setTollInputData({ ...tollInputData, index: ind });
    // console.log(tollInputData)
    console.log(id);
  }

  function addTollInput(e) {
    setTollInputData({ ...tollInputData, [e.target.name]: e.target.value });
    axios
      .get(
        `${searchTollPlaza}/${AllObjectData._id}?toll=${tollInputData.toll}`,
        {
          headers: {
            Authorization: `Hello ${token_user}`,
          },
        }
      )
      .then((response) => {
        setSuggestData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // closeJourneyFromToll(e)
  function closeJourneyFromToll(e, closeJourneyID) {
    axios
      .put(`${closeJourney}/${closeJourneyID}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(isShowFiveSec(true));
          nav("/MyJourney");
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addTollFromBackend() {
    axios
      .put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.allPlaza);
        ls.set("addButtonToggle", false);
        ls.set("journeyTollData", res.data.data, { encrypt: true });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeTollPlazeFunction() {
    const ind = ls.get("ParametersOfRemoveTollPlaza");
    console.log(ParametersOfRemoveTollPlaza);
    setTollIndex({ ...tollIndex, index: ind });
    axios
      .put(`${RemoveTollPlaza}/${AllObjectData._id}`, tollIndex, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.allPlaza);
        ls.set("journeyTollData", res.data.data, { encrypt: true });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function showTollDeleteConfirmationPopup(e, ind) {
    setconfirmTollDeletePop(true);
    ls.set("ParametersOfRemoveTollPlaza", ind);
  }

  // ADD TOLL ALL FUNCTION END .....

  // post journey summary to backend..
  function postTripToBackend(e) {
    console.log(e);
    axios
      .post(`${postTripSummary}/${AllObjectData._id}`)
      .then((response) => {
        if (response.data.success) {
          window.alert("your journey summary has posted!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // pre alert activate function
  function preAlertActive(e) {
    if (e.target.checked) {
      axios
        .put(`${preAlertCheck}/${AllObjectData._id}`)
        .then((response) => {
          if (response.data.success) {
            window.alert(response.data.message);
            nav("/OngoingJourneys");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // csv customizations
  console.log("-----toll-datas------");
  console.log(tollDatas,"tollDatas>>><");
  let latestCsv = [];
  tollDatas.map((elm) => {
    latestCsv.push({
      Toll_Plaza: elm.findplza,
      City: elm.city,
      State: elm.state,
      Distance: elm.distance,
      // Date: elm.date
    });
  });
  const [isLoader, setIsLoader] = useState();
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true })

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }
  // clickTrackFunction(e)
  function clickTrackFunction(e,vehicle_nos) {
    if (!iLogin) {
      window.alert("please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;
      const user_id = login_response_object.user._id;

      setIsLoader(true);
      if (
        vehicle_nos.length > 12 ||
        vehicle_nos.length < 9
      ) {
        window.alert("vehicle number must have 9 to 12 digits");
        setIsLoader(false);
      } else if (vehicle_nos.length == 0) {
        window.alert("please enter vehicle number");
        setIsLoader(false);
      } else if (!isFinite(vehicle_nos.slice(-4))) {
        window.alert("please enter valid vehicle number");
        setIsLoader(false);
      } else {
        if (balanceOBJ.credits < 1) {
          window.alert("please buy plan");
          nav("/all-plans");
        } else {
          ls.set("track_vehicle_no", vehicle_nos, { encrypt: true });
          const stateSuggestionDataLocal = ls.get("stateSuggestionData");
          stateSuggestionDataLocal.push(vehicle_nos);
          ls.set("stateSuggestionData", stateSuggestionDataLocal);
          // axios post
          axios
            .post(
              trackVehicle,
              {
                vehicle_no: vehicle_nos,
                u_id: user_id,
              },
              {
                headers: {
                  Authorization: `Hello ${token_user}`,
                },
              }
            )
            .then(function (response) {
              if (response.data.success === true) {
                console.log("response--" + JSON.stringify(response.data.data));
                dispatch(addVehicleTrackData(response.data.data));
                console.log("lines...");
                console.log(response.data.data);
                ls.set("trackVehicleData", response.data.data, {
                  encrypt: true,
                });
                setIsLoader(false);
                nav("/track");
              }
            })
            .catch(function (error) {
              setIsLoader(false);
              console.log(error);
              if (error.response.status == "500") {
                window.alert(`Internal Server Error`);
              }
              if (error.response.status == "400") {
                window.alert(
                  `Vehicle no. not available in ULIP/vehicle has not crossed any toll in last 72 hours/incorrect vehicle number`
                );
              }
              if (error.response.status == "404") {
                window.alert("404 Error");
              }
            });
        }
      }
    }
  }

  return (
    <> {isMobile?<>
    {console.log('isMobile for Active:', isMobile)}
     <Mobile_tollplazaActive/>
     
    </>:
    <>
    <div>
      <div className="Navbar">
        <SecondNavBar />
      </div>

      {/* top section Component */}
      <TopSection bigi={"Trip ID"} small={""}>
        <Button style={{color:"black"}}
          onClick={(e) => {
            clickTrackFunction(e,AllObjectData.vehicle_no)
          }}
        >
          Click to View 72 hours Data
        </Button>
      </TopSection>
      {/* top section Component END*/}

      {/* bottomer */}
      <div className="flex justify-center">
        <div
          className="whenclick_bottom_tollPlaza  width-for-toll-bottom-main  flex flex-col gggggdfefsdnvbx"
          style={{
            background: "none",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          {iLogin ? (
            <>
              <div
                className="flex justify-between pl-3 pr-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
              >
                {/* vehicle number */}
                <div className="text-start">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Vehicle Registration Number
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.vehicle_no}
                  </h1>
                </div>

                {/* start date */}
                <div className="text-start">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Start Date
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.start_date.slice(0, 10)}
                  </h1>
                </div>

                {/* end date */}
                <div className="text-start">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem" }}
                  >
                    End Date
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.end_date.slice(0, 10)}
                  </h1>
                </div>

                {/* total distance */}
                <div className="text-start">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem", fontWeight: "300" }}
                  >
                    Total Distance
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.total_distance}
                  </h1>
                </div>
              </div>

              <div className="flex justify-between mt-2 pl-3 pr-3 pb-2">
                {/* source */}
                <div className="text-start w-[36.5%]">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem" }}
                  >
                    From
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.source[0].source}
                  </h1>
                </div>

                {/* destination */}
                <div className="text-start w-[36.5%]">
                  <span
                    className="toll_bottom_para text-black diks"
                    style={{ fontSize: "0.8rem" }}
                  >
                    To
                  </span>
                  <h1 className=" text-black diks2">
                    {AllObjectData.destination[0].destination}
                  </h1>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div
        className={
          addButtonToggle ? "flex justify-between w-[73.5%] m-auto " : "hidden"
        }
        onClick={(e) => {
          addTollPlazeFunction(e, 0, 0);
        }}
      >
        <div
          className="w-[fit-content] m-auto cursor-pointer flex items-center text-gray-300"
          style={{ fontSize: "20rem" }}
        >
          <span>+</span> <span className="text-5xl">ADD Toll Plaza</span>
        </div>
      </div>

      <section
        className="track_my_goods"
        style={{ width: "73%", margin: "auto" }}
      >
        <div className="track_container">
          {/* main tracker section */}
          <section
            className="tracker_main_wrapper1"
            style={{ marginBottom: "3rem", width: "92%", margin: "auto" }}
          >
            <div className="tracker_main_container kam_gap">
              {iLogin ? (
                tollDatas.map((elm, ind) => {
                  console.log("elm:",elm);
                  return (
                    <div
                      className="tracker_row relative py-2"
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                    >
                      <div className="flex">
                        <div
                          className="relative"
                          style={{ display: "none" }}
                          id={`tollADD${ind}`}
                          onFocus={() => {
                            setShowInputDDL(true);
                          }}
                        >
                          <div
                            className="suggestion_tolls absolute bg-white max-h-[10rem] min-h-[0] overflow-auto w-[100%] p-3"
                            style={
                              showInputDDL
                                ? { display: "block", zIndex: "999" }
                                : { display: "none" }
                            }
                          >
                            {suggestData.map((elm) => {
                              return (
                                <div
                                  className="mt-5 cursor-pointer"
                                  onClick={(e) => {
                                    clickSuggestData(e, elm);
                                  }}
                                >
                                  {elm}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      {/* icon */}
                      <div
                        className={"w-[10%]"}
                        onClick={() => {
                          setShowInputDDL(false);
                        }}
                      >
                        {elm.color ? (
                          <span
                            class="material-symbols-outlined"
                            style={{ fontSize: "50px", color: elm.color }}
                          >
                            location_on
                          </span>
                        ) : (
                          <span
                            class="material-symbols-outlined"
                            style={{ fontSize: "50px" }}
                          >
                            location_on
                          </span>
                        )}
                      </div>

                      {/* plaza */}
                      <div
                        className="city1 w-[20%]"
                        onClick={() => {
                          setShowInputDDL(false);
                        }}
                      >
                        {/* <h1 style={{ fontWeight: "500" }}>{elm.findplza}</h1> */}
                        <h1 style={{ fontWeight: "500" }}>
                          {elm.match ? `${elm.findplza}*` : elm.findplza}
                        </h1>
                      </div>

                      <div
                        className="track_small_detail_wrapper"
                        style={{ width: "50%", overflow: "scroll" }}
                        onClick={() => {
                          setShowInputDDL(false);
                        }}
                      >
                        <div
                          className="track_small_detail"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            "align-items": "center",
                            width: "26rem",
                          }}
                        >
                          {/* checkin date */}
                          <div
                            className="checkinDate"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "400",
                                marginTop: "0.4rem",
                              }}
                            >
                              City
                            </span>

                            <b
                              style={{ fontSize: "0.9rem", fontWeight: "600" }}
                            >
                              {elm.city}
                            </b>
                          </div>

                          {/* checkin date */}
                          <div
                            className="checkinDate"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "400",
                                marginTop: "0.4rem",
                              }}
                            >
                              Date
                            </span>

                            <b
                              style={{ fontSize: "0.9rem", fontWeight: "600" }}
                            >
                              {elm.date ? elm.date.slice(0,10) : "-"}
                            </b>
                          </div>

                          {/* checkin time */}
                          <div
                            className="checkinTime"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "400",
                                marginTop: "0.4rem",
                              }}
                            >
                              State
                            </span>

                            <b
                              style={{ fontSize: "0.9rem", fontWeight: "600" }}
                            >
                              {elm.state}
                            </b>
                          </div>

                          {/* distance covered*/}
                          <div
                            className="timeLapse"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "400",
                                marginTop: "0.4rem",
                              }}
                            >
                              Distance Covered
                            </span>

                            <b
                              style={{ fontSize: "0.9rem", fontWeight: "600" }}
                            >
                              {elm.distance}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </section>
        </div>
      </section>

      {/* all toll plazas */}
      <section className="my-10 w-fit m-auto">
        <Button
          style={{
            color: "black",
            textDecoration: "underline",
            textTransform: "capitalize",
          }}
          onClick={(e) => {
            closeJourneyFromToll(e, AllObjectData._id);
          }}
        >
          Close Journey
        </Button>
        <a
          href={`https://www.google.com/maps/dir/${journeyPlan.source[0].source}/${journeyPlan.destination[0].destination}/`}
          target={"_blank"}
          style={{
            color: "black",
            textDecoration: "underline",
            fontSize: "0.875rem",
          }}
        >
          View on google map
        </a>
        <Button
          style={{
            color: "black",
            textDecoration: "underline",
            textTransform: "capitalize",
          }}
          onClick={() => {
            nav("/MyJourney");
          }}
        >
          Create another Journey
        </Button>
        <CSVLink
          style={{
            color: "black",
            textDecoration: "underline",
            fontSize: "0.875rem",
          }}
          data={latestCsv}
          className={"download_scv"}
          filename={`${AllObjectData.vehicle_no}`}
        >
          Download journey summary
        </CSVLink>
        <Button
          style={{
            color: "black",
            textDecoration: "underline",
            textTransform: "capitalize",
          }}
          onClick={() => {
            nav("/OngoingJourneys");
          }}
        >
          View all active journey
        </Button>
      </section>

      {/* sure you want to delete toll plaza */}
      <section
        className="plan_buy_confirmation absolute top-0 left-0 w-[100%] h-[100%]"
        style={
          confirmTollDeletePop
            ? { display: "flex", width: "100% !important" }
            : { display: "none" }
        }
      >
        <section className="plan_buy_confirmation__wrapper">
          <div className="plan_buy_confi__top flex justify-end items-center">
            <span
              className="material-symbols-outlined cursor-pointer"
              onClick={() => {
                setconfirmTollDeletePop(false);
              }}
            >
              close
            </span>
          </div>

          <div className="plan_buy_confi__message flex justify-between items-center mt-5">
            <div>
              <h1 className="text-lg">
                Sure you want to delete this toll from journey
              </h1>
            </div>
          </div>

          <div className="plan_buy_confi__button_group mt-5 flex">
            <Button
              onClick={() => {
                removeTollPlazeFunction();
              }}
              style={{
                background: "white",
                color: "black",
                boxShadow: "1px 1px 1px 1px black",
                marginLeft: "1rem",
              }}
            >
              Delete
            </Button>
          </div>
        </section>
      </section>

      <Footer />

      {/* input popup START */}
      <section
        className={
          showInputPop
            ? "input_popup_wrapper w-[100%] h-[100%] flex bg-white pb-5 justify-center items-start"
            : "input_popup_wrapper w-[100%] h-[100%] hidden justify-center items-start"
        }
        style={{ alignItems: "flex-start !important" }}
      >
        <div>
          <section className="input_popup_container  bg-white">
            <div
              className="flex justify-end pt-3 mr-3 cursor-pointer"
              onClick={() => {
                setShowInputPop(false);
              }}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
            <div className="border m-3">
              <input
                type="text"
                name="toll"
                className="border-none"
                placeholder="toll plaza name"
                style={{ outline: "0px solid black" }}
                onChange={(e) => {
                  addTollInput(e);
                }}
                autoComplete={"false"}
                value={tollInputData.toll}
              />
              <Button
                style={{
                  background: "#232323",
                  color: "white",
                  height: "100%",
                }}
                onClick={() => {
                  addTollFromBackend();
                }}
              >
                ADD
              </Button>
            </div>
            <div
              className={"mt-5"}
              style={{
                width: "100%",
                "max-height": "10rem",
                overflow: "auto",
                paddingLeft: "2rem",
              }}
            >
              {suggestData.map((elm) => {
                return (
                  <p
                    className="mt-3 cursor-pointer suggestion_boxing"
                    onClick={() => {
                      setTollInputData({ ...tollInputData, toll: elm });
                    }}
                  >
                    {elm}
                  </p>
                );
              })}
            </div>
          </section>
        </div>
      </section>
      {/* input popup END */}
    </div>
    </>}
    </>
  );
};

export default TollPlazaActive;
