import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: false,
}

export const isShowFeed = createSlice({
    name: 'isShowFeed',
    initialState,
    reducers: {
        setIsShowFeed: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIsShowFeed } = isShowFeed.actions

export default isShowFeed.reducer