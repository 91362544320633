import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisterSend, reSendOTP, otpvari, loginPost } from "../../apis/api";
import axios from "axios";
import ls from "localstorage-slim";
import MainHeading from "../../components/layouts/mainHeading/MainHeading";

const RegisterSingle = () => {
  const nav = useNavigate();
  const [isSendResendOtp, setIsSendResetOtp] = useState(true);

  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    conform_password: "",
    company_name: "",
    company_address: "",
  });

  const [otp, setOtp] = useState({
    otp: "",
  });

  const [showMobileInfo, setShowMobileInfo] = useState(false);
  const [showEmailInfo, setShowEmailInfo] = useState(false);
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);
  const [otpSection, setOTPSection] = useState(false);
  const [getIData, setGetIData] = useState("");

  function registerNow(e) {
    console.log("registerNow called");
    e.preventDefault();
    axios
      .post(RegisterSend, inputData)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setGetIData(res.data.data);
          console.log("res.data.data===" + JSON.stringify(res.data.data));
          ls.set("getIData", res.data.data, { encrypt: true });
          setOTPSection(true);
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.error);
      });
  }

  function changeOTP(e) {
    setOtp({ ...otp, [e.target.name]: e.target.value });
    console.log(otp);
  }

  // handleChange input...
  function handleChange(e, field) {
    setShowEmailInfo(false);
    if (field === "mobile") {
      setShowMobileInfo(true);
      setShowEmailInfo(false);
      if (e.target.value.length === 10 || e.target.value.length === 0) {
        setShowMobileInfo(false);
      } else {
        setShowMobileInfo(true);
      }
    }
    if (field === "email") {
      setShowEmailInfo(true);
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputData.email) ||
        e.target.value.length === 0
      ) {
        setShowEmailInfo(false);
      } else {
        setShowEmailInfo(true);
      }
    }
    if (field === "pass") {
      setShowPasswordInfo(true);
      if (e.target.value.length >= 8 || e.target.value.length === 0) {
        setShowPasswordInfo(false);
      } else {
        setShowPasswordInfo(true);
      }
    }

    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData);
  }

  // send otp to backend
  async function otpVarification() {
    try {
      const otpResponse = await axios.post(`${otpvari}/${getIData}`, otp);
      console.log("OTP Verification Response:", otpResponse);

      if (otpResponse.data.success) {
        console.log("Email>>>>>>>:", inputData.mobile);
        console.log("Password:", inputData.password);

        const loginResponse = await axios.post(loginPost, {
          email: inputData.mobile,
          password: inputData.password,
        });
        console.log("Login Response:", loginResponse);

        if (loginResponse.data.success) {
          // window.alert(loginResponse.data.message); 
          ls.set("login_data_object", loginResponse.data, { encrypt: true });
          ls.set("iLogin", true);
          nav("/");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      window.alert("An error occurred. Please try again.");
    }
  }

  // resend OTP
  async function resendOTP() {
    setIsSendResetOtp(false);

    setTimeout(function () {
      setIsSendResetOtp(true);
    }, 300000);

    await axios
      .post(`${reSendOTP}/${getIData}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="flex flex-col justify-center items-center h-[100vh]">
      <div
        autocomplete="off"
        className="profile_main_section w-[73.5%]  m-auto rounded-lg p-2 bg-white"
        style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
      >
        <div className="flex justify-between w-[55%] cjhdhgfuehfudjs">
          <button
            className="pt-2 ffgfgfgfgfgf ml-12"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => {
              nav(-1);
            }}
          >
            <span className="material-symbols-outlined">
              keyboard_backspace
            </span>{" "}
            <div className="flex justify-start flex-col items-start">
              <MainHeading
                ids={"hdfguoassj"}
                HeadinName="Sign up to LorryCare"
              />
              <span className="text-xs asdfghjhgf">Sign up for FREE</span>
            </div>
          </button>
          <img
            src="logogo.png"
            className="logoM"
            alt="logo_img"
            style={{ width: "6rem" }}
          />
        </div>
        <div className="container__profile_container w-[90%] m-auto h-[100%] py-2">
          {/* full name START*/}
          <div>
            <div className="inputItem flex flex-col mlsjsk">
              <label>
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                name="name"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                type={"text"}
                placeholder={"Ramlal Kumar"}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
          {/* full name END */}

          {/* Email and Mobile START */}
          <div className="second-Section flex  w-[100%] mt-2 justify-between">
            <div className="inputItem mlsjsk flex flex-col gap-2 w-[48%]">
              <label>
                Mobile Number <span className="text-red-500">*</span>
              </label>
              <input
                autocomplete="off"
                type="number"
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                placeholder="6350182509"
                name="mobile"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                onChange={(e) => {
                  handleChange(e, "mobile");
                }}
              />
              {showMobileInfo ? (
                <span className="info_message text-sm pl-1 text-red-500">
                  Please enter 10 digits
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="inputItem mlsjsk flex flex-col gap-2 w-[48%]">
              <label>Email</label>
              <input
                type="email"
                autoComplete="OFF"
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2 lowercase"
                placeholder="example@gmail.com"
                name="email"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                onChange={(e) => {
                  handleChange(e, "email");
                }}
              />
              {showEmailInfo ? (
                <span className="info_message text-sm pl-1 text-red-500">
                  Email must be valid
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* Email and Mobile END */}

          {/* password START */}
          <div className="second-Section flex  w-[100%] mt-2 justify-between">
            <div className="inputItem mlsjsk flex flex-col gap-2 w-[48%]">
              <label>
                Set Password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                placeholder="**********"
                name="password"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                onChange={(e) => {
                  handleChange(e, "pass");
                }}
              />
              {showPasswordInfo ? (
                <span className="info_message text-sm pl-1 text-red-500">
                  Password must have 8 digits minimum
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="inputItem mlsjsk flex flex-col gap-2 w-[48%]">
              <label>
                Confirm password <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                placeholder="**********"
                name="conform_password"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
          </div>
          {/* password END */}

          {/* company detail START */}
          <div className="second-Section mlsjsk flex flex-col mt-2 w-[100%] mhdiv">
            <div className="inputItem flex flex-col gap-2">
              <label>Company Name</label>
              <input
                type="text"
                name="company_name"
                className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                placeholder={"Enter your company name"}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            <div className="inputItem flex flex-col mt-2 mhdiv">
              <label>Company Address</label>
              <textarea
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder={"Enter your company address here"}
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                name="company_address"
                className=" border-none border-black rounded-md w-[100%] p-1 mt-2 pl-2"
                rows="2"
                cols="10"
                wrap="soft"
              ></textarea>
            </div>
            <div className="flex mt-5 items-center">
              <input type="checkbox" name="terms" id="terms" />
              <p className="ml-2 accept-terms">
                I accept{" "}
                <a href="/terms-and-conditions" className="underline">
                  terms of service
                </a>{" "}
                and{" "}
                <a href="/private-policy" className="underline">
                  privacy policy
                </a>
              </p>
            </div>
          </div>
          {/* company detail END */}

          <div className="flex justify-between items-center mt-5 mobile-register-buttons">
            <div>
              <Button
                style={{
                  background: "#232323",
                  color: "white",
                  fontFamily: "inter",
                  fontSize: "0.8rem",
                }}
                onClick={(e) => {
                  registerNow(e);
                }}
              >
                Register Now
              </Button>
            </div>

            <div className="for_lo">
              <Link
                to={"/forgetPassword"}
                className={"text-sm mr-3 text-blue-500"}
              >
                Forgot Password
              </Link>
              <Link to={"/login"} className={"text-sm text-blue-500"}>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          otpSection
            ? "profile_main_section w-[73.5%]  m-auto rounded-lg p-2 mt-1 bg-white"
            : "hidden"
        }
        style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
      >
        <div className="container__profile_container w-[90%] m-auto h-[100%] py-2">
          {/* OTP START*/}
          <div>
            <div className="inputItem flex flex-col">
              <label>OTP</label>
              <div
                className="border-none border-black rounded-md w-[100%]  flex justify-between items-center pl-2"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <input
                  className="border-none outline-none w-[78%]"
                  name="otp"
                  type={"text"}
                  placeholder={"Ex 7543"}
                  onChange={(e) => {
                    changeOTP(e);
                  }}
                  maxLength={4}
                />
                <div className="flex">
                  <Button
                    style={{
                      background: "#232323",
                      color: "white",
                      fontFamily: "inter",
                      fontSize: "0.8rem",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      otpVarification();
                    }}
                  >
                    Submit OTP
                  </Button>
                  {isSendResendOtp ?
                    <Button
                      style={{
                        background: "#232323",
                        color: "white",
                        fontFamily: "inter",
                        fontSize: "0.8rem",
                      }}
                      onClick={() => {
                        resendOTP();
                      }}
                    >
                      Resend OTP
                    </Button> :
                    <Button
                      style={{
                        background: "#232323",
                        color: "white",
                        fontFamily: "inter",
                        fontSize: "0.8rem",
                        cursor: "none", display: "none"
                      }}
                      onClick={() => {
                        console.log("click")
                      }}
                      disabled
                    >
                      Sending...
                    </Button>
                  }

                </div>
              </div>
            </div>
          </div>
          {/* OTP END */}
        </div>
      </div>
    </div>
  );
};

export default RegisterSingle;
