import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import TopSection from "../../TopSection";
import Button from "@mui/material/Button";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Chip, Divider, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../CommonTable";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Checkbox from "@mui/material/Checkbox";
import "./toll.css";
import moment from 'moment'
import Footer from "../../components/layouts/Footer/Footer";
import ls from "localstorage-slim";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { trackVehicle } from "../../apis/api";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    searchTollPlaza,
    AddTollPlaza,
    RemoveTollPlaza,
    closeJourney,
    preAlertCheck,
    postTripSummary,
} from "../../apis/api";
import { isShowFiveSec } from "../../redux/slices/five_sec_popup";
import { addTollData } from "../../redux/slices/journeyTollData";

const Mobile_tollplaza = ({ bigi, small, small2, small3, children, classes }) => {
    const nav = useNavigate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // add toll plaza and delete toll plaza dropdown
    const [addTollDDL, setAddTollDDL] = useState(false);
    const [removeTollDDL, setRemoveTollDDL] = useState(false);
    const [showInputPop, setShowInputPop] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [confirmTollDeletePop, setconfirmTollDeletePop] = useState(false);
    const [tollIndex, setTollIndex] = useState({
        "index": ""
    });
    const [tollInputData, setTollInputData] = useState({
        "index": "",
        "toll": ""
    })
    const [suggestData, setSuggestData] = useState([]);
    const [showInputDDL, setShowInputDDL] = useState(false);
    const [tollAddData, setTollAddData] = useState({
        "toll": ""
    });
    const [conditioner, setConditioner] = useState(true)
    
    // const [confirmTollDeletePop, setConfirmTollDeletePop] = useState(false);


    // toll planjourney form data get from localstorage
    const journeyPlan = ls.get("journeyPlan");
    const addButtonToggle = ls.get("addButtonToggle");
    const [showConfirmation, setShowConfirmation] = useState(false);
    
  const handleOpenInputPop = () => {
    setShowInputPop(true);
  };

  const handleCloseInputPop = () => {
    setShowInputPop(false);
  };
  const handleBackClick = () => {
    setShowConfirmation(true);
};

const handleConfirm = () => {
    navigate(-1); // User confirmed, navigate back
    setShowConfirmation(false);
};
const handleClose = () => {
    setShowConfirmation(false); // User canceled, stay on the same page
};



  
    // cancle on remove popup
    const closeDeletePopup = () => {
        setconfirmTollDeletePop(false);
    };

    // delete on remove popup
    const handleDeleteToll = () => {
        const ind = ls.get("ParametersOfRemoveTollPlaza");
        console.log(ind)
        setTollIndex({ ...tollIndex, "index": ind });
        // console.log(RemoveTollPlaza +" "+AllObjectData._id)
        // console.log(tollIndex)
        axios.put(`${RemoveTollPlaza}/${AllObjectData._id}`, { "index": ind }, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(res => {
            console.log(res.data.data.allPlaza)
            ls.set('journeyTollData', res.data.data, { encrypt: true })
            window.location.reload();
        }).catch(err => {
            console.log(err)
        })
    };
    // from localStorage Data START
    let tollMainData = useSelector((state) => state.journeyTollData.value);
    const iLogin = ls.get('iLogin');
    let ParametersOfRemoveTollPlaza;
    let AllObjectData, tollDatas, lastIndexOfTollPlaza;
    let login_response_object, token_user, user_id;
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;

        if (tollMainData) {
            AllObjectData = tollMainData;
            console.log("AllObjectData" + JSON.stringify(AllObjectData))
            tollDatas = AllObjectData.allPlaza;
            lastIndexOfTollPlaza = (tollDatas.length) - 1;
        } else {
            AllObjectData = ls.get('journeyTollData', { decrypt: true });
            console.log("AllObjectData" + JSON.stringify(AllObjectData))
            tollDatas = AllObjectData.allPlaza;
            lastIndexOfTollPlaza = (tollDatas.length) - 1;
        }
    }
    // from localStorage Data END

    // handleTollNameChange(e)
    function handleTollNameChange(e) {
        setTollAddData({ ...tollAddData, [e.target.name]: e.target.value })

    }

    // add first toll START
    // function addFirstToll() {
    //     console.log("fff")
    //     axios.put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
    //         headers: {
    //             Authorization: `Hello ${token_user}`
    //         }
    //     }).then(res => {
    //         console.log(res.data.data)
    //         ls.set('journeyTollData', res.data.data, { encrypt: true })
    //         // window.location.reload();
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }


    // clickSuggestData(e,elm)
    function clickSuggestData(e, elm) {
        setTollAddData({ ...tollAddData, "toll": elm })
        setShowInputDDL(false);
    }

    // ADD TOLL ALL FUNCTION START .....

    // addTollPlazeFunction(e,ind)
    function addTollPlazeFunction(e, ind, id) {
        window.scrollTo(0, 0)
        setShowInputPop(true)
        setTollInputData({ ...tollInputData, "index": ind })
        // console.log(tollInputData)
        console.log(id, "addTollPlazeFunction")
    }

    function addTollInput(e) {
        setTollInputData({ ...tollInputData, [e.target.name]: e.target.value });
        axios.get(`${searchTollPlaza}/${AllObjectData._id}?toll=${tollInputData.toll}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            setSuggestData(response.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    // closeJourneyFromToll(e)
    function closeJourneyFromToll(e, closeJourneyID) {
        console.log(AllObjectData)
        axios.put(`${closeJourney}/${closeJourneyID}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            if (response.data.success) {
                dispatch(isShowFiveSec(true));
                nav('/MyJourney')
                window.scrollTo(0, 0)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function addTollFromBackend() {
        axios.put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(res => {
            console.log(res.data.data.allPlaza)
            ls.set("addButtonToggle", false);
            ls.set('journeyTollData', res.data.data, { encrypt: true })
            window.location.reload();
        }).catch(err => {
            console.log(err)
        })
    }

    function removeTollPlazeFunction() {
        const ind = ls.get("ParametersOfRemoveTollPlaza");
        console.log(ind)
        setTollIndex({ ...tollIndex, "index": ind });
        // console.log(RemoveTollPlaza +" "+AllObjectData._id)
        // console.log(tollIndex)
        axios.put(`${RemoveTollPlaza}/${AllObjectData._id}`, { "index": ind }, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(res => {
            console.log(res.data.data.allPlaza)
            ls.set('journeyTollData', res.data.data, { encrypt: true })
            window.location.reload();
        }).catch(err => {
            console.log(err)
        })
    }

    function showTollDeleteConfirmationPopup(e, ind) {
        setconfirmTollDeletePop(true);
        window.scrollTo(0, 0)
        ls.set("ParametersOfRemoveTollPlaza", ind)
    }

    // ADD TOLL ALL FUNCTION END .....

    // post journey summary to backend..
    function postTripToBackend(e) {
        setIsLoader(true);
        console.log(e)
        axios.post(`${postTripSummary}/${AllObjectData._id}`).then(response => {
            if (response.data.success) {
                setIsLoader(false);
                window.alert("Congrats! This journey is posted. You can view this journey in active journey under journey tab")

                let currentTime = moment().format("LLLL");
                let currentTime2 = moment().format("DD-MM-YYYY");
                let obj = {
                    "name": "journeyCreated",
                    "message": "Your journey has been created succesfully",
                    "time": currentTime,
                    "forFilter": currentTime2
                }

                ls.set('journeyCreated', obj)
                nav('/OngoingJourneys')
                window.location.reload();
            }
        }).catch(err => {
            setIsLoader(false);
            console.log(err)
        })
    }

    // pre alert activate function 
    function preAlertActive(e) {
        if (e.target.checked) {
            axios.put(`${preAlertCheck}/${AllObjectData._id}`).then(response => {
                if (response.data.success) {
                    window.alert(response.data.message);

                    let currentTime = moment().format("LLLL");
                    let currentTime2 = moment().format("DD-MM-YYYY");
                    let obj = {
                        "name": "preAlertActive",
                        "message": response.data.message,
                        "time": currentTime,
                        "forFilter": currentTime2
                    }

                    ls.set('preAlertActive', obj)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            axios.put(`${preAlertCheck}/${AllObjectData._id}`).then(response => {
                if (response.data.success) {
                    window.alert(response.data.message);
                    let currentTime = moment().format("LLLL");
                    let currentTime2 = moment().format("DD-MM-YYYY");
                    let obj = {
                        "name": "preAlertActive",
                        "message": response.data.message,
                        "time": currentTime,
                        "forFilter": currentTime2
                    }

                    ls.set('preAlertActive', obj)
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    // addLastTollPlazaToList()
    function addLastTollPlazaToList(e, ind) {
        window.scrollTo(0, 0)
        setShowInputPop(true)
        setTollInputData({ ...tollInputData, "index": ind })
    }
    return (
        <section className="px-2">
            <div className="Navbar">
                <SecondNavBar />
            </div>
            {/* top */}
            <div className="flex justify-between items-center">
                {/* left start */}
                <section
                        className="track_top_section"
                        style={{
                            borderBottom: "1px solid gray",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "73.5%",
                            margin: "auto",
                        }}
                    >
                        {/* left */}
                        <div style={{ padding: "3px 0rem" }} className="flex top_sec_left">
                            <button onClick={() => setShowConfirmation(true)}>
                                <span className="material-symbols-outlined">
                                    keyboard_backspace
                                </span>
                            </button>
                            <div className="ml-5">
                                <h1 className="text-2xl font-Cardo font-bold my-2">Journey ID</h1>
                                <p style={{ fontSize: "0.9rem", marginLeft: "5px" }}>
                                    {small}
                                    {small2 ? <span> {small2} </span> : <></>}
                                    {small3}
                                </p>
                            </div>
                        </div>

                        {/* right */}
                        <div className={classes ? classes : "flex filter_div  top_sec_left"}>
                            {children}
                        </div>
                    </section>

                    {/* Confirmation Dialog */}
                    {/* {showConfirmation && (
                        <div className="confirmation-popup">
                            <div className="confirmation-popup-content">
                                <p>Are you sure you want to leave? Your changes may not be saved.</p>
                                <div className="confirmation-popup-buttons">
                                    <button onClick={() => setShowConfirmation(false)}>Cancel</button>
                                    <button onClick={handleBackClick}>OK</button>
                                </div>
                            </div>
                        </div>
                    )} */}
                    <Dialog
                        open={showConfirmation}
                        onClose={handleClose}
                        aria-labelledby="confirmation-dialog-title"
                        aria-describedby="confirmation-dialog-description"
                    >
                        <DialogTitle id="confirmation-dialog-title">
                            Are you sure you want to leave without Posting Your Journey?
                        </DialogTitle>
                        {/* <DialogContent>
                            <DialogContentText id="confirmation-dialog-description">
                                Your changes may not be saved.
                            </DialogContentText>
                        </DialogContent> */}
                        <DialogActions>
                            <Button onClick={handleClose} style={{color:'white',backgroundColor:'black',borderRadius:'4px'}}>
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} style={{color:'white',backgroundColor:'black',borderRadius:'4px'}}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                {/* <TopSection
                    bigi={"Journey ID"}
                    small={""}
                >
                </TopSection> */}
            </div>
            <Divider />
            <Chip
             style={{
                backgroundColor: 'black',
                color: 'white',
                fontSize:'1.5rem'
            }}
                label={AllObjectData.vehicle_no}
                className="mt-3"
                variant="outlined"
            />
            {/* toper */}
            <Paper
                style={{
                    marginTop: "0.5rem",
                    borderRadius: "0.2rem",
                    padding: "0.5rem",
                }}
                variant="outlined"
                square
            >
                <div className="flex justify-between flex-wrap">
                    <div>
                        <span
                            style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
                        >
                            Start Date
                        </span>
                        <p>{AllObjectData.start_date.slice(0, 10)}</p>
                    </div>

                    <div>
                        <span
                            style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
                        >
                            End Date
                        </span>
                        <p>{AllObjectData.end_date.slice(0, 10)}</p>
                    </div>

                    <div>
                        <span
                            style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
                        >
                            Total Distance
                        </span>
                        <p>{AllObjectData.total_distance}</p>
                    </div>
                </div>
            </Paper>
            <Paper
                style={{
                    marginTop: "0.5rem",
                    borderRadius: "0.2rem",
                    padding: "0.5rem",
                }}
                variant="outlined"
                square
            >
                <div className="flex justify-between flex-wrap">
                    <div>
                        <span
                            style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
                        >
                            From
                        </span>
                        <p>{AllObjectData.source[0].source}</p>
                    </div>
                    <div>
                        <span
                            style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
                        >
                            To
                        </span>
                        <p>{AllObjectData.destination[0].destination}</p>
                    </div>
                </div>
            </Paper>
            {/* divider */}
            <Divider style={{ marginTop: "0.4rem", marginBottom: "0.1rem" }} />{" "}
            {/* divider */}
            <Divider style={{ marginBottom: "0.3rem" }} />
            <h3>Toll List</h3>
            {/* main tracking list */}
            <section>
                {iLogin ? (
                    tollDatas.map((elm, ind) => {
                        return (

                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    "& > :not(style)": {
                                        m: 0.4,
                                        width: "100%",
                                    },
                                }}
                            >
                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                        paddingBottom: "0.5rem",
                                    }}
                                >
                                    {/* icon */}
                                    <div>
                                        <div onClick={() => { setShowInputDDL(false) }}>

                                            <span
                                                class="material-symbols-outlined"
                                                style={{ fontSize: "50px" }}
                                            >
                                                location_on
                                            </span>
                                        </div>
                                        {/* <Radio color="success" checked /> */}
                                        {/* <button className="text-sm
                                         mr-5 flex flex-col" onClick={(e) => { addTollPlazeFunction(e, ind, elm.id) }}>
                                            <span>ADD</span><span>+</span>
                                        </button>

                                       
                                        <button className="text-sm flex flex-col" onClick={(e) => { showTollDeleteConfirmationPopup(e, ind) }}>
                                            <span>Remove</span><b>-</b>
                                        </button> */}
                                    </div>
                                    <div style={{ "display": "none" }} id={`tollADD${ind}`} onFocus={() => { setShowInputDDL(true); }}>

                                        <div style={showInputDDL ? { "display": "block", "zIndex": "999" } : { "display": "none" }}>
                                            {suggestData.map(elm => {
                                                return <div onClick={(e) => { clickSuggestData(e, elm) }}>{elm}</div>
                                            })}
                                        </div>
                                    </div>
                                    {/* Location */}

                                    {/* main start */}
                                    <div style={{ width: "100%", paddingRight: "0.8rem" }}>
                                        <h1 className="mt-2" style={{ fontWeight: "bold" }}>
                                            {elm.match ? `${elm.findplza}*` : elm.findplza}
                                        </h1>
                                        <section>
                                            <div
                                                className="flex flex-wrap w-full"
                                                style={{ justifyContent: "space-between" }}
                                            >
                                                <div className="mt-2">
                                                    <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                                        City -
                                                    </b>
                                                    <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                                        {" "}
                                                        {elm.city}
                                                    </span>
                                                </div>
                                                <div className="mt-2">
                                                    <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                                        State -
                                                    </b>
                                                    <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                                        {" "}
                                                        {elm.state}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                className="flex flex-wrap w-full mt-2"
                                                style={{ justifyContent: "space-between" }}
                                            >

                                                <div className="mt-2">
                                                    <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                                                        Distance Covered -
                                                    </b>
                                                    <span style={{ color: "grey", fontSize: "0.9rem" }}>
                                                        {" "}
                                                        {elm.distance}
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    {/* main end */}
                                </Paper>
                                <div >
                                    {/* Add Button */}
                                    <button className="md-col-12" onClick={(e) => { addTollPlazeFunction(e, ind, elm.id) }} style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', marginRight: '10px', width: '120px', height: '30px' }}>
                                        <span>Add +</span>
                                        {suggestData.map((elm) => {
                                            return <p className="mt-3 cursor-pointer suggestion_boxing" onClick={() => { setTollInputData({ ...tollInputData, "toll": elm }) }}>{elm}</p>
                                        })}

                                    </button>

                                    {/* Remove Button */}
                                    <button className="md-col-12" onClick={(e) => { showTollDeleteConfirmationPopup(e, ind) }} style={{ backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '120px', height: '30px' }}>
                                        <span>Remove -</span>
                                    </button>
                                    {/* Add popup START */}
                                    {/* <section className={showInputPop ? "input_popup_wrapper w-[100%] h-[100%] flex bg-white pb-5 justify-center items-center" : "input_popup_wrapper w-[100%] h-[100%] hidden justify-center items-center"}>

                                        <div>
                                            <section className="input_popup_container  bg-white">
                                                <div className="flex justify-end pt-3 mr-3 cursor-pointer" onClick={() => { setShowInputPop(false) }}>
                                                    <span className="material-symbols-outlined">
                                                        close
                                                    </span>
                                                </div>
                                                <div className="border m-3">
                                                    <input type="text" name="toll" className="border-none" placeholder="toll plaza name" style={{ "outline": "0px solid black" }} onChange={(e) => { addTollInput(e) }} autoComplete={"false"} value={tollInputData.toll} />
                                                    <Button style={{ "background": "#232323", "color": "white", "height": "100%" }} onClick={() => { addTollFromBackend() }}>ADD</Button>
                                                </div>
                                                <div className={"mt-3"} style={{ "width": "100%", "max-height": "10rem", "overflow": "auto", "paddingLeft": "2rem" }}>
                                                    {suggestData.map((elm) => {
                                                        return <p className="mt-3 cursor-pointer suggestion_boxing" onClick={() => { setTollInputData({ ...tollInputData, "toll": elm }) }}>{elm}</p>
                                                    })}
                                                </div>
                                            </section>
                                        </div>

                                    </section> */}
                                    <Dialog open={showInputPop} onClose={handleCloseInputPop} maxWidth="xs">
                                        <DialogTitle>Do you want to add a toll plaza?</DialogTitle>
                                        <DialogContent>
                                            <input
                                                type="text"
                                                name="toll"
                                                placeholder="Toll plaza name"
                                                onChange={addTollInput}
                                                autoComplete="on"
                                                marginRight="10px"
                                            />
                                            <Button variant="contained" color="primary" onClick={addTollFromBackend} style={{backgroundColor:'black',marginTop:'5px',borderRadius:'10px'}}>
                                                ADD
                                            </Button>
                                            <div style={{ maxHeight: '10rem', overflow: 'auto', paddingLeft: '2rem', paddingRight: '2rem' }}>
                                                {suggestData.map((elm, index) => (
                                                    <p
                                                        key={index}
                                                        className="mt-3 cursor-pointer suggestion_boxing"
                                                        onClick={() => { setTollInputData({ ...tollInputData, "toll": elm }) }}
                                                    >
                                                        {elm}
                                                    </p>
                                                ))}
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                    {/* Add popup END */}

                                    {/* Remove Popup Start */}
                                    {/* <section className='plan_buy_confirmation absolute top-0 left-0 w-[100%] h-[100%]' style={confirmTollDeletePop ? { "display": "flex", "width": "200% !important" } : { "display": "none" }}>
                                        <section className="plan_buy_confirmation__wrapper">

                                            <div className="plan_buy_confi__top flex justify-end items-center">
                                                <span className="material-symbols-outlined cursor-pointer" onClick={() => { setconfirmTollDeletePop(false) }}>
                                                    close
                                                </span>
                                            </div>

                                            <div>
                                                <div>
                                                    <h1 className='text-lg'>Sure you want to delete this toll from journey</h1>
                                                </div>
                                            </div>

                                            <div className='plan_buy_confi__button_group mt-5 flex'>
                                                <Button onClick={() => { removeTollPlazeFunction() }} style={{ "background": "white", "color": "black", "boxShadow": "1px 1px 1px 1px black", "marginLeft": "1rem" }} >Delete</Button>
                                            </div>

                                        </section>
                                    </section> */}
                                    <Dialog
                                        open={confirmTollDeletePop}
                                        onClose={closeDeletePopup}
                                        fullWidth={true}
                                        maxWidth="sm"
                                        PaperProps={{
                                            style: {
                                                backgroundColor: 'white', // Background color
                                                opacity: 0.4, // Opacity (0-1) for the background
                                                color: 'black', // Text color
                                            },
                                        }}
                                    >
                                        <DialogTitle>Do you want to delete this toll from journey?</DialogTitle>
                                        <DialogContent>
                                            {/* <DialogContentText>
                                                This action cannot be undone.
                                            </DialogContentText> */}
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: 'flex-start' }} >
                                            <Button onClick={closeDeletePopup} color="primary" style={{ color: 'white', backgroundColor: 'black', borderRadius: '10px' }}>
                                                Cancel
                                            </Button>
                                            <Button onClick={handleDeleteToll} color="primary" style={{ color: 'white', backgroundColor: 'black', borderRadius: '10px' }}>
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    {/* Remove Popup End */}

                                </div>
                            </Box>

                        );
                    })
                ) : (
                    <></>
                )}
            </section>
            {/* Post button Start */}
            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                <Button onClick={(e) => { postTripToBackend(e) }} style={{ background: "#232323", color: "white", width: "50%", textAlign: "center" }} className="post_trip_button">Post</Button><br />
                <span className="text-sm">Journey will get created after posting</span>
            </section>

            {/* Post button End */}
        </section>
    );
};

export default Mobile_tollplaza;
