import React, { useState } from 'react'
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import ls from 'localstorage-slim'
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/layouts/Footer/Footer'

const RefundRequest = () => {

    const nav = useNavigate();
    const [isSelect, setIsSelect] = useState(false);

    function choosePlan(e) {
        ls.set('choosePlanInRefund', e.target.value, { encrypt: true });
        setIsSelect(true);
        if (e.target.value == "false") {
            setIsSelect(false);
        }
    }

    // ok function...
    function okFunction() {
        if (isSelect) {
            nav('/refund-request3')
        } else {
            window.alert("please choose plan");
        }
    }

    return (
        <>

            {/* navbar START*/}
            <SecondNavBar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Get Your Refund"}
                small={"lorem lorem lorem jhfge xuhfd gf"}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* about us Main Card START */}
            <Card css={{ "width": "53.5%", "margin": "2rem auto", "paddingTop": "1.6rem", "paddingBottom": "1rem" }}>

                <HeadingWithText head={"Raise Refund Request"} />

                <div className='px-8 pt-3'>

                    <p className='font-Cardo'>Choose your plan in Monthly Subscription</p>

                    {/* plan select box */}
                    <select className='rounded my-3 pl-3' onChange={(e) => { choosePlan(e) }} style={{ "width": "100%", "border": "1px solid #232323" }}>
                        <option defaultChecked value={"false"}>Choose Your Plan</option>
                        <option value="plan1">Plan1</option>
                        <option value="plan2">Plan2</option>
                    </select>

                    <div className='mt-3 flex items-center'>
                        <button className='flex  items-center text-white px-6 rounded shadow-md py-1' style={{ "background": "#262626" }} onClick={() => { okFunction() }}><p>ok</p><span className="material-symbols-outlined ml-1">
                            done
                        </span></button>
                        <div className='ml-5 cursor-pointer flex items-center'><p>Press Enter</p> <span className="material-symbols-outlined ml-2 border border-black rounded-full" style={{ "fontSize": "0.7rem" }}>
                            arrow_forward
                        </span></div>
                    </div>

                    {/* <div className='my-6 flex items-center justify-end'>
                        <button className='flex  items-center text-white px-1 shadow-md py-1' style={{ "background": "#262626", "borderTopLeftRadius": "0.2rem", "borderBottomLeftRadius": "0.2rem" }}><span className="material-symbols-outlined ml-1">
                            expand_more
                        </span></button>
                        <button className='flex  items-center text-white px-1 shadow-md py-1' style={{ "background": "#262626", "marginLeft": "1px", "borderTopRightRadius": "0.2rem", "borderBottomRightRadius": "0.2rem" }}><span className="material-symbols-outlined ml-1">
                            expand_less
                        </span></button>
                    </div> */}

                </div>

            </Card>
            {/* about us Main Card END */}

            {/* bottomer */}
            <div className='flex justify-center' onClick={() => { nav('/contact-us') }}>
                <div className='rounded-tl-lg cursor-pointer rounded-tr-lg flex justify-center items-center' style={{ "width": "53.5%", "margin": "auto", "height": "7rem", "background": "#262626" }}>
                    <p className='requst_bottom_para flex justify-center items-center'><span>Contact us</span> <span className="material-symbols-outlined ml-5">
                        arrow_forward
                    </span></p>
                </div>
            </div>

            <Footer />

        </>
    );
}

export default RefundRequest