import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import Footer from "../../components/layouts/Footer/Footer";

const TermsAndCondition = () => {
  return (
    <>
      {/* navbar START*/}
      <SecondNavBar translationHide={"true"} />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection bigi={"Terms of Service"} small={""}></TopSection>
      {/* top section Component END*/}

      {/* about us Main Card START */}
      <div className="my-5">
        <Card>
          <HeadingWithText
            // head={"Effective 1 April, 2023"}
            desc={[
              "Thank you for accessing/shopping at www.lorrycare.com This site is owned by ONEWHISTLE MARKETPLACE PVT.LTD.(hereinafter referred to as “www.lorrycare.com”). By accessing, shopping on this site, you indicate your unconditional acceptance of these terms & conditions. We reserve this right, in our sole discretion, to update or revise these terms & conditions. Continued use of the site following the posting of any changes to the ‘terms & conditions’, constitutes your acceptance of those changes. At “www.lorrycare.com”, we try our best to create a space where you can explore and shop for all your favorite things in a safe and secure environment. All products and information displayed on “www.lorrycare.com” constitutes an invitation to offer. “www.lorrycare.com” reserves the right to accept or reject your offer. Your order for purchase, constitutes your offer which shall be subject to the terms and conditions as listed below.",
            ]}
          />

          <HeadingWithText
            head={"Eligibility to use our site"}
            desc={[
              "Use of the Site is available only to persons who can legally enter into contracts under applicable laws. Persons who are incompetent to contract, within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Site. “www.lorrycare.com” reserves the right to terminate your access to the Site if it discovers that you are under the age of 18 years or suffers from any other disability, as recognized under Indian Contract Act, 1872.",
            ]}
          />

          <HeadingWithText
            head={"Membership"}
            desc={[
              "Although it's not essential to have an account to shop with “www.lorrycare.com”, you can shop as a guest. As a member, you agree to provide true, accurate, current, and complete information about yourself as prompted by the site's registration form. Registration where prohibited under any law shall be void. “www.lorrycare.com” reserves the right to revoke or terminate your registration for any reason at any time, without notice.",
            ]}
          />
           <HeadingWithText
            head={"Electronic Communications"}
            desc={[
              "When you use the site or send emails or other data, information or communicate to us, you agree and understand that you are communicating with us electronically and give your consent to receive communications electronically from us periodically, when required.",
            ]}
          />
           <HeadingWithText
            head={"Reviews, Feedback, Submissions"}
            desc={[
              "All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered to “www.lorrycare.com” directly or otherwise disclosed, submitted or offered in connection with your use of this Site (collectively referred to Comments) will remain “www.lorrycare.com” property. Such disclosure, submission or offer of any comments shall constitute an assignment to “www.lorrycare.com” of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the comments,thus, it exclusively owns all such rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise. “www.lorrycare.com” will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and distribute any comments you submit for any purpose whatsoever, without restriction and without compensating you in any way. “www.lorrycare.com” is and shall be under no obligation (1) to maintain any Comments in confidence; or (2) to pay you any compensation for any Comments; or (3) to respond to any Comments. You agree that any comments submitted by you to the Site will not violate this policy or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no comments submitted by you to the site will be libelous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any form of spam.",
              "“www.lorrycare.com” does not regularly review posted comments, but does reserve the right (but not the obligation) to monitor and edit or remove any comment submitted to the Site. You grant “www.lorrycare.com” the right to use the name that you submit in connection with any of the posted comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are and shall remain solely responsible for the content of any comments you make and you agree to indemnify “www.lorrycare.com” and its affiliates for all claims resulting from any Comments you submit, we take no responsibility and assume no liability for any comments submitted by you or any third party.",

            ]}
          />
           <HeadingWithText
            head={"Accuracy of Content/ Information of Products on the Web Site"}
            desc={[
              "While “www.lorrycare.com” strives to provide accurate product and pricing information, typographical errors may occur. In the event that a product is listed at an incorrect price or with incorrect information due to an error in pricing or product information, “www.lorrycare.com” shall have the right, on our sole discretion, to modify the price of the products, or information of the products or to refuse or cancel any orders placed for that product, unless the product has already been dispatched. In the event.",
            ]}
          />
          {/* <HeadingWithText
            head={"Effective 1 April, 2023"}
            desc={[
              "Before using lorrycare.com, we request you read the terms and conditions of the agreement below. By using the website, you are implying that you agree with all the policies of lorrycare.com Once you become a member of lorrycare.com, you will automatically enter into a contract and acknowledge that you have read and accepted the agreement, which will apply to your use of our website. www.lorrycare.com",
              "The agreement between the user and this website is subject to change at any time without prior notice. We may modify or change the terms and conditions and the new agreement shall be in effect as soon as it is updated on the website. We recommend you read the document carefully every time you use the website.",
              "If you do not agree with the contract, you may not access or use the website.",
              "Lorrycare.com provides a platform for its users to search for and track a vehicle for their business needs. We do not participate in any way in the actual search and or tracking or journey sharing that takes place. Hence, lorrycare.com is not responsible for any dispute or fallout that may occur between the concerned vehicle, its owner, driver, and the user or any stakeholder at any point in time.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"General Terms"}
            desc={[
              "The content of the pages of this website is for your general information and use only. It is subject to change without notice.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Disclaimer and Warranties"}
            desc={[
              "Neither we nor any third party provides any warranty or guarantee to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and material(s) may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. Lorrycare.com accepts no responsibility for checking and verifying the accuracy of reports or data files submitted by ULIP, visitors, or customers, who are searching, tracking, hiring, or renting a vehicle(s), movable or immovable assets, or any related properties through lorrycare.com. Users must verify details of the vehicle or asset which is being or to be tracked as well as its rightful owner, driver, etc on their own. Your use of any information or materials on this website is entirely at your own risk. It shall be your responsibility to ensure that any products, services, or information available through this website meet your specific requirements. Lorrycare.com is not involved in any transaction between any parties who are using our website. There are risks, that the Visitor/Customer assumes when dealing with people and the same shall be borne by the Visitor/Customer. The website is a platform only and does not screen or/and censor or/and otherwise control the vehicles or its related searches, tracking’s, or datasets from ULIP offered to other Visitors, nor does lorrycare.com screen or/and censor or/and otherwise control the Visitors of its service. Lorrycare.com cannot and does not control the behavior of the participants on this site and is in no way liable or responsible for their actions or inactions.",
              "It is extremely important that the user takes care throughout his dealings with other people or users on or off this site, datasets, search and or track and related results. Lorrycare.com does not accept or/and assumes responsibility for the data, content, or/and context displayed on the site. The Visitor/Customer understands and agrees that any data, search, information or/and material and/or goods or/and services obtained through this website are done at the Visitor's/Customer's discretion and risk and that the Visitor/Customer shall be solely responsible for any damage/s or/and cost or/and any other consequence/s resulting from any transaction. No advice or information, oral or/and written, obtained by the Visitors/Customers from lorrycare.com or/and through or/and from the service shall be construed as recommendations and shall create any warranty not expressly stated herein. We, as a portal, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed upon by us with our acquiring bank from time to time.",
              "TO THE FULLEST EXTENT POSSIBLE BY LAW, IN NO EVENT SHALL LORRYCARE.COM, ITS SUPPLIERS, ULIP, OR ANY THIRD PARTIES MENTIONED ON LORRYCARE.COM BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA, LOST EMPLOYMENT OPPORTUNITY OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE ENTIRE OR ANY PART OF LORRYCARE.COM AND THE LORRYCARE.COM CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT LORRYCARE.COM IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Software"}
            desc={[
              "Any software made available to operate this Site and/or to download materials from it (the 'Software') is the copyrighted work of lorrycare.com and/or its associates. You are authorized to use this Software on a non-exclusive basis solely to visit the Site and/or download materials from it. You may use this Software only for this purpose. Under no circumstances, you shall redistribute, sell, disassemble, or otherwise deal with the Software.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Limitation of Liability"}
            desc={[
              "You assume all the responsibilities and risks for the use of this site and the Internet in general. Under no circumstances, shall lorrycare.com or its affiliates be liable for any direct, special, indirect, or consequential damages or any damages whatsoever, including but not limited to loss of use, data, or profits, without regard to the form of any action, including but not limited to contract, negligence, or other tort actions, out of or in connection with the use, copying, or display of the content resulting from access to or use of this site, or the internet generally, under contract, tort or any other cause of action or legal theory.",
              "Lorrycare.com believes the entire content to be accurate, complete, and current, to the best of its knowledge. Under no circumstances, shall lorrycare.com make any warranty as to the accuracy, completeness, or currency of the content. As such, it remains your sole responsibility to verify any information before relying on it.",
              "However, lorrycare.com agrees that the content of this site may include technical inaccuracies or typographical errors. From time to time, changes are made to the content herein.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Termination"}
            desc={[
              "Most contents and some of the features on the website are made available to visitors free of cost. However, we reserve the right to terminate access to certain areas or features of the website (to paying or registered users) at any time for any reason, with or without notice. We also reserve the universal right to deny access to particular users to any/all of its services/content without any prior notice/explanation to protect the interests of the website and/or other visitors to the website. We have the right to limit, deny or create different access to the website and its features concerning different users, or to change any of the features or introduce new features without prior notice.",
              "We have the right to temporarily or permanently terminate the membership of any user for any of the following reasons:",
              "1.If it concludes that the users have provided any false information in connection with the member account, or are engaged in fraudulent or illegal activities.The user breaches any provisions of the terms and conditions of the user agreement and/or Agreement of web site.",
              "2.Utilize web site to send spam messages or repeatedly publish the same product information.Impersonate or unlawfully use another business's name to post information or conduct the business of any form. Any unauthorized access, use, modification, or control of the website database, network, or related services.Track/trace / share any vehicular data immorally / illegally / for fraudulent purposes Obtain by any means, website members, user name, and/or password.If lorrycare.com terminates a user's membership, he/she will not have the right to re-enroll or join the website under a new account or name unless formally invited to do so. In any case of termination, no membership charges will be refunded. Users acknowledge that the inability to use the website wholly or partially for whatever reason may harm its business. Users, hereby, agree that in no event the website shall be liable to the users or any third parties for any inability to use the website (whether due to disruption, limited access, changes to or termination of any features on the website or otherwise), any delays, errors or omissions for any communication or transmission, or any damage (direct indirect, consequential or otherwise) arising from the use of or inability to use the web site or any of its features.",
            ]}
          /> */}
          {/* 
          <HeadingWithText
            head={"User Eligibility"}
            desc={[
              "By user, lorrycare.com implies any individual or business entity/organization that legally operates in India or other countries. Our services are available only to those individuals or companies who can form legally binding contracts under the applicable law. Therefore, all users of lorrycare.com must not be a minor as per Indian Law. i.e. user(s) must be at least 18 years of age to be eligible to use our services.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"Registered Users"}
            desc={[
              "To become a Registered User with our website, there is a proper procedure defined by us so that they can easily log in and log out. An individual can become a registered user by filling out an online registration form on the website by giving desired information (name, contact information, details of its business, etc.). We will establish an account ('Account') for the user(s) upon registration and assign a user alias ('User ID') and password ('Password') for log-in access to your Account. The website may or may not assign a user upon registration a web-based email/message account with limited storage space to send or receive email/messages. Users will be responsible for the content of all the messages communicated through the Email Account, as well as the consequences of any such message.",
              "By registering on lorrycare.com Website and providing your email/mobile number, you expressly agree to receive promotional content from lorrycare, by mail/email or SMS/WhatsApp, from time to time. If you provide lorrycare with your phone number (at any stage of use of the Services) lorrycare shall be entitled to call you or send you promotional messages/notifications/notices from time to time. If you wish not to receive such promotional content/notices, you may notify lorrycare at any time.",
              "If you register on the behalf of a business entity, you represent that business entity and",
              "You have the authority to bind the entity to terms and conditions of use and/or this Agreement.",
              "The address you use when registering is the principal place of business of such a business entity.",
              "All other information submitted to lorrycare.com during the registration process is true, accurate, current, and complete. For purposes of this provision, a branch or representative office will not be considered a separate entity and its principal place of business will be deemed to be that of its head office.",
              "No Resale or Unauthorized Commercial Use",
              "You agree not to resell or assign your rights or obligations under these Terms. You also agree not to make any unauthorized commercial use of lorrycare.com.",
              "Posting Content on www.lorrycare.com",
              "Some of the content displayed on the website is provided or posted by third parties. Users can post their content on some of the sections/services of the website using the self-help, submit, and edit tools provided in the respective section. Users may need to register and/or pay for some of these services. lorrycare.com in such a case is not the author. The content here is contributed by the anonymous, registered, or paid user. Neither our website nor any of its affiliates, directors, officers, or employees has entered into a sale agency relationship with such a third party by our display of the Third-Party Content on the website. Any Third-Party content is the sole responsibility of the party who has provided the content. We are not responsible for the accuracy, propriety, lawfulness, or truthfulness of any Third-Party Content, and shall not be liable to any user in connection with his/her reliance on such Third-Party content. In addition, we are not responsible for the conduct of the user's activities on the website and shall not be liable to any person in connection with any damage suffered by any person as a result of any such user's conduct.",
              "Information posted on www.lorrycare.com by users will not:",
              "Contain fraudulent information or make fraudulent offers of items or involve the sale or attempted sale of counterfeit or stolen items or items whose sales and/or marketing is prohibited by applicable law, or otherwise promote other illegal activities.",
              "Be part of a scheme to defraud other users of the website or for any other unlawful purpose.",
              "Relate to the sale of products or services that infringe or otherwise abet or encourage the infringement or violation of any third party's copyright, patent, trademarks, trade secret, or other proprietary right or rights of publicity or privacy, or any other Third Party Rights.",
              "Violate any applicable law, statute, ordinance, or regulation (including without limitation those governing export control, consumer protection, unfair competition, anti-discrimination, or false advertising.)",
              "Be defamatory, libelous, unlawfully threatening or harassing",
              "• Contain any material that constitutes unauthorized advertising or harassment (including but not limited to spamming), invades anyone's privacy, or encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any law or regulation. • Contain any computer viruses or other destructive devices and codes that have the effect of damaging, interfering with, intercepting, or expropriating any software or hardware system, data, or personal information.",
              "Link directly or indirectly to or include descriptions of goods or services that are prohibited under the prevailing law.",
            ]}
          /> */}
        </Card>
      </div>
      {/* about us Main Card END */}

      <Footer />
    </>
  );
};

export default TermsAndCondition;
