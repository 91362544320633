import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import Filter3 from "../../components/layouts/Filter/Filter3";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useSelector, useDispatch } from 'react-redux'
import { bulkUploadEway, searchHistoryGet } from "../../apis/api";
import axios from 'axios'
import { isMobile } from "react-device-detect";
import ls from 'localstorage-slim'
import Footer from '../../components/layouts/Footer/Footer'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography, Button
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import csvtojson from 'csvtojson';
import { addewayTrackData } from "../../redux/slices/ewayTrackData";
import { useParams, useNavigate } from 'react-router-dom';
import { EWayBillNumber_Heading_Hn, EWayBillNumber_Subheading_Hn, SEARCH_TRACK_table_message_Hn } from '../../components/translation/Hn'
import { EWayBillNumber_Heading_En, EWayBillNumber_Subheading_En, SEARCH_TRACK_table_message_En } from '../../components/translation/En'
import { trackEway } from "../../apis/api";
// import MobileSearchHistory from "./MobileHistory";

const MobileEwayBill = () => {
    const eng = useSelector((state) => state.translate.value);
    let [apiData, setApiData] = useState([]);
    const iLogin = ls.get('iLogin');
    const [csvFile, setCSVFile] = useState(null);
    const [count, setCount] = useState(0);
    const [bulkUploadIds, setbulkUploadIds] = useState([]);
    const { bulkuploadId } = useParams();
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState();
    const nav = useNavigate();
    let login_response_object, token_user = " ", user_id = " ";
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }

    useEffect(() => {
        // Make sure token_user has a valid value
        // if (!token_user) {
        //   console.error('Invalid token_user value');
        //   return;
        // }

        axios.get(
            bulkUploadEway,
            {
                headers: {
                    Authorization: `Hello ${token_user}`
                },
                timeout: 15000 // Timeout set to 15 seconds
            }
        ).then(response => {
            console.log("API Response useEffect:", response);
            if (response.data) {
                setApiData(response.data);
            }
        }).catch(err => {
            console.log(err);
        });
    }, [count, token_user]);




    console.log("apiData:", apiData);

    let tableData = Array.isArray(apiData.data) ? [...apiData.data].reverse() : [];

    const handleDownload = () => {
        // Specify the path to your sample CSV file
        const filePath = `${process.env.PUBLIC_URL}/demo_EWay.csv`;;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'demo_EWay.csv';

        // Append the anchor to the body
        document.body.appendChild(link);

        // Trigger a click on the anchor to start the download
        link.click();

        // Remove the anchor from the body
        document.body.removeChild(link);
    };

    // CSV file Upload Start

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCSVFile(file);
    };

    const handleUpload = async () => {
        try {
            if (!csvFile) {
                console.error('Please select a CSV file.');
                alert('Please select a CSV file.Click on Upload CSV');
                return;
            }

            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    const csvContent = event.target.result;

                    // Convert CSV to JSON using csvtojson
                    const jsonArray = await csvtojson().fromString(csvContent);

                    // Log or use the JSON data as needed
                    console.log('JSON data:', jsonArray);

                    // Assuming token_user is available in your component's state
                    const headers = {
                        Authorization: `WeWork ${token_user}`,
                    };

                    // Make an API request to upload the JSON data
                    const response = await axios.post(
                        bulkUploadEway,
                        { data: jsonArray },
                        { headers, timeout: 15000 } // Timeout set to 15 seconds
                    );

                    console.log('API Response:', response.data);

                    // Check if the API call was successful
                    if (response.status === 200) {
                        alert('File Uploaded SUccessfully.');

                        // Extract all bulkUploadId values from the API response
                        // const bulkUploadIds = response.data.message.map(item => item.bulkuploadId);

                        // console.log('Extracted bulkUploadIds:', bulkUploadIds);
                        // setbulkUploadIds(bulkUploadIds)

                        // // Fetch data for each bulkUploadId
                        // for (const bulkUploadId of bulkUploadIds) {
                        //   const fetchDataResponse = await axios.get(
                        //     `http://192.168.1.69:8000/submit/get-csv/${bulkUploadId}`,
                        //     {
                        //       headers: {
                        //         Authorization: `Hello ${token_user}`,
                        //       },
                        //     }
                        //   );

                        //   console.log(`Fetched data for bulkUploadId ${bulkUploadId}:`, fetchDataResponse.data);

                        //   // Proceed with handling the fetched data or perform any other actions
                        //   if (fetchDataResponse.data) {
                        //     // Handle the data, for example, store it in state
                        //     setApiData(prevData => [...prevData, fetchDataResponse.data]);
                        //   }
                        // }

                    } else {
                        alert('Error uploading CSV data. Please check the format or try again.');
                    }

                } catch (error) {
                    console.error('Error converting CSV to JSON:', error);
                    alert('Error uploading CSV data. Please check the format or try again.');
                }
            };

            reader.readAsText(csvFile);
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
        }
    };




    // CSV file Upload End

    // csvData
    const csvData = tableData;
    let latestCsv = [];

    // tableData.forEach(elm => {
    //   console.log("elm16june====")
    //   console.log(elm)
    //   latestCsv.push({ Tracking_Number: elm.tracking_no, Vehicle_Number: elm.vehicle_no, 'Date(YYYY-MM-DD)': elm.request_time.slice(0, 10), Time: elm.request_time.slice(11, 16) })
    // })
    let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
    let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", { decrypt: true })
  
    if (trackVehicleData == '0') {
        trackVehicleData = trackVehicleDatafromLocalStorage;
    }
    function clickTrackFunctionEway(eWay_no) {
        if (!iLogin) {
            window.alert("Please register and sign in to view results");
        } else {
            const login_response_object = ls.get("login_data_object", {
                decrypt: true,
            });
            const token_user = login_response_object.token;
            console.log(token_user, "token_user")
            const user_id = login_response_object.user._id;
            let vehicleSearchId = trackVehicleData._id;
            const apiKey = "Jainish";

            // Set headers with the API key
            const headers = {
                Authorization: `Hello ${token_user}`,
                apiKey,
            };

            setIsLoader(false);
            ls.set("eWay_no", eWay_no, { encrypt: false });

            const stateSuggestionDataLocal = ls.get("stateSuggestionData");
            stateSuggestionDataLocal.push(eWay_no);
            ls.set("stateSuggestionData", stateSuggestionDataLocal);
            axios
                .get(
                    `${trackEway}/${eWay_no}`,
                    // Pass headers as the second parameter
                    { headers: headers }
                )
                .then(function (response) {
                    if (response.data.success === true) {
                        console.log("response--" + JSON.stringify(response.data.data[0].response));
                        dispatch(addewayTrackData(response.data.data[0].response));
                        console.log("lines...");
                        console.log(response.data.data[0].response, "dfghjk");
                        ls.set("trackEwayData", response.data.data[0].response, {
                            encrypt: true,
                        });
                        const vehicleList = response.data.data[0].response.VehiclListDetails;

                        // Check if the array is not empty
                        if (vehicleList.length > 0) {
                            // Get the last element of the array
                            const lastVehicle = vehicleList[vehicleList.length - 1];

                            // Access the vehicleNo property from the last element
                            const lastVehicleNo = lastVehicle.vehicleNo;

                            // Now you can use lastVehicleNo as needed
                            console.log("Last Vehicle No:", lastVehicleNo);

                            // If you want to store it using ls.set, you can use your existing code
                            ls.set("eway_latest_vehicle", lastVehicleNo, { encrypt: false });
                        } else {
                            console.log("The array is empty");
                        }

                        // ls.set("eway_latest_vehicle", response.data.data[0].response.VehiclListDetails, { encrypt: false })
                        setIsLoader(false);
                        nav("/when-click-track-EWay");
                    }
                    console.log(response, "response ewayyy");
                })
                .catch(function (error) {
                    // Handle error
                    console.error(error, "errorWway");
                });
        }
    }

    return (


        <>
            <div className="">
                <SecondNavBar active1={true} />
            </div>
            {/* top section Component */}
            <TopSection
                bigi={eng ? EWayBillNumber_Heading_En : EWayBillNumber_Heading_Hn}
                small={eng ? EWayBillNumber_Subheading_En : EWayBillNumber_Subheading_Hn}
            >

                <label style={{ marginLeft: '2rem', marginTop: '1.5rem' }}>
                    Choose a CSV file:
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".csv"
                        style={{ marginRight: '1rem', display: 'none', }}
                        ref={(input) => input && (input.value = null)} // Reset the input value for the same file selection
                    />
                </label>
                <button
                    type="button"
                    style={{
                        textDecoration: "none",
                        display: "inline-block",
                        background: "black",
                        color: "white",
                        borderRadius: "0.3rem",
                        padding: "0.4rem 2rem",
                        border: "none",
                        cursor: "pointer",
                        marginLeft: "2rem",
                        marginBottom: '1rem',
                        marginRight: '2rem',
                    }}
                    onClick={() => document.querySelector('input[type="file"]').click()}>
                    Upload CSV
                </button>
                {csvFile && <p style={{ marginTop: '10px' }}>Selected File: {csvFile.name}</p>}
                <button
                    type="button"
                    style={{
                        textDecoration: "none",
                        display: "inline-block",
                        background: "black",
                        color: "white",
                        borderRadius: "0.3rem",
                        padding: "0.4rem 2rem",
                        border: "none",
                        cursor: "pointer",
                        marginLeft: "2rem",
                        marginBottom: '1rem',
                        marginRight: '2rem',
                    }}
                    onClick={handleUpload}>
                    Process Uploaded CSV
                </button>


                {/* Download Sample CSV */}
                <button
                    style={{
                        textDecoration: "none",
                        display: "inline-block",
                        background: "black",
                        color: "white",
                        borderRadius: "0.3rem",
                        padding: "0.4rem 2rem",
                        border: "none",
                        cursor: "pointer",
                        marginLeft: "2rem",
                        marginBottom: '1rem',
                        marginRight: '2rem',
                    }}
                    onClick={handleDownload}
                >
                    Download Sample CSV
                </button>

                {/* Sort and Filter */}
                {/* <Filter3 tableData={tableData} /> */}

            </TopSection>
            {/* top section Component END*/}

            {/* common table START */}
            {/* <CommonTable
                type={"9"}
                tableHead={[
                    "EWayBill Number",
                    "Date",
                    "Time",
                    ""
                ]}
                tableData={Array.isArray(apiData.data) ? apiData.data : []}

                extraCss={'tracker_main_container control_gap'}
                defaultMessage={eng ? SEARCH_TRACK_table_message_En : SEARCH_TRACK_table_message_Hn}
            /> */}
            {
                Array.isArray(tableData) &&
                tableData.map((elm, ind) => (
                    <Accordion key={ind}>

                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{elm.eWaybillNo}</Typography>
                        </AccordionSummary>


                        <AccordionDetails>
                            <div className="mobile-table">
                                <table>
                                    {/* Existing header */}
                                    <thead>
                                        <tr>
                                            {/* <th>Property</th>
                                                    <th>Value</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            { property: 'eWaybillNo', header: 'EwayBill No.' },
                                            { property: 'uploadDate', header: 'Date' },
                                            { property: 'uploadDate', header: 'Time' },


                                        ].map(({ property, header }) => (
                                            <tr key={property}>
                                                <td>{header}</td>
                                                <td>
                                                    {property === 'uploadDate' &&
                                                        (header === 'Date'
                                                            ? new Date(elm[property]).toLocaleDateString()
                                                            : new Date(elm[property]).toLocaleTimeString())}
                                                    {property !== 'uploadDate' && elm[property]}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan="2">
                                                <button
                                                    style={{
                                                        background: 'black',
                                                        color: 'white',
                                                        padding: '0.3rem 1rem',
                                                        textAlign: 'left', // Align text to the left
                                                        border: 'none',
                                                        borderRadius: '0.3rem',
                                                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                        display: 'block', // This makes the button full-width
                                                    }}
                                                    onClick={() => clickTrackFunctionEway(elm.eWaybillNo)}
                                                >
                                                    Track Now
                                                </button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <div>{elm.eWaybillNo}</div>
                                <div>{new Date(elm.uploadDate).toLocaleDateString("en-IN")}</div>
                                <div>{new Date(elm.uploadDate).toLocaleTimeString("en-IN")}</div>
                                <div>
                                    <button
                                        style={{
                                            background: 'black',
                                            color: 'white',
                                            padding: '0.3rem 1rem',
                                            'margin-right': '5%',
                                            border: 'none',
                                            borderRadius: '0.3rem',
                                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                        }}
                                    // onClick={() => clickTrackFunctionEway(elm.eWaybillNo)}
                                    >
                                        Track Now
                                    </button>
                                </div>
                            </div> */}
                        </AccordionDetails>
                    </Accordion>
                ))
            }
            {/* common table END */}

            {/* <div className="flex justify-end w-[73.5%]" style={{ "margin": "auto" }}>
        <span className=" mr-[1rem]">please download within 90 days <span className="text-red-800">*</span></span>
      </div> */}

            <div className="flex justify-center pb-8">
                <Button style={(apiData.length < 5) ? { "display": "none" } : { "background": "#232323" }} variant="contained" onClick={() => { setCount(count + 5) }}>Load More</Button>
            </div>

            <Footer />
        </>


    );
};

export default MobileEwayBill;
