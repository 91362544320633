
//  HOME
const Home_tagline_En = "FASTag based vehicle tracking system";
const Home_small_tag_En = "With our FASTag based vehicle tracking system you can easily track any vehicle – be it yours or any third party vehicle, create new trips, set up pre-alerts for any trip, share trips ensuring higher visibility of movement of goods."

//PLAN_JOURNEY
const Plan_Journey_Top_Heading_En = "Plan Your Journey";
const Plan_Journey_Top_Subheading_En = "Create journey for any vehicle you wish to track";
const Plan_Journey_Top_Under_Button_En = "Frequently tracked vehicles";

// ACTIVE_JOURNEY
const Active_Journey_Top_Heading_En = "Ongoing Journeys";
const Active_Journey_Top_Subheading_En = "Track all your ongoing journeys here";
const Active_Default_table_message_En = "No active journeys found..."

// PAST JOURNEYS
const Past_Journey_Top_Heading_En = "Past Journeys";
const Past_Journey_Top_Subheading_En = "View all the past journeys you created here";
const Past_Default_table_message_En = "No closed journeys found..."

// SEARCH_TRACK_HISTORY 
const SEARCH_TRACK_Top_Heading_En = "Search History";
const SEARCH_TRACK_Top_Subheading_En = "View history of tracked vehicles here";
const SEARCH_TRACK_table_message_En = "please track ANY vehicle its history here..."

// EWayBill Tracker
const EWayBillNumber_Heading_En = "EWayBill Numbers List"
const EWayBillNumber_Subheading_En = "View EWayBill Numbers details here";

// PLAN_Modules
const PLAN_Top_Head_En = "Plans"
const PLAN_Top_Sub_En = "Choose a plan best suitable for you"
const PLAN_Benifits_En = {
    line1: "Track a vehicle - Get any vehicle's latest FASTag based toll crossing data",
    line2: "Each toll data costs 1 Tracking Credit",
    line3: "Share data with other users",
    line4: "Create, Track and Share multiple vehicles in real-time at 10 credits per vehicle per 24 hours",
    line5: "Find all toll plazas en route to plan journey",
    line6: "Estimate the distance and time to complete the journey",
    line7: "Extend journey duration as needed",
    line8: "Set pre-alerts for delays and diversions and receive notifications via email or SMS",
    line9: "Download customized summary of the journey upon completion, and view history of previously tracked vehicles",
    line10: "Transfer Tracking Credits to other registered users",
    line11: "Special Introductory Offer:",
    line12: "View last 72 hours of toll crossing data at no extra cost",
    line13: "Register on portal for FREE",
    line14: "On successful registration get 25 Tracking Credits at ZERO cost",
    line15: "The extra 50% Tracking Credits offer is to remain valid for the entire month of December",
    line16: "Just top up your account with INR 1000 or more,and the extra credits are yours!"
}

// WALLET
const Wallet_Top_Head_En = "Wallet"
const Wallet_Top_Sub_En = "All transactional activities"
const Wallet_Table1_Default_En = "No payments found..."
const Wallet_Table2_Default_En = "Please transfer Tracking Credits to view history..."
const Wallet_Table3_Default_En = "When someone send credits to you.then you can see all data in this table..."

// VEHICLE RELATED
const vehicle_Head_Top1_En = "Add Vehicle"
const vehicle_Head_Top2_En = "Vehicle List"
const vehicle_Sub_Top2_En = "List of your vehicles"
const vehicle_table_data_En = "Please add frequently track vehicles to view list"
const sub__heading_add_veh = "Add vehicles which you are going to track frequently";

// TRANSFER_CREDITS
const TRANSFER_CREDITS_Top_En = "Transfer Credits"
const TRANSFER_CREDITS_Sub_Top_En = "Transfer Credits to another user"
const TRANSFER_CREDITS_line_En = "mobile number of user to whom you wish to transfer Tracking Credits"



export { TRANSFER_CREDITS_line_En, sub__heading_add_veh, Wallet_Table3_Default_En, TRANSFER_CREDITS_Top_En, TRANSFER_CREDITS_Sub_Top_En, Home_tagline_En, Home_small_tag_En, Plan_Journey_Top_Heading_En, Plan_Journey_Top_Subheading_En, Plan_Journey_Top_Under_Button_En, Active_Journey_Top_Heading_En, Active_Journey_Top_Subheading_En, Active_Default_table_message_En, Past_Journey_Top_Heading_En, Past_Journey_Top_Subheading_En, Past_Default_table_message_En, SEARCH_TRACK_Top_Heading_En, EWayBillNumber_Heading_En, EWayBillNumber_Subheading_En, SEARCH_TRACK_Top_Subheading_En, SEARCH_TRACK_table_message_En, PLAN_Top_Head_En, PLAN_Top_Sub_En, PLAN_Benifits_En, Wallet_Top_Head_En, Wallet_Top_Sub_En, Wallet_Table1_Default_En, Wallet_Table2_Default_En, vehicle_Head_Top1_En, vehicle_Head_Top2_En, vehicle_Sub_Top2_En, vehicle_table_data_En };
