import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import Filter3 from "../../components/layouts/Filter/Filter3";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useSelector, useDispatch } from 'react-redux'
import { searchHistoryGet } from "../../apis/api";
import axios from 'axios'
import { isMobile } from "react-device-detect";
import ls from 'localstorage-slim'
import Footer from '../../components/layouts/Footer/Footer'
import { Button } from "@mui/material";
import { SEARCH_TRACK_Top_Heading_Hn, SEARCH_TRACK_Top_Subheading_Hn, SEARCH_TRACK_table_message_Hn } from '../../components/translation/Hn'
import { SEARCH_TRACK_Top_Heading_En, SEARCH_TRACK_Top_Subheading_En, SEARCH_TRACK_table_message_En } from '../../components/translation/En'
import MobileSearchHistory from "./MobileHistory";

const SearchHistory = () => {
  const eng = useSelector((state) => state.translate.value);
  let [apiData, setApiData] = useState([]);
  const iLogin = ls.get('iLogin');
  const [isSubscribe, setIsSubscribe] = useState(true)
  const [count, setCount] = useState(0);
  let login_response_object, token_user = " ", user_id = " ";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  useEffect(() => {
    axios.get(`${searchHistoryGet}/${user_id}/${count}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        console.log(response.data.data)
        setApiData(response.data.data.sendUser)
      }
    }).catch(err => {
      console.log(err)
    })

  }, [count])

  let tableData
  // get shorted and filter data
  const shortedFilterData2 = useSelector((state) => state.historyTableDatas.value)
  if (shortedFilterData2 === 0) {
    tableData = apiData;
  } else {
    tableData = shortedFilterData2;
  }



  // csvData
  const csvData = tableData;
  let latestCsv = [];

  tableData.forEach(elm => {
    console.log("elm16june====")
    console.log(elm)
    latestCsv.push({ Tracking_Number: elm.tracking_no, Vehicle_Number: elm.vehicle_no, 'Date(YYYY-MM-DD)': elm.request_time.slice(0, 10), Time: elm.request_time.slice(11, 16) })
  })

  return (
    <>
      {isMobile ? <>
        <MobileSearchHistory />
      </> :

        <>
          <div className="">
            <SecondNavBar active1={true} />
          </div>
          {/* top section Component */}
          <TopSection
            bigi={eng ? SEARCH_TRACK_Top_Heading_En : SEARCH_TRACK_Top_Heading_Hn}
            small={eng ? SEARCH_TRACK_Top_Subheading_En : SEARCH_TRACK_Top_Subheading_Hn}
          >
            {isSubscribe ?
              <CSVLink
                filename={"my-file.csv"}
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  background: "black",
                  color: "white",
                  borderRadius: "0.3rem",
                  padding: "0.4rem 2rem",
                  border: "none",
                  cursor: "pointer",
                }}
                data={latestCsv}
                className={"download_scv"}
              >
                Download All to CSV
              </CSVLink> : <button
                style={{
                  textDecoration: "none",
                  display: "inline-block",
                  background: "black",
                  color: "white",
                  borderRadius: "0.3rem",
                  padding: "0.4rem 2rem",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => { window.alert('if you want to download search history then you have to buy monthly plan') }}
              >
                Download All to CSV
              </button>}
            <Filter3 tableData={tableData} />
          </TopSection>
          {/* top section Component END*/}

          {/* common table START */}
          <CommonTable
            type={"4"}
            tableHead={[
              "Tracking Number",
              "Vehicle Number",
              "Date",
              "time",
              ""
            ]}
            tableData={tableData}
            extraCss={'tracker_main_container control_gap'}
            defaultMessage={eng ? SEARCH_TRACK_table_message_En : SEARCH_TRACK_table_message_Hn}
          />
          {/* common table END */}

          {/* <div className="flex justify-end w-[73.5%]" style={{ "margin": "auto" }}>
        <span className=" mr-[1rem]">please download within 90 days <span className="text-red-800">*</span></span>
      </div> */}

          <div className="flex justify-center pb-8">
            <Button style={(apiData.length < 5) ? { "display": "none" } : { "background": "#232323" }} variant="contained" onClick={() => { setCount(count + 5) }}>Load More</Button>
          </div>

          <Footer />
        </>}
    </>
  );
};

export default SearchHistory;
