import React from 'react';
import './headwithtext.css';

const HeadingWithText = ({ head, desc, isMargin, classes, children, boldId }) => {
    return (
        <div className={'heading_with_text'} style={isMargin ? { "marginTop": "1rem" } : {}}>
            <h1 className={classes ? classes : 'capitalize heading_with_text___heading'}>{head}</h1>
            <ul className='heading_with_text___para innerMAgix'>
                {!children ? (desc ? desc.map((elm, index) => {
                    // Check if the current element has the specified ID and apply bold style accordingly
                    const isBold = boldId && elm.includes(`<strong id='${boldId}'>`);
                    return <p className='mt-5' style={{fontWeight: isBold ? 'bold' : 'normal'}} dangerouslySetInnerHTML={{ __html: elm }} />
                }) : "") : children}
            </ul>
        </div>
)
}

export default HeadingWithText;
