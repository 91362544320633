import { Button } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import ls from 'localstorage-slim'

const NotSuccessfully = () => {

   

    const nav = useNavigate();
    return (
        <div className='flex justify-center items-center h-[100vh] p-4 rounded-md'>
            <div className='bg-white w-fit p-2' style={{ "boxShadow": "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px" }}>
                <div>Transaction failure. Please try again</div>
                <Button style={{ "background": "black", "marginTop": "1rem", "color": "white" }} onClick={() => { nav('/all-plans') }}>Try Again</Button>
            </div>
        </div>
    )
}

export default NotSuccessfully