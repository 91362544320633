import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { addTableData } from '../../../redux/slices/tableDatas'
import { addTableData2 } from '../../../redux/slices/historyTableDatas'
import { addTableData3 } from '../../../redux/slices/vehicleTabledata'
import { addTableData4 } from '../../../redux/slices/pastJourneyTable'
import { filterItem } from '../../../redux/slices/filterData'
import { useLocation } from 'react-router-dom';
import { Checkbox } from "@mui/material";

const Filter2 = ({ tableData }) => {
    const location = useLocation();
    const [isClick, setIsClick] = useState(false);
    const [isFilter1, setIsFilter1] = useState(false);
    const [isFilter2, setIsFilter2] = useState(false);
    const dispatch = useDispatch();
    const [isConfirmPop, setIsConfirmPop] = useState(false);
    const boolMouseOverOrNot = useSelector((state) => state.mouseOnVehicle.value)

    // filterDestinationOccur(e)}
    function filterDestinationOccur(e) {
        dispatch(filterItem(e.target.value));
    }
    function sortBySource() {
        let sortedList = tableData.slice().sort((a, b) => a.make.localeCompare(b.make))
        if (location.pathname == '/OngoingJourneys') {
            dispatch(addTableData(sortedList));
        }
        if (location.pathname == '/History') {
            dispatch(addTableData2(sortedList));
        }
        if (location.pathname == '/ListOfVehicle') {
            dispatch(addTableData3(sortedList));
        }
        if (location.pathname == '/PastJourney') {
            dispatch(addTableData4(sortedList));
        }
    }
    function sortByDestination() {
        let sortedList = tableData.slice().sort((a, b) =>
            a.type.localeCompare(b.type)
        );
        if (location.pathname == '/OngoingJourneys') {
            dispatch(addTableData(sortedList));
        }
        if (location.pathname == '/History') {
            dispatch(addTableData2(sortedList));
        }
        if (location.pathname == '/ListOfVehicle') {
            dispatch(addTableData3(sortedList));
        }
        if (location.pathname == '/PastJourney') {
            dispatch(addTableData4(sortedList));
        }
    }
    function sortByVehicleNumber() {
        let sortedList = tableData.slice().sort((a, b) => a.vin.localeCompare(b.vin));

        if (location.pathname == '/OngoingJourneys') {
            dispatch(addTableData(sortedList));
        }
        if (location.pathname == '/History') {
            dispatch(addTableData2(sortedList));
        }
        if (location.pathname == '/ListOfVehicle') {
            dispatch(addTableData3(sortedList));
        }
        if (location.pathname == '/PastJourney') {
            dispatch(addTableData4(sortedList));
        }
    }
    function sortByDate() {
        let sortedList = tableData.slice().sort((a, b) => b.validity.localeCompare(a.validity));

        if (location.pathname == '/OngoingJourneys') {
            dispatch(addTableData(sortedList));
        }
        if (location.pathname == '/History') {
            dispatch(addTableData2(sortedList));
        }
        if (location.pathname == '/ListOfVehicle') {
            dispatch(addTableData3(sortedList));
        }
        if (location.pathname == '/PastJourney') {
            dispatch(addTableData4(sortedList));
        }
    }
    function sortByModel() {
        let sortedList = tableData.slice().sort((a, b) => a.model.localeCompare(b.model));

        if (location.pathname == '/OngoingJourneys') {
            dispatch(addTableData(sortedList));
        }
        if (location.pathname == '/History') {
            dispatch(addTableData2(sortedList));
        }
        if (location.pathname == '/ListOfVehicle') {
            dispatch(addTableData3(sortedList));
        }
        if (location.pathname == '/PastJourney') {
            dispatch(addTableData4(sortedList));
        }
    }

    return (
        <>
            <div className={boolMouseOverOrNot?"":"relative filter-sec z-50"} style={{ marginLeft: "1rem"}}>

                <button
                    onClick={() => {
                        isClick ? setIsClick(false) : setIsClick(true);
                    }}
                    className={"button_first_to_last "}
                    style={{
                        width: "17rem",
                        background: "white",
                        color: "black",
                        borderRadius: "0.3rem",
                        padding: "0.4rem 2rem",
                        border: "none",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        cursor: "pointer",

                    }}
                >

                    Sort and Filter</button>

                {/* dropdown of sort and filter START*/}
                <section
                    className="dropOfFilter"
                    style={
                        isClick
                            ? {
                                display: "block",
                                borderRadius: "0.1rem",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                                background: "white",
                                position: "absolute",
                                width: "100%",
                                left: "0",
                                top: "38px",
                               
                            }
                            : {
                                display: "none",
                                borderRadius: "0.1rem",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                                background: "white",
                                position: "absolute",
                                width: "100%",
                                left: "0",
                                top: "38px",
                            }
                    }
                >
                    {/* sort */}
                    <div style={{ padding: "1rem" }}>
                        <div className="mt-3 pb-3"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid #cbd5e1",
                            }}
                        >
                            <p className="font-semibold">Sort</p>
                            <button
                                onClick={() => {
                                    isClick ? setIsClick(false) : setIsClick(true);
                                }}
                            >
                                X
                            </button>
                        </div>
                        <div className="mt-3"
                            onClick={() => {
                                sortBySource();
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ color: "#cbd5e1" }}>ZZ</span>
                            <p style={{ "margin-left": "1rem" }}>Sort By Make</p>
                        </div>

                        <div className="mt-3"
                            onClick={() => {
                                sortByDestination();
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ color: "#cbd5e1" }}>ZZ</span>
                            <p style={{ "margin-left": "1rem" }}>Sort By Vehicle Type</p>
                        </div>

                        <div className="mt-3"
                            onClick={() => {
                                sortByDate();
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ color: "#cbd5e1" }}>ZZ</span>
                            <p style={{ "margin-left": "1rem" }}>Sort By Validity</p>
                        </div>

                        <div className="mt-3"
                            onClick={() => {
                                sortByModel();
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ color: "#cbd5e1" }}>ZZ</span>
                            <p style={{ "margin-left": "1rem" }}>Sort By Model</p>
                        </div>

                        <div className="mt-3"
                            onClick={() => {
                                sortByVehicleNumber();
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ color: "#cbd5e1" }}>ZZ</span>
                            <p style={{ "margin-left": "1rem" }}>Sort By VehicleNumber</p>
                        </div>
                    </div>

                </section>
                {/* dropdown of */}

            </div>
        </>
    );
};

export default Filter2;
