import { Button, Card, IconButton, StepButton, Switch } from '@mui/material';
import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import './Plans.css';
import SecondNavBar from '../../components/layouts/Navbar/SecondNavbar/SecondNavbar';
import PlansHeader from '../../components/layouts/Plans/PlansHeader';
import PremiumPlans from './PremiumPlans';
import TopSection from '../../TopSection';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../components/layouts/Footer/Footer';

function Plans() {
  const nav = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  // switcherFunction(e)
  function switcherFunction(e) {
    nav('/plans');
  }

  return (
    <>
      <div className="nav">
        <SecondNavBar />
      </div>

      {/* top section Component */}
      <TopSection bigi={'Plans'} small={'Choose a plan best suitable for you'}>
        <div className="topbar_right_section flex items-center">
          <div className="font-Cardo font-medium">Pay Per Use</div>
          <Switch
            onClick={(e) => {
              switcherFunction(e);
            }}
            checked
          />
          <div className="font-Cardo font-medium">Monthly Subscription</div>
        </div>
      </TopSection>
      {/* top section Component END*/}

      {/* cardcontainer START */}
      <div
        div
        className="plan_card_container m-auto py-10"
        style={{ width: '73.5%' }}>
        <Carousel>
          <div className="flex justify-between">

            {/* item1 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 19
                      <div className="text-sm">for 10 credits</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 19</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

            {/* item2 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 99
                      <div className="text-sm">for 50 credits</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 99</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

            {/* item3 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 149
                      <div className="text-sm">for 100 credits</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 149</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

          </div>

          <div className="flex justify-between">

            {/* item4 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 200
                      <div className="text-sm">for 200 credits</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 200</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

            {/* item5 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 201 to 499
                      <div className="text-sm">for 201 to 499 credits</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 200 plus</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

            {/* item6 */}
            <div className="one p-5 rounded-md " style={{ "width": "33%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 500
                      <div className="text-sm">for 550 credits(10% extra)</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 500</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the journey
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the journey's starting or ending location while it's ongoing and extend the trip's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the trip upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

          </div>

          <div className="flex justify-between">

            {/* item7 */}
            <div className="one p-5 rounded-md " style={{ "width": "50%", "height": "auto" }}>
              <div className="card" style={{ "height": "100%" }}>
                <Card variant="outlined" style={{ "height": "100%", "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                  <div className="flex flex-col gap-4 py-8 pl-2 pr-2">
                    <div className="HeadMSg text-center font-Cardo text-2xl font-extrabold">
                      INR 501 to no limit
                      <div className="text-sm">for 501 - no limit credits(10% extra)</div>
                    </div>
                    <div className="flex justify-center gap-2">
                      <div className="font-Inter font-bold text-3xl">Pack 500 plus</div>
                    </div>

                    <div className="flex">
                      <ul className="flex ml-5 flex-col gap-3 text-[0.8rem] font-Inter list-disc">
                        <div>
                          <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                        </div>
                        <div>
                          <li>Find all toll plazas en route to plan travel expenses and rest stops
                          </li>
                        </div>
                        <div>
                          <li>Estimate the distance and time to complete the trip
                          </li>
                        </div>
                        <div className="">
                          <li>Modify the trip's starting or ending location while it's ongoing and extend the trip's duration as needed
                          </li>
                        </div>

                        {/* <div className="">
                          <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS
                          </li>
                        </div> */}

                        <div className="">
                          <li>Receive a customized summary of the trip upon completion, including expenses, and view a history of previously tracked vehicles
                          </li>
                        </div>

                        <div className="">
                          <li>Transfer tracking credits to other registered users
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>

                  <div className="flex justify-center items-center mb-4">
                    <Button onClick={() => nav('/checkout')} className="hoverButoon" variant="outlined" style={{ "background": "#262626", "border": "none", "color": "white", "cursor": "pointer" }}>
                      Choose Plan
                    </Button>
                  </div>
                </Card>
              </div>
            </div>

          </div>

        </Carousel>
      </div>
      {/* cardcontainer END */}

      {/* bottomer */}
      <div
        className="flex justify-center"
        onClick={() => {
          nav('/contact-us');
        }}>
        <div
          className="rounded-tl-lg cursor-pointer rounded-tr-lg flex justify-center items-center"
          style={{
            width: '53.5%',
            margin: 'auto',
            height: '7rem',
            background: '#262626',
          }}>
          <p className="requst_bottom_para flex justify-center items-center">
            <span>Contact us</span>{' '}
            <span className="material-symbols-outlined ml-5">
              arrow_forward
            </span>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Plans;
