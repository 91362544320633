import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import TopSection from "../../TopSection";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileTrack from "./MobileTrack";
import MobileTrack_Public from "./MobileTrack_Public";

const TrackPublic = () => {
  let [apiData, setApiData] = useState([]);
  let [apiData2, setApiData2] = useState([]);
  let [isFetched, setIsFetched] = useState(false);
  let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);
  const param = useParams();
  const nav = useNavigate();

  useEffect(() => {
    axios
      .get(
        `https://lorrycare.com:5000/v1/search-vehicle/${param._id}/shared/${param.random}`
      )
      .then((res) => {
        setIsFetched(true);
        setApiData(res.data.data.txn);
        console.log(res.data.data);
        setApiData2(res.data.data);
      })
      .catch((err) => {
        setIsFetched(false);
        console.log(err);
      });
  }, []);


  return (
    <>
      {isMobile ? <>
        <MobileTrack_Public />
      </> :
        <>
          {isFetched ? (
            <>
              {/* <TopSection
                bigi={apiData2.vehicle_no}
                small={"View last 72 hour journey"}
              ></TopSection> */}
              <section
                className="track_top_section"
                style={{
                  borderBottom: "1px solid gray",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "73.5%",
                  margin: "auto",
                }}
              >
                {/* left */}
                <div style={{ padding: "3px 0rem" }} className="flex top_sec_left">
                  {/* <button onClick={() => setShowConfirmation(true)}>
                                <span className="material-symbols-outlined">
                                    keyboard_backspace
                                </span>
                            </button> */}
                  <div className="ml-5">
                    <h1 className="text-2xl font-Cardo font-bold my-2">{apiData2.vehicle_no}</h1>
                    <p style={{ fontSize: "0.9rem", marginLeft: "5px" }}>
                      View last 72 hour journey

                    </p>
                  </div>
                </div>

                {/* right */}

              </section>
              {/* top section Component END*/}

              <section
                className="track_my_goods"
                style={{ width: "78%", margin: "auto" }}
              >
                <div className="track_container">
                  {/* main tracker section */}
                  <section
                    className="tracker_main_wrapper"
                    style={{ marginBottom: "3rem", width: "90%", margin: "auto" }}
                  >
                    {/* here */}
                    <div className="tracker_main_container">
                      {apiData ? (
                        apiData.map((elm, ind) => {
                          return (
                            <div
                              className="tracker_row"
                              style={{
                                marginTop: "3rem",
                                display: "flex",
                                justifyContent: "space-between",
                                "align-items": "center",
                              }}
                            >
                              {/* icon */}
                              <div
                                className={
                                  ind == apiData.length - 1
                                    ? "w-[8%]"
                                    : "statusIcon w-[8%]"
                                }
                              >
                                {ind == "0" ? (
                                  <svg
                                    style={{ color: "green" }}
                                    width="50"
                                    height="44"
                                    viewBox="0 0 38 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z"
                                      stroke="black"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75"
                                      stroke="green"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    style={{ color: "green" }}
                                    width="50"
                                    height="44"
                                    viewBox="0 0 38 44"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z"
                                      stroke="black"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75"
                                      stroke="green"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                )}
                              </div>

                              {/* city */}
                              <div className="city w-[20%]">
                                <h1
                                  style={{ fontWeight: "500", textAlign: "start" }}
                                >
                                  {elm.plaza}
                                </h1>
                              </div>

                              <div
                                className="track_small_detail_wrapper"
                                style={{ width: "70%", overflow: "scroll" }}
                              >
                                <div
                                  className="track_small_detail"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    "align-items": "center",
                                    width: "40rem",
                                  }}
                                >
                                  {/* checkin date */}
                                  <div
                                    className="checkinDate w-[14%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                        marginTop: "0.4rem",
                                      }}
                                    >
                                      Date
                                    </span>
                                    <b
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {elm.readerReadTime.slice(0, 10)}
                                    </b>
                                  </div>

                                  {/* checkin time */}
                                  <div
                                    className="checkinTime w-[10%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                        marginTop: "0.4rem",
                                      }}
                                    >
                                      Time
                                    </span>
                                    <b
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {elm.readerReadTime.slice(11, 19)}
                                    </b>
                                  </div>

                                  {/* time lapse*/}
                                  <div
                                    className="timeLapse"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                        marginTop: "0.4rem",
                                      }}
                                    >
                                      State
                                    </span>
                                    <b
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {elm.state}
                                    </b>
                                  </div>

                                  {/* distance covered*/}
                                  <div
                                    className="timeLapse w-[15%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                        marginTop: "0.4rem",
                                      }}
                                    >
                                      City
                                    </span>
                                    <b
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {elm.city}
                                    </b>
                                  </div>

                                  {/* in-betwwen speed */}
                                  <div
                                    className="timeLapse w-[15%]"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                        marginTop: "0.4rem",
                                      }}
                                    >
                                      Tracking No.
                                    </span>
                                    <b
                                      style={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {apiData2.tracking_no}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </section>
                </div>
              </section>

              <div className="flex justify-between pb-2">
                <CSVLink
                  className="text-sm inline-block m-auto"
                  data={apiData}
                  style={{
                    background: "black",
                    color: "white",
                    padding: "0.2rem",
                    borderRadius: "1rem",
                  }}
                  filename={`Tracking-History-Of ${apiData2.vehicle_no}`}
                >
                  Download
                </CSVLink>
              </div>
            </>
          ) : (
            <div className="flex justify-center">
              <div style={{ "width": "fit-content", background: "black", color: "white", fontSize: "0.8rem", padding: "0.1rem" }}>data Fetching...</div>
            </div>
          )}
        </>}
    </>
  );


};

export default TrackPublic;
