import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import ls from "localstorage-slim";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  transactionHistory,
  transferAllCredits,
  transferAllCredits_R,
  transaction_activity,
} from "../../apis/api";
import CommonTable from "../../CommonTable";
import {
  Wallet_Top_Head_En,
  Wallet_Top_Sub_En,
  Wallet_Table1_Default_En,
  Wallet_Table2_Default_En,
  Wallet_Table3_Default_En,
} from "../translation/En";
import {
  Wallet_Top_Head_Hn,
  Wallet_Top_Sub_Hn,
  Wallet_Table1_Default_Hn,
  Wallet_Table2_Default_Hn,
  Wallet_Table3_Default_Hn,
} from "../translation/Hn";
import { Button } from "@mui/material";
import { CSVLink } from "react-csv";
import Mobile_BasicTabs from "./Mobile_TabMui";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const eng = useSelector((state) => state.translate.value);
  const [value, setValue] = React.useState(0);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiData2, setApiData2] = useState([]);
  const [apiData3, setApiData3] = useState([]);
  const [apiData4, setApiData4] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const iLogin = ls.get("iLogin");
  let login_response_object = "",
    token_user = "",
    user_id = "";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  useEffect(() => {
    axios
      .get(`${transactionHistory}/${user_id}/${count}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setApiData(res.data.data);
          console.log("datajuly" + JSON.stringify(res));
        }
        console.log("jank" + JSON.stringify(apiData));
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transferAllCredits}/${user_id}/${count2}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response2" + JSON.stringify(res));
        if (res.data.success) {
          setApiData2(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transferAllCredits_R}/${user_id}/${count3}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response3" + JSON.stringify(res));
        if (res.data.success) {
          setApiData3(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${transaction_activity}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("response4");
        console.log("response4" + JSON.stringify(res));
        if (res.data.success) {
          setApiData4(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [count, count2, count3, count4]);

  let tableData = apiData;
  let tableData2 = apiData2;
  console.log("tableData2:".tableData2);
  let tableData3 = apiData3;
  let tableData4 = apiData4;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {isMobile ? <>
        {console.log('isMobile:', isMobile)}
        <Mobile_BasicTabs />
      </> :
        <>
          <Box
            sx={{ width: "100%", marginTop: "1rem", width: "73.5%", margin: "auto" }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Transaction history" {...a11yProps(0)} color={"black"} />
                <Tab label="Credits Send" {...a11yProps(1)} color={"black"} />
                <Tab label="Credits Received" {...a11yProps(2)} color={"black"} />
                <Tab label="Transaction Activity" {...a11yProps(3)} color={"black"} />
              </Tabs>
            </Box>
            {/* Tab 0 - Transaction History */}
            <TabPanel value={value} index={0}>
              {console.log("table1")}
              {tableData.details
                ? console.log(tableData.details)
                : console.log(tableData)}
              <CommonTable
                type={"5"}
                tableHead={["Date", "Time", "ID", "Amount", "Credits", "Status",""]}
                tableData={tableData.details ? tableData.details : tableData}
                defaultMessage={
                  eng ? Wallet_Table1_Default_En : Wallet_Table1_Default_Hn
                }
                marginSet={{
                  margin: "0.4rem auto",
                  width: "100%",
                  border: "none",
                  position: "relative",
                }}
              />
              <div className="flex justify-center pb-8 mt-2">
                <Button
                  variant="contained"
                  style={
                    apiData.length < 5
                      ? { display: "none" }
                      : { background: "#232323" }
                  }
                  onClick={() => {
                    setCount(count + 5);
                  }}
                >
                  Load More
                </Button>
                <Button style={{ background: "rgb(35, 35, 35)", color: "white", marginLeft: "2rem" }}>
                  <CSVLink
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      background: "black",
                      color: "white",
                      borderRadius: "0.3rem",
                      padding: "0.4rem 2rem",
                      border: "none",
                      cursor: "pointer",
                    }}
                    filename="Transaction_history.csv"
                    data={tableData.details ? tableData.details : tableData}
                    className={"download_scv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              </div>
            </TabPanel>
            {/* Tab 1 - Credit Send */}
            <TabPanel value={value} index={1}>
              <CommonTable
                type={"6"}
                tableHead={[
                  "Date",
                  "Time",
                  "Transaction Id",
                  "Receiver Mobile",
                  "Credits",
                  "",
                ]}
                tableData={tableData2}
                defaultMessage={
                  eng ? Wallet_Table2_Default_En : Wallet_Table2_Default_Hn
                }
                marginSet={{
                  margin: "0.4rem auto",
                  width: "100%",
                  border: "none",
                  position: "relative",
                }}
              />
              <div className="flex justify-center pb-8">
                <Button
                  variant="contained"
                  style={
                    apiData2.length < 5
                      ? { display: "none" }
                      : { background: "#232323" }
                  }
                  onClick={() => {
                    setCount2(count2 + 5);
                  }}
                >
                  Load More
                </Button>
                <Button
                  style={{
                    background: "rgb(35, 35, 35)",
                    color: "white",
                    marginLeft: "0.4rem",
                  }}
                >
                  <CSVLink
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      background: "black",
                      color: "white",
                      borderRadius: "0.3rem",
                      padding: "0.4rem 2rem",
                      border: "none",
                      cursor: "pointer",
                    }}
                    filename="Send_credits_history.csv"
                    data={tableData2}
                    className={"download_scv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              </div>
            </TabPanel>
            {/* Tab 2 - Credits Received */}
            <TabPanel value={value} index={2}>
              <CommonTable
                type={"7"}
                tableHead={[
                  "Date",
                  "Time",
                  "Transaction id",
                  "Sender Mobile",
                  "Credit",         
                  "",
                ]}
                tableData={tableData3}
                defaultMessage={
                  eng ? Wallet_Table3_Default_En : Wallet_Table3_Default_Hn
                }
                marginSet={{
                  margin: "0.4rem auto",
                  width: "100%",
                  border: "none",
                  position: "relative",
                }}
              />
              <div className="flex justify-center pb-8">
                <Button
                  variant="contained"
                  style={
                    apiData2.length < 5
                      ? { display: "none" }
                      : { background: "#232323" }
                  }
                  onClick={() => {
                    setCount3(count3 + 5);
                  }}
                >
                  Load More
                </Button>
                <Button
                  style={{
                    background: "rgb(35, 35, 35)",
                    color: "white",
                    marginLeft: "0.4rem",
                  }}
                >
                  <CSVLink
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      background: "black",
                      color: "white",
                      borderRadius: "0.3rem",
                      padding: "0.4rem 2rem",
                      border: "none",
                      cursor: "pointer",
                    }}
                    filename="Received_credits_history.csv"
                    data={tableData3}
                    className={"download_scv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              </div>
            </TabPanel>
            {/* Transaction Activity */}
            <TabPanel value={value} index={3}>
              <CommonTable
                type={"20"}
                tableHead={[ "Date","Time","Transaction id", "Credits", "Type", ""]}
                tableData={tableData4}
                marginSet={{
                  margin: "0.4rem auto",
                  width: "100%",
                  border: "none",
                  position: "relative",
                }}
              />
              <div className="flex justify-center pb-8">
                <Button
                  style={{
                    background: "rgb(35, 35, 35)",
                    color: "white",
                    marginLeft: "0.4rem",
                  }}
                >
                  <CSVLink
                    style={{
                      textDecoration: "none",
                      display: "inline-block",
                      background: "black",
                      color: "white",
                      borderRadius: "0.3rem",
                      padding: "0.4rem 2rem",
                      border: "none",
                      cursor: "pointer",
                    }}
                    filename="Transaction_activity.csv"
                    data={tableData4}
                    className={"download_scv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              </div>
            </TabPanel>
          </Box>
        </>}
    </>
  );
}
