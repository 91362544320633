import styled, { css } from "styled-components";
import React from "react";

function MainHeading(props) {
  return (
    <>
      <Title>
        <h2 className="Main-Heading text-2xl font-bold" id={props.ids} style={props.css}>
          {props.HeadinName}
        </h2>
        <p className="text-sm">{props.para?props.para:""}</p>
      </Title>
    </>
  );
}
const Title = styled.div`
  textalign: start;
  width: 100%;
  paddingleft: 0px;
  marginbottom: 0.5rem;
`;

export default MainHeading;
