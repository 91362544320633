import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { sendRegsiterEmailOTP, forgot_email, modifyPasswordOtp } from "../../../apis/api";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import Timer from "../../../components/layouts/Timer/Timer";
import ls from 'localstorage-slim'

const FStep2 = () => {
  const nav = useNavigate();
  const [otpFull1, setotpFull1] = useState();
  const [otpFull2, setotpFull2] = useState();
  const [otpFull3, setotpFull3] = useState();
  const [otpFull4, setotpFull4] = useState();
  let otpFullString, otpFullNumber;

  function handleInputChange1(e) {
    setotpFull1(e.target.value);
    console.log(otpFull1);
  }
  function handleInputChange2(e) {
    setotpFull2(e.target.value);
    console.log(otpFull2);
  }
  function handleInputChange3(e) {
    setotpFull3(e.target.value);
    console.log(otpFull3);
  }
  function handleInputChange4(e) {
    setotpFull4(e.target.value);
    console.log(otpFull4);
  }


  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // Submit code
        console.log('submit code ');
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  }
  function onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }

  // auto swap functionality
  function getCodeBoxElement(index) {
    return document.getElementById('codeBox' + index);
  }
  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // Submit code
        console.log('submit code ');
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  }
  function onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }
  // auto swap functionality
  const otpID = ls.get("emailOTPId", { decrypt: true })
  // clickContinue
  async function clickContinue(e) {
    otpFullString = `${otpFull1}${otpFull2}${otpFull3}${otpFull4}`;
    otpFullNumber = String(otpFullString);
    console.log(otpFullNumber)

    // axios for post email to backend START
    await axios.put(`${modifyPasswordOtp}/${otpID}`, {
      otp: otpFullNumber
    })
      .then(function (response) {
        console.log(response);
        ls.set('user_id_from_forgot', response.data.data, { encrypt: true });
        if (response.data.success) {
          console.log(response)
          nav('/ResetPassword')
        }
      })
      .catch(function (error) {
        console.log(error)
        if (error.response.status === 400) {
          window.alert("wrong otp")
        } else if (error.response.status === 500) {
          window.alert("internal server error")
        }
      });
    //  axios post END
  }
  let inputData;
  if (ls.get('emailonForgetResend')) {
    inputData = ls.get('emailonForgetResend');
  }


  // resendOTP(e)
  async function resendOTP(e) {
    e.preventDefault();
    // post forgot form-1 data to backend..
    await axios
      .post(forgot_email, inputData)
      .then((response) => {
        if (response.data.success) {
          ls.set("emailOTPId", response.data.data.id, { encrypt: true })
          window.location.reload();
        }
        console.log(response)
      })
      .catch((error) => {
        window.alert(error.response.data.message);
      });
  }


  return (
    <>
      <div className="flex items-center w-full h-[100vh] justify-center border ">
        <Row className="py-8">
          <div className=" flex items-center flex-col justify-center w-[100%] mlpotrewazxcv">

            {/* top section */}
            <div className="flex flex-row-reverse justify-between items-center mb-8 w-[100%]">

              <div className="flex items-center">

                <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
              </div>

              <div className="flex items-center">
                <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                  keyboard_backspace
                </span></button>
                <div>
                  <MainHeading HeadinName="Verification" />
                </div>
                {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
              </div>

            </div>

          </div>

          <div className="fygaeojjj flex items-center flex-col justify-center gap-4">

            <div className="w-[100%]">

              <div className="flex justify-between items-center w-full" id="otp">

                {/* input1 */}
                <input
                  onChange={(e) => { handleInputChange1(e) }}
                  id="codeBox1"
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(1, event) }} onFocus={() => { onFocusEvent(1) }}
                  type="number"
                  className="w-16 h-16 inner_shad border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input2 */}
                <input
                  id={"codeBox2"}
                  onKeyUp={(event) => { onKeyUpEvent(2, event) }} onFocus={() => { onFocusEvent(2) }}
                  maxLength={"1"}
                  onChange={(e) => { handleInputChange2(e, 2) }}
                  type="number"
                  className="w-16 ml-5 inner_shad h-16 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input3 */}
                <input
                  id={"codeBox3"}
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(3, event) }} onFocus={() => { onFocusEvent(3) }}
                  onChange={(e) => { handleInputChange3(e) }}
                  type="number"
                  className="w-16 h-16 inner_shad ml-5 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input4 */}
                <input
                  id={"codeBox4"}
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(4, event) }} onFocus={() => { onFocusEvent(4) }}
                  onChange={(e) => { handleInputChange4(e) }}
                  type="number"
                  className="w-16 inner_shad h-16 ml-5 border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

              </div>
            </div>

            <div className="w-full mt-2">
              <div className="w-full mt-3" onClick={(e) => { clickContinue(e) }}>
                <Button style={{ "background": "#232323", "color": "white", "width": "100%" }}
                >Continue</Button>
              </div>
            </div>

            <div className="w-[100%] flex justify-between items-center">
              <p className="flex gap-2 text-sm">
                Expire in{" "}
                <span className="text-red-600">
                  {" "}
                  <Timer />
                </span>
              </p>
              <p className="flex content-center items-center cursor-pointer text-sm" onClick={(e) => { resendOTP(e) }}>
                Resend OTP{" "}
                <span>
                  <BsArrowRightShort className="text-[1.5rem]" />
                </span>
              </p>
            </div>
          </div>
        </Row>
      </div>

    </>
  );
};
const Row = styled.div`

`;
export default FStep2;
