import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const HistoryVSTrack = createSlice({
    name: 'HistoryVSTrack',
    initialState,
    reducers: {
        ADDPAGENAME: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { ADDPAGENAME } = HistoryVSTrack.actions

export default HistoryVSTrack.reducer