import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
    date: 0,
    cd: 0,
}

export const InvoiceAmount = createSlice({
    name: 'InvoiceAmount',
    initialState,
    reducers: {
        addAMT: (state, action) => {
            state.value = (action.payload);
        },
        addDate: (state, action) => {
            state.date = (action.payload);
        },
        addCD: (state, action) => {
            state.cd = (action.payload);
        }
    },
})


export const { addAMT, addDate, addCD } = InvoiceAmount.actions

export default InvoiceAmount.reducer