import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Tick from "../../components/assets/svg/Tick";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { fetchKYC, postKYC } from '../../apis/api'
import ls from 'localstorage-slim'
import axios from 'axios'
import FileBase from 'react-file-base64'
import TopSection from '../../TopSection'
import Footer from "../../components/layouts/Footer/Footer";


function KYC() {

  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const kycId = ls.get("kyc_id", { decrypt: true })
  console.log("kycISS", kycId)
  let formData;
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;
  const [apigetData, setApiGetData] = useState([]);

  const [inputData, setInputData] = useState({
    u_id: user_id,
    aadhar_number: "",
    pan_number: "",
    aadhar_file: "",
    pan_file: "",
    gst_number: "",
    gst_file: "",
  });

  // editkyc(e)
  async function editContacts(e) {
    if (inputData.aadhar_number == "" || inputData.aadhar_number == " " || inputData.aadhar_number == "  " || inputData.aadhar_number == "   " || inputData.aadhar_number == "    " || inputData.aadhar_number == "     " || inputData.aadhar_number == "      " || inputData.aadhar_number == "       " || inputData.pan_number == "" || inputData.pan_number == " " || inputData.pan_number == "  " || inputData.pan_number == "   " || inputData.pan_number == "    " || inputData.pan_number == "     " || inputData.pan_number == "      " || inputData.pan_number == "       ") {
      window.alert("all field required")
    } else {
      // post
      await axios.post(postKYC, inputData, {
        headers: {
          Authorization: `Hello ${token_user}`
        }
      })
        .then(function (response) {
          console.log(response)
          if (response.data.success) {
            console.log(response)
            ls.set("kyc_id", response.data.data._id, { encrypt: true })
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    }
  }

  // change contact detail...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
    console.log(inputData)
  }

  useEffect(() => {

    // get
    axios.get(`${fetchKYC}/${kycId}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    })
      .then(function (response) {
        setApiGetData(response.data.data)
        console.log(JSON.stringify(response.data.success) + "/////")
        if (response.data.success) {
          ls.set('isGst', response.data.data.gst_number);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  return (
    <>
      <div className="nav">
        <SecondNavbar />
      </div>

      {/* top section Component */}
      <TopSection
        bigi={"Profile"}
        small={""}
      >
      </TopSection>
      {/* top section Component END*/}

      <div className="ProfileNav w-[73%] m-auto bg-[#232323] rounded-xl">
        <ul className="font-Inter font-normal text-base flex flex-wrap justify-between items-center text-white p-5">
          <li className="zxcvbb">
            <NavLink to="/Profile" className="p-1 px-4 rounded-md ">
              Profile
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/ContactDetail" className="p-1 px-4 rounded-md">
              Contact Details
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/Password" className="p-1 px-4 rounded-md">
              Password
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/kyc" className="p-1 px-4 rounded-md">
              KYC
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/subscription" className="p-1 px-4 rounded-md">
              Subscription
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/company" className="p-1 px-4 rounded-md">
              Company
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="parent-elem relative my-16">
        <div>
          <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
            <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
              <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

              {/* <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                <h1>You want to edit your details</h1>
              </div> */}
              <div className="second-Section flex flex-col gap-7 w-[85%]">
                <div className="inputItem flex flex-col gap-2">
                  <div className="flex justify-between">
                    <div>
                      <label>Aadhar Card No.</label>
                      <input
                        type="number" name="aadhar_number"
                        className=" border-none border-black rounded-md w-[97%] p-1 pl-2"
                        placeholder={apigetData.aadhar_number} onChange={(e) => {
                          inputChange(e);
                        }}
                        style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                      />
                    </div>
                    <div className="flex">
                      <div>
                        <label>Pan Card No.</label>
                        <input
                          type="text"
                          className=" border-none border-black rounded-md w-[97%] p-1 pl-2"
                          placeholder={apigetData.pan_number} name={"pan_number"} onChange={(e) => {
                            inputChange(e);
                          }}
                          style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputItem flex flex-col gap-2">
                  <div>

                    <div>
                      <label>Gst Number</label>
                      <input
                        type="text" name="gst_number"
                        className="border-none border-black rounded-md w-[97%] p-1 pl-2"
                        placeholder={apigetData.gst_number} onChange={(e) => {
                          inputChange(e);
                        }}
                        style={{ "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                      />
                    </div>

                    <div className=" mt-5">
                      <label>Upload Aadhar Card </label><br></br>
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                          setInputData({ ...inputData, aadhar_file: base64 })
                        }
                        name="aadhar_file"
                        id={"aadhar_file"}
                      />
                    </div>



                  </div>
                </div>

                <div className="inputItem flex flex-col gap-2">
                  <div className="flex">

                    <div className="SecondComponent">
                      <div className="firstComponent">
                        <label>Upload Pan Card </label>
                        <FileBase
                          type="file"
                          multiple={false}
                          onDone={({ base64 }) =>
                            setInputData({ ...inputData, pan_file: base64 })
                          }
                          name="pan_file"
                        />
                      </div>
                    </div>

                    <div className="">
                      <label>Upload Gst Document</label><br></br>
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                          setInputData({ ...inputData, gst_file: base64 })
                        }
                        name="gst_file"
                      />
                    </div>

                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="flex bg-black text-white rounded-md px-10 py-1" onClick={(e) => { editContacts(e) }}>
                    <Link className="flex">
                      <div className="font-Cardo font-normal text-xl pr-3 w-40 flex flex-col justify-center items-center">
                        Save Changes
                      </div>
                      <div className="flex justify-center items-center">
                        <Tick />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default KYC;
