// import section
import React, { useRef, useState, useEffect } from "react";
import "./Home.css";
import Track from "../../components/assets/svg/Track";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "../../components/layouts/Navbar/Navbar";
import ls from "localstorage-slim";
import { trackVehicle, getWalletBalances, trackEway } from "../../apis/api";
import axios, { formToJSON } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData";
import { addewayTrackData } from "../../redux/slices/ewayTrackData";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../components/layouts/Footer/Footer";
import { getWalletBalance } from "../../redux/slices/WalletBalance";
import Tooltip from "@mui/material/Tooltip";
import Draggable from "react-draggable";
import {
  Home_tagline_Hn,
  Home_small_tag_Hn,
} from "../../components/translation/Hn";
import {
  Home_tagline_En,
  Home_small_tag_En,
} from "../../components/translation/En";
import moment from "moment";
import { Button } from "@mui/material";
import Subnavbar from "../../components/layouts/Navbar/Subnavbar";

const Home = () => {
  const eng = useSelector((state) => state.translate.value);
  // const [eng,setEng] = useState(true);
  const [apiData, setApiData] = useState([]);
  const iLogin = ls.get("iLogin");

  let login_response_object, token_user, user_id;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    let currentTime = moment().format("LLLL");
    let currentTime2 = moment().format("DD-MM-YYYY");
    let obj = {
      name: "loginSuccess",
      message: login_response_object.message,
      time: "",
      forFilter: "",
    };
    ls.set("loginSuccess", obj);
    console.log(
      "login_response_object" + JSON.stringify(login_response_object)
    );
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );

    axios
      .get(`${getWalletBalances}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.success);
          setApiData(response.data.data);
          dispatch(getWalletBalance(response.data.data));
          ls.set("walletBalance", response.data.data, { encrypt: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // const disker1 = document.getElementById("disker1");
  // disker1.addEventListener('keypress', (e) => {
  //     console.log(e)
  //     if(e.key=='Enter'){
  //       clickTrackFunction();
  //     }
  //   })

  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  // react states...
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [stateSuggChecker, setStateSuggChecker] = useState(true);
  const [showSuggestionEway, setShowSuggestionEway] = useState(false);
  const [stateSuggCheckerEway, setStateSuggCheckerEway] = useState(true);
  const [vehicle_no, setVehicle_number] = useState();
  const [eWay_no, setEway_number] = useState();
  const [isLoader, setIsLoader] = useState();

  // use ref
  let state_suggesREF = useRef();
  const inputElement = useRef();
  let state_suggesREFEway = useRef();
  const inputElementEway = useRef();

  // vehicle number suggestion data store in given array
  let stateSuggestionData = [];
  let ewaySuggestion = [];


  // store vehicle suggestion data in local storage and get START HERE
  if (!ls.get("stateSuggestionData")) {
    ls.set("stateSuggestionData", stateSuggestionData);
  }

  let stateArray = [];
  if (ls.get("stateSuggestionData")) {
    stateArray = ls.get("stateSuggestionData");
  }
  // store vehicle suggestion data in local storage and get END HERE

  // store vehicle suggestion data in local storage and get START HERE
  if (!ls.get("ewaySuggestion")) {
    ls.set("ewaySuggestion", ewaySuggestion);
  }

  let ewayArray = [];
  if (ls.get("ewaySuggestion")) {
    ewayArray = ls.get("ewaySuggestion");
  }
  // remove duplication of suggestion data array START HERE
  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }
  // remove duplication of suggestion data array START HERE

  // onFocusVehicleNumber(e)
  function onFocusVehicleNumber(e) {
    e.preventDefault();
    setStateSuggChecker(false);
    !stateSuggChecker ? setShowSuggestion(true) : setShowSuggestion(false);
  }

  // stackClicking(e)
  function stackClicking(e, elms) {
    inputElement.current.value = elms;
    vehicleInputChange(elms, true);
    setShowSuggestion(false);
  }
  function onFocusEwayNumber(e) {
    e.preventDefault();
    setStateSuggCheckerEway(false);
    !stateSuggCheckerEway ? setShowSuggestionEway(true) : setShowSuggestionEway(false);
  }

  // stackClicking(e)
  function stackClickingEway(e, elms) {
    inputElementEway.current.value = elms;
    eWayInputChange(elms, true);
    setShowSuggestionEway(false);
  }
  const dispatch = useDispatch();
  const nav = useNavigate();

  // vehicleInputChange(e)
  function vehicleInputChange(e, check) {
    if (check) {
      setVehicle_number(e);
      // console.log("withTrue"+vehicle_no);
    } else {
      e.preventDefault();
      const value = e.target.value.replace(/\s/g, "").toUpperCase();
      setVehicle_number(value);
      // console.log("withFalse"+vehicle_no);
    }
  }

  // eWayInputChange(e)
  function eWayInputChange(e, check) {
    if (check) {
      setEway_number(e);
      // console.log("withTrue"+vehicle_no);
    } else {
      e.preventDefault();
      const value = e.target.value.replace(/\s/g, "").toUpperCase();
      setEway_number(value);
      // console.log("withFalse"+vehicle_no);
    }
  }

  // clickTrackFunction(e) Track Thorugh vehicle number
  function clickTrackFunction(e) {
    if (!iLogin) {
      window.alert("please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;

      const user_id = login_response_object.user._id;

      setIsLoader(true);
      if (
        inputElement.current.value.length > 12 ||
        inputElement.current.value.length < 9
      ) {
        window.alert("vehicle number must have 9 to 12 digits");
        setIsLoader(false);
      } else if (inputElement.current.value.length == 0) {
        window.alert("please enter vehicle number");
        setIsLoader(false);
      } else if (!isFinite(inputElement.current.value.slice(-4))) {
        window.alert("please enter valid vehicle number");
        setIsLoader(false);
      } else {
        if (balanceOBJ.credits < 1) {
          window.alert("please buy plan");
          nav("/all-plans");
        } else {
          ls.set("track_vehicle_no", vehicle_no, { encrypt: true });
          const stateSuggestionDataLocal = ls.get("stateSuggestionData");
          stateSuggestionDataLocal.push(vehicle_no);
          ls.set("stateSuggestionData", stateSuggestionDataLocal);
          // axios post
          axios
            .post(
              trackVehicle,
              {
                vehicle_no: vehicle_no,
                u_id: user_id,
              },
              {
                headers: {
                  Authorization: `Hello ${token_user}`,
                },
              }
            )
            .then(function (response) {
              if (response.data.success === true) {
                console.log("response--" + JSON.stringify(response.data.data));
                dispatch(addVehicleTrackData(response.data.data));
                console.log("lines...");
                console.log(response.data.data, "klllll");
                ls.set("trackVehicleData", response.data.data, {
                  encrypt: true,
                });
                setIsLoader(false);
                nav("/when-click-track");
              }
            })
            .catch(function (error) {
              setIsLoader(false);
              console.log(error);
              if (error.response.status == "500") {
                window.alert(`Internal Server Error`);
              }
              if (error.response.status == "400") {
                window.alert(
                  `Vehicle no. not available in ULIP/vehicle has not crossed any toll in last 72 hours/incorrect vehicle number`
                );
              }
              if (error.response.status == "404") {
                window.alert("404 Error");
              }
            });
        }
      }
    }
  }

  // clickTrackFunctionEway(e) Track through EWay number
  function clickTrackFunctionEway(e) {
    if (!iLogin) {
      window.alert("Please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;
      console.log(token_user, "token_user")
      const user_id = login_response_object.user._id;

      setIsLoader(true);

      if (eWay_no.length !== 12) {
        window.alert("E-way number must have 12 digits");
        setIsLoader(false);
      } else if (!/^\d+$/.test(eWay_no)) {
        window.alert("Please enter a valid Eway bill number (numeric only)");
        setIsLoader(false);
      } else if (eWay_no.length === 0) {
        window.alert("Please enter Eway bill number");
        setIsLoader(false);
      } else if (!isFinite(eWay_no.slice(-4))) {
        window.alert("Please enter a valid Eway bill number");
        setIsLoader(false);
      } else {
        if (balanceOBJ.credits < 1) {
          window.alert("Please buy a plan");
          nav("/all-plans");
        } else {
          // Set your API key here
          const apiKey = "Jainish";

          // Set headers with the API key
          const headers = {
            Authorization: `Hello ${token_user}`,
            apiKey,
          };

          setIsLoader(false);
          ls.set("eWay_no", eWay_no, { encrypt: false });

          const ewaySuggestionLocal = ls.get("ewaySuggestion");
          ewaySuggestionLocal.push(eWay_no);
          ls.set("ewaySuggestion", ewaySuggestionLocal);
          axios
            .get(
              `${trackEway}/${eWay_no}`,
              // Pass headers as the second parameter
              { headers: headers }
            )
            .then(function (response) {
              if (response.data.success === true) {
                console.log("response--" + JSON.stringify(response.data.data[0].response));
                dispatch(addewayTrackData(response.data.data[0].response));
                console.log("lines...");
                console.log(response.data.data[0].response, "dfghjk");
                ls.set("trackEwayData", response.data.data[0].response, {
                  encrypt: true,
                });
                const vehicleList = response.data.data[0].response.VehiclListDetails;

                // Check if the array is not empty
                if (vehicleList.length > 0) {
                  // Get the last element of the array
                  const lastVehicle = vehicleList[vehicleList.length - 1];

                  // Access the vehicleNo property from the last element
                  const lastVehicleNo = lastVehicle.vehicleNo;

                  // Now you can use lastVehicleNo as needed
                  console.log("Last Vehicle No:", lastVehicleNo);

                  // If you want to store it using ls.set, you can use your existing code
                  ls.set("eway_latest_vehicle", lastVehicleNo, { encrypt: false });
                } else {
                  console.log("The array is empty");
                }

                // ls.set("eway_latest_vehicle", response.data.data[0].response.VehiclListDetails, { encrypt: false })
                setIsLoader(false);
                nav("/when-click-track-EWay");
              }
              console.log(response, "response ewayyy");
            })
            .catch(function (error) {
              // Handle error
              console.error(error, "errorWway");
            });
        }
      }
    }
  }

  const tip1 = "View last toll crossing location";
  const tip2 = "cost 1 tracking credit";

  return (
    <>
      <div
        className="h-[100%]"
        onClick={() => {
          stateSuggChecker ? setShowSuggestion(false) : setShowSuggestion(true);
          stateSuggCheckerEway ? setShowSuggestionEway(false) : setShowSuggestionEway(true);
        }}
      >
        {/* <div id="google_translate_element"></div> */}
        <div className="main-container-home" style={{ height: "75vh" }}>
          <div className="navigation-Bar">
            <NavBar />
          </div>

          <div className="w-[90%] m-auto flex flex-col justify-center md:w-[80%] lg:w-[70%]">
            <div className="heading text-white font-Cardo text-[2.5rem] mt-10 mb-10  sm:text-6xl md:text-[4rem] lg:text-7xl">
              <div className="only_mobile" style={{ display: "none" }}>
                <Subnavbar />
              </div>
              <p className="title text-3xl">
                {eng ? Home_tagline_En : Home_tagline_Hn}
              </p>
            </div>
            {/* Track by Vehicle Number Start */}
            <div className=" -mt-8">
              {/* 1 */}
              <div
                style={{
                  boxShadow:
                    " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                }}
                className="btn bg-white py-3 rounded-xl cursor-pointer m-auto text-black p-5"
                onClick={() => {
                  stateSuggChecker
                    ? setShowSuggestion(false)
                    : setShowSuggestion(false);
                  setStateSuggChecker(true);
                }}
              >
                <p className="font-Cardo font-normal text-start text-black">
                  Track{" "}
                  <b className="text-xl" style={{ color: "red" }}>
                    Any<span>*</span>
                  </b>{" "}
                  Vehicle
                </p>
                <div className="flex flex-col justify-between md:items-center md:justify-center md:flex-row gap-4">
                  <Tooltip
                    title="Enter your vehicle number here"
                    placement="left"
                  >
                    <div
                      className="inputFeild relative my-[10px] w-full md:w-[50%] lg:w-[50%]"
                      id="disker1"
                    >
                      <input
                        onChange={(e) => {
                          vehicleInputChange(e, false);
                        }}
                        ref={inputElement}
                        name="vehicle_number"
                        autoComplete="off"
                        type="text"
                        placeholder="Example GJ18AH0998"
                        onFocus={(e) => {
                          onFocusVehicleNumber(e);
                        }}
                        className="border-none  border-gray-400 uppercase text-black rounded-sm p-1 w-[100%] flex md:w-[100%] lg:py-1 lg:pl-4 mobile_planbtn_home"
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      />
                      <div
                        className="suggestion__box"
                        style={
                          showSuggestion
                            ? {
                              display: "flex",
                              alignItems: "start",
                              flexDirection: "column",
                              background: "#ffffffa6",
                            }
                            : { display: "none" }
                        }
                      >
                        <div className="max-h-[10rem] w-[100%] overflow-auto suggestion_inner_div">
                          {removeDuplicates(stateArray).map((elm, index) => {
                            return (
                              <div
                                ref={state_suggesREF}
                                key={`${elm}${index}`}
                                className="font-normal text-start hover_on_suggestion w-[100%] uppercase mt-5"
                                onClick={(e) => {
                                  stackClicking(e, elm);
                                }}
                              >
                                {elm}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Tooltip>

                  <Tooltip
                    title={
                      <div style={{ textAlign: "center" }}>
                        {tip1}
                        <br />
                        {tip2}
                      </div>
                    }
                    placement="bottom"
                  >
                    <div className="flex justify-between md:w-[30%] md:justify-evenly lg:w-[30%]">
                      <div
                        className="max-md:w-[100%]  2xl:w-full md:w-full "
                        onClick={(e) => {
                          clickTrackFunction(e);
                        }}
                      >
                        <div className="firstButton bg-black text-white py-1 rounded px-4 text-sm lg:w-60 ">
                          <div className=" flex justify-center gap-2 lg:py-1">
                            <div className="msg">Track</div>
                            <div className="flex justify-center items-center">
                              <Link className="">
                                <Track />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tooltip>

                  <Tooltip
                    title="Create journey and track vehicle"
                    placement="bottom"
                  >
                    <Button
                      className="mobile_planbtn_home"
                      style={{
                        background: "black",
                        color: "white",
                        width: "30%",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        nav("/MyJourney");
                      }}
                    >
                      Plan Journey{" "}
                      <img
                        src="planJICon.svg"
                        alt=""
                        style={{ width: "1rem" }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              {/* 1 end */}
              <div className="title text-3xl" style={{ marginTop: '3rem' }}><p>OR</p></div>

              {/* 2 */}
              <div style={{ marginTop: '4rem' }}>
                <div
                  style={{
                    boxShadow:
                      " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                  }}
                  className="btn bg-white py-3 rounded-xl cursor-pointer m-auto text-black p-5"
                  onClick={() => {
                    stateSuggCheckerEway
                      ? setShowSuggestionEway(false)
                      : setShowSuggestionEway(false);
                    setStateSuggCheckerEway(true);
                  }}
                >
                  <p className="font-Cardo font-normal text-start text-black">
                    Track{" "}
                    <b className="text-xl" style={{ color: "red" }}>
                      Ewaybill<span>*</span>
                    </b>{" "}
                    Validity And Vehicle Location
                  </p>
                  <div className="flex flex-col justify-between md:items-center md:justify-flex-start md:flex-row gap-4">
                    <Tooltip
                      title="Enter your E-way bill number here"
                      placement="left"
                    >
                      <div
                        className="inputFeild relative my-[10px] w-full md:w-[80%] lg:w-[50%]"
                      // id="disker1"
                      >
                        <input
                          onChange={(e) => {
                            eWayInputChange(e, false);
                          }}
                          ref={inputElementEway}
                          // name="vehicle_number"
                          autoComplete="off"
                          type="text"
                          placeholder="Enter Ewaybill Number                                               "
                          onFocus={(e) => {
                            onFocusEwayNumber(e);
                          }}
                          className="border-none  border-gray-400 uppercase text-black rounded-sm p-1 w-[100%] flex md:w-[100%] lg:py-1 lg:pl-4 mobile_planbtn_home"
                          style={{
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                        />
                        {/* Show suggestion in Eway start */}
                        <div
                          className="suggestion__box"
                          style={
                            showSuggestionEway
                              ? {
                                display: "flex",
                                alignItems: "start",
                                flexDirection: "column",
                                background: "#ffffffa6",
                              }
                              : { display: "none" }
                          }
                        >
                          <div className="max-h-[10rem] w-[100%] overflow-auto suggestion_inner_div">
                            {removeDuplicates(ewayArray).map((elm, index) => {
                              return (
                                <div
                                  ref={state_suggesREFEway}
                                  key={`${elm}${index}`}
                                  className="font-normal text-start hover_on_suggestion w-[100%] uppercase mt-5"
                                  onClick={(e) => {
                                    stackClickingEway(e, elm);
                                  }}
                                >
                                  {elm}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* shhow suggestion in Eway end */}
                      </div>
                    </Tooltip>

                    <Tooltip
                      title={
                        <div style={{ textAlign: "center" }}>
                          {tip1}
                          <br />
                          {tip2}
                        </div>
                      }
                      placement="bottom"
                    >
                      <div className="flex justify-between md:w-[30%] md:justify-evenly lg:w-[30%]">
                        <div
                          className="max-md:w-[100%]  2xl:w-full md:w-full "
                          onClick={(e) => {
                            clickTrackFunctionEway(e);
                          }}
                        >
                          <div className="firstButton bg-black text-white py-1 rounded px-4 text-sm lg:w-60 ">
                            <div className=" flex justify-center gap-2 lg:py-1">
                              <div className="msg">Track</div>
                              <div className="flex justify-center items-center">
                                <Link className="">
                                  <Track />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="text-start mt-3 text-sm">
                <b className=" text-md pl-2" style={{ color: "red" }}>
                  Any <span>*</span>
                </b>{" "}
                commercial vehicle
              </div>
              {/* #hagtag */}
              <div className="-mt-5 only_desktop"></div>

              <div className="only_desktop">
                <Subnavbar />
              </div>
              <p
                className="mt-2 hashtagOfMain text-3xl"
                style={{ fontFamily: "'Tiro Devanagari Hindi', serif" }}
              >
                #गाड़ीकहाँहै
              </p>

              <div className="intro flex flex-col justify-center -mt-8">
                <p
                  style={{ fontSize: "14px" }}
                  className="intro-para font-Inter  leading-5 sm:text-base sm:font-normal md:text-lg lg:text-xl mt-8 text-start"
                >
                  {eng ? Home_small_tag_En : Home_small_tag_Hn}
                </p>
              </div>
            </div>
            {/* Track by vehicle number End */}
          </div>
        </div>
      </div>

      <Footer />

      {/* loader */}
      <section
        style={isLoader ? { display: "flex" } : { display: "none" }}
        id="home_loader"
        className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center"
      >
        <CircularProgress />
      </section>
    </>
  );
};

export default Home;