import React, { useState } from "react";
import styled from "styled-components";
import { registerPost } from "../../../apis/api";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import ls from 'localstorage-slim'


const RStep7 = () => {
  const nav = useNavigate();

  // local register data get from local stoarge...
  const general = JSON.parse(ls.get('registerDataStep1', { decrypt: true }));
  const password = ls.get('register_password', { decrypt: true });
  const fullName = general.FName + " " + general.LName;


  // iniatize state variables...
  const [inputData, setInputData] = useState({
    name: fullName,
    company_name: "",
    company_address: "",
    mobile: "",
    email: general.email,
    password: password.pass
  });

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData)
  }

  // onContinue(e)
  async function onContinue(e) {
    // axios for post register data to backend START
    await axios.post(registerPost, inputData)
      .then(function (response) {
        console.log(response)
        if (response.data.success) {
          console.log(response.data.data._id)
          ls.set("user-id-when-signup", response.data.data._id, { encrypt: true })
          nav("/login");
        }
      })
      .catch(function (error) {
        console.log(error)
        window.alert("something went wrong")
      });
    //  axios post END

  }


  return (
    <div className="flex items-center w-full h-[100vh] justify-center">
      <Row className="py-8 w-[70%]">

        <div className="px-4 md:px-32 lg:px-36 flex items-center flex-col justify-center h-[30rem]">

          {/* top section */}
          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[80%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                keyboard_backspace
              </span></button>
              <div>
                <MainHeading HeadinName="Signup to LorryCare" />
                <span className="text-xs">Sign up / Register for FREE</span>
              </div>
              {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
            </div>

          </div>

          <div className="w-[80%]">

            {/* company name*/}
            <label htmlFor="">Company Name</label>
            <Input
              label="Company Name"
              onChange={(e) => {
                inputChange(e);
              }}
              type="text"
              name="company_name"
              placeholder="Example Lorem"
            />

            {/* company discription*/}
            <div className="pass w-[100%] relative mt-3">
              <label htmlFor="">Address</label>

              <textarea
                placeholder="Enter your address" name="company_address" onChange={(e) => {
                  inputChange(e);
                }}
                className="w-full mt-3 py-3 pr-9 pl-4 font-Inter rounded-[5px] inner_shad border-[0px]"
              />


              <div />
              {/* input field of company_name END */}
            </div>



            <div className="w-full mt-5">
              <Button variant="contained" style={{"background":"#232323"}} className="w-[100%]" onClick={(e) => { onContinue(e) }}>
                Continue
              </Button>
              <div className="text-sm mt-2 text-center flex justify-between w-[100%]"><Link className="underline" to={"/login"}>Have an account?</Link><b onClick={(e) => { onContinue(e) }} className="underline text-sm text-blue-600 cursor-pointer">Skip</b></div>
            </div>
          </div>

        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  
`;
const Input = styled.input`
margin-top: 0.75rem;
width: 100%;
padding: 0.75rem 2.25rem 0.75rem 1rem;
border-radius: 5px;
border: none;
outline:none !important;
box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
font-family: "Inter", sans-serif;
`;
export default RStep7;
