import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import TextField from '@mui/material/TextField'
import AccordianByTar from "../../components/final_components/AccordianByTar/AccordianByTar";
import Footer from "../../components/layouts/Footer/Footer"

const Faqs = () => {

    return (
        <>

            {/* navbar START*/}
            <SecondNavBar translationHide={"true"}/>
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Frequently Asked Questions"}
                small={""}
            >
            </TopSection>
            {/* top section Component END*/}

            {/* about us Main Card START */}
            <Card>

                <HeadingWithText head={"Have Questions? We’re here to help."} />

                <AccordianByTar />

            </Card>
            {/* about us Main Card END */}

            <Footer />
        </>
    );
};

export default Faqs;
