import React, { useState, useEffect, useRef } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import { isMobile } from "react-device-detect";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import "./ongoing.css";
import { useSelector, useDispatch } from "react-redux";
import {
  closeJourney,
  onGoingFetch,
  postFeedback,
  shareRoute,
  shareRoute2,
} from "../../apis/api";
import axios from "axios";
import ls from "localstorage-slim";
import { addBoolean } from "../../redux/slices/share_pop_up";
import { setIsShowFeed } from "../../redux/slices/isShowFeed";
import Footer from "../../components/layouts/Footer/Footer";
import { Button, TextField } from "@mui/material";
import { isShowFiveSec } from "../../redux/slices/five_sec_popup";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import {
  Active_Journey_Top_Heading_Hn,
  Active_Journey_Top_Subheading_Hn,
  Active_Default_table_message_Hn,
} from "../../components/translation/Hn";
import {
  Active_Journey_Top_Heading_En,
  Active_Journey_Top_Subheading_En,
  Active_Default_table_message_En,
} from "../../components/translation/En";
import CopyToClipboard from "react-copy-to-clipboard";
import MobileOnGoingJourney from "./Mobile_OnGoingJourney";

const OnGoingJourney = () => {
  const eng = useSelector((state) => state.translate.value);
  const nav = useNavigate();
  const closeJourneyID = useSelector(
    (state) => state.closeJourneyPostData.value
  );
  let isFiveSecPop = useSelector((state) => state.five_sec_popup.value);
  let isFiveSecPopFromLocal;
  if (ls.get("isFiveSecPopFromLocal")) {
    isFiveSecPopFromLocal = ls.get("isFiveSecPopFromLocal");
  } else {
    isFiveSecPopFromLocal = false;
  }
  let tableNew = [];
  const dispatch = useDispatch();
  const iLogin = ls.get("iLogin");
  let shareLink = useRef();
  const [count, setCount] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [dataLegnth, setDataLength] = useState();
  const showSharePop = useSelector((state) => state.share_pop_up.value);
  let shareLinkss = useSelector((state) => state.share_pop_up.link);
  const isFilterClick = useSelector((state) => state.isFilterClick.value);
  const isFilterClick2 = useSelector((state) => state.isFilterClick2.value);
  const isShowFeed = useSelector((state) => state.isShowFeed.value);
  let login_response_object = "",
    token_user = "",
    user_id = "";
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  let [apiData, setApiData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  let [apiData2, setApiData2] = useState([]);
  let [star, setStar] = useState(0);
  let [reload, setReload] = useState(0);
  let [suggestionData, setSuggestionData] = useState({
    star: star,
    discription: "",
  });
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500); // Reset the "copied" state after 1.5 seconds

    // You can also perform any other actions after copying, if needed
  };
  // function comparing data length to table

  // get ongoing data...
  useEffect(() => {
    axios
      .get(`${onGoingFetch}/${user_id}/${count}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          console.log("onGoingData" + JSON.stringify(response.data.data));
          setDataLength(response.data.data.totalData);
          setApiData(response.data.data.sendUser);
          setApiData2(response.data.data.sendUser);
        }
      })
      .catch((err) => {
        console.log("error" + err);
      });
  }, [count]);

  let tableData;
  const filterDestinationWise = useSelector((state) => state.filterData);
  const filterSourceWise = useSelector((state) => state.filterDataSource);
  // const [filterDestination,setFilterDestination] = useState();
  let filterDestination;
  let filterSource;
  if (filterDestinationWise.length !== 0) {
    filterDestination = apiData.filter(function (obj) {
      return (
        filterDestinationWise.indexOf(obj.destination[0].destination) !== -1
      );
    });
  }
  if (filterSourceWise.length !== 0) {
    filterSource = apiData.filter(function (obj) {
      return filterSourceWise.indexOf(obj.source[0].source) !== -1;
    });
  }
  console.log(filterSource);

  setTimeout(() => {
    ls.set("isFiveSecPopFromLocal", false);
    setReload(1);
  }, 3000);

  // get shorted and filter data
  const shortedFilterData = useSelector((state) => state.tableDatas.value);
  const totalRows = useSelector((state) => state.tableLegnth.value);

  // main logic for filter and sorting...
  // if (shortedFilterData === 0 && filterDestinationWise.length === 0) {
  //   tableData = apiData;
  // } else if (filterDestinationWise.length === 0) {
  //   if (shortedFilterData !== 0) {
  //     if (!isFilterClick) {
  //       tableData = shortedFilterData;
  //     } else {
  //       if (filterDestinationWise.length === 0) {
  //         tableData = apiData;
  //       }
  //     }
  //   }
  // } else {
  //   if (filterDestinationWise.length !== 0) {
  //     tableData = filterDestination;
  //     if (shortedFilterData !== 0) {
  //       tableData = shortedFilterData;
  //       if (isFilterClick) {
  //         tableData = filterDestination;
  //       }
  //     }
  //   }
  //   if (filterDestinationWise.length === 0) {
  //     if (shortedFilterData !== 0) {
  //       if (filterDestinationWise.length !== 0) {
  //         tableData = shortedFilterData;
  //       } else {
  //         tableData = apiData;
  //       }
  //     }
  //   }
  // }

  // testing code START  //changes start13
  if (
    shortedFilterData === 0 &&
    filterDestinationWise.length === 0 &&
    filterSourceWise.length === 0
  ) {
    tableData = apiData;
  } else {
    if (shortedFilterData === 0) {
      if (filterDestinationWise.length !== 0 && filterSourceWise.length !== 0) {
        // tableData = [...filterDestination, ...filterSource];
        tableData = filterDestination.filter((element) =>
          filterSource.includes(element)
        );
      } else if (filterSourceWise.length !== 0) {
        tableData = filterSource;
      } else if (filterDestinationWise.length !== 0) {
        tableData = filterDestination;
      }
    } else {
      tableData = shortedFilterData;
      if (filterDestinationWise.length !== 0 && filterSourceWise.length !== 0) {
        tableData = [...filterDestination, ...filterSource];
      } else if (filterSourceWise.length !== 0) {
        tableData = filterSource;
      } else if (filterDestinationWise.length !== 0) {
        tableData = filterDestination;
      }
    }
  }
  // testing code END

  console.log(token_user);
  // closeJourneyNow(e)
  function closeJourneyNow(e) {
    axios
      .post(`${postFeedback}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .put(`${closeJourney}/${closeJourneyID}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          ls.set("isFiveSecPopFromLocal", true);
          isFiveSecPopFromLocal = ls.get("isFiveSecPopFromLocal");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // handleChange(e)
  function handleChange(e, isClass) {
    let star = document.querySelectorAll(".star");
    if (isClass === "star_ox1") {
      star[0].style.boxShadow =
        " rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
      star[1].style.boxShadow = "none";
      star[2].style.boxShadow = "none";
      star[3].style.boxShadow = "none";
      star[4].style.boxShadow = "none";
    }
    if (isClass === "star_ox2") {
      star[1].style.boxShadow =
        "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
      star[0].style.boxShadow = "none";
      star[2].style.boxShadow = "none";
      star[3].style.boxShadow = "none";
      star[4].style.boxShadow = "none";
    }
    if (isClass === "star_ox3") {
      star[2].style.boxShadow =
        "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
      star[1].style.boxShadow = "none";
      star[0].style.boxShadow = "none";
      star[3].style.boxShadow = "none";
      star[4].style.boxShadow = "none";
    }
    if (isClass === "star_ox4") {
      star[3].style.boxShadow =
        "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
      star[1].style.boxShadow = "none";
      star[2].style.boxShadow = "none";
      star[0].style.boxShadow = "none";
      star[4].style.boxShadow = "none";
    }
    if (isClass === "star_ox5") {
      star[4].style.boxShadow =
        "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px";
      star[1].style.boxShadow = "none";
      star[2].style.boxShadow = "none";
      star[3].style.boxShadow = "none";
      star[0].style.boxShadow = "none";
    }
    setSuggestionData({ ...suggestionData, star: e.target.dataset.value });
    console.log(e.target.dataset.value);
  }

  function handleChange2(e) {
    setSuggestionData({ ...suggestionData, [e.target.name]: e.target.value });
    console.log(suggestionData);
  }

  // copyToclipboard
  function copyToclipboard(e) {
    // Get the text field
    var copyText = document.getElementById("myInput");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }

  // journey creadits calculation
  function dayCalculate(journeyDate, Current) {
    console.log(journeyDate + "=" + Current);
    var end_day, today;
    end_day = new Date(journeyDate);
    today = new Date(Current);

    //calculate time difference
    var time_difference = end_day.getTime() - today.getTime();

    //calculate days difference by dividing total milliseconds in a day
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return days_difference;
  }

  // csvData
  const csvData = tableData;

  // customizing csvs
  let latestCsv = [];
  tableData.forEach((elm) => {
    latestCsv.push({
      Journey_Id: elm.trip_no,
      Vehicle_Register_Number: elm.vehicle_no,
      Source: elm.source[0].source,
      Destination: elm.destination[0].destination,
      Start_Date: elm.start_date.slice(0, 10),
      End_Date: elm.end_date,
      Credits_Consumed:
        (dayCalculate(elm.end_date, elm.start_date.slice(0, 10)) + 1) * 10,
    });
  });

  return (
    <>
     {isMobile ? <>
        <MobileOnGoingJourney />
      </> :
    <>
      <div className="Navbar">
        <SecondNavBar active5={true} />
      </div>
      {/* top section Component */}
      <TopSection
        bigi={
          eng ? Active_Journey_Top_Heading_En : Active_Journey_Top_Heading_Hn
        }
        small={
          eng
            ? Active_Journey_Top_Subheading_En
            : Active_Journey_Top_Subheading_Hn
        }
      >
        <CSVLink
          style={{
            textDecoration: "none",
            display: "inline-block",
            background: "black",
            color: "white",
            borderRadius: "0.3rem",
            padding: "0.4rem 2rem",
            border: "none",
            cursor: "pointer",
          }}
          filename="All-OnGoing-journeys.csv"
          data={latestCsv}
          className={"download_scv"}
        >
          Download All to CSV
        </CSVLink>
        <Filter tableData={tableData} tableData22={apiData2} />
      </TopSection>
      {/* top section Component END*/}

      {/* common table START */}
      <CommonTable
        type={"1"}
        tableHead={[
          "vehicle registration number",
          "origin",
          "destination",
          "journey id",
          "start date",
          "end date",
          "credits consumed",
          "",
          "",
        ]}
        tableData={tableData}
        defaultMessage={
          eng
            ? Active_Default_table_message_En
            : Active_Default_table_message_Hn
        }
        // ids={"ongoing_id"}
        extraCss={"tracker_main_container control_gap"}
      />
      {/* common table END */}
      <div className="flex justify-center pb-8">
        <Button
          variant="contained"
          style={
            apiData.length < 5 ? { display: "none" } : { background: "#232323" }
          }
          onClick={() => {
            setCount(count + 5);
          }}
        >
          Load More
        </Button>
      </div>

      {/* <div>
        <RWebShare
          data={{
            url: shareLinkss,
            title: "sasas",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button>Share 🔗</button>
        </RWebShare>
      </div> */}

      <Footer />

      {/* share popup 2 */}
      <div style={showSharePop ? { display: "block" } : { display: "none" }}>
        <section className="pop_up_main_wrapper_ongoing z-[999] ">
          <div className="main_popup_2">
            <div className="close_icon flex justify-between items-start">
              <p className="track_popup1_mess2 font-bold">Share</p>
              <span
                onClick={() => {
                  dispatch(addBoolean(false));
                }}
                className="material-symbols-outlined"
                style={{
                  background: "#F8F9FA",
                  borderRadius: "50%",
                  padding: "0.1rem",
                  cursor: "pointer",
                }}
              >
                close
              </span>
            </div>

            <div className="mt-5 flex justify-between">
              <div>
                <RWebShare
                  data={{
                    url: `${shareRoute2}${shareLinkss}`,
                    title: "sasas",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <button className={"only_desktop"}>
                    Share on any social media 🔗
                  </button>
                </RWebShare>
                <div className="only_mobile" style={{ display: "none" }}>
                  <input type="text" value={`${shareRoute2}${shareLinkss}`} />
                  <CopyToClipboard text={`${shareRoute2}${shareLinkss}`} onCopy={handleCopy}>
                    <button style={{fontSize:"0.7rem"}}>Copy</button>
                  </CopyToClipboard>
                  {isCopied ? <span style={{ color: 'green' }}>Copied!</span> : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* suggestion and feddback popup */}
      <div style={isShowFeed ? { display: "block" } : { display: "none" }}>
        <section className="pop_up_main_wrapper_ongoing z-[999]">
          <div className="pop_container_feedback p-5 h-fit bg-white">
            {/* top */}
            <div className="feedback_top flex justify-between">
              <h1 className="text-xl font-Cardo">
                Give Feedbacks and Suggestion
              </h1>
              <span
                className="material-symbols-outlined ml-5 w-fit cursor-pointer"
                onClick={() => {
                  dispatch(setIsShowFeed(false));
                }}
              >
                close
              </span>
            </div>

            {/* middle */}
            <div className="star_system flex">
              {/* star box START*/}
              <div
                className="star star_ox1 bg-black m-4 flex flex-col items-center p-2 rounded-md cursor-pointer"
                data-value={1}
                onClick={(e) => {
                  handleChange(e, "star_ox1");
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-value={1}
                >
                  <path
                    d="M9.56641 0.0429688C7.625 0.230469 5.69922 1.02344 4.19141 2.25C3.74609 2.60938 3.04297 3.32031 2.69141 3.76172C1.79688 4.88281 1.0625 6.39844 0.757812 7.75781C0.566406 8.60938 0.523438 9.02734 0.523438 10.0039C0.523438 10.9805 0.566406 11.3984 0.757812 12.25C1.06641 13.6367 1.82031 15.1758 2.74609 16.3125C3.10547 16.7578 3.81641 17.4609 4.25781 17.8125C5.37891 18.707 6.89453 19.4414 8.25391 19.7461C9.10547 19.9375 9.52344 19.9805 10.5 19.9805C11.4766 19.9805 11.8945 19.9375 12.7461 19.7461C13.7656 19.5195 15.1016 18.9492 15.9805 18.3672C17.5 17.3555 18.668 16.0273 19.4688 14.3984C19.957 13.4023 20.2344 12.5078 20.4102 11.3711C20.5039 10.7422 20.5039 9.26562 20.4102 8.63672C20.2344 7.5 19.957 6.60547 19.4688 5.60938C18.9453 4.54688 18.3633 3.73438 17.5234 2.90625C16.6992 2.08984 15.9453 1.55469 14.8945 1.03906C13.9102 0.554688 12.9961 0.269531 11.9062 0.101562C11.4141 0.0273438 10.0781 -0.00390625 9.56641 0.0429688ZM11.8867 1.36719C12.8242 1.52344 13.5234 1.75 14.3867 2.17188C16.6133 3.26562 18.3359 5.36719 18.957 7.76172C19.168 8.57031 19.2266 9.05469 19.2266 10.0039C19.2266 10.9531 19.168 11.4375 18.957 12.2461C18.3359 14.6406 16.6133 16.7422 14.3867 17.8359C13.5234 18.2578 12.8359 18.4805 11.8672 18.6445C11.1641 18.7656 9.83594 18.7656 9.13281 18.6445C8.16406 18.4805 7.47656 18.2578 6.61328 17.8359C4.38672 16.7422 2.66406 14.6406 2.04297 12.2461C1.83203 11.4375 1.77344 10.9531 1.77344 10.0039C1.77344 9.05469 1.83203 8.57031 2.04297 7.76172C2.25781 6.93359 2.70703 5.92578 3.17578 5.21875C4.66797 2.97656 6.97266 1.57812 9.66016 1.29297C10.1641 1.23828 11.3555 1.27734 11.8867 1.36719Z"
                    fill="white"
                  />
                  <path
                    d="M6.10547 5.62891L5.67578 6.05859L6.39844 6.78125L7.12109 7.50391H6.30859H5.5V8.12891V8.75391H7.11328C8.61719 8.75391 8.73437 8.74609 8.88672 8.67578C9.06641 8.59766 9.19531 8.42578 9.23437 8.21875C9.28906 7.9375 9.23437 7.86719 7.87109 6.49609C7.15625 5.78516 6.56641 5.19922 6.55469 5.19922C6.54297 5.19922 6.33984 5.39453 6.10547 5.62891Z"
                    fill="white"
                  />
                  <path
                    d="M13.1117 6.51562C11.971 7.66406 11.7953 7.85547 11.7718 7.98047C11.7328 8.19141 11.7757 8.37891 11.8968 8.52344C12.0921 8.75391 12.1038 8.75391 13.8812 8.75391H15.5023V8.12891V7.50391H14.6937H13.8812L14.6038 6.78125L15.3265 6.05859L14.8968 5.62891C14.6624 5.39453 14.4593 5.19922 14.4476 5.19922C14.4359 5.19922 13.8343 5.79297 13.1117 6.51562Z"
                    fill="white"
                  />
                  <path
                    d="M9.85547 10.0469C8.70703 10.2031 7.70703 10.7148 6.89844 11.5547C6.07813 12.4102 5.60938 13.4609 5.51172 14.6602L5.48438 15.0039H6.11719H6.74609V14.8555C6.74609 14.4531 6.91797 13.8047 7.14063 13.3516C8.05859 11.4922 10.293 10.7305 12.1445 11.6445C12.8984 12.0195 13.4805 12.5977 13.8516 13.3516C14.0742 13.8047 14.2461 14.4531 14.2461 14.8555V15.0039H14.875H15.5078L15.4805 14.6602C15.3789 13.3945 14.8594 12.293 13.957 11.418C13.2461 10.7344 12.4297 10.3008 11.4531 10.1016C11.0781 10.0234 10.2266 9.99219 9.85547 10.0469Z"
                    fill="white"
                  />
                </svg>

                <p className="text-white" data-value={1}>
                  Terrible
                </p>
              </div>
              {/* star box END*/}

              {/* star box START*/}
              <div
                className="star star_ox2 bg-black m-4 flex flex-col items-center p-2 rounded-md cursor-pointer"
                data-value={2}
                style={{ width: "73.5px" }}
                onClick={(e) => {
                  handleChange(e, "star_ox2");
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-value={2}
                >
                  <path
                    d="M9.56641 0.0390625C7.625 0.226562 5.69922 1.01953 4.19141 2.24609C3.74609 2.60547 3.04297 3.31641 2.69141 3.75781C1.79688 4.87891 1.0625 6.39453 0.757812 7.75391C0.566406 8.60547 0.523438 9.02344 0.523438 10C0.523438 10.9766 0.566406 11.3945 0.757812 12.2461C1.06641 13.6328 1.82031 15.1719 2.74609 16.3086C3.10547 16.7539 3.81641 17.457 4.25781 17.8086C5.37891 18.7031 6.89453 19.4375 8.25391 19.7422C9.10547 19.9336 9.52344 19.9766 10.5 19.9766C11.4766 19.9766 11.8945 19.9336 12.7461 19.7422C13.7656 19.5156 15.1016 18.9453 15.9805 18.3633C17.5 17.3516 18.668 16.0234 19.4688 14.3945C19.957 13.3984 20.2344 12.5039 20.4102 11.3672C20.5039 10.7383 20.5039 9.26172 20.4102 8.63281C20.2344 7.49609 19.957 6.60156 19.4688 5.60547C18.9453 4.54297 18.3633 3.73047 17.5234 2.90234C16.6992 2.08594 15.9453 1.55078 14.8945 1.03516C13.9102 0.550781 12.9961 0.265625 11.9062 0.0976562C11.4141 0.0234375 10.0781 -0.0078125 9.56641 0.0390625ZM11.8867 1.36328C12.8242 1.51953 13.5234 1.74609 14.3867 2.16797C16.6133 3.26172 18.3359 5.36328 18.957 7.75781C19.168 8.56641 19.2266 9.05078 19.2266 10C19.2266 10.9492 19.168 11.4336 18.957 12.2422C18.3359 14.6367 16.6133 16.7383 14.3867 17.832C13.5234 18.2539 12.8359 18.4766 11.8672 18.6406C11.1641 18.7617 9.83594 18.7617 9.13281 18.6406C8.16406 18.4766 7.47656 18.2539 6.61328 17.832C4.38672 16.7383 2.66406 14.6367 2.04297 12.2422C1.83203 11.4336 1.77344 10.9492 1.77344 10C1.77344 9.05078 1.83203 8.56641 2.04297 7.75781C2.25781 6.92969 2.70703 5.92188 3.17578 5.21484C4.66797 2.97266 6.97266 1.57422 9.66016 1.28906C10.1641 1.23438 11.3555 1.27344 11.8867 1.36328Z"
                    fill="white"
                  />
                  <path
                    d="M6.99376 5.69629C6.56798 5.84863 6.29845 6.13379 6.17345 6.55957C6.06016 6.95801 6.18907 7.4502 6.49376 7.75488C6.97032 8.22754 7.77501 8.22754 8.25157 7.75488C8.72423 7.27832 8.72423 6.47363 8.25157 5.99707C7.93126 5.68066 7.3961 5.55176 6.99376 5.69629Z"
                    fill="white"
                  />
                  <path
                    d="M13.2464 5.69531C12.8206 5.84766 12.5511 6.13281 12.4261 6.55859C12.2659 7.12109 12.5628 7.74609 13.1136 8.00391C13.2893 8.08594 13.3909 8.10547 13.6253 8.10547C14.1292 8.10547 14.5393 7.84375 14.7542 7.38672C14.9651 6.9375 14.8597 6.35547 14.5042 5.99609C14.1839 5.67969 13.6487 5.55078 13.2464 5.69531Z"
                    fill="white"
                  />
                  <path
                    d="M9.84375 9.41406C7.22656 9.74609 5.21484 11.7812 4.91406 14.3945C4.89453 14.5859 4.875 14.8047 4.875 14.8711V15H5.49609H6.11328L6.13672 14.707C6.21484 13.7773 6.54687 12.9492 7.11328 12.2461C7.74609 11.4688 8.62891 10.9336 9.64062 10.7148C10.0937 10.6211 10.9062 10.6211 11.3594 10.7148C12.3711 10.9336 13.2539 11.4688 13.8867 12.2461C14.4531 12.9492 14.7852 13.7773 14.8633 14.707L14.8867 15H15.5117H16.1367L16.1094 14.6367C15.9141 12.0664 14.1055 10.0039 11.5937 9.49219C11.125 9.39844 10.2852 9.35938 9.84375 9.41406Z"
                    fill="white"
                  />
                </svg>

                <p className="text-white" data-value={2}>
                  Bad
                </p>
              </div>
              {/* star box END*/}

              {/* star box START*/}
              <div
                className=" star star_ox3 bg-black m-4 flex flex-col items-center p-2 rounded-md cursor-pointer"
                style={{ width: "73.5px" }}
                data-value={3}
                onClick={(e) => {
                  handleChange(e, "star_ox3");
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-value={3}
                >
                  <path
                    d="M9.56641 0.0390625C7.625 0.226562 5.69922 1.01953 4.19141 2.24609C3.74609 2.60547 3.04297 3.31641 2.69141 3.75781C1.79688 4.87891 1.0625 6.39453 0.757812 7.75391C0.566406 8.60547 0.523438 9.02344 0.523438 10C0.523438 10.9766 0.566406 11.3945 0.757812 12.2461C1.06641 13.6328 1.82031 15.1719 2.74609 16.3086C3.10547 16.7539 3.81641 17.457 4.25781 17.8086C5.37891 18.7031 6.89453 19.4375 8.25391 19.7422C9.10547 19.9336 9.52344 19.9766 10.5 19.9766C11.4766 19.9766 11.8945 19.9336 12.7461 19.7422C13.7656 19.5156 15.1016 18.9453 15.9805 18.3633C17.5 17.3516 18.668 16.0234 19.4688 14.3945C19.957 13.3984 20.2344 12.5039 20.4102 11.3672C20.5039 10.7383 20.5039 9.26172 20.4102 8.63281C20.2344 7.49609 19.957 6.60156 19.4688 5.60547C18.9453 4.54297 18.3633 3.73047 17.5234 2.90234C16.6992 2.08594 15.9453 1.55078 14.8945 1.03516C13.9102 0.550781 12.9961 0.265625 11.9062 0.0976562C11.4141 0.0234375 10.0781 -0.0078125 9.56641 0.0390625ZM11.8867 1.36328C12.8242 1.51953 13.5234 1.74609 14.3867 2.16797C16.6133 3.26172 18.3359 5.36328 18.957 7.75781C19.168 8.56641 19.2266 9.05078 19.2266 10C19.2266 10.9492 19.168 11.4336 18.957 12.2422C18.3359 14.6367 16.6133 16.7383 14.3867 17.832C13.5234 18.2539 12.8359 18.4766 11.8672 18.6406C11.1641 18.7617 9.83594 18.7617 9.13281 18.6406C8.16406 18.4766 7.47656 18.2539 6.61328 17.832C4.38672 16.7383 2.66406 14.6367 2.04297 12.2422C1.83203 11.4336 1.77344 10.9492 1.77344 10C1.77344 9.05078 1.83203 8.56641 2.04297 7.75781C2.25781 6.92969 2.70703 5.92188 3.17578 5.21484C4.66797 2.97266 6.97266 1.57422 9.66016 1.28906C10.1641 1.23438 11.3555 1.27344 11.8867 1.36328Z"
                    fill="white"
                  />
                  <path
                    d="M6.99376 5.69629C6.56798 5.84863 6.29845 6.13379 6.17345 6.55957C6.06016 6.95801 6.18907 7.4502 6.49376 7.75488C6.97032 8.22754 7.77501 8.22754 8.25157 7.75488C8.72423 7.27832 8.72423 6.47363 8.25157 5.99707C7.93126 5.68066 7.3961 5.55176 6.99376 5.69629Z"
                    fill="white"
                  />
                  <path
                    d="M13.2464 5.69531C12.8206 5.84766 12.5511 6.13281 12.4261 6.55859C12.2659 7.12109 12.5628 7.74609 13.1136 8.00391C13.2893 8.08594 13.3909 8.10547 13.6253 8.10547C14.1292 8.10547 14.5393 7.84375 14.7542 7.38672C14.9651 6.9375 14.8597 6.35547 14.5042 5.99609C14.1839 5.67969 13.6487 5.55078 13.2464 5.69531Z"
                    fill="white"
                  />
                  <path
                    d="M6.125 12.5V13.125H10.5H14.875V12.5V11.875H10.5H6.125V12.5Z"
                    fill="white"
                  />
                </svg>

                <p className="text-white" data-value={3}>
                  Okay
                </p>
              </div>
              {/* star box END*/}

              {/* star box START*/}
              <div
                className=" star star_ox4 bg-black m-4 flex flex-col items-center p-2 rounded-md cursor-pointer"
                style={{ width: "73.5px" }}
                data-value={4}
                onClick={(e) => {
                  handleChange(e, "star_ox4");
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-value={4}
                >
                  <path
                    d="M9.56244 0.0221977C7.61685 0.210077 5.68692 1.00465 4.17587 2.23369C3.72959 2.59379 3.02496 3.30617 2.67264 3.74846C1.77618 4.87183 1.04022 6.39051 0.73488 7.75264C0.543061 8.60592 0.5 9.02473 0.5 10.0033C0.5 10.9818 0.543061 11.4006 0.73488 12.2539C1.04414 13.6434 1.79967 15.1856 2.72744 16.3246C3.08759 16.7708 3.80006 17.4754 4.24242 17.8276C5.36592 18.724 6.88481 19.4598 8.24711 19.7652C9.10051 19.9569 9.51938 20 10.498 20C11.4767 20 11.8956 19.9569 12.749 19.7652C13.7707 19.5381 15.1095 18.9667 15.9903 18.3835C17.5131 17.3697 18.6836 16.0389 19.4861 14.4067C19.9754 13.4086 20.2534 12.5122 20.4295 11.3732C20.5235 10.743 20.5235 9.2635 20.4295 8.63332C20.2534 7.4943 19.9754 6.59796 19.4861 5.59986C18.9615 4.53521 18.3783 3.72107 17.5366 2.89127C16.7106 2.07321 15.9551 1.53697 14.902 1.02031C13.9155 0.53495 12.9995 0.249218 11.9073 0.0809097C11.4141 0.0065403 10.0753 -0.0247726 9.56244 0.0221977ZM11.8877 1.34909C12.8273 1.50566 13.528 1.73268 14.3931 2.15541C16.6245 3.25137 18.3509 5.35718 18.9733 7.75655C19.1847 8.56678 19.2434 9.05213 19.2434 10.0033C19.2434 10.9544 19.1847 11.4398 18.9733 12.25C18.3509 14.6494 16.6245 16.7552 14.3931 17.8511C13.528 18.2739 12.839 18.497 11.8682 18.6614C11.1635 18.7827 9.83255 18.7827 9.12791 18.6614C8.15708 18.497 7.4681 18.2739 6.60296 17.8511C4.3716 16.7552 2.64523 14.6494 2.0228 12.25C1.81141 11.4398 1.75269 10.9544 1.75269 10.0033C1.75269 9.05213 1.81141 8.56678 2.0228 7.75655C2.23811 6.92675 2.6883 5.9169 3.15805 5.20844C4.65345 2.96172 6.9631 1.56046 9.65639 1.27472C10.1614 1.21993 11.3554 1.25907 11.8877 1.34909Z"
                    fill="white"
                  />
                  <path
                    d="M6.97999 5.68751C6.55329 5.84016 6.28318 6.12589 6.15791 6.55253C6.04438 6.95177 6.17357 7.44496 6.47891 7.75026C6.9565 8.22387 7.76292 8.22387 8.24051 7.75026C8.71418 7.27273 8.71418 6.46642 8.24051 5.98889C7.91951 5.67185 7.3832 5.54268 6.97999 5.68751Z"
                    fill="white"
                  />
                  <path
                    d="M13.2482 5.69044C12.8215 5.84309 12.5514 6.12883 12.4262 6.55547C12.2657 7.1191 12.5632 7.74537 13.1151 8.0037C13.2913 8.0859 13.3931 8.10547 13.628 8.10547C14.133 8.10547 14.544 7.84322 14.7593 7.38527C14.9707 6.93514 14.865 6.35193 14.5088 5.99183C14.1878 5.67478 13.6514 5.54562 13.2482 5.69044Z"
                    fill="white"
                  />
                  <path
                    d="M4.87897 10.3644C5.01598 12.1571 5.92809 13.7266 7.40784 14.7091C7.99503 15.0966 8.65661 15.3667 9.40431 15.5272C9.99151 15.6524 11.0093 15.6524 11.5965 15.5272C13.1154 15.2023 14.3642 14.3607 15.2058 13.0965C15.7265 12.3097 16.0475 11.3547 16.1219 10.3644L16.1493 10.0043H15.5229H14.8966L14.8731 10.2979C14.7948 11.2294 14.4621 12.0592 13.8944 12.7638C13.4051 13.3626 12.6339 13.9067 11.9293 14.1455C11.0328 14.4508 9.96802 14.4508 9.07157 14.1455C8.36693 13.9067 7.59574 13.3626 7.10641 12.7638C6.53878 12.0592 6.20604 11.2294 6.12774 10.2979L6.10425 10.0043H5.47791H4.85156L4.87897 10.3644Z"
                    fill="white"
                  />
                </svg>

                <p className="text-white" data-value={4}>
                  Good
                </p>
              </div>
              {/* star box END*/}

              {/* star box START*/}
              <div
                className=" star star_ox5 bg-black m-4 flex flex-col items-center p-2 rounded-md cursor-pointer"
                style={{ width: "73.5px" }}
                data-value={5}
                onClick={(e) => {
                  handleChange(e, "star_ox5");
                }}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  data-value={5}
                >
                  <path
                    d="M9.56641 0.0390625C7.625 0.226562 5.69922 1.01953 4.19141 2.24609C3.74609 2.60547 3.04297 3.31641 2.69141 3.75781C1.79688 4.87891 1.0625 6.39453 0.757812 7.75391C0.566406 8.60547 0.523438 9.02344 0.523438 10C0.523438 10.9766 0.566406 11.3945 0.757812 12.2461C1.06641 13.6328 1.82031 15.1719 2.74609 16.3086C3.10547 16.7539 3.81641 17.457 4.25781 17.8086C5.37891 18.7031 6.89453 19.4375 8.25391 19.7422C9.10547 19.9336 9.52344 19.9766 10.5 19.9766C11.4766 19.9766 11.8945 19.9336 12.7461 19.7422C13.7656 19.5156 15.1016 18.9453 15.9805 18.3633C17.5 17.3516 18.668 16.0234 19.4688 14.3945C19.957 13.3984 20.2344 12.5039 20.4102 11.3672C20.5039 10.7383 20.5039 9.26172 20.4102 8.63281C20.2344 7.49609 19.957 6.60156 19.4688 5.60547C18.9453 4.54297 18.3633 3.73047 17.5234 2.90234C16.6992 2.08594 15.9453 1.55078 14.8945 1.03516C13.9102 0.550781 12.9961 0.265625 11.9062 0.0976562C11.4141 0.0234375 10.0781 -0.0078125 9.56641 0.0390625ZM11.8867 1.36328C12.8242 1.51953 13.5234 1.74609 14.3867 2.16797C16.6133 3.26172 18.3359 5.36328 18.957 7.75781C19.168 8.56641 19.2266 9.05078 19.2266 10C19.2266 10.9492 19.168 11.4336 18.957 12.2422C18.3359 14.6367 16.6133 16.7383 14.3867 17.832C13.5234 18.2539 12.8359 18.4766 11.8672 18.6406C11.1641 18.7617 9.83594 18.7617 9.13281 18.6406C8.16406 18.4766 7.47656 18.2539 6.61328 17.832C4.38672 16.7383 2.66406 14.6367 2.04297 12.2422C1.83203 11.4336 1.77344 10.9492 1.77344 10C1.77344 9.05078 1.83203 8.56641 2.04297 7.75781C2.25781 6.92969 2.70703 5.92188 3.17578 5.21484C4.66797 2.97266 6.97266 1.57422 9.66016 1.28906C10.1641 1.23438 11.3555 1.27344 11.8867 1.36328Z"
                    fill="white"
                  />
                  <path
                    d="M6.10547 5.625L5.67578 6.05469L6.39844 6.77734L7.12109 7.5H6.30859H5.5V8.125V8.75H7.11328C8.61719 8.75 8.73437 8.74219 8.88672 8.67188C9.06641 8.59375 9.19531 8.42188 9.23437 8.21484C9.28906 7.93359 9.23437 7.86328 7.87109 6.49219C7.15625 5.78125 6.56641 5.19531 6.55469 5.19531C6.54297 5.19531 6.33984 5.39062 6.10547 5.625Z"
                    fill="white"
                  />
                  <path
                    d="M13.1117 6.51172C11.971 7.66016 11.7953 7.85156 11.7718 7.97656C11.7328 8.1875 11.7757 8.375 11.8968 8.51953C12.0921 8.75 12.1038 8.75 13.8812 8.75H15.5023V8.125V7.5H14.6937H13.8812L14.6038 6.77734L15.3265 6.05469L14.8968 5.625C14.6624 5.39062 14.4593 5.19531 14.4476 5.19531C14.4359 5.19531 13.8343 5.78906 13.1117 6.51172Z"
                    fill="white"
                  />
                  <path
                    d="M5.87372 10.6797C5.70966 10.75 5.5495 10.9492 5.51825 11.1172C5.42841 11.5938 5.68232 12.7852 6.01435 13.4453C6.71357 14.8438 8.00263 15.8281 9.56513 16.1602C10.0222 16.2539 10.9831 16.2539 11.4401 16.1602C12.4987 15.9375 13.362 15.4648 14.0886 14.7109C14.9675 13.8008 15.4675 12.6211 15.4792 11.4375C15.4831 10.9844 15.4518 10.9023 15.2136 10.7266C15.1081 10.6445 15.0847 10.6445 10.5495 10.6367C6.81904 10.6289 5.96747 10.6367 5.87372 10.6797ZM14.1511 12.1094C13.8308 13.4766 12.7292 14.5703 11.3229 14.9102C10.9206 15.0078 10.0847 15.0078 9.68232 14.9102C8.27607 14.5703 7.1745 13.4766 6.85419 12.1094L6.7995 11.875H10.5026H14.2058L14.1511 12.1094Z"
                    fill="white"
                  />
                </svg>

                <p className="text-white" data-value={5}>
                  Amazing
                </p>
              </div>
              {/* star box END*/}
            </div>

            {/* input fied */}
            <div className="input_suggestion mb-4">
              <h1 className="text-xl font-Cardo my-3">
                Give Feedbacks and Suggestion
              </h1>
              <TextField
                placeholder="Your Response"
                name="discription"
                style={{ width: "100%" }}
                onChange={(e) => {
                  handleChange2(e);
                }}
              />
            </div>
            <h1></h1>
            <Button
              variant="contained"
              className=" w-[100%]"
              id={"load_more"}
              style={{ background: "black" }}
              onClick={(e) => {
                closeJourneyNow(e);
              }}
            >
              Close Journey
            </Button>
          </div>
        </section>
      </div>

      {/* 5 sec popup after close journey from toll plaza list page */}
      <div
        className={isFiveSecPopFromLocal ? "five_sec_popup_show" : "hidden"}
        style={{ background: "#d4edda", color: "#155724" }}
      >
        you can see all closed journey on past journey page
      </div>
    </>}
    </>
  );
};

export default OnGoingJourney;
