import React, { useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import ls from "localstorage-slim";
import { logOut } from "../../../apis/api";
import axios from "axios";
import { useSelector } from "react-redux";

const ResponsiveNavbar = ({ classes1, classes2 }) => {
  const [isShow, setIsShow] = useState(false);
  const [showJourney, setShowJourney] = useState(false);
  const [showVehicle, setShowVehicle] = useState(false);
  const [showProfile, setProfile] = useState(false);
  const iLogin = ls.get("iLogin");
  const nav = useNavigate();

  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  let login_response_object, token_user, user_id;

  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  // Logout()
  function Logout() {
    axios
      .put(`${logOut}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {});
    localStorage.clear();
    nav("/login");
  }

  return (
    <>
      <div
        className={
          isShow
            ? classes1
              ? classes1
              : "topnav responsive "
            : classes2
            ? classes2
            : "topnav flex items-center justify-between px-8"
        }
        id="myTopnav"
      >
        <div>
          {/* logo */}
          <Link to="/">
            {/* <NavIcon /> */}
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="100px"
              height="90px"
              viewBox="0 0 584.000000 427.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,427.000000) scale(0.100000,-0.100000)"
                fill="#FFFFFF"
                stroke="none"
              >
                <path
                  d="M3015 4254 c-198 -30 -384 -102 -544 -208 -183 -121 -344 -296 -439
-475 -41 -76 -45 -80 -80 -83 l-37 -3 0 -65 0 -65 140 0 140 0 3 66 c3 60 1
67 -18 72 -11 3 -20 10 -20 15 0 21 103 163 171 236 248 266 627 412 979 378
314 -31 562 -150 775 -373 165 -172 270 -374 321 -619 21 -104 24 -342 5 -445
-49 -264 -166 -491 -346 -670 -174 -174 -387 -288 -630 -336 -90 -18 -368 -18
-450 0 -163 36 -327 104 -457 192 -131 88 -390 381 -361 410 4 4 53 10 108 11
l100 3 0 70 0 70 -249 3 c-193 2 -251 -1 -258 -10 -4 -7 -8 -38 -8 -69 0 -65
5 -69 80 -69 l48 0 38 -68 c194 -351 504 -582 914 -679 96 -23 418 -25 525 -4
445 88 831 404 996 817 77 193 104 334 103 549 -1 232 -45 418 -147 620 -260
519 -833 817 -1402 729z"
                />
                <path
                  d="M2300 3420 l0 -70 180 0 180 0 0 -60 0 -60 -225 0 -225 0 0 -75 0
-75 225 0 225 0 0 -60 0 -60 -365 0 -365 0 0 -70 0 -70 365 0 365 0 0 -60 0
-60 -130 0 -130 0 0 -70 0 -70 130 0 130 0 0 -60 0 -60 -90 0 -90 0 0 -75 0
-75 150 0 150 0 0 600 0 600 -240 0 -240 0 0 -70z"
                />
                <path
                  d="M2880 2890 l0 -600 94 0 c78 0 97 3 119 20 23 18 25 27 29 112 4 108
26 162 92 226 65 64 118 86 211 90 103 5 175 -21 240 -84 68 -66 99 -139 100
-242 2 -113 5 -116 147 -120 128 -4 178 7 207 45 20 24 21 39 21 280 0 200 -3
260 -14 282 -22 41 -171 86 -350 106 -76 8 -146 15 -156 15 -11 0 -43 47 -94
139 -76 135 -81 140 -138 173 -33 18 -92 54 -132 79 -95 62 -156 79 -278 79
l-98 0 0 -600z m440 415 c20 -10 49 -52 99 -140 39 -69 71 -127 71 -130 0 -3
-119 -5 -265 -5 l-265 0 0 145 0 145 165 0 c127 0 173 -3 195 -15z"
                />
                <path d="M1700 3155 l0 -75 190 0 190 0 0 75 0 75 -190 0 -190 0 0 -75z" />
                <path d="M1560 2890 l0 -70 125 0 125 0 0 70 0 70 -125 0 -125 0 0 -70z" />
                <path d="M2120 2630 l0 -70 75 0 75 0 0 70 0 70 -75 0 -75 0 0 -70z" />
                <path
                  d="M3321 2644 c-90 -45 -151 -162 -136 -257 23 -134 125 -223 255 -221
144 1 252 109 252 254 0 69 -22 123 -70 174 -52 54 -103 76 -183 76 -51 0 -77
-6 -118 -26z m205 -138 c30 -30 34 -41 34 -84 0 -115 -129 -171 -209 -91 -80
80 -24 209 91 209 43 0 54 -4 84 -34z"
                />
                <path
                  d="M678 727 c-91 -26 -185 -107 -227 -197 -36 -77 -42 -211 -13 -288 98
-256 425 -323 612 -126 78 83 95 128 95 254 0 89 -4 113 -24 157 -29 64 -122
155 -188 184 -61 27 -189 34 -255 16z m210 -146 c82 -42 122 -113 122 -213 -1
-138 -90 -228 -225 -228 -136 0 -228 93 -228 230 0 96 44 169 128 213 44 23
156 22 203 -2z"
                />
                <path
                  d="M3583 722 c-98 -35 -162 -91 -210 -186 -38 -73 -44 -216 -14 -298 60
-157 205 -248 374 -235 82 7 167 43 220 95 64 62 64 65 -2 105 l-59 35 -20
-28 c-31 -42 -98 -70 -165 -70 -69 0 -120 20 -165 63 -47 44 -64 91 -65 169 0
86 30 150 92 193 37 26 53 30 120 33 88 4 141 -15 181 -65 l21 -27 52 28 c29
16 56 32 61 36 15 14 -76 101 -139 132 -79 40 -201 49 -282 20z"
                />
                <path
                  d="M0 375 l0 -355 205 0 205 0 0 65 0 65 -135 0 -135 0 0 290 0 290 -70
0 -70 0 0 -355z"
                />
                <path
                  d="M1250 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 56 -3 70 -117 69
-118 75 0 75 0 -20 33 c-101 168 -134 231 -123 235 28 9 85 69 102 107 26 56
25 149 -2 200 -11 22 -36 55 -56 73 -56 50 -113 62 -290 62 l-153 0 0 -355z
m325 211 c18 -8 39 -25 46 -37 17 -33 15 -91 -5 -122 -23 -34 -64 -47 -152
-47 l-74 0 0 110 0 110 75 0 c43 0 90 -6 110 -14z"
                />
                <path
                  d="M1880 376 l0 -356 70 0 70 0 0 120 0 120 53 0 53 0 69 -120 70 -120
73 0 c39 0 72 2 72 6 0 3 -31 58 -70 123 -38 65 -70 121 -70 124 0 3 24 30 53
59 95 96 103 211 19 308 -64 75 -93 84 -289 88 l-173 4 0 -356z m331 201 c65
-43 62 -137 -5 -178 -23 -14 -50 -19 -108 -19 l-78 0 0 110 0 110 79 0 c65 0
84 -4 112 -23z"
                />
                <path
                  d="M2436 698 c9 -18 63 -115 120 -216 l104 -185 0 -138 0 -139 70 0 70
0 0 136 0 136 120 213 c66 117 120 216 120 220 0 3 -34 5 -76 3 l-77 -3 -75
-142 c-41 -79 -78 -143 -81 -143 -3 0 -40 66 -81 145 l-75 145 -78 0 -78 0 17
-32z"
                />
                <path
                  d="M4272 703 c-5 -15 -28 -80 -50 -143 -111 -315 -182 -522 -182 -530 0
-6 33 -10 73 -10 l74 0 18 60 18 60 148 0 148 0 22 -60 22 -60 68 0 c38 0 69
3 68 8 0 4 -55 162 -122 352 l-123 345 -86 3 -86 3 -10 -28z m152 -291 c25
-73 46 -135 46 -138 0 -2 -45 -4 -101 -4 -77 0 -100 3 -97 13 3 6 25 71 49
144 24 72 47 128 50 125 4 -4 27 -67 53 -140z"
                />
                <path
                  d="M4780 376 l0 -356 65 0 65 0 0 120 0 120 58 0 57 -1 68 -119 69 -120
74 0 c72 0 74 1 64 20 -6 11 -40 71 -76 132 -41 70 -61 114 -54 116 7 3 28 18
47 35 119 105 98 290 -44 374 -47 27 -52 28 -220 31 l-173 4 0 -356z m327 204
c70 -42 63 -154 -11 -185 -21 -9 -67 -15 -111 -15 l-75 0 0 110 0 110 83 0
c64 0 89 -4 114 -20z"
                />
                <path
                  d="M5400 375 l0 -355 220 0 220 0 0 65 0 65 -150 0 -150 0 0 80 0 80
135 0 135 0 0 65 0 65 -135 0 -135 0 0 75 0 75 145 0 145 0 0 70 0 70 -215 0
-215 0 0 -355z"
                />
              </g>
            </svg>
          </Link>

          {/* journey dropdown START*/}
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => {
                showJourney ? setShowJourney(false) : setShowJourney(true);
                setShowVehicle(false);
                setProfile(false);
              }}
            >
              Journey <span className="text-sm">(Beta)</span>
              <i className="fa fa-caret-down ml-5"></i>
            </button>
            <div
              className="dropdown-content"
              style={showJourney ? { display: "block" } : { display: "none" }}
            >
              <Link to="/MyJourney">
                Plan Your Journey <span className="text-sm">(Beta)</span>
              </Link>
              <a href="/OngoingJourneys">Active Journey</a>
              <a href="/PastJourneys">Past Journey</a>
            </div>
          </div>
          {/* journey dropdown END */}

          {/* history link */}
          <Link to="/History">History</Link>

          {/* plan link */}
          <Link to="/all-plans">Plans</Link>

          {/* vehicle Dropdown START */}
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => {
                showVehicle ? setShowVehicle(false) : setShowVehicle(true);
                setShowJourney(false);
                setProfile(false);
              }}
            >
              Vehicle
              <i className="fa fa-caret-down ml-5"></i>
            </button>
            <div
              className="dropdown-content"
              style={showVehicle ? { display: "block" } : { display: "none" }}
            >
              <Link to="/vehicle-add">Add My Vehicle</Link>
              <Link to="/ListOfVehicle">List Of Vehicle</Link>
            </div>
          </div>
          {/* vehicle DropDown END */}

          {/* EWayBill Start */}
          <div className="dropdown">
            <button
              className="dropbtn"
              onClick={() => {
                // showVehicle ? setShowVehicle(false) : setShowVehicle(true);
                // setShowJourney(false);
                setProfile(false);
              }}
            >
              EWayBill Tracker
              <i className="fa fa-caret-down ml-5"></i>
            </button>
            <div
              className="dropdown-content"
              // style={showVehicle ? { display: "block" } : { display: "none" }}
            >
              <Link to="/EWayBill">Upload EWayBill Number</Link>
             
            </div>
          </div>
          {/* EWayBill end */}

          {iLogin ? (
            <>
              {/* notifications link */}
              <Link to="/notifications">Notifications</Link>

              {/* wallet link */}
              <Link to="/WalletContinue">
                Wallet - {balanceOBJ ? balanceOBJ.credits : ""} Tracking Credits
              </Link>

              {/* profile download START */}
              <div className="dropdown">
                <button
                  className="dropbtn"
                  onClick={() => {
                    showProfile ? setProfile(false) : setProfile(true);
                    setShowVehicle(false);
                    setShowJourney(false);
                  }}
                >
                  Profile
                  <i className="fa fa-caret-down ml-5"></i>
                </button>
                <div
                  className="dropdown-content"
                  style={
                    showProfile ? { display: "block" } : { display: "none" }
                  }
                >
                  <Link to="/profile-main">Profile Page</Link>
                  <Link
                    onClick={(e) => {
                      Logout();
                    }}
                  >
                    Logout
                  </Link>
                  <hr />
                  <Link to={"/WalletContinue"}>My Invoices</Link>
                  <Link to="/about-us">About Us</Link>
                  <Link to="/refund-policy">Refund Policy</Link>
                  <Link to="/blog">Blog</Link>
                  <Link to="/faqs">FAQs</Link>
                  <Link to="/terms-and-conditions">Terms of Service</Link>
                  <Link to="/private-policy">Privacy Policy</Link>
                  <Link to="/contact-us">Contact Us</Link>
                  <a target="_black" href="https://www.youtube.com/watch?v=ZET9i_Qdrso">How To Use</a>
                  <Link
                    style={{
                      background: "black",
                      color: "white",
                      padding: "0.5rem",
                      borderRadius: "0.3rem",
                    }}
                    to="/profile-main"
                  >
                    Add Gst
                  </Link>
                </div>
              </div>
              {/* profile download end */}
            </>
          ) : (
            <>
              {/* <li className="text-white mr-5" style={{ listStyle: "none" }}> */}
                <a
                  target="_black"
                  href="https://www.youtube.com/watch?v=ZET9i_Qdrso"
                >
                  How To Use
                </a>
              {/* </li> */}
              {/* sign in link */}
              <Link to="/login">Sign In</Link>
              {/* sign up link */}
              <Link to="/single-page-register">Sign Up</Link>
            </>
          )}
        </div>
        <div className="">
          <button
            className={isShow ? "flex justify-center text-white" : "text-white"}
            type="button"
            onClick={() => {
              isShow ? setIsShow(false) : setIsShow(true);
            }}
          >
            {isShow ? (
              <span className="material-symbols-outlined pl-3 text-white pt-5 pb-5">
                close
              </span>
            ) : (
              <span className="material-symbols-outlined pl-3 text-white">
                menu
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default ResponsiveNavbar;
