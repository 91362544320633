import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { gOS } from '../../apis/api';
import moment from 'moment'
import ls from 'localstorage-slim'

const PaymentSuccess = () => {
    const param = useParams();
    const nav = useNavigate();
    const [data, setData] = useState([]);

    let currentTime = moment().format("LLLL");
    let currentTime2 = moment().format("DD-MM-YYYY");
    let obj = {
        "name": "planSuccess",
        "message": "Payment Successful - Check wallet for further detail",
        "time": currentTime,
        "forFilter": currentTime2
    }

    ls.set('planSuccess', obj)

    useEffect(() => {
        axios.get(`${gOS}/${param.oi}`).then(res => {
            console.log("dekh")
            console.log(res.data.data)
            setData(res.data.data)
            sessionStorage.setItem("order_id", res.data.data.order_id);
            sessionStorage.setItem("amount", res.data.data.amount);
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <section className='payment_success_page flex justify-center items-center h-[100vh]'>

            <div className='payment_success_boxa p-5 rounded bg-white' style={{ "boxShadow": "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}>
                <h1 className='text-2xl'>Your payment was successfully</h1>
                <div className='mt-3 flex justify-between'>
                    <div>
                        <p className='font-semibold'>Order id</p>
                        <span>{sessionStorage.getItem("order_id")}</span>
                    </div>

                    <div>
                        <p className='font-semibold'>Amount</p>
                        <span>{sessionStorage.getItem("amount")}</span>
                    </div>
                </div>
                <br />
                <button class="button-86 " role="button" onClick={() => { nav('/all-plans'); nav('/'); nav('/all-plans'); window.location.reload() }}>Back</button>
            </div>

        </section>
    )
}

export default PaymentSuccess