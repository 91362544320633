// PRODUCTION ROUTES
const hostDomain = "https://lorrycare.com:5000";
// const hostDomain = "http://localhost:5000";
// const hostDomain = "http://192.168.1.69:5000";
const shareRoute = "https://lorrycare.com";
const shareRoute2 = "https://lorrycare.com/";
const QRS = "https://lorrycare.com";

// ALL API's
const QR = `${QRS}/WalletContinue`;
const mobileSMS = `${hostDomain}/v1/send-sms`
const registerPost = `${hostDomain}/v1/register`;
const loginPost = `${hostDomain}/v1/signin`;
const forgot_resetPassword = `${hostDomain}/v1/forget-password`;
const trackVehicle = `${hostDomain}/v1/search-vehicle/data`;
const trackEway = `${hostDomain}/v1/ewbTrack`;
const bulkUploadEway = `${hostDomain}/v1/bulkUpload/ewb`;
const journeyPlanPost = `${hostDomain}/v1/trip`;
const getVehicleListForUser = `${hostDomain}/v1/vehicle-by-userId`
const vehiclePost = `${hostDomain}/v1/vehical-details`
const onGoingFetch = `${hostDomain}/v1/trips/active`;
const searchHistoryGet = `${hostDomain}/v1/search-history`
const sendRegisterEmailForVarification = `${hostDomain}/v1/verify`
const sendRegsiterEmailOTP = `${hostDomain}/v1/verify-otp`
const getProfile = `${hostDomain}/v1/users`
const postKYC = `${hostDomain}/v1/kyc`
const fetchKYC = `${hostDomain}/v1/kyc`
const updateVehicle = `${hostDomain}/v1/vehical-details`
const deleteVehicle = `${hostDomain}/v1/vehical-details`
const forgot_email = `${hostDomain}/v1/email-verify`;
const update_password = `${hostDomain}/v1/forget-password`;
const seachHistoryTrackData = `${hostDomain}/v1/search-vehicle/data`
const fullVehicleList = `${hostDomain}/v1/vehicle-by-userId`
const deleteActiveJourney = `${hostDomain}/v1/trip`
const getSingleClickJourney = `${hostDomain}/v1/trip/active/details`
const updateJourney1 = `${hostDomain}/v1/trip`
const updateJourney2 = `${hostDomain}/v1/trip-update`
const closeJourney = `${hostDomain}/v1/trip/close`
const getPastJourney = `${hostDomain}/v1/trips/close`
const share_trip = `${hostDomain}/v1/trip`
const postFeedback = `${hostDomain}/v1/feedback`
const searchTollPlaza = `${hostDomain}/v1/tollplaza`;
const pastJourneyDelete = `${hostDomain}/v1/trip`;
const sendAmount = `${hostDomain}/v1/checkout`;
const AddTollPlaza = `${hostDomain}/v1/trip`
const RemoveTollPlaza = `${hostDomain}/v1/tollplaza-delete`
const monthlyPlanGet = `${hostDomain}/v1/plan/monthly`
const ppcPlanGet = `${hostDomain}/v1/plan/ppc`
const logOut = `${hostDomain}/v1/logout`
const transferCredits = `${hostDomain}/v1/send-creadits`
const oriDes_suggestion = `${hostDomain}/v1/suggest-name?input=`
const preAlertCheck = `${hostDomain}/v1/pre-alert`;
const postTripSummary = `${hostDomain}/v1/post-trip`
const getWalletBalances = `${hostDomain}/v1/balance`
const buyPlan = `${hostDomain}/v1/plan-purchase`
const transactionHistory = `${hostDomain}/v1/history`
const searchTrack = `${hostDomain}/v1/trip`;
const transferAllCredits = `${hostDomain}/v1/sends-credits`
const transferAllCredits_R = `${hostDomain}/v1/receive-credits`
const closeJourneyCredits = `${hostDomain}/v1/transaction-out`
const RegisterSend = `${hostDomain}/v1/register`
const reSendOTP = `${hostDomain}/v1/reset-otp`
const otpvari = `${hostDomain}/v1/verify-otp`;
const hdfcFirstSubmit = `${hostDomain}/v1/orderStatus`
const testApi = `https://test.ccavenue.com/transaction/transaction.do?command=`
const sendContact = `${hostDomain}/v1/contact-us`
const modifyPasswordOtp = `${hostDomain}/v1/verify/reset-otp`
const modifyPassword = `${hostDomain}/v1`
const gOS = `${hostDomain}/v1/get-data`;
const transaction_activity = `${hostDomain}/v1/activity/transaction`;


export { mobileSMS, updateJourney2, transaction_activity, shareRoute2, modifyPassword, gOS, QR, modifyPasswordOtp, sendContact, otpvari, RegisterSend, testApi, hdfcFirstSubmit, reSendOTP, transferAllCredits, transferAllCredits_R, closeJourneyCredits, postTripSummary, shareRoute, transactionHistory, searchTrack, buyPlan, getWalletBalances, preAlertCheck, logOut, transferCredits, oriDes_suggestion, monthlyPlanGet, ppcPlanGet, AddTollPlaza, RemoveTollPlaza, sendAmount, pastJourneyDelete, journeyPlanPost, deleteActiveJourney, searchTollPlaza, postFeedback, share_trip, getPastJourney, closeJourney, updateJourney1, getSingleClickJourney, seachHistoryTrackData, fullVehicleList, forgot_email, update_password, getProfile, deleteVehicle, fetchKYC, postKYC, updateVehicle, searchHistoryGet, sendRegisterEmailForVarification, sendRegsiterEmailOTP, onGoingFetch, vehiclePost, getVehicleListForUser, loginPost, registerPost, forgot_resetPassword, trackVehicle, trackEway, bulkUploadEway };
