import React from "react";
import styled from "styled-components";

function Para(props) {
  return (
    <>
      <Paragraph>{props.Detail}</Paragraph>
    </>
  );
}
const Paragraph = styled.p`
  margin-top: 0.675rem;
  margin-bottom: 2rem;
  font-size: 14px;
  width: 100%;
  font-family: var(--para-font);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
`;

export default Para;
