import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Stroke1 from "../../components/assets/svg/Stroke1";
import Stroke2 from "../../components/assets/svg/Stroke2";
import Tick from "../../components/assets/svg/Tick";
import HeadWithUnderline from "../../components/layouts/HeadingUnderline/HeadWithUnderline";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from '../../TopSection'
import { update_password } from "../../apis/api"
import axios from 'axios'
import ls from 'localstorage-slim'
import Footer from "../../components/layouts/Footer/Footer";


function Password() {
  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;

  const [inputData, setInputData] = useState(
    {
      "password": "",
      "compare_password": ""
    }
  );

  // UpdateYourPassword(e)
  function UpdateYourPassword(e) {

    axios.post(`${update_password}/${user_id}`, inputData, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    })
      .then(function (response) {
        if (response.data.success) {
          window.location.reload();
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  function changePassword(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value })
    console.log(inputData);
  }

  return (
    <>
      <div className="nav">
        <SecondNavbar />
      </div>

      {/* top section Component */}
      <TopSection
        bigi={"Profile"}
        small={""}
      >
      </TopSection>
      {/* top section Component END*/}


      <div className="ProfileNav w-[73%] m-auto bg-[#232323] rounded-xl">
        <ul className="font-Inter font-normal text-base flex flex-wrap justify-between items-center text-white p-5">
          <li className="zxcvbb">
            <NavLink to="/Profile" className="p-1 px-4 rounded-md ">
              Profile
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/ContactDetail" className="p-1 px-4 rounded-md">
              Contact Details
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/Password" className="p-1 px-4 rounded-md">
              Password
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/kyc" className="p-1 px-4 rounded-md">
              KYC
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/subscription" className="p-1 px-4 rounded-md">
              Subscription
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/company" className="p-1 px-4 rounded-md">
              Company
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="parent-elem relative my-16">
        <div>
          <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
            <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
              <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

              {/* <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                <h1>You want to edit your details</h1>
              </div> */}
              <div className="second-Section flex flex-col gap-7 w-[85%]">
                <div className="inputItem flex flex-col gap-2">
                  <label>Password</label>
                  <input
                    type="password"
                    className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                    placeholder="**********"
                    onChange={(e) => changePassword(e)}
                    name="password"
                    style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                  />
                </div>
                <div className="inputItem flex flex-col gap-2">
                  <label>Confirm password</label>
                  <input
                    type="password"
                    className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                    placeholder="**********"
                    onChange={(e) => changePassword(e)}
                    name="compare_password"
                    style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                  />
                </div>
                <div className="flex gap-4">
                  <div className="flex bg-black text-white rounded-md px-10 py-1" onClick={(e) => UpdateYourPassword(e)}>
                    <Link className="flex">
                      <div className="font-Cardo font-normal text-xl pr-3 w-40 flex flex-col justify-center items-center">
                        Save Changes
                      </div>
                      <div className="flex justify-center items-center">
                        <Tick />
                      </div>
                    </Link>
                  </div>
                  {/* <div className="Two-Button flex justify-end w-full">
                    <Link>
                      <div className="frst-btn bg-[#232323] p-4 arrow-btn">
                        <Stroke1 />
                      </div>
                    </Link>
                    <Link>
                      <div className="sc-btn bg-[#232323] p-4 arrow-btn">
                        <Stroke2 />
                      </div>
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Password;
