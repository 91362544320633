import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { addTableData } from '../../../redux/slices/tableDatas'
import { addTableData2 } from '../../../redux/slices/historyTableDatas'
import { addTableData3 } from '../../../redux/slices/vehicleTabledata'
import { addTableData4 } from '../../../redux/slices/pastJourneyTable'
import { filterItem, removeItem } from '../../../redux/slices/filterData'
import { addSourceForFilter, removeSourceForFilter } from '../../../redux/slices/filterDataSource'
import { setIsFilter } from '../../../redux/slices/isFilterClick'
import { setIsFilter22 } from '../../../redux/slices/isFilterClick2'
import { useLocation } from 'react-router-dom';
import { Checkbox } from "@mui/material";

const Filter = ({ tableData, tableData22 }) => {

  // [+] REMOVE DUPLICATE ARRAY OF OBJECT ELEMENT
  let tableData2 = [];
  let uniqueObject = {};
  for (let i in tableData22) {
    let objTitle = tableData22[i].destination[0].destination;
    uniqueObject[objTitle] = tableData22[i];
  }
  for (let i in uniqueObject) {
    tableData2.push(uniqueObject[i]);
  }
  // [+]REMOVE DUPLICATE ARRAY OF OBJECT ELEMENT END

  const location = useLocation();
  const [isClick, setIsClick] = useState(false);
  const [isFilter1, setIsFilter1] = useState(false);
  const [isFilter2, setIsFilter2] = useState(false);
  const dispatch = useDispatch();
  const [isConfirmPop, setIsConfirmPop] = useState(false);
  const boolMouseOverOrNot = useSelector((state) => state.mouseOnVehicle.value)

  // filterDestinationOccur(e)}
  function filterDestinationOccur(e) {
    if (e.target.checked) {
      dispatch(filterItem(e.target.value));
      dispatch(setIsFilter(true))
    } else {
      dispatch(removeItem(e.target.value));
      dispatch(setIsFilter(true))
    }
  }

  // filter for source
  function filterSourceOccur(e) {
    if (e.target.checked) {
      dispatch(addSourceForFilter(e.target.value));
      dispatch(setIsFilter22(true))
    } else {
      dispatch(removeSourceForFilter(e.target.value));
      dispatch(setIsFilter22(true))
    }
  }

  // sort by source..
  function sortBySource() {
    let sortedList = tableData.slice().sort((a, b) => a.source[0].source.localeCompare(b.source[0].source))
    if (location.pathname == '/OngoingJourneys') {
      dispatch(addTableData(sortedList));
      dispatch(setIsFilter(false))
    }
    if (location.pathname == '/History') {
      dispatch(addTableData2(sortedList));
      dispatch(setIsFilter(false))
    }
    if (location.pathname == '/ListOfVehicle') {
      dispatch(addTableData3(sortedList));
      dispatch(setIsFilter(false))
    }
    if (location.pathname == '/PastJourney') {
      dispatch(addTableData4(sortedList));
      dispatch(setIsFilter(false))
    }
  }

  // sort by destination..
  function sortByDestination() {
    let sortedList = tableData.slice().sort((a, b) =>
      a.destination[0].destination.localeCompare(b.destination[0].destination)
    );
    if (location.pathname == '/OngoingJourneys') {
      dispatch(addTableData(sortedList));
    }
    if (location.pathname == '/History') {
      dispatch(addTableData2(sortedList));
    }
    if (location.pathname == '/ListOfVehicle') {
      dispatch(addTableData3(sortedList));
    }
    if (location.pathname == '/PastJourney') {
      dispatch(addTableData4(sortedList));
    }
  }

  // sort by vehicle..
  function sortByVehicleNumber() {
    let sortedList = tableData.slice().sort((a, b) => a.vehical_no.localeCompare(b.vehical_no));

    if (location.pathname == '/OngoingJourneys') {
      dispatch(addTableData(sortedList));
    }
    if (location.pathname == '/History') {
      dispatch(addTableData2(sortedList));
    }
    if (location.pathname == '/ListOfVehicle') {
      dispatch(addTableData3(sortedList));
    }
    if (location.pathname == '/PastJourney') {
      dispatch(addTableData4(sortedList));
    }
  }

  // sort by start date
  function sortByStartDate() {
    let sortedList = tableData.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt));

    if (location.pathname == '/OngoingJourneys') {
      dispatch(addTableData(sortedList));
    }
    if (location.pathname == '/History') {
      dispatch(addTableData2(sortedList));
    }
    if (location.pathname == '/ListOfVehicle') {
      dispatch(addTableData3(sortedList));
    }
    if (location.pathname == '/PastJourney') {
      dispatch(addTableData4(sortedList));
    }
  }

  //sort by end date
  function sortByEndDate() {
    let sortedList = tableData.slice().sort((a, b) => b.end_date.localeCompare(a.end_date));

    if (location.pathname == '/OngoingJourneys') {
      dispatch(addTableData(sortedList));
    }
    if (location.pathname == '/History') {
      dispatch(addTableData2(sortedList));
    }
    if (location.pathname == '/ListOfVehicle') {
      dispatch(addTableData3(sortedList));
    }
    if (location.pathname == '/PastJourney') {
      dispatch(addTableData4(sortedList));
    }
  }

  return (
    <>
      <div className={boolMouseOverOrNot ? "" : "relative filter-sec z-50"} style={{ marginLeft: "1rem" }}>

        <button
          onClick={() => {
            isClick ? setIsClick(false) : setIsClick(true);
          }}
          className={"button_first_to_last "}
          style={{
            width: "17rem",
            background: "white",
            color: "black",
            borderRadius: "0.3rem",
            padding: "0.4rem 2rem",
            border: "none",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            cursor: "pointer",

          }}
        >

          Sort and Filter</button>

        {/* dropdown of sort and filter START*/}
        <section
          className={"dropOfFilter block"}
          style={
            isClick
              ? {
                borderRadius: "0.1rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                background: "white",
                position: "absolute",
                width: "100%",
                left: "0",
                top: "38px",
              }
              : {
                display: "none",
                borderRadius: "0.1rem",
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                background: "white",
                position: "absolute",
                width: "100%",
                left: "0",
                top: "38px",
              }
          }
        >
          {/* sort */}
          <div style={{ padding: "1rem" }}>
            <div className="mt-3 pb-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #cbd5e1",
              }}
            >
              <p className="font-semibold">Sort</p>
              <button
                onClick={() => {
                  isClick ? setIsClick(false) : setIsClick(true);
                }}
              >
                X
              </button>
            </div>
            <div className="mt-3"
              onClick={() => {
                sortBySource();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#cbd5e1" }}>ZZ</span>
              <p style={{ "marginLeft": "1rem" }}>Sort By Source</p>
            </div>

            <div className="mt-3"
              onClick={() => {
                sortByDestination();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#cbd5e1" }}>ZZ</span>
              <p style={{ "marginLeft": "1rem" }}>Sort By Destination</p>
            </div>

            <div className="mt-3"
              onClick={() => {
                sortByVehicleNumber();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#cbd5e1" }}>ZZ</span>
              <p style={{ "marginLeft": "1rem" }}>Sort By VehicleNumber</p>
            </div>

            <div className="mt-3"
              onClick={() => {
                sortByStartDate();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#cbd5e1" }}>ZZ</span>
              <p style={{ "marginLeft": "1rem" }}>Sort By Start Date</p>
            </div>

            <div className="mt-3"
              onClick={() => {
                sortByEndDate();
              }}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#cbd5e1" }}>ZZ</span>
              <p style={{ "marginLeft": "1rem" }}>Sort By End Date</p>
            </div>
          </div>

          {/* filter */}
          <div style={{ padding: "1rem" }}>

            <div className="mt-3 pb-3 "
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #cbd5e1",
              }}
            >
              <p className="font-semibold">Filter</p>
            </div>

            <div className="relative z-40">

              <div>
                <div >

                  <div onClick={() => { isFilter1 ? setIsFilter1(false) : setIsFilter1(true) }} className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >

                    <span className="material-symbols-outlined">
                      search
                    </span>

                    <div className="filterBySource flex justify-between w-[90%] ml-2 rounded pl-3" style={{ "border": "1px solid black" }} >
                      Search By Source <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>


                  </div>
                </div>
              </div>

              <div style={isFilter1 ? { "display": "block" } : { "display": "none" }} className="filter-source-ddl absolute top-8 p-3 border right-0 w-[87%] h-[auto] bg-white ">
                <b>Source</b>

                <div>

                  {tableData2.map((elm, ind) => {
                    return <div key={`${ind}${elm.source[0].source}`} className="checkbox-wrapper flex justify items-center">
                      <Checkbox id={elm.source[0].source} value={elm.source[0].source} onChange={(e) => { filterSourceOccur(e) }} />
                      <label htmlFor={elm.source[0].source} className="font-bold font-Inter">{elm.source[0].source}</label>
                    </div>
                  })}


                </div>

              </div>

            </div>

            <div className="relative ">

              <div>
                <div >

                  <div onClick={() => { isFilter2 ? setIsFilter2(false) : setIsFilter2(true) }} className="mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >

                    <span className="material-symbols-outlined">
                      search
                    </span>

                    <div className="filterBySource flex justify-between w-[90%] ml-2 rounded pl-3" style={{ "border": "1px solid black" }} >
                      Search By Destina.. <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>


                  </div>
                </div>
              </div>

              <div style={isFilter2 ? { "display": "block" } : { "display": "none" }} className="filter-source-ddl absolute top-8 p-3 border right-0 w-[87%] h-[auto] bg-white ">
                <b>Destination</b>

                <div>
                  {tableData2.map((elm, ind) => {
                    { console.log("24april" + JSON.stringify(elm)) }
                    return <div key={`${elm.destination[0].destination}${ind}`} className="checkbox-wrapper flex justify items-center">
                      <Checkbox id={elm.destination[0].destination} value={elm.destination[0].destination} onChange={(e) => { filterDestinationOccur(e) }} />
                      <label htmlFor={elm.destination[0].destination} className="font-bold font-Inter">{elm.destination[0].destination}</label>
                    </div>
                  })}


                </div>

              </div>

            </div>

          </div>

        </section>

      </div>
    </>
  );
};

export default Filter;
