import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import Footer from "../../components/layouts/Footer/Footer";

const AboutUs = () => {
  return (
    <>
      {/* navbar START*/}
      <SecondNavBar translationHide={"true"} />
      {/* navbar END */}

      {/* top section Component */}
      <TopSection bigi={"About Us"} small={""}></TopSection>
      {/* top section Component END*/}

      <div className="my-5">
        {/* about us Main Card START */}
        <Card>
          <HeadingWithText
            head={"Get to know us!"}
            desc={[
              "ONEWHISTLE MARKETPLACE PVT.LTD. was established in Aug'21 at Ahmedabad,Gujarat. ONEWHISTLE MARKETPLACE PVT.LTD. was formed by Vikas Vijaykumar Goyal , And he has been actively engaged in the same field since 2021. We are the leading Service Provider for Tracking Commercial Lorries Via FastTag servers since 2023.",
              "At present, we have services across India. We focus to help our customers by saving time and money as we offer proper Vehicle Tracking Service through our FastTag servers at extremely competitive & unmatchable prices.",
              "When you are buying credits for tracking from ONEWHISTLE MARKETPLACE PVT.LTD., you can be rest assured that you are buying credits for tracking from a Trusted Name.",
              "GST- 24AADCO5355J1ZC",
              "Trade Name- ONEWHISTLE MARKETPLACE PVT.LTD.",
              "Company Name- ONEWHISTLE MARKETPLACE PVT.LTD.",
              "GSTIN- 24AADCO5355J1ZC ",
            ]}
            isMargin={true}
          />
          {/*           
          <HeadingWithText
            head={"who we are?"}
            desc={[
              "Lorrycare.com is one of the leading players in the logistics industry that lets stakeholders to track vehicles in near real time across the length and breadth of India by ULIP’s Fastag data sharing initiative with the objective of making the vehicle tracking simpler, quicker and efficient at affordable rates.We assist all stakeholders of the supply chain and logistics sector achieve better profitability in their own businesses. We follow best practices and business ethics for the benefit of transporter and customer community.",
            ]}
          /> */}

          {/* <HeadingWithText
            head={"what we do?"}
            desc={[
              "Our solution minimizes the pain points of transporters, shippers, consignees and various stakeholders while the goods are in transit by providing non-invasive, untamperable, low cost vehicle tracking. Our easy-to-use web & mobile based solution help in vehicle’s tracking, creating journey, setting up pre-alerts and journey sharing efficiently and safely. Our best in class tool namely post-journey-data analysis, allows our users to measure any vehicle’s performance like speed, distance, time while covering a particular distance, route analysis, comparison with other vehicle’s within the app, day driving – night driving comparison, etc.",
            ]}
            isMargin={true}
          /> */}

          {/* <HeadingWithText head={"how do we do?"} isMargin={true}>
            <div className="pl-5">
              <div className="mt-5">
                <h1 className="font-bold font-Cardo text-xl">
                  Vehicle-Tracker
                </h1>
                <p className="mt-3 ">
                  As per the guidelines of ULIP, we mandatorily require the user
                  to register on our portal. A registered user can then request
                  tracking of a vehicle by entering it’s Vehicle Registration
                  Number. Our portal shall raise the query with ULIP and fetch
                  the last toll crossing data and display the same to the user
                  within a few seconds.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="font-bold font-Cardo text-xl">
                  Journey-Creator
                </h1>
                <p className="mt-3">
                  Registered users can create a journey from any Origin point to
                  any Destination point in India for any commercial vehicle. Our
                  solution will provide an approximate distance and estimated
                  transit time for that journey alongwith tracking updates in
                  near-real-time basis.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="font-bold font-Cardo text-xl">
                  Set Up Pre Alerts
                </h1>
                <p className="mt-3">
                  Once a vehicle’s journey is created, user can further set
                  various alerts as per his requirements. Our system is designed
                  to provide alerts through push notifications in case user is
                  using our mobile app, or through sms and email notification if
                  the user is using the desktop version of the app.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="font-bold font-Cardo text-xl">Share Journeys</h1>
                <p className="mt-3">
                  User can share vehicle’s created journey with his client,
                  consignee, own branch office or any concerned stakeholder for
                  better monitoring of the vehicle so tracked.
                </p>
              </div>

              <div className="mt-5">
                <h1 className="font-bold font-Cardo text-xl">
                  Big Data Analysis
                </h1>
                <p className="mt-3">
                  Our research & technology team has infused an extremely
                  powerful tool in the application which will assist our users
                  in finding the root cause of one of the biggest challenging
                  factor in transportation process – INTRANSIT DELAY & VEHICLE
                  VISIBILITY. This tool, will assist users in identifying the
                  underlying problems associated with the delay and help them
                  address this challenge in real time.
                </p>
              </div>
            </div>
          </HeadingWithText> */}

          {/* <HeadingWithText
            head={"Our Vision"}
            desc={[
              "To revolutionize the entire supply chain and logistics sector by providing cost-effective & efficient tracking & other solutions for all stakeholders leading to a reduction in in-transit delays and increased visibility of shipments en route, ultimately leading to a reduction in overall freight cost ",
            ]}
            isMargin={true}
          /> */}

          {/* <HeadingWithText
            head={"Our Mission"}
            desc={[
              "To leverage the existing Fastag infrastructure to provide a reliable and cost-effective technology solutions for Supply Chain and Logistics sector enabling them to optimize their operations and provide better services to their clients. Our aim is to increase the adoption of modern technological advancements in the logistics sector, leading to a more organized and efficient transport network.",
            ]}
            isMargin={true}
          /> */}

          {/* <HeadingWithText
            head={"No data extraction policy"}
            desc={[
              "Lorrycare.com does not ask for any permission nor take / store any data of the user except minimum mandatory data as asked by ULIP for user registration. User is free to enter any origin, any destination without sharing any sensitive data with lorrycare.com. We do not ask for any driver data which is considered sacred to the transporters. We only need user to input vehicle number, origin city, destination city as these are the basic minimum requirements to track any vehicle during its journey.",
            ]}
            isMargin={true}
          /> */}

          {/* <HeadingWithText
            head={"Team Purpose"}
            desc={[
              "We at lorrycare.com are committed to our self, customers, employees and partners to provide best in class logistics solutions. Lorrycare.com enables transporters and customers to meet their business objectives effectively. We aim at achieving highest standards of work for our customers. Our commitment to excellence will help the transportation industries and customers achieve financial growth and prosperity. To know more about us, please contact us and our representative will assist you.",
            ]}
            isMargin={true}
          /> */}
        </Card>
        {/* about us Main Card END */}
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
