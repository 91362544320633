import React from "react";
import styled from "styled-components";
import "./InputFeild.css";

function InputFeild(props) {
  return (
    <>
      <Label style={props.Styles} className="InputLabel">
        {props.label}
      </Label>
      <Input
        style={props.InputStyle}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
      ></Input>
    </>
  );
}

const Label = styled.label`
  font-weight: 700;
  font-family: "Inter";
`;
const Input = styled.input`
  margin-top: 0.75rem;
  width: 100%;
  padding: 0.75rem 2.25rem 0.75rem 1rem;
  border-radius: 5px;
  border: none;
  border: 1px solid #b2b2b2;
  font-family: "Inter", sans-serif;
`;
export default InputFeild;
