import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { BsChevronDown } from "react-icons/bs";
import ls from "localstorage-slim";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import axios from "axios";
import ResponsiveNavbar from "./ResponsiveNavbar";
import { logOut } from "../../../apis/api";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../../redux/slices/translate";

function NavBar() {
  let dispatch = useDispatch();
  let totalNotification;
  if (ls.get("totalNoty")) {
    totalNotification = ls.get("totalNoty");
  } else {
    totalNotification = 0;
  }
  const eng = useSelector((state) => state.translate.value);
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });
  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }
  console.log("balanceOBJ" + JSON.stringify(balanceOBJ));
  const nav = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const iLogin = ls.get("iLogin");
  let login_response_object, token_user, user_id;
  let FirstLetterOfName;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    FirstLetterOfName = login_response_object.user.name
      .slice(0, 1)
      .toUpperCase();
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  } else {
    FirstLetterOfName = "_";
  }

  // profile dropdown START
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // notification option START
  const [open1, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose1 = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  // notification option END

  // Logout()
  function Logout() {
    axios
      .put(`${logOut}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log("abhikiya" + JSON.stringify(res));
      });
    localStorage.clear();
    nav("/login");
  }

  // profile dropdown states END

  const MouseHover = () => {
    const ShowData = (document.getElementById("SetEffect").style.display =
      "initial");
    if (ShowData) {
      setTimeout(() => {
        document.getElementById("SetEffect").style.display = "none";
      }, 2000);
    }
  };

  return (
    <div>
      <nav
        id="navbar_main"
        className="navbar desktop_navbar_1 navbar_for_setting w-full bg-transparent shadow flex h-[6rem]"
      >
        <div className="justify-between w-[100%] px-4 mx-auto lg:justify-evenly lg:items-center lg:flex lg:px-8">
          <div className="">
            <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
              <div className="logo w-[20%]">
                {/* <NavIcon /> */}
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100px"
                  height="90px"
                  viewBox="0 0 584.000000 427.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,427.000000) scale(0.100000,-0.100000)"
                    fill="#FFFFFF"
                    stroke="none"
                  >
                    <path
                      d="M3015 4254 c-198 -30 -384 -102 -544 -208 -183 -121 -344 -296 -439
-475 -41 -76 -45 -80 -80 -83 l-37 -3 0 -65 0 -65 140 0 140 0 3 66 c3 60 1
67 -18 72 -11 3 -20 10 -20 15 0 21 103 163 171 236 248 266 627 412 979 378
314 -31 562 -150 775 -373 165 -172 270 -374 321 -619 21 -104 24 -342 5 -445
-49 -264 -166 -491 -346 -670 -174 -174 -387 -288 -630 -336 -90 -18 -368 -18
-450 0 -163 36 -327 104 -457 192 -131 88 -390 381 -361 410 4 4 53 10 108 11
l100 3 0 70 0 70 -249 3 c-193 2 -251 -1 -258 -10 -4 -7 -8 -38 -8 -69 0 -65
5 -69 80 -69 l48 0 38 -68 c194 -351 504 -582 914 -679 96 -23 418 -25 525 -4
445 88 831 404 996 817 77 193 104 334 103 549 -1 232 -45 418 -147 620 -260
519 -833 817 -1402 729z"
                    />
                    <path
                      d="M2300 3420 l0 -70 180 0 180 0 0 -60 0 -60 -225 0 -225 0 0 -75 0
-75 225 0 225 0 0 -60 0 -60 -365 0 -365 0 0 -70 0 -70 365 0 365 0 0 -60 0
-60 -130 0 -130 0 0 -70 0 -70 130 0 130 0 0 -60 0 -60 -90 0 -90 0 0 -75 0
-75 150 0 150 0 0 600 0 600 -240 0 -240 0 0 -70z"
                    />
                    <path
                      d="M2880 2890 l0 -600 94 0 c78 0 97 3 119 20 23 18 25 27 29 112 4 108
26 162 92 226 65 64 118 86 211 90 103 5 175 -21 240 -84 68 -66 99 -139 100
-242 2 -113 5 -116 147 -120 128 -4 178 7 207 45 20 24 21 39 21 280 0 200 -3
260 -14 282 -22 41 -171 86 -350 106 -76 8 -146 15 -156 15 -11 0 -43 47 -94
139 -76 135 -81 140 -138 173 -33 18 -92 54 -132 79 -95 62 -156 79 -278 79
l-98 0 0 -600z m440 415 c20 -10 49 -52 99 -140 39 -69 71 -127 71 -130 0 -3
-119 -5 -265 -5 l-265 0 0 145 0 145 165 0 c127 0 173 -3 195 -15z"
                    />
                    <path d="M1700 3155 l0 -75 190 0 190 0 0 75 0 75 -190 0 -190 0 0 -75z" />
                    <path d="M1560 2890 l0 -70 125 0 125 0 0 70 0 70 -125 0 -125 0 0 -70z" />
                    <path d="M2120 2630 l0 -70 75 0 75 0 0 70 0 70 -75 0 -75 0 0 -70z" />
                    <path
                      d="M3321 2644 c-90 -45 -151 -162 -136 -257 23 -134 125 -223 255 -221
144 1 252 109 252 254 0 69 -22 123 -70 174 -52 54 -103 76 -183 76 -51 0 -77
-6 -118 -26z m205 -138 c30 -30 34 -41 34 -84 0 -115 -129 -171 -209 -91 -80
80 -24 209 91 209 43 0 54 -4 84 -34z"
                    />
                    <path
                      d="M678 727 c-91 -26 -185 -107 -227 -197 -36 -77 -42 -211 -13 -288 98
-256 425 -323 612 -126 78 83 95 128 95 254 0 89 -4 113 -24 157 -29 64 -122
155 -188 184 -61 27 -189 34 -255 16z m210 -146 c82 -42 122 -113 122 -213 -1
-138 -90 -228 -225 -228 -136 0 -228 93 -228 230 0 96 44 169 128 213 44 23
156 22 203 -2z"
                    />
                    <path
                      d="M3583 722 c-98 -35 -162 -91 -210 -186 -38 -73 -44 -216 -14 -298 60
-157 205 -248 374 -235 82 7 167 43 220 95 64 62 64 65 -2 105 l-59 35 -20
-28 c-31 -42 -98 -70 -165 -70 -69 0 -120 20 -165 63 -47 44 -64 91 -65 169 0
86 30 150 92 193 37 26 53 30 120 33 88 4 141 -15 181 -65 l21 -27 52 28 c29
16 56 32 61 36 15 14 -76 101 -139 132 -79 40 -201 49 -282 20z"
                    />
                    <path
                      d="M0 375 l0 -355 205 0 205 0 0 65 0 65 -135 0 -135 0 0 290 0 290 -70
0 -70 0 0 -355z"
                    />
                    <path
                      d="M1250 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 56 -3 70 -117 69
-118 75 0 75 0 -20 33 c-101 168 -134 231 -123 235 28 9 85 69 102 107 26 56
25 149 -2 200 -11 22 -36 55 -56 73 -56 50 -113 62 -290 62 l-153 0 0 -355z
m325 211 c18 -8 39 -25 46 -37 17 -33 15 -91 -5 -122 -23 -34 -64 -47 -152
-47 l-74 0 0 110 0 110 75 0 c43 0 90 -6 110 -14z"
                    />
                    <path
                      d="M1880 376 l0 -356 70 0 70 0 0 120 0 120 53 0 53 0 69 -120 70 -120
73 0 c39 0 72 2 72 6 0 3 -31 58 -70 123 -38 65 -70 121 -70 124 0 3 24 30 53
59 95 96 103 211 19 308 -64 75 -93 84 -289 88 l-173 4 0 -356z m331 201 c65
-43 62 -137 -5 -178 -23 -14 -50 -19 -108 -19 l-78 0 0 110 0 110 79 0 c65 0
84 -4 112 -23z"
                    />
                    <path
                      d="M2436 698 c9 -18 63 -115 120 -216 l104 -185 0 -138 0 -139 70 0 70
0 0 136 0 136 120 213 c66 117 120 216 120 220 0 3 -34 5 -76 3 l-77 -3 -75
-142 c-41 -79 -78 -143 -81 -143 -3 0 -40 66 -81 145 l-75 145 -78 0 -78 0 17
-32z"
                    />
                    <path
                      d="M4272 703 c-5 -15 -28 -80 -50 -143 -111 -315 -182 -522 -182 -530 0
-6 33 -10 73 -10 l74 0 18 60 18 60 148 0 148 0 22 -60 22 -60 68 0 c38 0 69
3 68 8 0 4 -55 162 -122 352 l-123 345 -86 3 -86 3 -10 -28z m152 -291 c25
-73 46 -135 46 -138 0 -2 -45 -4 -101 -4 -77 0 -100 3 -97 13 3 6 25 71 49
144 24 72 47 128 50 125 4 -4 27 -67 53 -140z"
                    />
                    <path
                      d="M4780 376 l0 -356 65 0 65 0 0 120 0 120 58 0 57 -1 68 -119 69 -120
74 0 c72 0 74 1 64 20 -6 11 -40 71 -76 132 -41 70 -61 114 -54 116 7 3 28 18
47 35 119 105 98 290 -44 374 -47 27 -52 28 -220 31 l-173 4 0 -356z m327 204
c70 -42 63 -154 -11 -185 -21 -9 -67 -15 -111 -15 l-75 0 0 110 0 110 83 0
c64 0 89 -4 114 -20z"
                    />
                    <path
                      d="M5400 375 l0 -355 220 0 220 0 0 65 0 65 -150 0 -150 0 0 80 0 80
135 0 135 0 0 65 0 65 -135 0 -135 0 0 75 0 75 145 0 145 0 0 70 0 70 -215 0
-215 0 0 -355z"
                    />
                  </g>
                </svg>
              </div>
              <div className="lg:hidden">
                <button
                  className="p-2 rounded-lg outline-none text-white focus:border-gray-400 focus:border"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 lg:block lg:pb-0 lg:mt-0 z-10 ${isOpen ? "block" : "hidden"
                }`}
            >
              <ul className="items-center justify-center space-y-8 lg:flex lg:space-x-6 lg:space-y-0">
                <li className="text-white">
                  <Link to="/">Home</Link>
                </li>

                <li className=" relative w-[100%] flex max-sm:justify-center">
                  <div className="dropdown">
                    <span className="flex items-center gap-1 cursor-pointer">
                      Journey <span className="text-sm">(Beta)</span>{" "}
                      <BsChevronDown />
                    </span>

                    <div
                      style={{
                        boxShadow:
                          "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                      }}
                      className="dropdownItems w-52 absolute bg-white text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10"
                    >
                      <div className="flex flex-col my-0 text-start">
                        <Link
                          to="/MyJourney"
                          className=" font-Inter font-medium "
                          style={{ fontSize: "13px" }}
                        >
                          Plan Your Journey <span>(Beta)</span>
                        </Link>
                        <span className="font-Inter font-normal text-xs">
                          Create a customized trip
                        </span>
                      </div>
                      <div className="flex flex-col my-0 text-start">
                        <a
                          href="/OngoingJourneys"
                          style={{ fontSize: "13px" }}
                          className=" font-Inter font-medium"
                        >
                          Active Journeys
                        </a>
                        <span className="font-Inter font-normal text-xs">
                          List of all ongoing trips
                        </span>
                      </div>
                      <div className="flex flex-col my-0 text-start">
                        <a
                          href="/PastJourneys"
                          className=" font-Inter font-medium"
                          style={{ fontSize: "13px" }}
                        >
                          Past Journeys
                        </a>
                        <span className="font-Inter font-normal text-xs">
                          History of closed trips
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="">
                  <Link to="/History">History</Link>
                </li>
                <li>
                  <Link to="/all-plans">Plans</Link>
                </li>
                <li>
                  <Link to="/WalletContinue">Wallet</Link>
                </li>
                <li className=" relative w-[100%] flex items-center text-center justify-center">
                  <div className="dropdown">
                    <span className="flex items-center gap-1 max-sm:justify-center cursor-pointer">
                      Vehicle <BsChevronDown />
                    </span>
                    <div
                      style={{
                        boxShadow:
                          "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                      }}
                      className="dropdownItems w-52 absolute bg-[#ffffff] text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10 "
                    >
                      <div className="flex flex-col text-start">
                        <Link
                          to="/vehicle-add"
                          className=" font-Inter font-medium"
                          style={{ fontSize: "13px" }}
                        >
                          Add My Vehicle
                        </Link>

                        <span className="font-Inter font-normal text-xs">
                          Enter details about vehicle
                        </span>
                      </div>
                      <div className="flex flex-col  text-start">
                        <Link
                          to="/ListOfVehicle"
                          className=" font-Inter font-medium"
                          style={{ fontSize: "13px" }}
                        >
                          List of Vehicles
                        </Link>
                        <span className="font-Inter font-normal text-xs">
                          List of all vehicles associated with account
                        </span>
                      </div>
                    </div>
                  </div>
                </li>

                {/* EWayBill Tracker */}
                <li className=" relative w-[100%] flex items-center text-center justify-center">
                  <div className="dropdown">
                    <span className="flex items-center gap-1 max-sm:justify-center cursor-pointer">
                      EWayBill Tracker <BsChevronDown />
                    </span>
                    <div
                      style={{
                        boxShadow:
                          "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                      }}
                      className="dropdownItems w-52 absolute bg-[#ffffff] text-black w-50 px-6 py-2 right-[-3.5rem]  rounded-lg max-sm:right-10 "
                    >
                      <div className="flex flex-col text-start">
                        <Link
                          to="/EWayBill"
                          className=" font-Inter font-medium"
                          style={{ fontSize: "13px" }}
                        >
                        Upload EWayBill Number
                        </Link>

                        <span className="font-Inter font-normal text-xs">
                          Enter details about EWayBill Number
                        </span>
                      </div>

                    </div>
                  </div>
                </li>
                {/* End EWayBill Tracker */}
                <li></li>
              </ul>
            </div>
          </div>
          {!iLogin ? (
            <div className="button flex items-center gap-4 font-Cardo  text-lg max-sm:flex-col">
              <Tooltip title="Translate" placement="top">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    eng
                      ? dispatch(changeLanguage(false))
                      : dispatch(changeLanguage(true));
                  }}
                >
                  <span class="material-symbols-outlined">g_translate</span>
                </div>
              </Tooltip>
              <div className="signin flex justify-center items-center">
                <li className="text-white mr-5" style={{ listStyle: "none" }}>
                  <a
                    target="_black"
                    href="https://www.youtube.com/watch?v=ZET9i_Qdrso"
                  >
                    How To Use
                  </a>
                </li>
                <Link to="/login">Sign In</Link>
              </div>

              <div className="bg-[#ffffff] text-black font-bold px-6 py-1 rounded-lg vertical-shake">
                <Tooltip title="Register For Free" placement="top">
                  <div className="cursor-pointer">
                    <Link to="/single-page-register">Sign Up</Link>
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="button flex gap-4 font-Cardo items-center text-lg max-sm:flex-col">
              <li className="text-white mr-5" style={{ listStyle: "none" }}>
                <a
                  target="_black"
                  href="https://www.youtube.com/watch?v=ZET9i_Qdrso"
                >
                  How To Use
                </a>
              </li>
              {/* notification DDL START */}
              <Stack
                direction="row"
                spacing={2}
                onClick={() => {
                  nav("/notifications");
                }}
              >
                <div>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open1 ? "composition-menu" : undefined}
                    aria-expanded={open1 ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    {/* notification icon */}
                    <Tooltip title="All notification" placement="top">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.5 21.3096C18.549 21.3096 21.8101 20.4053 22.125 16.7756C22.125 13.1485 19.8514 13.3817 19.8514 8.93139C19.8514 5.45517 16.5565 1.5 11.5 1.5C6.44346 1.5 3.14856 5.45517 3.14856 8.93139C3.14856 13.3817 0.875 13.1485 0.875 16.7756C1.19119 20.419 4.45222 21.3096 11.5 21.3096Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Tooltip>
                    <span
                      className="absolute text-white px-1 bottom-[15px] left-[40px]"
                      style={{
                        background: "red",
                        borderRadius: "1rem",
                        left: "40px",
                      }}
                    >
                      {totalNotification}
                    </span>
                  </Button>
                </div>
              </Stack>
              {/* notification icon end */}

              {/* profile end */}
              <Tooltip title="Tracking Credits" placement="top">
                <div>
                  <Link to={"/WalletContinue"} className="flex items-center">
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "2rem" }}
                    >
                      account_balance_wallet
                    </span>
                    <p className="font-normal">
                      {balanceOBJ ? balanceOBJ.credits : ""}
                      <span
                        className="text-sm"
                        style={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        {" "}
                        Credits
                      </span>
                    </p>
                  </Link>
                </div>
              </Tooltip>

              {/* translator */}
              <Tooltip title="Translate" placement="top">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    eng
                      ? dispatch(changeLanguage(false))
                      : dispatch(changeLanguage(true));
                  }}
                >
                  <span class="material-symbols-outlined">g_translate</span>
                </div>
              </Tooltip>

              {/* profile start*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings" placement="top">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {FirstLetterOfName}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose}>
                  <Link
                    to={"/profile-main"}
                    className={"flex justify-between items-center"}
                  >
                    {" "}
                    <Avatar /> Profile
                  </Link>
                </MenuItem>
                <Divider />
                {/* <MenuItem onClick={handleClose}>
                  <Link to={"/profile"} className={"flex justify-between items-center"}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings</Link>
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    Logout();
                  }}
                >
                  <div
                    className="material-symbols-outlined mr-3"
                    style={{ color: "#757575" }}
                  >
                    logout
                  </div>
                  Logout
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                  <Link to={"/WalletContinue"}>My Invoices</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/about-us"}>About us</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/refund-policy"}>Refund Policy</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/faqs"}>FAQs</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/blog"}>Blog</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/terms-and-conditions"}>Terms of Service</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to={"/private-policy"}>Privacy Policy</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/contact-us">Contact Us</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link
                    style={{
                      background: "black",
                      color: "white",
                      padding: "0.5rem",
                      borderRadius: "0.3rem",
                    }}
                    to="/profile-main"
                  >
                    Add Gst
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </nav>

      <ResponsiveNavbar
        classes1={"topnav responsive trans_nav"}
        classes2={"topnav flex items-center justify-between px-8 trans_nav"}
      />
    </div>
  );
}

export default NavBar;
