import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import SortIcon from "@mui/icons-material/Sort";
import { Box, Chip, Divider, Paper } from "@mui/material";
import Radio from "@mui/material/Radio";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../CommonTable";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Checkbox from "@mui/material/Checkbox";
import "./toll.css";
import Footer from "../../components/layouts/Footer/Footer";
import ls from "localstorage-slim";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { trackVehicle } from "../../apis/api";
import { addVehicleTrackData } from "../../redux/slices/vehicleTrackData";

import {
  searchTollPlaza,
  AddTollPlaza,
  RemoveTollPlaza,
  closeJourney,
  preAlertCheck,
  postTripSummary,
} from "../../apis/api";
import { isShowFiveSec } from "../../redux/slices/five_sec_popup";
import { addTollData } from "../../redux/slices/journeyTollData";

const Mobile_tollplazaActive = () => {
  // start copy
  const nav = useNavigate();
  const dispatch = useDispatch();
  // add toll plaza and delete toll plaza dropdown
  const [addTollDDL, setAddTollDDL] = useState(false);
  const [removeTollDDL, setRemoveTollDDL] = useState(false);
  const [showInputPop, setShowInputPop] = useState(false);
  const [confirmTollDeletePop, setconfirmTollDeletePop] = useState(false);
  const [tollIndex, setTollIndex] = useState({
    index: "",
  });
  const [tollInputData, setTollInputData] = useState({
    index: "",
    toll: "",
  });
  const [showInputDDL, setShowInputDDL] = useState(false);
  const [tollAddData, setTollAddData] = useState({
    toll: "",
  });
  const [conditioner, setConditioner] = useState(true);
  const [suggestData, setSuggestData] = useState([]);

  // toll planjourney form data get from localstorage
  const journeyPlan = ls.get("journeyPlan");
  const addButtonToggle = ls.get("addButtonToggle");

  // from localStorage Data START
  let tollMainData = useSelector((state) => state.journeyTollData.value);
  const iLogin = ls.get("iLogin");
  let ParametersOfRemoveTollPlaza;
  let AllObjectData, tollDatas, lastIndexOfTollPlaza;
  let login_response_object, token_user, user_id;
  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;

    if (tollMainData) {
      AllObjectData = tollMainData;
      console.log("AllObjectData" + JSON.stringify(AllObjectData));
      tollDatas = AllObjectData.allPlaza;
      lastIndexOfTollPlaza = tollDatas.length - 1;
    } else {
      AllObjectData = ls.get("journeyTollData", { decrypt: true });
      console.log("AllObjectData" + JSON.stringify(AllObjectData));
      tollDatas = AllObjectData.allPlaza;
      lastIndexOfTollPlaza = tollDatas.length - 1;
    }
  }
  // from localStorage Data END

  // handleTollNameChange(e)
  function handleTollNameChange(e) {
    setTollAddData({ ...tollAddData, [e.target.name]: e.target.value });
  }

  // clickSuggestData(e,elm)
  function clickSuggestData(e, elm) {
    setTollAddData({ ...tollAddData, toll: elm });
    setShowInputDDL(false);
  }

  // ADD TOLL ALL FUNCTION START .....

  // addTollPlazeFunction(e,ind)
  function addTollPlazeFunction(e, ind, id) {
    setShowInputPop(true);
    setTollInputData({ ...tollInputData, index: ind });
    // console.log(tollInputData)
    console.log(id);
  }

  function addTollInput(e) {
    setTollInputData({ ...tollInputData, [e.target.name]: e.target.value });
    axios
      .get(
        `${searchTollPlaza}/${AllObjectData._id}?toll=${tollInputData.toll}`,
        {
          headers: {
            Authorization: `Hello ${token_user}`,
          },
        }
      )
      .then((response) => {
        setSuggestData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // closeJourneyFromToll(e)
  function closeJourneyFromToll(e, closeJourneyID) {
    axios
      .put(`${closeJourney}/${closeJourneyID}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          dispatch(isShowFiveSec(true));
          nav("/MyJourney");
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addTollFromBackend() {
    axios
      .put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.allPlaza);
        ls.set("addButtonToggle", false);
        ls.set("journeyTollData", res.data.data, { encrypt: true });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeTollPlazeFunction() {
    const ind = ls.get("ParametersOfRemoveTollPlaza");
    console.log(ParametersOfRemoveTollPlaza);
    setTollIndex({ ...tollIndex, index: ind });
    axios
      .put(`${RemoveTollPlaza}/${AllObjectData._id}`, tollIndex, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then((res) => {
        console.log(res.data.data.allPlaza);
        ls.set("journeyTollData", res.data.data, { encrypt: true });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function showTollDeleteConfirmationPopup(e, ind) {
    setconfirmTollDeletePop(true);
    ls.set("ParametersOfRemoveTollPlaza", ind);
  }

  // ADD TOLL ALL FUNCTION END .....

  // post journey summary to backend..
  function postTripToBackend(e) {
    console.log(e);
    axios
      .post(`${postTripSummary}/${AllObjectData._id}`)
      .then((response) => {
        if (response.data.success) {
          window.alert("your journey summary has posted!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // pre alert activate function
  function preAlertActive(e) {
    if (e.target.checked) {
      axios
        .put(`${preAlertCheck}/${AllObjectData._id}`)
        .then((response) => {
          if (response.data.success) {
            window.alert(response.data.message);
            nav("/OngoingJourneys");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // csv customizations
  console.log("-----toll-datas------");
  console.log(tollDatas);
  let latestCsv = [];
  tollDatas.map((elm) => {
    latestCsv.push({
      Toll_Plaza: elm.findplza,
      City: elm.city,
      State: elm.state,
      Distance: elm.distance,
    });
  });
  const [isLoader, setIsLoader] = useState();
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });

  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }
  // clickTrackFunction(e)
  function clickTrackFunction(e, vehicle_nos) {
    if (!iLogin) {
      window.alert("please register and sign in to view results");
    } else {
      const login_response_object = ls.get("login_data_object", {
        decrypt: true,
      });
      const token_user = login_response_object.token;
      const user_id = login_response_object.user._id;

      setIsLoader(true);
      if (vehicle_nos.length > 12 || vehicle_nos.length < 9) {
        window.alert("vehicle number must have 9 to 12 digits");
        setIsLoader(false);
      } else if (vehicle_nos.length == 0) {
        window.alert("please enter vehicle number");
        setIsLoader(false);
      } else if (!isFinite(vehicle_nos.slice(-4))) {
        window.alert("please enter valid vehicle number");
        setIsLoader(false);
      } else {
        if (balanceOBJ.credits < 1) {
          window.alert("please buy plan");
          nav("/all-plans");
        } else {
          ls.set("track_vehicle_no", vehicle_nos, { encrypt: true });
          const stateSuggestionDataLocal = ls.get("stateSuggestionData");
          stateSuggestionDataLocal.push(vehicle_nos);
          ls.set("stateSuggestionData", stateSuggestionDataLocal);
          // axios post
          axios
            .post(
              trackVehicle,
              {
                vehicle_no: vehicle_nos,
                u_id: user_id,
              },
              {
                headers: {
                  Authorization: `Hello ${token_user}`,
                },
              }
            )
            .then(function (response) {
              if (response.data.success === true) {
                console.log("response--" + JSON.stringify(response.data.data));
                dispatch(addVehicleTrackData(response.data.data));
                console.log("lines...");
                console.log(response.data.data);
                ls.set("trackVehicleData", response.data.data, {
                  encrypt: true,
                });
                setIsLoader(false);
                nav("/track");
              }
            })
            .catch(function (error) {
              setIsLoader(false);
              console.log(error);
              if (error.response.status == "500") {
                window.alert(`Internal Server Error`);
              }
              if (error.response.status == "400") {
                window.alert(
                  `Vehicle no. not available in ULIP/vehicle has not crossed any toll in last 72 hours/incorrect vehicle number`
                );
              }
              if (error.response.status == "404") {
                window.alert("404 Error");
              }
            });
        }
      }
    }
  }
  // end copy
  // dropdwon start setting
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // dropdwon end setting
  const navigate = useNavigate();
  return (
    <section className="px-2">
      {/* top */}
      <div className="flex justify-between items-center">
        {/* left start */}
        <div className="flex py-6">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="material-symbols-outlined texl-sm">
              keyboard_backspace
            </span>
          </button>
          <div className="ml-2">
            <h1 className="text-xl font-Cardo font-bold">Trip ID</h1>
          </div>
        </div>
        {/* left end */}

        {/* right start */}
        <Button
          aria-describedby={id}
          style={{ color: "black" }}
          onClick={handleClick}
        >
          <SortIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          style={{ marginTop: "1rem" }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Button
            onClick={(e) => {
              clickTrackFunction(e, AllObjectData.vehicle_no);
            }}
            style={{ color: "black", textTransform: "capitalize" }}
          >
            Click to View 72 hours Data
          </Button>
          <br />
          <Button
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
            onClick={(e) => {
              closeJourneyFromToll(e, AllObjectData._id);
            }}
          >
            Close Journey
          </Button>
          <br />
          <a
            style={{ display: "inline-block", width: "100%" }}
            href={`https://www.google.com/maps/dir/${journeyPlan.source[0].source}/${journeyPlan.destination[0].destination}/`}
          >
            <Button style={{ color: "black", textTransform: "capitalize" }}>
              View on google map
            </Button>
          </a>
          <br />
          <Button
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
            onClick={() => {
              nav("/MyJourney");
            }}
          >
            Create another Journey
          </Button>
          <br />
          <Button
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
          >
            <CSVLink
              style={{
                color: "black",
              }}
              data={latestCsv}
              className={"download_scv"}
              filename={`${AllObjectData.vehicle_no}`}
            >
              Download journey summary
            </CSVLink>
          </Button>
          <br />
          <Button
            style={{
              color: "black",
              textTransform: "capitalize",
            }}
            onClick={() => {
              nav("/OngoingJourneys");
            }}
          >
            View all active journey
          </Button>
        </Popover>
        {/* right end */}
      </div>
      {/* divider */}
      <Divider />
      <Chip
        label={AllObjectData.vehicle_no}
        className="mt-3"
        variant="outlined"
      />
      {/* toper */}
      <Paper
        style={{
          marginTop: "0.5rem",
          borderRadius: "0.2rem",
          padding: "0.5rem",
        }}
        variant="outlined"
        square
      >
        <div className="flex justify-between flex-wrap">
          <div>
            <span
              style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
            >
              Start Date
            </span>
            <p>{AllObjectData.start_date.slice(0, 10)}</p>
          </div>

          <div>
            <span
              style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
            >
              End Date
            </span>
            <p>{AllObjectData.end_date.slice(0, 10)}</p>
          </div>

          <div>
            <span
              style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
            >
              Total Distance
            </span>
            <p>{AllObjectData.total_distance}</p>
          </div>
        </div>
      </Paper>
      <Paper
        style={{
          marginTop: "0.5rem",
          borderRadius: "0.2rem",
          padding: "0.5rem",
        }}
        variant="outlined"
        square
      >
        <div className="flex justify-between flex-wrap">
          <div>
            <span
              style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
            >
              From
            </span>
            <p>{AllObjectData.source[0].source}</p>
          </div>
          <div>
            <span
              style={{ fontSize: "0.8rem", color: "grey", fontWeight: "500" }}
            >
              To
            </span>
            <p>{AllObjectData.destination[0].destination}</p>
          </div>
        </div>
      </Paper>
      {/* divider */}
      <Divider style={{ marginTop: "0.4rem", marginBottom: "0.1rem" }} />{" "}
      {/* divider */}
      <Divider style={{ marginBottom: "0.3rem" }} />
      <h3>Toll List</h3>
      {/* main tracking list */}
      <section>
        {iLogin ? (
          tollDatas.map((elm, ind) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 0.4,
                    width: "100%",
                  },
                }}
              >
                <Paper
                  style={{
                    display: "flex",
                    alignItems: "start",
                    paddingBottom: "0.5rem",
                  }}
                >
                  {/* icon */}
                  <div>
                    <Radio color="success" checked />
                  </div>

                  {/* main start */}
                  <div style={{ width: "100%", paddingRight: "0.8rem" }}>
                    <h1 className="mt-2" style={{ fontWeight: "bold" }}>
                      {elm.match ? `${elm.findplza}*` : elm.findplza}
                    </h1>

                    <section>
                      <div
                        className="flex flex-wrap w-full"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="mt-2">
                          <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                            City -
                          </b>
                          <span style={{ color: "grey", fontSize: "0.9rem" }}>
                            {" "}
                            {elm.city}
                          </span>
                        </div>
                        <div className="mt-2">
                          <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                            State -
                          </b>
                          <span style={{ color: "grey", fontSize: "0.9rem" }}>
                            {" "}
                            {elm.state}
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex flex-wrap w-full mt-2"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="mt-2">
                          <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                            Date -
                          </b>
                          <span style={{ color: "grey", fontSize: "0.9rem" }}>
                            {" "}
                            {elm.date ? elm.date.slice(0,10) : "-"}
                          </span>
                        </div>
                        <div className="mt-2">
                          <b style={{ fontWeight: "500", fontSize: "0.9rem" }}>
                            Distance Covered -
                          </b>
                          <span style={{ color: "grey", fontSize: "0.9rem" }}>
                            {" "}
                            {elm.distance}
                          </span>
                        </div>
                      </div>
                    </section>
                  </div>
                  {/* main end */}
                </Paper>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </section>
    </section>
  );
};

export default Mobile_tollplazaActive;
