import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Checkbox from "@mui/material/Checkbox";
import './toll.css';
import Footer from "../../components/layouts/Footer/Footer";
import ls from 'localstorage-slim'
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { searchTollPlaza, AddTollPlaza, RemoveTollPlaza, closeJourney, preAlertCheck, postTripSummary } from "../../apis/api";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import { isShowFiveSec } from '../../redux/slices/five_sec_popup'
import { isMobile } from "react-device-detect";
import { addTollData } from "../../redux/slices/journeyTollData";
import moment from 'moment'
import { CircularProgress } from "@mui/material";
import Mobile_tollplaza from "./Mobile_tollplaza";
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const OnGoingJourney = ({ bigi, small, small2, small3, children, classes }) => {
    const nav = useNavigate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    console.log("location:", location);
    // add toll plaza and delete toll plaza dropdown
    const [addTollDDL, setAddTollDDL] = useState(false);
    const [removeTollDDL, setRemoveTollDDL] = useState(false);
    const [showInputPop, setShowInputPop] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [confirmTollDeletePop, setconfirmTollDeletePop] = useState(false);
    const [tollIndex, setTollIndex] = useState({
        "index": ""
    });
    const [tollInputData, setTollInputData] = useState({
        "index": "",
        "toll": ""
    })
    const [showInputDDL, setShowInputDDL] = useState(false);
    const [tollAddData, setTollAddData] = useState({
        "toll": ""
    });
    const [conditioner, setConditioner] = useState(true)
    const [suggestData, setSuggestData] = useState([]);


    // toll planjourney form data get from localstorage
    const journeyPlan = ls.get("journeyPlan");
    const addButtonToggle = ls.get("addButtonToggle");
    const [showConfirmation, setShowConfirmation] = useState(false);

    // const handleBackClick = () => {
    //     if (window.confirm('Are you sure you want to leave? Your changes may not be saved.')) {
    //         navigate(-1); // User confirmed, navigate back
    //     } else {
    //         // User canceled, stay on the same page
    //     }
    // };
    const handleBackClick = () => {
        setShowConfirmation(true);
    };

    const handleConfirm = () => {
        navigate(-1); // User confirmed, navigate back
        setShowConfirmation(false);
    };
    const handleClose = () => {
        setShowConfirmation(false); // User canceled, stay on the same page
    };

    // from localStorage Data START
    let tollMainData = useSelector((state) => state.journeyTollData.value);
    const iLogin = ls.get('iLogin');
    let ParametersOfRemoveTollPlaza;
    let AllObjectData, tollDatas, lastIndexOfTollPlaza;
    let login_response_object, token_user, user_id;
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;

        if (tollMainData) {
            AllObjectData = tollMainData;
            console.log("AllObjectData" + JSON.stringify(AllObjectData))
            tollDatas = AllObjectData.allPlaza;
            lastIndexOfTollPlaza = (tollDatas.length) - 1;
        } else {
            AllObjectData = ls.get('journeyTollData', { decrypt: true });
            console.log("AllObjectData" + JSON.stringify(AllObjectData))
            tollDatas = AllObjectData.allPlaza;
            lastIndexOfTollPlaza = (tollDatas.length) - 1;
        }
    }
    // from localStorage Data END
    // useEffect(() => {
    //     if (location.pathname === '/toll-plaza') {
    //         console.log("location.pathname", location.pathname);
    //         window.onpopstate = function (e) {
    //             if (window.confirm('Are you sure you want to leave? Your changes may not be saved.')) {
    //                 // User confirmed, you can navigate back
    //             } else {
    //                 // User canceled, prevent the default navigation (staying on the same page)
    //                 e.preventDefault();
    //                 window.history.pushState(null, null, window.location.href);
    //                 console.log("window.location.href:", window.location.href);
    //             }
    //         };
    //     }
    // }, [location.pathname]);

    // handleTollNameChange(e)
    function handleTollNameChange(e) {
        setTollAddData({ ...tollAddData, [e.target.name]: e.target.value })

    }

    // add first toll START
    // function addFirstToll() {
    //     console.log("fff")
    //     axios.put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
    //         headers: {
    //             Authorization: `Hello ${token_user}`
    //         }
    //     }).then(res => {
    //         console.log(res.data.data)
    //         ls.set('journeyTollData', res.data.data, { encrypt: true })
    //         // window.location.reload();
    //     }).catch(err => {
    //         console.log(err)
    //     })
    // }


    // clickSuggestData(e,elm)
    function clickSuggestData(e, elm) {
        setTollAddData({ ...tollAddData, "toll": elm })
        setShowInputDDL(false);
    }

    // ADD TOLL ALL FUNCTION START .....

    // addTollPlazeFunction(e,ind)
    function addTollPlazeFunction(e, ind, id) {
        window.scrollTo(0, 0)
        setShowInputPop(true)
        setTollInputData({ ...tollInputData, "index": ind })
        // console.log(tollInputData)
        console.log(id)
    }

    function addTollInput(e) {
        setTollInputData({ ...tollInputData, [e.target.name]: e.target.value });
        axios.get(`${searchTollPlaza}/${AllObjectData._id}?toll=${tollInputData.toll}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            setSuggestData(response.data.data)
        }).catch(err => {
            console.log(err)
        })
    }

    // closeJourneyFromToll(e)
    function closeJourneyFromToll(e, closeJourneyID) {
        console.log(AllObjectData)
        axios.put(`${closeJourney}/${closeJourneyID}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            if (response.data.success) {
                dispatch(isShowFiveSec(true));
                nav('/MyJourney')
                window.scrollTo(0, 0)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function addTollFromBackend() {
        axios.put(`${AddTollPlaza}/${AllObjectData._id}`, tollInputData, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(res => {
            console.log(res.data.data.allPlaza)
            ls.set("addButtonToggle", false);
            ls.set('journeyTollData', res.data.data, { encrypt: true })
            window.location.reload();
        }).catch(err => {
            console.log(err)
        })
    }

    function removeTollPlazeFunction() {
        const ind = ls.get("ParametersOfRemoveTollPlaza");
        console.log(ind)
        setTollIndex({ ...tollIndex, "index": ind });
        // console.log(RemoveTollPlaza +" "+AllObjectData._id)
        // console.log(tollIndex)
        axios.put(`${RemoveTollPlaza}/${AllObjectData._id}`, { "index": ind }, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(res => {
            console.log(res.data.data.allPlaza)
            ls.set('journeyTollData', res.data.data, { encrypt: true })
            window.location.reload();
        }).catch(err => {
            console.log(err)
        })
    }

    function showTollDeleteConfirmationPopup(e, ind) {
        setconfirmTollDeletePop(true);
        window.scrollTo(0, 0)
        ls.set("ParametersOfRemoveTollPlaza", ind)
    }

    // ADD TOLL ALL FUNCTION END .....

    // let isPostButtonClicked = false;
    // window.onbeforeunload = function (e) {
    //     if (!isPostButtonClicked) {
    //         const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
    //         (e || window.event).returnValue = confirmationMessage;
    //         return confirmationMessage;
    //     }
    // };
    // post journey summary to backend..
    function postTripToBackend(e) {
        // isPostButtonClicked = true;
        setIsLoader(true);
        console.log(e)
        axios.post(`${postTripSummary}/${AllObjectData._id}`).then(response => {
            if (response.data.success) {
                setIsLoader(false);
                window.alert("Congrats! This journey is posted. You can view this journey in active journey under journey tab")

                let currentTime = moment().format("LLLL");
                let currentTime2 = moment().format("DD-MM-YYYY");
                let obj = {
                    "name": "journeyCreated",
                    "message": "Your journey has been created succesfully",
                    "time": currentTime,
                    "forFilter": currentTime2
                }

                ls.set('journeyCreated', obj)
                nav('/OngoingJourneys')
                window.location.reload();
            }
        }).catch(err => {
            setIsLoader(false);
            console.log(err)
        })
    }



    // pre alert activate function 
    function preAlertActive(e) {
        if (e.target.checked) {
            axios.put(`${preAlertCheck}/${AllObjectData._id}`).then(response => {
                if (response.data.success) {
                    window.alert(response.data.message);

                    let currentTime = moment().format("LLLL");
                    let currentTime2 = moment().format("DD-MM-YYYY");
                    let obj = {
                        "name": "preAlertActive",
                        "message": response.data.message,
                        "time": currentTime,
                        "forFilter": currentTime2
                    }

                    ls.set('preAlertActive', obj)
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            axios.put(`${preAlertCheck}/${AllObjectData._id}`).then(response => {
                if (response.data.success) {
                    window.alert(response.data.message);
                    let currentTime = moment().format("LLLL");
                    let currentTime2 = moment().format("DD-MM-YYYY");
                    let obj = {
                        "name": "preAlertActive",
                        "message": response.data.message,
                        "time": currentTime,
                        "forFilter": currentTime2
                    }

                    ls.set('preAlertActive', obj)
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    // addLastTollPlazaToList()
    function addLastTollPlazaToList(e, ind) {
        window.scrollTo(0, 0)
        setShowInputPop(true)
        setTollInputData({ ...tollInputData, "index": ind })
    }


    return (
        <> {isMobile ? <>
            {console.log('isMobile:', isMobile)
            }
            <Mobile_tollplaza />
        </> :
            <>
                <div>
                    <div className="Navbar">
                        <SecondNavBar />
                    </div>

                    {/* top section Component */}
                    {/* <TopSection
                        bigi={"Journey ID"}
                        small={""}
                    >


                    </TopSection> */}
                    <section
                        className="track_top_section"
                        style={{
                            borderBottom: "1px solid gray",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "73.5%",
                            margin: "auto",
                        }}
                    >
                        {/* left */}
                        <div style={{ padding: "3px 0rem" }} className="flex top_sec_left">
                            <button onClick={() => setShowConfirmation(true)}>
                                <span className="material-symbols-outlined">
                                    keyboard_backspace
                                </span>
                            </button>
                            <div className="ml-5">
                                <h1 className="text-2xl font-Cardo font-bold my-2">Journey ID</h1>
                                <p style={{ fontSize: "0.9rem", marginLeft: "5px" }}>
                                    {small}
                                    {small2 ? <span> {small2} </span> : <></>}
                                    {small3}
                                </p>
                            </div>
                        </div>

                        {/* right */}
                        <div className={classes ? classes : "flex filter_div  top_sec_left"}>
                            {children}
                        </div>
                    </section>

                    {/* Confirmation Dialog */}
                    {/* {showConfirmation && (
                        <div className="confirmation-popup">
                            <div className="confirmation-popup-content">
                                <p>Are you sure you want to leave? Your changes may not be saved.</p>
                                <div className="confirmation-popup-buttons">
                                    <button onClick={() => setShowConfirmation(false)}>Cancel</button>
                                    <button onClick={handleBackClick}>OK</button>
                                </div>
                            </div>
                        </div>
                    )} */}
                    <Dialog
                        open={showConfirmation}
                        onClose={handleClose}
                        aria-labelledby="confirmation-dialog-title"
                        aria-describedby="confirmation-dialog-description"
                    >
                        <DialogTitle id="confirmation-dialog-title">
                            Are you sure you want to leave without Posting Your Journey?
                        </DialogTitle>
                        {/* <DialogContent>
                            <DialogContentText id="confirmation-dialog-description">
                                Your changes may not be saved.
                            </DialogContentText>
                        </DialogContent> */}
                        <DialogActions>
                            <Button onClick={handleClose} style={{color:'white',backgroundColor:'black',borderRadius:'4px'}}>
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} style={{color:'white',backgroundColor:'black',borderRadius:'4px'}}>
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* top section Component END*/}

                    {/* bottomer */}
                    <div className="flex justify-center">
                        <div className="whenclick_bottom_tollPlaza gggggdfefsdnvbx width-for-toll-bottom-main  flex flex-col" style={{ "background": "none", "boxShadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                            {iLogin ?
                                <>
                                    <div className="flex justify-between pl-3 pr-3" style={{ "boxShadow": "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                                        {/* vehicle number */}
                                        <div className="text-start">
                                            <span className="toll_bottom_para  text-black diks" style={{ "fontSize": "0.8rem" }}>Vehicle Registration Number</span>
                                            <h1 className=" text-black diks2">{AllObjectData.vehicle_no}</h1>
                                        </div>

                                        {/* start date */}
                                        <div className="text-start">
                                            <span className="toll_bottom_para text-black diks" style={{ "fontSize": "0.8rem" }}>Start Date</span>
                                            <h1 className=" text-black diks2">{AllObjectData.start_date.slice(0, 10)}</h1>
                                        </div>

                                        {/* end date */}
                                        <div className="text-start">
                                            <span className="toll_bottom_para text-black diks" style={{ "fontSize": "0.8rem" }}>End Date</span>
                                            <h1 className=" text-black diks">{AllObjectData.end_date.slice(0, 10)}</h1>
                                        </div>

                                        {/* total distance */}
                                        <div className="text-start">
                                            <span className="toll_bottom_para text-black diks" style={{ "fontSize": "0.8rem", "fontWeight": "300" }}>Total Distance</span>
                                            <h1 className=" text-black diks2">{AllObjectData.total_distance}</h1>
                                        </div>

                                    </div>


                                    <div className="flex justify-between mt-2 pl-3 pr-3 pb-2">

                                        {/* source */}
                                        <div className="text-start w-[36.5%]">
                                            <span className="toll_bottom_para text-black diks" style={{ "fontSize": "0.8rem" }}>From</span>
                                            <h1 className=" text-black diks2">{AllObjectData.source[0].source}</h1>
                                        </div>

                                        {/* destination */}
                                        <div className="text-start w-[36.5%]">
                                            <span className="toll_bottom_para text-black diks" style={{ "fontSize": "0.8rem" }}>To</span>
                                            <h1 className=" text-black diks2">{AllObjectData.destination[0].destination}</h1>
                                        </div>

                                    </div>
                                </> : <></>
                            }


                        </div>
                    </div>

                    <p className={"w-[73.5%] m-auto text-center font-bold pt-3 "} style={{ "lineHeight": "17px" }}>Kindly click on post button to create journey for this vehicle <br /> You can add or remove toll plaza as per vehicle's actual route</p>

                    <div className={addButtonToggle ? "flex justify-between w-[73.5%] mt-3 mb-3 m-auto " : "hidden"} onClick={(e) => { addTollPlazeFunction(e, 0, 0) }}>
                        <div className="w-[fit-content] m-auto cursor-pointer flex items-center text-gray-300"><span className="text-5xl">+</span> <span className="text-5xl">ADD Toll Plaza</span></div>
                    </div>


                    <section
                        className="track_my_goods height_adjus"
                        style={{ width: "73%", margin: "auto" }}
                    >
                        <div className="track_container ">

                            {/* main tracker section */}
                            <section
                                className="tracker_main_wrapper1"
                                style={{ marginBottom: "3rem", width: "92%", margin: "auto" }}
                            >
                                <div className="tracker_main_container kam_gap">
                                    {iLogin ? tollDatas.map((elm, ind) => {
                                        return <div
                                            className="tracker_row relative py-2"
                                            style={{
                                                "marginTop": "0.5rem",
                                                "display": "flex",
                                                "justifyContent": "space-between",
                                                "alignItems": "center",
                                                "boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                            }}
                                        >

                                            <div className="flex">
                                                <div className="relative" style={{ "display": "none" }} id={`tollADD${ind}`} onFocus={() => { setShowInputDDL(true); }}>

                                                    <div className="suggestion_tolls absolute bg-white max-h-[10rem] min-h-[0] overflow-auto w-[100%] p-3" style={showInputDDL ? { "display": "block", "zIndex": "999" } : { "display": "none" }}>
                                                        {suggestData.map(elm => {
                                                            return <div className="mt-5 cursor-pointer" onClick={(e) => { clickSuggestData(e, elm) }}>{elm}</div>
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* add and minus button START*/}
                                            <div className="flex w-[12%]">

                                                {/* add button */}
                                                <button className="text-sm
                                         mr-5 flex flex-col" onClick={(e) => { addTollPlazeFunction(e, ind, elm.id) }}>
                                                    <span>ADD</span><span>+</span>
                                                </button>

                                                {/* minus button */}
                                                <button className="text-sm flex flex-col" onClick={(e) => { showTollDeleteConfirmationPopup(e, ind) }}>
                                                    <span>Remove</span><b>-</b>
                                                </button>

                                            </div>
                                            {/* add and minus button START*/}


                                            {/* icon */}
                                            <div className={"w-[10%]"} onClick={() => { setShowInputDDL(false) }}>
                                                <span
                                                    class="material-symbols-outlined"
                                                    style={{ fontSize: "50px" }}
                                                >
                                                    location_on
                                                </span>
                                            </div>

                                            {/* plaza */}
                                            <div className="city1 w-[20%]" onClick={() => { setShowInputDDL(false) }}>
                                                <h1 style={{ fontWeight: "500" }}>{elm.match ? `${elm.findplza}*` : elm.findplza}</h1>
                                            </div>

                                            <div
                                                className="track_small_detail_wrapper"
                                                style={{ width: "50%", overflow: "scroll" }}
                                                onClick={() => { setShowInputDDL(false) }}
                                            >
                                                <div
                                                    className="track_small_detail"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        "align-items": "center",
                                                        width: "26rem",
                                                    }}
                                                >
                                                    {/* checkin date */}
                                                    <div
                                                        className="checkinDate"
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                fontWeight: "400",
                                                                marginTop: "0.4rem",
                                                            }}
                                                        >
                                                            City
                                                        </span>

                                                        <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                            {elm.city}
                                                        </b>

                                                    </div>

                                                    {/* checkin date */}
                                                    {/* <div
                                                className="checkinDate"
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        fontWeight: "400",
                                                        marginTop: "0.4rem",
                                                    }}
                                                >
                                                    Date
                                                </span>

                                                <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                    {elm.date?elm.date:"-"}
                                                </b>

                                            </div> */}

                                                    {/* checkin date */}
                                                    {/* <div
                                                className="checkinDate"
                                                style={{ display: "flex", flexDirection: "column" }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "0.8rem",
                                                        fontWeight: "400",
                                                        marginTop: "0.4rem",
                                                    }}
                                                >
                                                    Date
                                                </span>

                                                <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                    {elm.date?elm.date:"-"}
                                                </b>

                                            </div> */}

                                                    {/* checkin time */}
                                                    <div
                                                        className="checkinTime"
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                fontWeight: "400",
                                                                marginTop: "0.4rem",
                                                            }}
                                                        >
                                                            State
                                                        </span>

                                                        <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                            {elm.state}
                                                        </b>

                                                    </div>

                                                    {/* distance covered*/}
                                                    <div
                                                        className="timeLapse"
                                                        style={{ display: "flex", flexDirection: "column" }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "0.8rem",
                                                                fontWeight: "400",
                                                                marginTop: "0.4rem",
                                                            }}
                                                        >
                                                            Distance Covered
                                                        </span>

                                                        <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                            {elm.distance}
                                                        </b>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }) : <></>}
                                </div>
                            </section>

                        </div>

                        {/* last add button START */}
                        <div className="flex justify-center pt-0 pb-6">
                            <button onClick={(e) => { addLastTollPlazaToList(e, tollDatas.length) }} className="bg-black font-semibold text-2xl text-white py-2 px-4" style={{ "borderRadius": "50%" }}>+</button>
                        </div>
                        {/* last add button END */}

                        {/* post journey START */}
                        {/* <section className="post_trip flex justify-center w-[90%] m-auto items-center mb-3" >
                    <div><input type="checkbox" name="checkbox" id="checkbox" onClick={(e) => { preAlertActive(e) }} className="mr-3" />Pre-Alerts : Set Pre-Alerts to get email / sms for deviation related to this vehicle</div>
                    <p></p>
                </section> */}
                        {/* post journey END */}
                    </section>

                    {/* post journey START */}
                    <section className="post_trip flex flex-col justify-center items-center mb-3" >
                        {/* <div>Prealerts<input type="checkbox" name="checkbox" id="checkbox" onClick={(e) => { preAlertActive(e) }} className="mr-5" /></div> */}
                        <Button onClick={(e) => { postTripToBackend(e) }} style={{ "background": "#232323", "color": "white", "width": "10%" }} className="post_trip_button">Post</Button>
                        <span className="text-sm">Journey will get created after posting</span>
                    </section>
                    {/* post journey END */}

                    {/* all toll plazas */}
                    {/* <section className="my-10 w-fit m-auto">
                <Button style={{ "color": "black", "textDecoration": "underline" }} onClick={(e) => { closeJourneyFromToll(e, AllObjectData._id) }}>Close journey</Button>
                <a href={`https://www.google.com/maps/dir/${journeyPlan.source[0].source}/${journeyPlan.destination[0].destination}/`} target={"_blank"} style={{ "color": "black", "textDecoration": "underline" }}>View on google map</a>
                <Button style={{ "color": "black", "textDecoration": "underline" }} onClick={() => { nav('/MyJourney') }}>Create another journey</Button>
                <CSVLink
                    style={{
                        "color": "black", "textDecoration": "underline"
                    }}
                    data={tollDatas}
                    className={"download_scv"}
                >
                    Download journey summary
                </CSVLink>
                <Button style={{ "color": "black", "textDecoration": "underline" }} onClick={() => { nav('/OngoingJourneys') }}>View all active journey</Button>
            </section> */}

                    {/* sure you want to delete toll plaza */}
                    <section className='plan_buy_confirmation absolute top-0 left-0 w-[100%] h-[100%]' style={confirmTollDeletePop ? { "display": "flex", "width": "100% !important" } : { "display": "none" }}>
                        <section className="plan_buy_confirmation__wrapper">

                            <div className="plan_buy_confi__top flex justify-end items-center">
                                <span className="material-symbols-outlined cursor-pointer" onClick={() => { setconfirmTollDeletePop(false) }}>
                                    close
                                </span>
                            </div>

                            <div className="plan_buy_confi__message flex justify-between items-center mt-5">
                                <div>
                                    <h1 className='text-lg'>Do you want to delete this toll from journey?</h1>
                                </div>
                            </div>

                            <div className='plan_buy_confi__button_group mt-5 flex'>
                                <Button onClick={() => { removeTollPlazeFunction() }} style={{
                                    "background": "black", "color": "white",
                                    // "boxShadow": "1px 1px 1px 1px black",
                                    "borderRadius": "2px",
                                    "marginLeft": "1rem"
                                }} >Delete</Button>
                            </div>

                        </section>
                    </section>

                    <Footer />

                    {/* input popup START */}
                    <section className={showInputPop ? "input_popup_wrapper w-[100%] h-[100%] flex bg-white pb-5 justify-center items-center" : "input_popup_wrapper w-[100%] h-[100%] hidden justify-center items-center"}>

                        <div>
                            <section className="input_popup_container  bg-white">

                                <div className="flex justify-end pt-3 mr-3 cursor-pointer" onClick={() => { setShowInputPop(false) }}>
                                    <h6>Do you want to add a toll plaza?</h6>
                                    <span className="material-symbols-outlined">

                                        close
                                    </span>
                                </div>
                                <div className="border m-3">
                                    <input type="text" name="toll" className="border-none" placeholder="Toll plaza name" style={{ "outline": "0px solid black" }} onChange={(e) => { addTollInput(e) }} autoComplete={"false"} value={tollInputData.toll} /><br />
                                </div>
                                <div style={{ "marginTop": "1rem", "marginLeft": "1rem" }}>
                                    <Button style={{ "background": "#232323", "color": "white", "height": "100%" }} onClick={() => { addTollFromBackend() }}>ADD</Button>

                                </div>
                                <div className={"mt-5"} style={{ "width": "100%", "max-height": "10rem", "overflow": "auto", "paddingLeft": "2rem" }}>
                                    {suggestData.map((elm) => {
                                        return <p className="mt-3 cursor-pointer suggestion_boxing" onClick={() => { setTollInputData({ ...tollInputData, "toll": elm }) }}>{elm}</p>
                                    })}
                                </div>
                            </section>
                        </div>

                    </section>
                    {/* input popup END */}

                    {/* loader */}
                    <section style={isLoader ? { "display": "flex" } : { "display": "none" }} id="home_loader" className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center">
                        <CircularProgress />
                    </section>
                </div>
            </>}
        </>
    );
};

export default OnGoingJourney;
