import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { sendRegsiterEmailOTP } from "../../../apis/api";
import { BsArrowRightShort } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import Timer from "../../../components/layouts/Timer/Timer";
import ls from 'localstorage-slim';
import { Button } from "@mui/material";

const FStep5 = () => {

  const nav = useNavigate();

  // iniatize state variables...
  // const [otpFull, setotpFull] = useState([]);
  const [otpFull1, setotpFull1] = useState();
  const [otpFull2, setotpFull2] = useState();
  const [otpFull3, setotpFull3] = useState();
  const [otpFull4, setotpFull4] = useState();
  let otpFullString, otpFullNumber;


  // input changes function
  // function handleInputChange(e) {
  //   setotpFull([...otpFull, e.target.value]);
  //   console.log(otpFull);
  // }

  function handleInputChange1(e) {
    setotpFull1(e.target.value);
    console.log(otpFull1);
  }
  function handleInputChange2(e) {
    setotpFull2(e.target.value);
    console.log(otpFull2);
  }
  function handleInputChange3(e) {
    setotpFull3(e.target.value);
    console.log(otpFull3);
  }
  function handleInputChange4(e) {
    setotpFull4(e.target.value);
    console.log(otpFull4);
  }

  // clickContinue
  const otpID = ls.get("mobileOTP_id",{ decrypt: true })
  async function clickContinue(e) {
    otpFullString = `${otpFull1}${otpFull2}${otpFull3}${otpFull4}`;
    otpFullNumber = String(otpFullString);

    await axios.post(`${sendRegsiterEmailOTP}/${otpID}`, {
      otp: otpFullNumber
    }).then(res=>{
      if(res.data.success){
        nav('/SignUp');
      }
    }).catch(err=>{
      window.alert(err.response.data.message)
      // console.log(err)
    })
  }

  // auto swap functionality
  function getCodeBoxElement(index) {
    return document.getElementById('codeBox' + index);
  }
  function onKeyUpEvent(index, event) {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 4) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
        // Submit code
        console.log('submit code ');
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  }
  function onFocusEvent(index) {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }
  // auto swap functionality


  return (
    <>
      <div className="flex items-center w-full h-[100vh] justify-center border ">
        <Row className="py-8 w-[50%]">
          <div className="px-4 md:px-32 lg:px-36 flex items-center flex-col justify-center w-[100%]">

            {/* top section */}
            <div className="flex flex-row-reverse justify-between items-center mb-8 w-[100%]">

              <div className="flex items-center">

                <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
              </div>

              <div className="flex items-center mt-3 -ml-2">
                <button className="pt-2" onClick={() => { nav(-1) }}><span className="material-symbols-outlined">
                  keyboard_backspace
                </span></button>
                <div>
                  <MainHeading HeadinName="Verification" para={"Check your mobile for otp"} />
                </div>
                {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
              </div>

            </div>

          </div>

          <div className="px-36 flex items-center flex-col justify-center gap-4">

            <div className="w-[100%]">

              <div className="flex justify-between items-center w-full " id="otp">

                {/* input1 */}
                <input
                  onChange={(e) => { handleInputChange1(e) }}
                  id="codeBox1"
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(1, event) }} onFocus={() => { onFocusEvent(1) }}
                  type="number"
                  className="w-16 h-16 inner_shad border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input2 */}
                <input
                  id={"codeBox2"}
                  onKeyUp={(event) => { onKeyUpEvent(2, event) }} onFocus={() => { onFocusEvent(2) }}
                  maxLength={"1"}
                  onChange={(e) => { handleInputChange2(e, 2) }}
                  type="number"
                  className="w-16 h-16 inner_shad border-2 rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input3 */}
                <input
                  id={"codeBox3"}
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(3, event) }} onFocus={() => { onFocusEvent(3) }}
                  onChange={(e) => { handleInputChange3(e) }}
                  type="number"
                  className="w-16 h-16 border-2 inner_shad rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

                {/* input4 */}
                <input
                  id={"codeBox4"}
                  maxLength={"1"}
                  onKeyUp={(event) => { onKeyUpEvent(4, event) }} onFocus={() => { onFocusEvent(4) }}
                  onChange={(e) => { handleInputChange4(e) }}
                  type="number"
                  className="w-16 h-16 border-2 inner_shad rounded bg-transparent outline-none text-center font-semibold text-xl spin-button-none border-gray-400 focus:border-gray-700 focus:text-gray-700 text-gray-400 transition"
                />

              </div>
            </div>

            <div className="w-full mt-2">
              <Button variant="contained" style={{ "background": "#232323" }} className="w-[100%]" onClick={(e) => { clickContinue(e) }}>
                Continue
              </Button>
            </div>
            {/* <Timer /> */}

            <div className="w-[100%] flex justify-between items-center">
              <p className="flex gap-2 text-sm">
                Expire in{" "}
                <span className="text-red-600">
                  {" "}
                  <Timer />
                </span>
              </p>
              <p className="flex content-center items-center cursor-pointer text-sm">
                {" "}
                Resend OTP
                <span>
                  <BsArrowRightShort className="text-[1.5rem]" />
                </span>
              </p>
            </div>
            <div className="text-sm mt-2 text-center flex justify-between w-[100%]"><Link className="underline" to={"/login"}>Have an account?</Link><Link className="underline" to={"/forgetPassword"}>Forgot password?</Link></div>
          </div>
        </Row>
      </div>

    </>
  );
};
const Row = styled.div`
  
`;
export default FStep5;
