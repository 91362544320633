import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const ewayTrackData = createSlice({
    name: 'ewayTrackData',
    initialState,
    reducers: {
        addewayTrackData: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { addewayTrackData } = ewayTrackData.actions

export default ewayTrackData.reducer