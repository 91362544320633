import React from 'react'

const Block = ({sub,main}) => {
    return (
        <div className="block1 flex flex-col w-[64rem]">
            <span className='text-xs text-center'>{sub}</span>
            <span className='text-gray-500 text-center'>{main}</span>
        </div>
    )
}

export default Block