import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

export const AllNotification = createSlice({
    name: 'AllNotification',
    initialState,
    reducers: {
        pushNotification: (state, action) => {
            state.push(action.payload);
        },
    },
})


export const { pushNotification } = AllNotification.actions

export default AllNotification.reducer