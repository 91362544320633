import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Profile.css";
import SecondNavbar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import {
  getProfile,
  modifyPassword,
  modifyPasswordOtp,
  forgot_resetPassword,
  postKYC,
  fetchKYC,
} from "../../apis/api";
import ls from "localstorage-slim";
import axios from "axios";
import FileBase from "react-file-base64";
import TopSection from "../../TopSection";
import Footer from "../../components/layouts/Footer/Footer";
import { Buffer } from "buffer";
import { Button } from "@mui/material";
import { deflate } from "pako";
import { Tooltip } from "@mui/material";

function ProfileTest() {
  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;
  const [apigetData, setApiGetData] = useState([]);
  const [otpSection, setOtpSection] = useState(false);
  let kycId = ls.get("kyc_id", { decrypt: true });
  const loginData = ls.get('login_data_object', { decrypt: true });
  if(!kycId){
    kycId=loginData.user.kyc_id
  }
  const [userData, setUserData] = useState({
    name: "",
    company_name: "",
    company_address: "",
    company_document: "",
    email: "",
    mobile: "",
  });
  const [otp, setOtp] = useState({
    otp: "",
  });

  const [password, setPassword] = useState({
    password: "",
    compare_password: "",
  });

  const [confirmSec, setConfirmSec] = useState(false);

  const [kycData, setKycData] = useState({
    u_id: user_id,
    // aadhar_number: "",
    // pan_number: "",
    // aadhar_file: "",
    // pan_file: "",
    gst_number: "",
    // gst_file: "",
  });

  function changeOTP(e) {
    setOtp({ ...otp, [e.target.name]: e.target.value });
    console.log(otp);
  }

  function deleteAccount(e) {}

  function CancleAccountDeletion(e) {
    setConfirmSec(false);
  }

  useEffect(() => {
    // get profile data
    axios
      .get(`${getProfile}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setUserData({
          name: response.data.data.name,
          company_name: response.data.data.company_name,
          company_address: response.data.data.company_address,
          email: response.data.data.email,
          mobile: response.data.data.mobile,
          gst_file: "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${fetchKYC}/${kycId}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        console.log("todayKycData");
        setApiGetData(response.data.data);
        setKycData({
          u_id: user_id,
          aadhar_number: response.data.data.aadhar_number,
          pan_number: response.data.data.pan_number,
          aadhar_file: "",
          pan_file: "",
          gst_number: response.data.data.gst_number,
          gst_file: "",
        });
        console.log(JSON.stringify(response) + "/////");
        if (response.data.success) {
          ls.set("isGst", response.data.data.gst_number);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // handleFileUpload(base64)
  const handleFileUpload = (file) => {
    // const data = window.atob(file); // Decode the Base64 string
    const data = URL.createObjectURL(new Blob([file], { type: "text/plain" }));
    const compressedData = deflate(data, { to: "string" }); // Compress the decoded data
    const compressedBase64 = window.btoa(compressedData); // Encode the compressed data to Base64
    setUserData({ ...userData, company_document: compressedBase64 });
  };
  // changeUserNormalDetail(e)
  function changeUserNormalDetail(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    console.log(userData);
  }
  // send otp to backend
  async function otpVarification() {
    console.log(otp);
    await axios
      .put(`${modifyPasswordOtp}/${user_id}`, otp)
      .then((res) => {
        console.log("isTrueComing...");
        console.log(res);
        if (res.data.success) {
          axios
            .post(`${forgot_resetPassword}/${user_id}`, password)
            .then((response) => {
              console.log(response);
              if (response.data.success) {
                window.alert("Congrats! your password is updated successfully");
                setOtpSection(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert(err.response.data.message);
      });
  }
  //handleFormSubmitUser(e)
  async function handleFormSubmitUser(event) {
    if (userData.name.length < 5) {
      window.alert("Please enter minimum 5 characters");
    } else {
      // post
      await axios
        .put(`${getProfile}/${user_id}`, userData, {
          headers: {
            Authorization: `Hello ${token_user}`,
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            window.alert("User detail updated successfully");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  // changePassword(e)
  function changePassword(e) {
    setPassword({ ...password, [e.target.name]: e.target.value });
    console.log(password);
  }
  // send Password(e)
  async function sendPassword(e) {
    if (password.password.length < 8) {
      window.alert("password must have 8 digits");
    } else if (password.compare_password.length !== password.password.length) {
      window.alert("password must be match");
    } else {
      await axios
        .post(`${modifyPasswordOtp}/${user_id}`)
        .then((response) => {
          console.log(response.data.success);
          if (response.data.success) {
            setOtpSection(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  // handleKYChange(e)
  function handleKYChange(e) {
    setKycData({ ...kycData, [e.target.name]: e.target.value });
    console.log(kycData);
  }
  // kyc file upload
  function kycFileUpload(base64String, name) {
    if (name === "aadhar_file") {
      const data = URL.createObjectURL(
        new Blob([base64String], { type: "text/plain" })
      );
      const compressedData = deflate(data, { to: "string" }); // Compress the decoded data
      const compressedBase64 = window.btoa(compressedData); // Encode the compressed data to Base64
      setKycData({ ...kycData, aadhar_file: compressedBase64 });
    }
    if (name === "pan_file") {
      const data = URL.createObjectURL(
        new Blob([base64String], { type: "text/plain" })
      );
      const compressedData = deflate(data, { to: "string" }); // Compress the decoded data
      const compressedBase64 = window.btoa(compressedData); // Encode the compressed data to Base64
      setKycData({ ...kycData, pan_file: compressedBase64 });
    }
    if (name === "gst_file") {
      const data = URL.createObjectURL(
        new Blob([base64String], { type: "text/plain" })
      );
      const compressedData = deflate(data, { to: "string" }); // Compress the decoded data
      const compressedBase64 = window.btoa(compressedData); // Encode the compressed data to Base64
      setKycData({ ...kycData, gst_file: compressedBase64 });
    }
  }
  function kycFileUpload2(e, name) {
    if (name === "aadhar_file") {
      setKycData({ ...kycData, aadhar_file: e.target.files[0] });
      console.log("kycUploads")
      console.log(e.target.files[0])
    }
    if (name === "pan_file") {
      setKycData({ ...kycData, pan_file: e.target.files[0] });
      console.log("kycUploads")
      console.log(e.target.files[0])
    }
    if (name === "gst_file") {
      setKycData({ ...kycData, gst_file: e.target.files[0] });
      console.log("kycUploads")
      console.log(e.target.files[0])
    }
  }
  // doUserKYC()
  async function doUserKYC(e) {
    // if (kycData.aadhar_number == "" || kycData.aadhar_number == " " || kycData.aadhar_number == "  " || kycData.aadhar_number == "   " || kycData.aadhar_number == "    " || kycData.aadhar_number == "     " || kycData.aadhar_number == "      " || kycData.aadhar_number == "       " || kycData.pan_number == "" || kycData.pan_number == " " || kycData.pan_number == "  " || kycData.pan_number == "   " || kycData.pan_number == "    " || kycData.pan_number == "     " || kycData.pan_number == "      " || kycData.pan_number == "       ") {
    //     window.alert("all field required")
    // } else {
    // post
    await axios
      .post(postKYC, kycData, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          console.log(response);
          ls.set("kyc_id", response.data.data._id, { encrypt: true });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // }
  }

  return (
    <>
      <div className="">
        <div className="nav">
          <SecondNavbar />
        </div>

        {/* top section Component */}
        <TopSection bigi={"Profile"} small={""}></TopSection>
        {/* top section Component END*/}

        {/* profile main section START */}
        <main
          className="profile_main_section w-[73.5%] m-auto rounded-sm p-2 my-2 bg-white"
          style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
        >
          <div className="container__profile_container w-[90%] m-auto py-2">
            {/* full name START*/}
            <div>
              <div className="inputItem flex flex-col">
                <label>Full Name</label>
                <input
                  className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                  name="name"
                  onChange={(e) => {
                    changeUserNormalDetail(e);
                  }}
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  value={userData.name}
                />
                <span className="text-xs mt-1">
                  Minimum 5 characters required
                </span>
              </div>
            </div>
            {/* full name END */}

            {/* email and mobile START */}
            <div className="second-Section email_phone_sec flex  w-[100%] mt-2 justify-between">
              <div className="inputItem flex flex-col gap-2 w-[48%]">
                <label>Email</label>
                <input
                  type="email"
                  className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                  placeholder="example@gmail.com"
                  name="email"
                  onChange={(e) => {
                    changeUserNormalDetail(e);
                  }}
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  value={userData.email}
                />
                {/* <span className="text-xs">you can't update email</span> */}
              </div>
              <div className="inputItem flex flex-col gap-2 w-[48%]">
                <label>Phone</label>
                <input
                  type="number"
                  className="border-none bg-slate-100 border-black rounded-md w-[100%] p-1 pl-2"
                  placeholder="mobile number"
                  onChange={(e) => {
                    changeUserNormalDetail(e);
                  }}
                  name="mobile"
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  value={userData.mobile}
                  disabled
                />
                <span className="text-xs">you can't update mobile number</span>
              </div>
            </div>
            {/* email and mobile END */}

            {/* company detail START */}
            <div className="second-Section flex flex-col mt-2 w-[100%]">
              <div className="inputItem flex flex-col gap-2">
                <label>Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  onChange={(e) => {
                    changeUserNormalDetail(e);
                  }}
                  value={userData.company_name}
                />
              </div>
              <div className="inputItem flex flex-col mt-2">
                <label>Company Address</label>

                <textarea
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  name="company_address"
                  value={userData.company_address}
                  className=" border-none border-black rounded-md w-[100%] p-1 mt-2 pl-2"
                  rows="2"
                  cols="10"
                  wrap="soft"
                  onChange={(e) => {
                    changeUserNormalDetail(e);
                  }}
                ></textarea>
              </div>
              <div
                className="mt-2 pl-3 rounded-md"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <label>Upload Document</label>
                <br></br>
                <FileBase
                  type="file"
                  multiple={false}
                  onDone={({ base64 }) => handleFileUpload(base64)}
                  max={1000000}
                  name="company_document"
                  style={{ width: "100%" }}
                />
              </div>
              <span className="inline-block mt-3 underline text-sm">
                file upload limit is- 1 MB
              </span>
            </div>
            {/* company detail END */}

            {/* save button */}
            <Button
              onClick={(e) => {
                handleFormSubmitUser(e);
              }}
              style={{
                marginTop: "1rem",
                background: "#232323",
                color: "white",
                fontFamily: "inter",
              }}
            >
              Save Changes
            </Button>
            {/* save button */}
          </div>
        </main>
        {/* profile main section END */}

        {/* password section START */}
        <main
          className="profile_main_section w-[73.5%] m-auto rounded-sm p-2 my-2 bg-white"
          style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
        >
          <div className="container__profile_container w-[90%] m-auto py-2">
            {/* password START */}
            <div className="second-Section passowddsdsdw flex  w-[100%] mt-2 justify-between">
              <div className="inputItem flex flex-col gap-2 w-[48%]">
                <label>Password</label>
                <input
                  type="password"
                  className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                  placeholder="********"
                  name="password"
                  onChange={(e) => {
                    changePassword(e);
                  }}
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                />
              </div>
              <div className="inputItem flex flex-col gap-2 w-[48%]">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="border-none border-black rounded-md w-[100%] p-1 pl-2"
                  placeholder="********"
                  onChange={(e) => {
                    changePassword(e);
                  }}
                  name="compare_password"
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                />
              </div>
            </div>
            {/* password END */}

            {/* save button */}
            <Button
              onClick={(e) => {
                sendPassword(e);
              }}
              style={{
                marginTop: "1rem",
                background: "#232323",
                color: "white",
                fontFamily: "inter",
              }}
            >
              Submit
            </Button>
            {/* save button */}

            {/* otp */}
            <div
              className={otpSection ? "inputItem flex flex-col t-2" : "hidden"}
            >
              <label>OTP</label>
              <div
                className="border-none border-black rounded-md w-[100%]  flex justify-between items-center pl-2"
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
              >
                <input
                  className="border-none outline-none w-[78%]"
                  name="otp"
                  type={"text"}
                  placeholder={"Ex 7543"}
                  onChange={(e) => {
                    changeOTP(e);
                  }}
                  maxLength={4}
                />
                <div>
                  <Button
                    style={{
                      background: "#232323",
                      color: "white",
                      fontFamily: "inter",
                      fontSize: "0.8rem",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      otpVarification();
                    }}
                  >
                    Submit OTP
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* password main section END */}

        {/* kyc main section START */}
        <main
          className="profile_main_section w-[73.5%] m-auto rounded-sm p-2 my-2 bg-white"
          style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
        >
          <div className="container__profile_container w-[90%] m-auto py-2">

            {/* second Row START */}
            <div className="inputItem flex flex-col gap-2">
              <div>
                <label>Gst Number</label>
                <input
                  placeholder="Example 22AAAAA0000A1Z5"
                  type="text"
                  name="gst_number"
                  className="border-none uppercase border-black rounded-md w-[100%] p-1 pl-2"
                  style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                  value={kycData.gst_number}
                  onChange={(e) => {
                    handleKYChange(e);
                  }}
                />
              </div>
            </div>
            {/* second Row END */}

           

            {/* save button */}
            <Button
              onClick={() => {
                doUserKYC();
              }}
              style={{
                marginTop: "1rem",
                background: "#232323",
                color: "white",
                fontFamily: "inter",
              }}
            >
              Save Changes
            </Button>
            {/* save button */}
          </div>
        </main>
        {/* kyc main section END */}

        {/* Dangerous section START */}
        <main
          className="profile_main_section w-[73.5%] m-auto rounded-sm p-2 my-2 bg-white"
          style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
        >
          <div className="container__profile_container w-[90%] m-auto py-2">
            <h1 className="text-red-600 font-semibold">Dangerous Zone</h1>

            <div className="flex items-center">
              <Button
                onClick={() => {
                  setConfirmSec(true);
                }}
                style={{ border: "none", textTransform: "lowercase" }}
                variant="outlined"
              >
                delete your account
              </Button>
              <div
                style={confirmSec ? { display: "block" } : { display: "none" }}
              >
                <button
                  onClick={(e) => {
                    deleteAccount(e);
                  }}
                >
                  <Tooltip
                    title={"Are you sure you want to delete account?"}
                    placement={"top"}
                  >
                    <span className="material-symbols-outlined text-xl">
                      done
                    </span>
                  </Tooltip>
                </button>
                <button
                  onClick={(e) => {
                    CancleAccountDeletion(e);
                  }}
                >
                  <Tooltip title={"Cancle request?"} placement={"top"}>
                    <span className="material-symbols-outlined text-xl">
                      close
                    </span>
                  </Tooltip>
                </button>
              </div>
            </div>
          </div>
        </main>
        {/* Dangerous section END */}
      </div>

      <Footer />
    </>
  );
}

export default ProfileTest;
