import React, { useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TruckIcon from "../../components/assets/svg/TruckIcon";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import ls from "localstorage-slim";
import { useDispatch, useSelector } from "react-redux";
import { addTollData } from "../../redux/slices/journeyTollData";
import "./JourneyPlanner.css";
import {
  journeyPlanPost,
  vehiclePost,
  oriDes_suggestion,
} from "../../apis/api";
import axios from "axios";
import Footer from "../../components/layouts/Footer/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { isShowFiveSec } from "../../redux/slices/five_sec_popup";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import {
  Plan_Journey_Top_Heading_Hn,
  Plan_Journey_Top_Subheading_Hn,
  Plan_Journey_Top_Under_Button_Hn,
} from "../../components/translation/Hn";
import {
  Plan_Journey_Top_Heading_En,
  Plan_Journey_Top_Subheading_En,
  Plan_Journey_Top_Under_Button_En,
} from "../../components/translation/En";

function JourneyPlanner() {
  const param = useParams();
  let vehicleFromRedux;
  let conditionFromPara = param.from;
  let planVehicle = ls.get('planVehicle');


  vehicleFromRedux = useSelector(
    (state) => state.Vehicle_Number_from_vehicleList.value
  );
  const eng = useSelector((state) => state.translate.value);
  const walletBalance = useSelector((state) => state.WalletBalance.value);
  const walletBalanceFromLocal = ls.get("walletBalance", { decrypt: true });

  // for credits calculation declare variables
  let backDate, fronDate;
  let balanceOBJ;
  if (walletBalance) {
    balanceOBJ = walletBalance;
  } else {
    balanceOBJ = walletBalanceFromLocal;
  }

  const [googleSuggestionData, setGoogleSuggestion] = useState([]);
  const [googleSuggestionDest, setGoogleSuggestionDest] = useState([]);
  const [showOriginBox, setShowOriginBox] = useState(false);
  const [showDestination, setShowDestinationBox] = useState(false);

  const dispatch = useDispatch();
  const inputElement = useRef();
  const vehicleADD = useRef();
  const EndDatee = useRef();
  const nav = useNavigate();
  const iLogin = ls.get("iLogin");
  const [isLoader, setIsLoader] = useState();

  let walletMoney = useSelector((state) => state.hasWalletMoney.value);
  let isFiveSecPop = useSelector((state) => state.five_sec_popup.value);

  setTimeout(() => {
    dispatch(isShowFiveSec(false));
  }, 3000);

  let login_response_object,
    token_user,
    user_id = "1";

  if (iLogin) {
    login_response_object = ls.get("login_data_object", { decrypt: true });
    token_user = login_response_object.token;
    user_id = login_response_object.user._id;
  }

  // vehicle data object for post
  const [inputDatasss, setInputData] = useState({
    u_id: user_id,
    make: "",
    vin: "",
    validity: "",
    type: "",
    model: "",
  });

  const [isConfirmPop, setIsConfirmPop] = useState(false);
  const [AddVehicleForm, setAddVehicleForm] = useState(false);
  const [journyPlanData, setJourneyData] = useState({
    u_id: user_id,
    vehicle_no: planVehicle?planVehicle:"",
    source: "",
    destination: "",
    end_date: "",
  });

  let currentDate = new Date().toLocaleDateString();
  let test = new Date();
  const currentDate2 = moment(test).format("YYYY-MM-DD");

  function dayCalculate(journeyDate, Current) {
    console.log(journeyDate + "=" + Current);
    var end_day, today;
    end_day = new Date(journeyDate);
    today = new Date(Current);

    //calculate time difference
    var time_difference = end_day.getTime() - today.getTime();

    //calculate days difference by dividing total milliseconds in a day
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return days_difference;
  }

  // submitClickFunction(e)
  function submitClickFunction(e) {
    let currentDatea = moment(test).format("YYYY-MM-DD");

    e.preventDefault();

    if (!iLogin) {
      window.alert("please login first");
    } else {
      if (
        inputElement.current.value.length > 12 ||
        inputElement.current.value.length < 9
      ) {
        window.alert("Please enter correct vehicle detail");
      } else if (!isFinite(inputElement.current.value.slice(6, 10))) {
        window.alert("Please enter correct vehicle detail");
      } else if (!EndDatee.current.value || !inputElement.current.value) {
        window.alert("please select end_date of this journey");
      } else if (journyPlanData.end_date < currentDatea) {
        window.alert("Please select today/future date");
      } else {
        setIsConfirmPop(true);
      }
    }
  }

  // change input function...
  function changeInput(e, typer) {
    console.log('june')
    console.log(e.target.value)
    let currentDatea = moment(test).format("YYYY-MM-DD");
    // if(e.target.value < currentDatea){
    //   window.alert("please do not selecte past date")
    // }

    setJourneyData({ ...journyPlanData, [e.target.name]: e.target.value });
    console.log(journyPlanData)
    ls.set("formData", JSON.stringify(journyPlanData), { encrypt: true });
    // console.log(`${oriDes_suggestion}/${journyPlanData.source}`);
    if (typer == "origin") {
      setShowOriginBox(true);
      setShowDestinationBox(false);

      axios
        .get(`${oriDes_suggestion}/${e.target.value}`)
        .then((res) => {
          console.log(res.data);
          setGoogleSuggestion(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (typer == "dest") {
      setShowDestinationBox(true);
      setShowOriginBox(false);
      axios
        .get(`${oriDes_suggestion}/${e.target.value}`)
        .then((res) => {
          console.log(res.data);
          setGoogleSuggestionDest(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // vehiclechangeInput..
  function vehiclechangeInput(e) {
    const value = e.target.value.replace(/\s/g, "").toUpperCase();
    setJourneyData({ ...journyPlanData, [e.target.name]: value });
    console.log(journyPlanData);
    ls.set("formData", JSON.stringify(journyPlanData), { encrypt: true });
  }

  // sendDataToBackend(e)
  function sendDataToBackend(e, compareCredits) {
    console.log(compareCredits);
    if (balanceOBJ.credits < compareCredits || balanceOBJ.credits === 0) {
      window.alert("please buy plan");
      nav("/all-plans");
    } else {
      if (
        journyPlanData.source.length < 3 ||
        journyPlanData.destination.length < 3
      ) {
        setIsLoader(true);
        axios
          .post(journeyPlanPost, journyPlanData, {
            headers: {
              Authorization: `Hello ${token_user}`,
            },
          })
          .then(function (response) {
            ls.set("addButtonToggle", true);
            console.log(response);
            dispatch(addTollData(response.data.data));

            ls.set("journeyPlan", journyPlanData);
            ls.set("journeyTollData", response.data.data);
            setIsLoader(false);
            console.log(response);
            nav("/toll-plaza");
          })
          .catch(function (error) {
            setIsLoader(false);
            window.alert(error.response.data.message);
            console.log(error.response.data.message);
          });
      } else {
        setIsLoader(true);
        axios
          .post(journeyPlanPost, journyPlanData, {
            headers: {
              Authorization: `Hello ${token_user}`,
            },
          })
          .then(function (response) {
            ls.set("addButtonToggle", false);
            console.log(response);
            dispatch(addTollData(response.data.data));
            ls.set("journeyPlan", journyPlanData);
            ls.set("journeyTollData", response.data.data, { encrypt: true });
            setIsLoader(false);
            nav("/toll-plaza");
          })
          .catch(function (error) {
            setIsLoader(false);
            console.log(error);
            window.alert(error.response.data.message);
            console.log(error.response.data.message);
          });
        // setIsConfirmPop(false);
        console.log(journeyPlanPost);
        console.log(journyPlanData);
      }
    }
  }

  // inputChange(e)
  function inputChange(e) {
    if (e.target.name == "vin") {
      const value = e.target.value.replace(/\s/g, "").toUpperCase();
      setInputData({ ...inputDatasss, vin: value });
    } else {
      setInputData({ ...inputDatasss, [e.target.name]: e.target.value });
      console.log(inputDatasss);
    }
  }

  // addVehicalToBackend()
  let conditioner = 1;
  function addVehicalToBackend() {
    if (conditioner === 1) {
      conditioner = 0;
      if (inputDatasss.vin.length > 12 || inputDatasss.vin.length < 8) {
        window.alert("vehicle number must have 9 to 12 digits");
      } else if (inputDatasss.vin.length == 0) {
        window.alert("please fill your vehicle number");
      } else if (!isFinite(inputDatasss.vin.slice(-4))) {
        window.alert("please enter valid vehicle number");
      } else {
        axios
          .post(vehiclePost, inputDatasss, {
            headers: {
              Authorization: `Hello ${token_user}`,
            },
          })
          .then(function (response) {
            console.log(response);
            if (response.data.success) {
              window.alert("your vehicle has been added succesfully");
              let currentTime = moment().format("LLLL");
              let currentTime2 = moment().format("DD-MM-YYYY");
              let obj = {
                name: "vehicleAddSuccess",
                message: "your vehicle has been added succesfully",
                time: currentTime,
                forFilter: currentTime2,
              };
              ls.set("vehicleAddSuccess", obj);
              nav("/ListOfVehicle");
              window.location.reload();
            }
          })
          .catch(function (error) {
            window.alert(error.response.data.message);
            console.log(error);
          });
      }
    }
  }

  return (
    <>
      <div className="main-container w-full">
        <div className="navbar">
          <SecondNavBar active4={true} />
        </div>
        {/* top section Component */}
        <TopSection
          bigi={eng ? Plan_Journey_Top_Heading_En : Plan_Journey_Top_Heading_Hn}
          small={
            eng
              ? Plan_Journey_Top_Subheading_En
              : Plan_Journey_Top_Subheading_Hn
          }
          extraText={""}
          classes={"flex filter_div  top_sec_left flex-col"}
        >
          <Tooltip
            title="Add your vehicle number quickly from here"
            placement="left"
          >
            <Button
              onClick={() => {
                iLogin
                  ? setAddVehicleForm(true)
                  : window.alert("please login first");
              }}
              variant="outline"
              style={{
                background: "#232323",
                color: "white",
                textTransform: "capitalize",
                marginTop: "0.7rem",
              }}
            >
              Add Vehicle Quickly
            </Button>
          </Tooltip>
          <p
            className={"pt-1"}
            style={{ fontSize: "0.9rem", marginLeft: "5px" }}
          >
            Frequently tracked vehicles
          </p>
        </TopSection>

        {/* top section Component END*/}
        <div className={"journey_planner_bottom hidden"}>
          <div className="flex justify-center ">
            {/* <div>
            <div className="text-center">
              <TruckRight />
            </div>
          </div> */}

            <div className="whenclick_bottom flex items-center">
              <div
                className="text-center first_onjourney_icon flex flex-col items-center justify-center p-5"
                style={{ background: "#28282B" }}
              >
                <svg
                  width="38"
                  height="44"
                  viewBox="0 0 38 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5409 14.6873C5.64506 -3.35432 32.3742 -3.33349 36.4576 14.7082C38.8534 25.2915 32.2701 34.2498 26.4992 39.7915C24.4827 41.7364 21.7904 42.8232 18.9888 42.8232C16.1872 42.8232 13.4949 41.7364 11.4784 39.7915C5.7284 34.2498 -0.854936 25.2707 1.5409 14.6873Z"
                    fill="#232323"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M19 24.979C19.8536 24.979 20.6988 24.8109 21.4874 24.4842C22.2761 24.1576 22.9926 23.6788 23.5962 23.0752C24.1998 22.4716 24.6786 21.7551 25.0052 20.9664C25.3319 20.1778 25.5 19.3326 25.5 18.479C25.5 17.6254 25.3319 16.7802 25.0052 15.9916C24.6786 15.2029 24.1998 14.4864 23.5962 13.8828C22.9926 13.2792 22.2761 12.8004 21.4874 12.4738C20.6988 12.1471 19.8536 11.979 19 11.979C17.2761 11.979 15.6228 12.6638 14.4038 13.8828C13.1848 15.1018 12.5 16.7551 12.5 18.479C12.5 20.2029 13.1848 21.8562 14.4038 23.0752C15.6228 24.2942 17.2761 24.979 19 24.979Z"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>

                <p className="text-white">{journyPlanData.source}</p>
              </div>

              <div
                className="flex flex-col justify-center items-center"
                style={{ width: "80%" }}
              >
                <TruckIcon />
                <div className="dashed_line"></div>
                <p className="text-white mt-2">{journyPlanData.vehicle_no}</p>
              </div>

              <div
                className="text-center flex flex-col p-5 items-center justify-center"
                style={{ background: "#28282B" }}
              >
                <svg
                  width="38"
                  height="44"
                  viewBox="0 0 38 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5409 14.6873C5.64506 -3.35432 32.3742 -3.33349 36.4576 14.7082C38.8534 25.2915 32.2701 34.2498 26.4992 39.7915C24.4827 41.7364 21.7904 42.8232 18.9888 42.8232C16.1872 42.8232 13.4949 41.7364 11.4784 39.7915C5.7284 34.2498 -0.854936 25.2707 1.5409 14.6873Z"
                    fill="#232323"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M19 24.979C19.8536 24.979 20.6988 24.8109 21.4874 24.4842C22.2761 24.1576 22.9926 23.6788 23.5962 23.0752C24.1998 22.4716 24.6786 21.7551 25.0052 20.9664C25.3319 20.1778 25.5 19.3326 25.5 18.479C25.5 17.6254 25.3319 16.7802 25.0052 15.9916C24.6786 15.2029 24.1998 14.4864 23.5962 13.8828C22.9926 13.2792 22.2761 12.8004 21.4874 12.4738C20.6988 12.1471 19.8536 11.979 19 11.979C17.2761 11.979 15.6228 12.6638 14.4038 13.8828C13.1848 15.1018 12.5 16.7551 12.5 18.479C12.5 20.2029 13.1848 21.8562 14.4038 23.0752C15.6228 24.2942 17.2761 24.979 19 24.979Z"
                    stroke="white"
                    strokeWidth="1.5"
                  />
                </svg>

                <p className="text-white">{journyPlanData.destination}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="center pt-[17px] pb-[3rem] w-[42%] form_journey_planeer_inner m-auto flex flex-col gap-4 items-center max-sm:justify-start max-sm:w-[90%] p-5"
          style={{
            background: "white",
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
          }}
        >
          <div className="w-full flex  flex-col bg-white">
            <div className="origin font-Cardo font-normal">
              <label>
                Vehicle Registration Number
                <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <div className="input w-full">
              <input
                className="placeholder:text-xs uppercase border-none rounded-md border-gray-500 p-1 font-Inter font-normal w-full px-2 text-base bg-transparent"
                type="text"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                name="vehicle_no"
                id="origin"
                placeholder="Example GJ18 AH 0998"
                onChange={(e) => {
                  vehiclechangeInput(e);
                }}
                value={journyPlanData.vehicle_no}
                ref={inputElement}
                // value={vehicleFromRedux ? vehicleFromRedux : inputDatasss.vin}
              />
            </div>
          </div>

          <div className="flex w-[100%]">
            <div className="w-full flex flex-col" style={{ marginTop: "-7px" }}>
              <div className="origin font-Cardo font-normal">
                <label>Origin</label>
              </div>

              <div
                onBlur={() => {
                  setShowOriginBox(false);
                }}
              >
                <div className="input w-full  relative">
                  <input
                    className="border-none bg-transparent placeholder:text-xs rounded-md border-gray-500 p-1 font-Inter font-normal w-full px-2"
                    type="text"
                    name="source"
                    id="origin"
                    placeholder="Example Bhiwandi"
                    onInput={(e) => {
                      changeInput(e, "origin");
                    }}
                    autoComplete={"false"}
                    value={journyPlanData.source}
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  />

                  {/* suggestion box of origin  */}
                  <div
                    className={
                      showOriginBox
                        ? "ori_suggestion cursor-pointer rounded mt-1 p-1"
                        : "hidden"
                    }
                    style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
                  >
                    {googleSuggestionData.map((elm) => {
                      return (
                        <div
                          className="origin_jp text-sm"
                          data-value={elm.description}
                          onMouseOver={(e) => {
                            setJourneyData({
                              ...journyPlanData,
                              source: e.target.dataset.value,
                            });
                            console.log(journyPlanData);
                          }}
                        >
                          {elm.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full placeholder:text-xs flex flex-col ml-5"
              style={{ marginTop: "-7px" }}
            >
              <div className="Destination font-Cardo font-normal">
                <label>Destination</label>
              </div>

              <div
                className="input w-full relative"
                onBlur={() => {
                  setShowOriginBox(false);
                }}
              >
                <input
                  className="placeholder:text-xs bg-transparent border-none rounded-md border-gray-500 p-1 font-Inter font-normal w-full px-2"
                  type="text"
                  name="destination"
                  id="Destination"
                  placeholder="Example Calcutta"
                  on
                  onInput={(e) => {
                    changeInput(e, "dest");
                  }}
                  value={journyPlanData.destination}
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  onBlur={() => {
                    setShowDestinationBox(false);
                  }}
                />

                {/* suggestion box of destination  */}
                <div
                  className={
                    showDestination
                      ? "ori_suggestion cursor-pointer rounded mt-1 p-1"
                      : "hidden"
                  }
                  style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}
                >
                  {googleSuggestionDest.map((elm) => {
                    return (
                      <div
                        className="destination_jp text-sm"
                        data-value={elm.description}
                        onMouseOver={(e) => {
                          setJourneyData({
                            ...journyPlanData,
                            destination: e.target.dataset.value,
                          });
                          console.log(journyPlanData);
                        }}
                      >
                        {elm.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "-7px" }}
            className="last-two placeholder:text-xs w-full flex justify-between gap-20 max-sm:flex-col max-sm:gap-0"
          >
            <div className="w-full flex flex-col">
              <div className="Start-Date font-Cardo font-normal">
                <label>
                  Estimated End Date<span style={{ color: "red" }}>*</span>
                </label>
              </div>

              <div className="input w-full">
                <Tooltip title={"You can preclose or extend the End Date after POSTING the journey also."}>
                  <input
                    className="border-none bg-transparent rounded-md border-gray-500 p-1 font-Inter font-normal w-full px-2"
                    type="date"
                    name="end_date"
                    id="Destination"
                    placeholder="Example 11/02/2023 "
                    onChange={(e) => {
                      changeInput(e);
                    }}
                    ref={EndDatee}
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col w-full gap-10 "
            style={{ marginTop: "-7px" }}
          >
            <button
              onClick={(e) => {
                submitClickFunction(e);
              }}
            >
              <div className="w-full mt-4 max-sm:mb-0">
                <div className="bg-[#232323] p-1 text-white text-center rounded-md font-Cardo text-2xl ">
                  <span>Submit</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      <Footer />

      {/* pop Add Vehicle Form */}
      <div
        style={
          AddVehicleForm
            ? { display: "block", boxShadow: " black 0px 7px 29px 0px" }
            : { display: "none" }
        }
      >
        <section className="pop_up_main_wrapper overwrite_main_wrapper_popup">
          {/* add vehicle form */}
          <section className="vehicle_add_form bg-white mt-16 mb-16 w-[60%]">
            {/* top section */}
            <div
              className="w-[100%] flex justify-between items-center"
              style={{ background: "#232323" }}
            >
              <h1
                className={"text-white w-[100%] py-5 pl-5 text-xl font-medium"}
              >
                Add Your Vehicle
              </h1>

              <span
                className="material-symbols-outlined text-white mr-5 cursor-pointer"
                onClick={() => {
                  setAddVehicleForm(false);
                }}
              >
                close
              </span>
            </div>

            {/* form */}
            <form className="p-5">
              {/* row 1 */}
              <div className="w-[100%] flex flex-col justify-center my-5 row_row">
                <label htmlFor="vin">
                  Vehicle number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  style={{
                    textTransform: "uppercase",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    border: "none",
                    outline: "none",
                  }}
                  ref={vehicleADD}
                  autoComplete="true"
                  placeholder="Eg WB25D1324"
                  onChange={(e) => {
                    inputChange(e);
                  }}
                  id="vin"
                  name="vin"
                  className="uppercase w-[100%] py-2 px-3 border-none"
                />
              </div>

              {/* row 2*/}
              <div className="w-[100%] flex justify-between my-5 row_row">
                <div style={{ width: "49%" }}>
                  <label htmlFor="make">Make</label>
                  <input
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="Example Tata"
                    onChange={(e) => {
                      inputChange(e);
                    }}
                    name={"make"}
                    id={"make"}
                    className=" w-[100%] py-2 px-3"
                  />
                </div>

                <div style={{ width: "49%" }}>
                  <label htmlFor="model">Model</label>
                  <input
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="Example LP2516"
                    onChange={(e) => {
                      inputChange(e);
                    }}
                    name="model"
                    id="model"
                    className="w-[100%] py-2 px-3"
                  />
                </div>
              </div>

              {/* row 3*/}
              <div className="w-[100%] flex justify-between my-5 row_row">
                <div style={{ width: "49%" }}>
                  <label htmlFor="type">Year</label>
                  <input
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="Example 2022"
                    onChange={(e) => {
                      inputChange(e);
                    }}
                    name={"type"}
                    id={"type"}
                    className=" w-[100%] py-2 px-3"
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <label htmlFor="validity">RTO Validity</label>
                  <input
                    style={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="mm/dd/yyyy"
                    onChange={(e) => {
                      inputChange(e);
                    }}
                    type={"date"}
                    name="validity"
                    id="validity"
                    className="w-[100%] py-2 px-3"
                  />
                </div>
              </div>

              <div className="button_group_journeyplanner">
                <Button
                  onClick={() => {
                    addVehicalToBackend();
                  }}
                  className="px-10"
                  style={{
                    background: "#232323",
                    color: "white",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  Add Vehicle
                </Button>
                <Button
                  onClick={() => {
                    setAddVehicleForm(false);
                  }}
                  style={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    color: "#232323",
                    marginLeft: "1rem",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </section>
        </section>
      </div>

      {/* popup */}
      <div style={isConfirmPop ? { display: "block" } : { display: "none" }}>
        <section className="pop_up_main_wrapper">
          <div className="main_popup_1ss">
            <div className="close_icon flex justify-between items-center">
              <p className="plan_journey_pop_para">Confirm your details</p>
              <span
                onClick={() => {
                  setIsConfirmPop(false);
                }}
                className="material-symbols-outlined"
                style={{
                  background: "#F8F9FA",
                  borderRadius: "50%",
                  padding: "0.1rem",
                  cursor: "pointer",
                }}
              >
                close
              </span>
            </div>

            <div className="pop1_body_main my-5">
              {/* {row1} */}
              <div className="popup__row1">
                {/* vehicle number */}
                <div>
                  <div className="text-center -mt-5 ">
                    <span className="popup__row1_span1">
                      Vehicle Registration Number
                    </span>
                    <h1 className="popup__row1_h1 break-words bg-gray-300">
                      {journyPlanData.vehicle_no}
                    </h1>
                  </div>
                </div>

                {/* origin */}
                <div>
                  <div className="text-center">
                    <span className="popup__row1_span1">Origin</span>
                    <h1 className="popup__row1_h1 w-[100%]  break-words bg-gray-300">
                      {journyPlanData.source}
                    </h1>
                  </div>
                </div>

                {/* destination */}
                <div>
                  <div className="text-center ">
                    <span className="popup__row1_span1">Destination</span>
                    <h1 className="popup__row1_h1 break-words bg-gray-300">
                      {journyPlanData.destination}
                    </h1>
                  </div>
                </div>

                {/* end date */}
                <div>
                  <div className="text-center ">
                    <span className="popup__row1_span1">End Date</span>
                    <h1 className="popup__row1_h1 break-words bg-gray-300">
                      {journyPlanData.end_date}
                    </h1>
                  </div>
                </div>

                {/* cutting credits */}
                <div className="text-center mt-3">
                  {`Total journey duration is ${
                    dayCalculate(journyPlanData.end_date, currentDate2) + 1
                  } days.`}{" "}
                  {`Total journey cost is`}{" "}
                  <span className="underline">
                    {(dayCalculate(journyPlanData.end_date, currentDate2) + 1) *
                      10}
                  </span>{" "}
                  {`tracking credits`}
                </div>
                <div className="text-center mt-3">
                  {`Please keep sufficient tracking credits in your account`}
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div
                className="flex justify-between items-center"
                style={{ width: "100%" }}
              >
                {journyPlanData.end_date === currentDate2 ? (
                  <button
                    className="journey_button_pop rounded w-[40%]"
                    onClick={(e) => sendDataToBackend(e, 10)}
                  >
                    View estimated toll plaza list
                  </button>
                ) : (
                  <button
                    className="journey_button_pop rounded w-[40%]"
                    onClick={(e) =>
                      sendDataToBackend(
                        e,
                        (dayCalculate(journyPlanData.end_date, currentDate2) +
                          1) *
                          10
                      )
                    }
                  >
                    View estimated toll plaza list
                  </button>
                )}

                <a
                  href={`https://www.google.com/maps/dir/${journyPlanData.source}/${journyPlanData.destination}/`}
                  target={"_blank"}
                  className="text-center rounded inline-block w-[48%] text-sm underline"
                >
                  View on map
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* popup end */}

      {/* 5 sec popup after close journey from toll plaza list page */}
      <div
        className={isFiveSecPop ? "five_sec_popup_show" : "hidden"}
        style={{ background: "#d4edda", color: "#155724" }}
      >
        you can see all closed journey on past journey page
      </div>

      {/* loader */}
      <section
        style={isLoader ? { display: "flex" } : { display: "none" }}
        id="home_loader"
        className="absolute top-0 left-0 w-[100%] h-[100%] bg-white opacity-50 flex justify-center items-center"
      >
        <CircularProgress />
      </section>
    </>
  );
}

export default JourneyPlanner;
