import React from "react";
import TopSection from "../../TopSection";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import HeadingWithText from "../../components/final_components/HeadingWithText/HeadingWithText";
import AccordianByTar from "../../components/final_components/AccordianByTar/AccordianByTar";
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Radio from '@mui/material/Radio';
import { Tooltip } from "@mui/material";
import axios from 'axios'
import { sendContact } from "../../apis/api";

import './info.css';
import Footer from "../../components/layouts/Footer/Footer";
import { useState } from "react";

const Faqs = () => {
    const [inputData, setInputData] = useState(
        {
            "name": "",
            "email": "",
            "message": ""
        }
    )

    function handleChange(e) {
        setInputData({ ...inputData, [e.target.name]: e.target.value })
        console.log(inputData)
    }

    async function sendQuery() {

        await axios.post(sendContact, inputData).then(res => {
            console.log(res)
            if (res.data.success) {
                window.alert(res.data.message)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <>

            {/* navbar START*/}
            <SecondNavBar translationHide={"true"} />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Contact Us"}
                small={"Send your query/feedback/suggestion"}
            >

            </TopSection>
            {/* top section Component END*/}


            <div className="my-4">
                {/* contact main START */}
                <section className="contact_main_wrapper bg-white padding-sunya" style={{ "width": "73.5%", "margin": "0.5rem auto" }}>
                    <div className="container_contact mr-3">

                        <div className="left_middle bg-black flex flex-wrap items-center justify-between py-4 px-4 rounded">

                            {/* contact number */}
                            <a href="tel:+917359304277" className="flex vvvvvbbbb">
                                <span className="material-symbols-outlined text-white">
                                    phone_in_talk
                                </span>
                                <span className="text-white inline-block">7359304277</span>
                            </a>

                            {/* email */}
                            <a href="mailto:info@lorrycare.com" className="flex ml-2 vvvvvbbbb">
                                <span className="material-symbols-outlined text-white">
                                    mail
                                </span>
                                <span className="text-white inline-block ">info@lorrycare.com</span>
                            </a>

                            {/* address */}
                            <a target={"_blank"} href="https://goo.gl/maps/ss2LZo9X4RosojV79" className={"flex ml-2 vvvvvbbbb"}>
                                <span className="material-symbols-outlined text-white text-sm">
                                    location_on
                                </span>
                                <span className="text-white inline-block  text-sm">A/514, Joyos Hubtown,Geeta Mandir,Ahmedabad</span>
                            </a>

                        </div>
                        {/* right */}
                        <div className=" w-[80%] m-auto">

                            <div className="flex justify-between p-3">
                                <TextField variant="filled" onChange={(e) => { handleChange(e) }} label="First Name" name="name" style={{ "width": "100%" }} />
                            </div>

                            <div className="flex justify-between p-3">
                                <TextField variant="filled" onChange={(e) => { handleChange(e) }} label="Email/Phone" name="email"  style={{ "width": "100%" }} />
                            </div>

                            <div className="p-3">
                                <div style={{ "color": "#8D8D8D", "fontSize": "0.8rem" }}>Message</div>
                                <TextareaAutosize variant="filled" onChange={(e) => { handleChange(e) }} name="message" minRows={"5"} maxLength={"500"} className="textarea_contact border-none" style={{ "width": "100%", "marginTop": "0.1rem", "borderBottom": "2px solid #8D8D8D", "background": "#f0f0f0","borderRadius":"0.5rem","paddingLeft":"0.3rem","paddingTop":"0.3rem" }} placeholder={"Write your message"} />
                            </div>

                            <div className="p-3 flex justify-end">
                                <button onClick={(e) => { sendQuery() }} className="contact_button rounded shadow">Send Message</button>
                            </div>

                        </div>

                    </div>
                </section>
                {/* contact main END */}
            </div>

            <Footer />
        </>
    );
};

export default Faqs;
