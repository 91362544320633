import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { mobileSMS } from "../../../apis/api";
import { Link, useNavigate } from "react-router-dom";
import MainHeading from "../../../components/layouts/mainHeading/MainHeading";
import { Button } from "@mui/material";
import ls from 'localstorage-slim';

const RStep2 = () => {
  const nav = useNavigate()
  const [phone, setPhone] = useState({
    "mobile": ""
  })

  // when user user will input data in input box given function will be run...
  function inputChange(e) {
    setPhone({ mobile: e.target.value });
    console.log(phone)
  }

  // clickOnContinue(e)
  async function clickOnContinue(e) {
    if ((phone.mobile).length !== 10) {
      window.alert("Please Enter 10 digits mobile number")
    } else {
      await axios.post(mobileSMS, phone).then(res => {
        console.log(res)
        if (res.data.success) {
          ls.set("mobileOTP_id", res.data.data, { encrypt: true })
          ls.set('registerPhone', phone.mobile, { encrypt: true })
          nav('/mobileVerificationOtp')
        }
      }).catch(err => {
        window.alert('something went wrong')
      })
    }
  }


  return (

    <div className="flex items-center w-full h-[100vh] justify-center border">
      <Row className="py-8 w-[40%]">
        <div className="px-4 md:px-2 lg:px-2 flex items-center flex-col justify-center">

          {/* top section START */}
          <div className="flex flex-row-reverse justify-between items-center mb-5 w-[100%]">

            <div className="flex items-center">

              <img src="logogo.png" alt="logo_img" style={{ "width": "6rem" }} />
            </div>

            <div className="flex items-center">
              <div>
                <MainHeading HeadinName="Signup to LorryCare" />
                <span className="text-xs">Sign up / Register for FREE</span>
              </div>
              {/* <Para Detail="Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio" /> */}
            </div>

          </div>
          {/* top section END */}

        </div>
        <div className="px-2 flex items-center flex-col justify-center w-[100%]">
          <div className="w-[100%] ">
            <label htmlFor="">Phone Number</label>
            <Input
              label="Phone Number"
              onChange={(e) => {
                inputChange(e);
              }}
              type="number"
              name="mobile"
              value={phone.mobile}
              placeholder="Example 8934567321"
            />
          </div>

          <div className="w-full mt-5">
            <Button variant="contained" className="w-[100%]" style={{ "background": "#232323" }} onClick={(e) => { clickOnContinue(e) }}>Continue</Button>
          </div>
          <div className="mt-2 flex justify-between w-[100%]">
            <p className="font-Inter font-normal text-[rgba(0,0,0,1)] text-sm">
              Already have an account?
              <span
                style={{ color: "black", fontWeight: "600", cursor: "pointer" }}
              >
                <Link to="/login" className="ml-1 underline">Login</Link>
              </span>{" "}
            </p>

            <Link to={"/SignUp"} className={"underline text-blue-600"}>skip</Link>
          </div>
        </div>
      </Row>
    </div>
  );
};
const Row = styled.div`
  
`;
const Input = styled.input`
margin-top: 0.75rem;
width: 100%;
padding: 0.75rem 2.25rem 0.75rem 1rem;
border-radius: 5px;
border: none;
outline:none !important;
box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
font-family: "Inter", sans-serif;
`;
export default RStep2;
