import React, { useState, useEffect, useRef } from "react";
import Filter from "../../components/layouts/Filter/Filter";
import { CSVLink } from "react-csv";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import { useSelector, useDispatch } from 'react-redux'
import ls from 'localstorage-slim'
import axios from 'axios';
import { getVehicleListForUser, seachHistoryTrackData, fullVehicleList } from "../../apis/api";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/layouts/Footer/Footer";
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import { isMobile } from "react-device-detect";
import MobileTrack from "./MobileTrack";
import MobileHistoryTrack from "./MobileHistoryTrack";

const HistoryTrack = () => {

    // nav reference...
    const nav = useNavigate();

    const buttonGetVehicle = useRef();

    let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);

    // user is login or not data
    const iLogin = ls.get('iLogin');

    // last index of array

    let login_response_object, token_user, user_id;

    // if user login then it will be execute...
    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }

    const [apiData, setApiData] = useState([]);
    const [completeData, setCompleteData] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const lastIndexOfMainDataArray = (apiData.length) - 1;
    const params = useParams();

    // get history track...
    useEffect(() => {


        axios.get(`${seachHistoryTrackData}/${params.id}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            console.log(response)
            setCompleteData(response.data.data)
            setApiData(response.data.data.more)
            console.log("----apiData----")
            console.log(apiData)
        }).catch(err => {
            console.log("errorss")
            console.log(err)
        })

        axios.get(`${fullVehicleList}/${user_id}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            if (response.data.success) {
                setVehicleList(response.data.data)
            }

        }).catch(err => {
            console.log(err)
        })
    }, []);


    // lastToFirst(e)
    function FirstToLast(e) {
        if (!isSortLastToFirst) {
            setApiData(apiData.reverse());
            setIsSortLastToFirst(true);
        }
    }

    // FirstToLast(e)
    function lastToFirst(e) {
        if (isSortLastToFirst) {
            setApiData(apiData.reverse());
            setIsSortLastToFirst(false);
        }
    }

    // getVehicleInformation(e)
    async function getVehicleInformation(e) {
        let shouldTermine = false;
        vehicleList.map((elm) => {
            if (elm.vin == completeData.vehicle_no) {
                shouldTermine = true;
                nav('/ListOfVehicle')
            }
        })

        if (!shouldTermine) {
            nav('/AddVehicle')
        }
    }

    let latestCsv = [];
    apiData.forEach(elm => {
        console.log("elm====")
        console.log(elm)
        latestCsv.push({ Toll: elm.plaza, Checking_Date: elm.readerReadTime.split(' ')[0], Checking_Time: elm.readerReadTime.split(' ')[1], City: elm.city, State: elm.state })
    })

    return (
        <>
            {isMobile ? <>
                <MobileHistoryTrack />
            </> :
                <>

                    {/* navbar START*/}
                    <SecondNavBar />
                    {/* navbar END*/}

                    {/* top section Component */}
                    <TopSection
                        bigi={completeData.vehicle_no}
                        small={"View last 72 hour journey"}
                    >
                        <button
                            style={isSortLastToFirst ? {
                                "textDecoration": "none",
                                "display": "inline-block",
                                "color": "black",
                                "background": "white",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "none",
                                "cursor": "pointer",
                                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                            } : {
                                "textDecoration": "none",
                                "display": "inline-block",
                                "background": "black",
                                "color": "white",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "none",
                                "cursor": "pointer",
                                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                            onClick={(e) => lastToFirst(e)}
                        >
                            Sort by Last to First
                        </button>

                        <button
                            style={!isSortLastToFirst ? {
                                "textDecoration": "none",
                                "display": "inline-block",
                                "color": "black",
                                "background": "white",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "none",
                                "cursor": "pointer",
                                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                "marginLeft": "1rem"
                            } : {
                                "textDecoration": "none",
                                "display": "inline-block",
                                "background": "black",
                                "color": "white",
                                "borderRadius": "0.3rem",
                                "padding": "0.4rem 2rem",
                                "border": "none",
                                "cursor": "pointer",
                                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                "marginLeft": "1rem"
                            }}
                            onClick={(e) => { FirstToLast(e) }}
                        >
                            Sort by First to Last
                        </button>


                    </TopSection>
                    {/* top section Component END*/}

                    <div className="w-[73.5%] m-auto pt-3 text-xl font-semibold">{completeData.track_no}</div>

                    <section
                        className="track_my_goods"
                        style={{ width: "78%", margin: "auto" }}
                    >
                        <div className="track_container">



                            {/* main tracker section */}
                            <section
                                className="tracker_main_wrapper"
                                style={{ marginBottom: "3rem", width: "90%", margin: "auto" }}
                            >
                                <div className="flex justify-end cvcvcvd" style={{ "display": "none" }}>
                                    <h1 className='mt-5  please_scroll' style={{ "display": "none" }}><p>scroll for view</p> <span className="material-symbols-outlined">
                                        arrow_forward
                                    </span></h1>
                                </div>
                                {/* here */}
                                <div className="tracker_main_container only_track tracker_main_container_remove">

                                    {
                                        apiData.map((elm, ind) => {
                                            return <div
                                                className="tracker_row"
                                                style={{
                                                    marginTop: "3rem",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    "align-items": "center",
                                                }}
                                            >
                                                {/* icon */}
                                                <div className={ind == lastIndexOfMainDataArray ? "w-[8%] statusIconLast" : "statusIcon w-[8%]"} >
                                                    {ind == '0' ? <svg style={{ "color": "green" }} width="50" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z" stroke="black" strokeWidth="1.5" />
                                                        <path d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>

                                                        : <svg style={{ "color": "green" }} width="50" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z" stroke="black" strokeWidth="1.5" />
                                                            <path d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>

                                                    }

                                                </div>

                                                {/* city */}
                                                <div className="city w-[20%]">
                                                    <h1 style={{ fontWeight: "500", "textAlign": "start" }}>{elm.plaza}</h1>
                                                </div>

                                                <div
                                                    className="track_small_detail_wrapper"
                                                    style={{ width: "70%", overflow: "scroll" }}
                                                >

                                                    <div
                                                        className="track_small_detail"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            "align-items": "center",
                                                            width: "40rem",
                                                        }}
                                                    >
                                                        {/* checkin date */}
                                                        <div
                                                            className="checkinDate w-[14%]"
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                        >

                                                            <span
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    fontWeight: "400",
                                                                    marginTop: "0.4rem",
                                                                }}
                                                            >
                                                                Checking Date
                                                            </span>
                                                            <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                                {elm.readerReadTime.slice(0, 10)}
                                                            </b>
                                                        </div>

                                                        {/* checkin time */}
                                                        <div
                                                            className="checkinTime w-[10%]"
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                        >

                                                            <span
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    fontWeight: "400",
                                                                    marginTop: "0.4rem",
                                                                }}
                                                            >
                                                                Checking Time
                                                            </span>
                                                            <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                                {elm.readerReadTime.slice(11, 19)}
                                                            </b>
                                                        </div>

                                                        {/* time lapse*/}
                                                        <div
                                                            className="timeLapse w-[15%]"
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                        >

                                                            <span
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    fontWeight: "400",
                                                                    marginTop: "0.4rem",
                                                                }}
                                                            >
                                                                State
                                                            </span>
                                                            <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                                {elm.state}
                                                            </b>
                                                        </div>

                                                        {/* distance covered*/}
                                                        <div
                                                            className="timeLapse w-[15%]"
                                                            style={{ display: "flex", flexDirection: "column" }}
                                                        >

                                                            <span
                                                                style={{
                                                                    fontSize: "0.8rem",
                                                                    fontWeight: "400",
                                                                    marginTop: "0.4rem",
                                                                }}
                                                            >
                                                                city
                                                            </span>
                                                            <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                                                {elm.city}
                                                            </b>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        })}


                                </div>

                            </section>



                        </div>

                    </section>
                    <div className="flex justify-between pb-2">
                        <CSVLink className="text-sm inline-block m-auto" data={latestCsv} style={{ "background": "black", "color": "white", "padding": "0.2rem", "borderRadius": "1rem" }} filename={`Tracking-History-Of ${completeData.vehicle_no}`}>Download</CSVLink>
                    </div>

                    <Footer />

                </>}
        </>
    );
};

export default HistoryTrack;
