import React from "react";

function Share() {
  return (
    <div>
      {/* share section */}
      <section
        className="share_wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100%",
          background: "rgba(177, 177, 177, 0.404)",
          backdropFilter: "blur(5px)",
          position: "absolute",
          left: "0",
          top: "0",
        }}
      >
        <div
          className="share_section"
          style={{
            width: "25%",
            height: "18rem",
            background: "white",
            borderRadius: "0.4rem",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            padding: "1rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Share</h1>
            <span
              className="material-symbols-outlined"
              style={{ cursor: "pointer" }}
            >
              close
            </span>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                padding: "1rem",
                borderRadius: "50%",
                background: "rgb(244, 246, 248)",
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="fa fa-facebook-f" style={{ fontSize: "24px" }}></i>
            </div>

            <div
              style={{
                padding: "1rem",
                borderRadius: "50%",
                background: "rgb(244, 246, 248)",
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="fa fa-twitter" style={{ fontSize: "24px" }}></i>
            </div>

            <div
              style={{
                padding: "1rem",
                borderRadius: "50%",
                background: "rgb(244, 246, 248)",
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="fa fa-linkedin" style={{ fontSize: "24px" }}></i>
            </div>

            <div
              style={{
                padding: "1rem",
                borderRadius: "50%",
                background: "rgb(244, 246, 248)",
                width: "3rem",
                height: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="fa fa-github" style={{ fontSize: "24px" }}></i>
            </div>
          </div>

          <div style={{ marginTop: "2rem" }}>
            <p>page link</p>
            <p
              style={{
                background: "rgb(244, 246, 248)",
                marginTop: "0.6rem",
                padding: "0.4rem 0.4rem",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>https:fjdhfhfe/sudhsudhw/q123</p>
              <span class="material-symbols-outlined">content_copy</span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Share;
