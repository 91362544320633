import React from "react";

function Track() {
  return (
    <div>
      <svg
        width="15"
        height="15"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3327 9.99996C18.3327 5.39996 14.5993 1.66663 9.99935 1.66663C5.39935 1.66663 1.66602 5.39996 1.66602 9.99996C1.66602 14.6 5.39935 18.3333 9.99935 18.3333"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66665 2.5H7.49998C5.87501 7.36797 5.87501 12.632 7.49998 17.5H6.66665M12.5 2.5C13.3083 4.93333 13.7166 7.46667 13.7166 10"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 13.3333V12.5C4.93333 13.3083 7.46667 13.7166 10 13.7166M2.5 7.49998C7.36797 5.87501 12.632 5.87501 17.5 7.49998M18.3333 18.3333L17.5 17.5M15.1667 17.8333C15.8739 17.8333 16.5522 17.5524 17.0523 17.0523C17.5524 16.5522 17.8333 15.8739 17.8333 15.1666C17.8333 14.4594 17.5524 13.7811 17.0523 13.281C16.5522 12.7809 15.8739 12.5 15.1667 12.5C14.4594 12.5 13.7811 12.7809 13.281 13.281C12.781 13.7811 12.5 14.4594 12.5 15.1666C12.5 15.8739 12.781 16.5522 13.281 17.0523C13.7811 17.5524 14.4594 17.8333 15.1667 17.8333Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}

export default Track;
