import React, { useState, useEffect } from "react";
import Filter from "../../components/layouts/Filter/Filter";
import { CSVLink } from "react-csv";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import { useSelector, useDispatch } from 'react-redux'
import ls from 'localstorage-slim'
import axios from 'axios';
import { getVehicleListForUser, fullVehicleList } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layouts/Footer/Footer";
import { isMobile } from 'react-device-detect';
import MobileTrack from "./MobileTrack";

const Track = () => {
  let [apiData, setApiData] = useState([]);
  let [isSortLastToFirst, setIsSortLastToFirst] = useState(true);

  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;
  const nav = useNavigate();

  useEffect(() => {
    axios.get(`${fullVehicleList}/${user_id}`, {
      headers: {
        Authorization: `Hello ${token_user}`
      }
    }).then(response => {
      if (response.data.success) {
        setApiData(response.data.data)
        console.log("divine",response.data.data)
      }

    }).catch(err => {
      console.log(err)
    })
  }, []);

  let trackVehicleData = useSelector((state) => state.vehicleTrackData.value);
  let trackVehicleDatafromLocalStorage = ls.get("trackVehicleData", { decrypt: true })
  const [isSubscribe, setIsSubscribe] = useState(true);
  const [isSubscribeShow, setIsSubscribeShow] = useState(false);

  // close sub popup
  function closeSubPop() {
    setIsSubscribeShow(false);
  }

  // buySubscribePopup
  function buySubscribePopup() {
    setIsSubscribeShow(true);
  }


  const track_vehicle_no = ls.get('track_vehicle_no', { decrypt: true })
  if (trackVehicleData == '0') {
    trackVehicleData = trackVehicleDatafromLocalStorage;
  }

  // let mainDataArray = trackVehicleData.txn;
  let [mainDataArray, setMainDataArray] = useState(trackVehicleData.txn);
  let vehical_number = trackVehicleData.vehicle_no
  const trackinNumber = trackVehicleData.tracking_no;
  let averageSpeed, averageDistance;
  averageSpeed = trackVehicleDatafromLocalStorage.speed;
  averageDistance = trackVehicleDatafromLocalStorage.distance;


  const lastIndexOfMainDataArray = (mainDataArray.length) - 1;
  let mainDataArra;

  // lastToFirst(e)
  function FirstToLast(e) {
    if (!isSortLastToFirst) {
      let newMainDataArray = JSON.parse(JSON.stringify(mainDataArray));
      mainDataArra = newMainDataArray.reverse();
      console.log(mainDataArra)
      setIsSortLastToFirst(true);
      setMainDataArray(mainDataArra);
    }
  }

  // FirstToLast(e)
  function lastToFirst(e) {
    if (isSortLastToFirst) {
      let newMainDataArray = JSON.parse(JSON.stringify(mainDataArray));
      mainDataArra = newMainDataArray.reverse();
      console.log(mainDataArra)
      setIsSortLastToFirst(false);
      setMainDataArray(mainDataArra);
    }
  }

  // console.log("hhhorse" + JSON.stringify(firstTollData)); 
  let tableData = [
    {
      date: "2019-06-01",
      v_no: "gj 18 AA 0998",
      source: "cali",
      destination: "mumbai",
    },
    {
      date: "2019-06-02",
      v_no: "agj 18 AA 0998",
      source: "zali",
      destination: "mumbai",
    },
    {
      date: "2019-07-01",
      v_no: "bgj 18 AA 0998",
      source: "aali",
      destination: "apali",
    },
    {
      date: "2018-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
    {
      date: "2018-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
    {
      date: "2022-07-01",
      v_no: "gj 18 AA 0998",
      source: "pali",
      destination: "mumbai",
    },
  ];
  const csvData = tableData;


  // getVehicleInformation(e)
  async function getVehicleInformation(e) {
    let shouldTermine = false;
    if (isSubscribe) {
      apiData.map((elm) => {
        if (elm.vin == vehical_number) {
          shouldTermine = true;
          nav('/ListOfVehicle')
        }
      })

      if (!shouldTermine) {
        nav('/AddVehicle')
      }

    } else {
      buySubscribePopup();
    }
  }

  // customizing csvs
  let latestCsv = [];
  mainDataArray.forEach(elm => {
    latestCsv.push({ PlazaName: elm.plaza, Date: elm.readerReadTime.slice(0, 11), Time: elm.readerReadTime.slice(11, 16), City: elm.city, State: elm.state })
  });

  return (
    <>
      {isMobile ? <>
        <MobileTrack />
      </> :
        <>
          {/* navbar START*/}
          <SecondNavBar />
          {/* navbar END*/}

          {/* top section Component */}
          <TopSection
            bigi={vehical_number}
            small={"View Last 72 Hour Journey"}
          >
            <button
              style={isSortLastToFirst ? {
                "textDecoration": "none",
                "display": "inline-block",
                "color": "black",
                "background": "white",
                "borderRadius": "0.3rem",
                "padding": "0.4rem 2rem",
                "border": "none",
                "cursor": "pointer",
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px"
              } : {
                "textDecoration": "none",
                "display": "inline-block",
                "background": "black",
                "color": "white",
                "borderRadius": "0.3rem",
                "padding": "0.4rem 2rem",
                "border": "none",
                "cursor": "pointer",
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
              onClick={(e) => lastToFirst(e)}
            >
              Sort by Last to First
            </button>

            <button className="qweerty_vd"
              style={!isSortLastToFirst ? {
                "textDecoration": "none",
                "display": "inline-block",
                "color": "black",
                "background": "white",
                "borderRadius": "0.3rem",
                "padding": "0.4rem 2rem",
                "border": "none",
                "cursor": "pointer",
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                "marginLeft": "1rem"
              } : {
                "textDecoration": "none",
                "display": "inline-block",
                "background": "black",
                "color": "white",
                "borderRadius": "0.3rem",
                "padding": "0.4rem 2rem",
                "border": "none",
                "cursor": "pointer",
                "boxShadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                "marginLeft": "1rem"
              }}
              onClick={(e) => { FirstToLast(e) }}
            >
              Sort by First to Last
            </button>


          </TopSection>
          {/* top section Component END*/}

          <div className="w-[73.5%] m-auto pt-3 text-xl font-semibold">{trackinNumber}</div>


          <section
            className="track_my_goods"
            style={{ width: "78%", margin: "auto" }}
          >
            <div className="track_container">




              {/* main tracker section */}
              <section
                className="tracker_main_wrapper"
                style={{ marginBottom: "3rem", width: "90%", margin: "auto" }}
              >
                <div className="flex justify-end">
                  <h1 className='mt-5  please_scroll' style={{ "display": "none" }}><p>scroll for view</p> <span className="material-symbols-outlined">
                    arrow_forward
                  </span></h1>
                </div>
                {/* here */}
                <div className="tracker_main_container  only_track tracker_main_container_remove">

                  {
                    mainDataArray.map((elm, ind) => {
                      return <div
                        className="tracker_row"
                        style={{
                          marginTop: "3rem",
                          display: "flex",
                          justifyContent: "space-between",
                          "align-items": "center",
                        }}
                      >
                        {/* icon */}
                        <div className={ind == lastIndexOfMainDataArray ? "w-[8%] statusIconLast" : "statusIcon w-[8%]"} >
                          {ind == '0' ? <svg style={{ "color": "green" }} width="50" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z" stroke="black" strokeWidth="1.5" />
                            <path d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                            : <svg style={{ "color": "green" }} width="50" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5409 14.6875C5.64506 -3.3542 32.3742 -3.33337 36.4576 14.7083C38.8534 25.2916 32.2701 34.25 26.4992 39.7916C24.4825 41.736 21.7902 42.8225 18.9888 42.8225C16.1874 42.8225 13.4952 41.736 11.4784 39.7916C5.7284 34.25 -0.854936 25.2708 1.5409 14.6875Z" stroke="black" stroke-width="1.5" />
                              <path d="M13.2695 20.9583L16.3945 24.0833L24.7279 15.75" stroke="green" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          }

                        </div>

                        {/* city */}
                        <div className="city w-[20%]">
                          <h1 style={{ fontWeight: "500", "textAlign": "start" }}>{elm.plaza}</h1>
                        </div>

                        <div
                          className="track_small_detail_wrapper"
                          style={{ width: "70%", overflow: "scroll" }}
                        >
                          <div
                            className="track_small_detail"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              "align-items": "center",
                              width: "40rem",
                            }}
                          >
                            {/* checkin date */}
                            <div
                              className="checkinDate w-[14%]"
                              style={{ display: "flex", flexDirection: "column" }}
                            >
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  marginTop: "0.4rem",
                                }}
                              >
                                Date
                              </span>
                              <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                {elm.readerReadTime.slice(0, 10)}
                              </b>

                            </div>

                            {/* checkin time */}
                            <div
                              className="checkinTime w-[10%]"
                              style={{ display: "flex", flexDirection: "column" }}
                            >
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  marginTop: "0.4rem",
                                }}
                              >
                                Time
                              </span>
                              <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                {elm.readerReadTime.slice(11, 19)}
                              </b>

                            </div>

                            {/* time lapse*/}
                            <div
                              className="timeLapse w-[15%]"
                              style={{ display: "flex", flexDirection: "column" }}
                            >
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  marginTop: "0.4rem",
                                }}
                              >
                                State
                              </span>
                              <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                {elm.state}
                              </b>

                            </div>

                            {/* distance covered*/}
                            <div
                              className="timeLapse w-[15%]"
                              style={{ display: "flex", flexDirection: "column" }}
                            >
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  fontWeight: "400",
                                  marginTop: "0.4rem",
                                }}
                              >
                                city
                              </span>
                              <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                                {elm.city}
                              </b>

                            </div>

                            {/* tracking number */}
                            {/* <div
                          className="timeLapse w-[15%]"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "400",
                              marginTop: "0.4rem",
                            }}
                          >
                            Tracking No.
                          </span>
                          <b style={{ fontSize: "0.9rem", fontWeight: "600" }}>
                            {trackinNumber}
                          </b>

                        </div> */}
                          </div>
                        </div>
                      </div>
                    })}


                </div>

              </section>



            </div>

          </section>

          <div className="flex justify-between pb-2">
            <CSVLink className="text-sm inline-block m-auto" data={latestCsv} style={{ "background": "black", "color": "white", "padding": "0.2rem", "borderRadius": "1rem" }} filename={`Tracking-History-Of ${vehical_number}`}>Download</CSVLink>
          </div>

          <Footer />


          {/* all popup */}

          {/* Oops ! You can’t access full journey. Buy subscription plan for view full journey. */}
          <div style={isSubscribeShow ? { "display": "block" } : { "display": "none" }}>
            <section className="pop_up_main_wrapper" >
              <div className="main_popup_1" >

                <div className="close_icon flex justify-end">
                  <span onClick={() => { closeSubPop() }} className="material-symbols-outlined" style={{ "background": "#F8F9FA", "borderRadius": "50%", "padding": "0.1rem", "cursor": "pointer" }}>
                    close
                  </span>
                </div>

                <div className="flex flex-col justify-center items-center">
                  <div className="icon_wrapper">
                    <span className="material-symbols-outlined" style={{ "fontSize": "3rem" }}>
                      check_box
                    </span>
                  </div>

                  <div>
                    <p className="track_popup1_mess">Oops ! You can’t access full journey. Buy subscription plan for view full journey.</p>
                  </div>

                  <div>
                    <button className="track_whenclick_button" onClick={() => { nav('/Plans') }}>Buy Plan</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>}




    </>
  );
};

export default Track;
