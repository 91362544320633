import React, { useEffect, useState } from "react";
import Card from "../../components/final_components/CardWithBorderDesign/Card";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/Block/Block";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { getProfile, fetchKYC, QR } from "../../apis/api";
import ls from "localstorage-slim";
import { useSelector } from "react-redux";

const Invoice = () => {
  const params = useParams();
  const nav = useNavigate();
  let kycId = ls.get("kyc_id", { decrypt: true });
  const loginData = ls.get("login_data_object", { decrypt: true });
  if (!kycId) {
    kycId = loginData.user.kyc_id;
  }
  const [apigetData, setApiGetData] = useState([]);
  const login_response_object = ls.get("login_data_object", { decrypt: true });
  const token_user = login_response_object.token;
  const user_id = login_response_object.user._id;
  // InvoiceAmount
  const InvoiceAmount = useSelector((state) => state.InvoiceAmount.value);
  const InvoiceDate = useSelector((state) => state.InvoiceAmount.date);
  const credits = useSelector((state) => state.InvoiceAmount.cd);

  const [userData, setUserData] = useState({
    name: "",
    company_name: "",
    company_address: "",
    company_document: "",
    email: "",
    mobile: "",
  });

  const [kycData, setKycData] = useState({
    u_id: user_id,
    aadhar_number: "",
    pan_number: "",
    aadhar_file: "",
    pan_file: "",
    gst_number: "",
    gst_file: "",
  });
  // window.onbeforeunload = function () {
  //     return "Are you sure you want to leave this page?";
  // };

  useEffect(() => {
    // get profile data
    axios
      .get(`${getProfile}/${user_id}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        console.log(response.data.data);
        setUserData({
          name: response.data.data.name,
          company_name: response.data.data.company_name,
          company_address: response.data.data.company_address,
          email: response.data.data.email,
          mobile: response.data.data.mobile,
          gst_file: "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(`${fetchKYC}/${kycId}`, {
        headers: {
          Authorization: `Hello ${token_user}`,
        },
      })
      .then(function (response) {
        console.log("todayKycData");
        setApiGetData(response.data.data);
        setKycData({
          u_id: user_id,
          aadhar_number: response.data.data.aadhar_number,
          pan_number: response.data.data.pan_number,
          aadhar_file: "",
          pan_file: "",
          gst_number: response.data.data.gst_number,
          gst_file: "",
        });
        console.log("19july");
        console.log(kycData);
        console.log(JSON.stringify(response) + "/////");
        if (response.data.success) {
          ls.set("isGst", response.data.data.gst_number);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("kycData");
  console.log(kycData);

  return (
    <section className="invoice_wrapper">
      <div className="w-[80%] m-auto flex items-center">
        <button
          className="pt-2 ml-12 mt-4"
          onClick={() => {
            nav(-1);
          }}
        >
          <span className="material-symbols-outlined">keyboard_backspace</span>
        </button>
        <div
          id="navbar_main"
          className="w-[fit-content] mt-5 p-2 rounded-md bg-black text-white "
        >
          <a
            onClick={() => {
              window.print();
            }}
          >
            Download
          </a>
        </div>
      </div>

      <section className="invoice bg-white border mt-2 p-5 w-[50rem] m-auto">
        <div className="flex justify-between items-center ">
          <h1 className="font-semibold text-2xl ">TAX INVOICE</h1>
          {/* <logo /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="6rem"
            viewBox="0 0 584.000000 427.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <metadata>
              Created by potrace 1.16, written by Peter Selinger 2001-2019
            </metadata>
            <g
              transform="translate(0.000000,427.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path d="M3020 4256 c-168 -26 -352 -90 -484 -168 -215 -126 -410 -330 -521 -545 -24 -47 -30 -52 -63 -55 l-37 -3 0 -65 0 -65 143 -3 142 -3 0 70 c0 60 -2 69 -20 74 -11 3 -20 10 -20 15 0 27 114 177 199 262 200 199 423 308 711 347 95 13 136 13 232 4 318 -30 545 -136 764 -355 181 -182 291 -389 341 -646 27 -137 22 -357 -10 -496 -113 -492 -468 -840 -962 -945 -93 -20 -362 -17 -463 4 -249 55 -430 153 -608 331 -103 103 -213 251 -197 267 4 4 53 10 108 11 l100 3 0 70 0 70 -255 0 -255 0 -3 -64 c-4 -75 1 -81 75 -81 l51 0 40 -73 c187 -340 501 -574 902 -672 105 -26 375 -31 501 -10 557 93 994 519 1111 1083 31 150 31 402 0 549 -119 558 -530 971 -1073 1078 -112 22 -341 28 -449 11z" />
              <path d="M2300 3420 l0 -70 180 0 180 0 0 -60 0 -60 -225 0 -225 0 0 -75 0 -75 225 0 225 0 0 -60 0 -60 -365 0 -365 0 0 -70 0 -70 365 0 365 0 0 -60 0 -60 -130 0 -130 0 0 -70 0 -70 130 0 130 0 0 -60 0 -60 -90 0 -90 0 0 -75 0 -75 150 0 150 0 0 600 0 600 -240 0 -240 0 0 -70z" />
              <path d="M2880 2891 l0 -601 94 0 c78 0 97 3 119 20 23 18 25 27 29 112 4 111 21 152 93 223 68 69 113 89 210 93 103 5 172 -19 239 -84 69 -66 100 -138 101 -242 2 -113 5 -116 147 -120 128 -4 178 7 207 45 20 24 21 39 21 280 0 199 -3 260 -14 281 -23 45 -163 86 -363 108 l-162 17 -75 136 c-75 136 -75 137 -146 179 -38 23 -73 42 -75 42 -3 0 -29 16 -58 36 -85 59 -111 66 -244 72 l-123 4 0 -601z m439 415 c19 -10 50 -54 99 -140 40 -69 72 -128 72 -131 0 -3 -119 -5 -265 -5 l-265 0 0 145 0 145 165 0 c125 0 173 -4 194 -14z" />
              <path d="M1700 3155 l0 -75 190 0 190 0 0 75 0 75 -190 0 -190 0 0 -75z" />
              <path d="M1560 2890 l0 -70 125 0 125 0 0 70 0 70 -125 0 -125 0 0 -70z" />
              <path d="M2120 2630 l0 -70 75 0 75 0 0 70 0 70 -75 0 -75 0 0 -70z" />
              <path d="M3390 2669 c-138 -26 -233 -172 -200 -307 23 -94 99 -170 193 -193 172 -41 340 119 307 293 -13 65 -31 97 -83 145 -61 56 -134 77 -217 62z m136 -163 c30 -30 34 -41 34 -84 0 -116 -129 -172 -208 -89 -54 58 -50 132 9 182 24 19 42 25 81 25 44 0 54 -4 84 -34z" />
              <path d="M665 723 c-96 -35 -171 -103 -214 -193 -36 -76 -42 -211 -13 -288 99 -260 428 -324 616 -121 77 83 99 146 94 269 -3 77 -9 104 -30 145 -32 63 -122 149 -185 177 -68 30 -200 35 -268 11z m224 -142 c48 -25 95 -79 111 -126 20 -61 9 -165 -23 -214 -42 -64 -97 -95 -176 -99 -145 -8 -244 84 -244 228 0 96 44 169 128 213 44 24 156 22 204 -2z" />
              <path d="M3593 726 c-100 -33 -169 -92 -221 -189 -25 -48 -27 -61 -27 -167 0 -107 2 -119 28 -168 34 -64 100 -133 154 -160 147 -74 332 -44 439 70 25 27 42 53 38 58 -5 4 -32 22 -60 38 l-52 30 -20 -28 c-31 -42 -98 -70 -165 -70 -139 0 -228 90 -229 231 -1 87 29 150 91 194 37 26 53 30 120 33 88 4 141 -15 181 -65 l21 -27 52 28 c29 16 56 32 61 36 15 14 -76 101 -139 132 -50 26 -74 31 -145 34 -55 3 -100 -1 -127 -10z" />
              <path d="M0 375 l0 -355 205 0 205 0 0 65 0 65 -135 0 -135 0 0 290 0 290 -70 0 -70 0 0 -355z" />
              <path d="M1250 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 57 -3 67 -115 67 -115 77 -3 77 -3 -20 33 c-102 172 -134 232 -123 236 7 2 29 18 49 36 101 88 98 260 -6 346 -61 50 -108 60 -284 60 l-158 0 0 -355z m325 211 c41 -18 55 -42 55 -98 -1 -79 -45 -108 -166 -108 l-74 0 0 110 0 110 75 0 c43 0 90 -6 110 -14z" />
              <path d="M1880 375 l0 -355 70 0 70 0 0 120 0 120 53 0 53 0 69 -120 69 -120 73 0 c40 0 73 3 73 6 0 3 -31 59 -70 124 -38 65 -70 123 -70 128 0 5 15 20 34 33 130 89 123 283 -14 376 -52 36 -95 43 -257 43 l-153 0 0 -355z m320 210 c32 -17 60 -61 60 -95 0 -37 -30 -81 -65 -96 -19 -8 -66 -14 -105 -14 l-70 0 0 110 0 110 75 0 c48 0 87 -6 105 -15z" />
              <path d="M2423 723 c2 -5 56 -103 120 -218 l117 -211 0 -137 0 -137 70 0 70 0 0 136 0 135 120 213 c66 117 120 216 120 220 0 3 -35 6 -78 6 l-77 0 -15 -32 c-35 -77 -132 -258 -138 -258 -4 0 -41 66 -81 145 l-75 145 -79 0 c-43 0 -76 -3 -74 -7z" />
              <path d="M4272 703 c-5 -16 -28 -80 -50 -143 -22 -63 -72 -206 -111 -317 -39 -111 -71 -206 -71 -212 0 -7 27 -11 73 -11 l74 0 18 60 18 60 147 0 148 0 11 -30 c6 -17 16 -44 22 -60 11 -29 13 -30 80 -30 38 0 69 2 69 4 0 2 -54 157 -120 344 -66 187 -120 345 -120 351 0 7 -32 11 -89 11 -88 0 -89 0 -99 -27z m148 -283 c24 -69 45 -131 48 -137 3 -10 -21 -13 -98 -13 -77 0 -101 3 -97 13 3 6 24 71 48 144 24 72 47 129 50 125 3 -4 26 -63 49 -132z" />
              <path d="M4770 375 l0 -355 70 0 70 0 0 120 0 121 57 -3 58 -3 69 -118 68 -117 75 0 c86 0 88 -19 -15 155 -41 70 -60 111 -52 113 6 2 28 18 47 35 108 95 101 265 -15 354 -60 46 -97 53 -274 53 l-158 0 0 -355z m330 207 c77 -38 74 -155 -5 -188 -24 -10 -61 -14 -108 -12 l-72 3 -3 108 -3 107 78 0 c54 0 89 -5 113 -18z" />
              <path d="M5400 375 l0 -355 220 0 220 0 0 65 0 65 -150 0 -150 0 0 80 0 80 135 0 135 0 0 65 0 65 -135 0 -135 0 0 75 0 75 145 0 145 0 0 70 0 70 -215 0 -215 0 0 -355z" />
            </g>
          </svg>
        </div>

        <div
          className="flex pb-3 mt-2"
          style={{ borderBottom: "2px solid black" }}
        >
          <div className="left_in w-[75%]">
            <div className="row flex justify-between">
              <Block sub={"INVOICE NO."} extra={true} main={params.asdfg} />
              <Block
                sub={"DATE:"}
                main={`${InvoiceDate.slice(8, 10)}/${InvoiceDate.slice(
                  5,
                  7
                )}/${InvoiceDate.slice(0, 4)}`}
              />
              <Block sub={"PLACE OF SUPPLY:"} main={"Gujarat(24)"} />
            </div>
          </div>
          <div className="left_in w-[25%] flex justify-end">
            <QRCodeSVG value={QR} />,
          </div>
        </div>

        <div>
          <h1 className="font-semibold my-2">
            INVOICE ISSUED FOR PURCHASE OF TRACKING CREDITS
          </h1>
          <div className="flex justify-between">
            <div className="flex flex-col mt-5">
              <span className="text-slate-700 font-semibold">
                CUSTOMER NAME
              </span>
              <span className="font-normal">{userData.name}</span>
            </div>
            <div className="flex flex-col mt-5">
              <span className="text-slate-700 font-semibold">
                Customer's GST Number
              </span>
              <span className="font-normal uppercase">
                {kycData.gst_number}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <table
            className="w-[100%] rounded-md"
            style={{ border: "1px solid black" }}
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ border: "1px solid black" }}>
                <td
                  className="pr-3 font-semibold"
                  style={{ border: "1px solid black" }}
                >
                  PLAN NAME
                </td>
                <td
                  className="pr-3 font-semibold"
                  style={{ border: "1px solid black" }}
                >
                  CREDITS
                </td>
                <td
                  className="pr-3 font-semibold"
                  style={{ border: "1px solid black" }}
                >
                  PRICE
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <td
                  className="pr-3"
                  style={{ border: "1px solid black" }}
                >{`Pack ${InvoiceAmount}`}</td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {credits ? credits : 0} Credits
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  ₹{(InvoiceAmount/1.18).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-5 flex justify-end">
          <table
            className="w-[50%] rounded-md"
            style={{ border: "1px solid black" }}
          >
            <thead style={{ border: "1px solid black" }}>
              <tr style={{ border: "1px solid black" }}>
                <td
                  className="pr-3 font-semibold"
                  style={{ border: "1px solid black" }}
                >
                  Taxes
                </td>
                <td
                  className="pr-3 font-semibold"
                  style={{ border: "1px solid black" }}
                >
                  Rate
                </td>
                {/* <td className='pr-3 font-semibold' style={{ "border": "1px solid black" }}></td> */}
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: "1px solid black" }}>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  IGST
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? "0%"
                      : `18%`
                    : "-"}
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? "₹0"
                      : `₹${((InvoiceAmount/1.18).toFixed(2) * 0.18).toFixed(2)}`
                    : "-"}
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  CGST
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? `9%`
                      : "0%"
                    : "-"}
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? `₹${((InvoiceAmount/1.18).toFixed(2) * 0.09).toFixed(2)}`
                      : `₹0`
                    : "-"}
                </td>
              </tr>
              <tr style={{ border: "1px solid black" }}>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  SGST
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? `9%`
                      : "0%"
                    : "-"}
                </td>
                <td className="pr-3" style={{ border: "1px solid black" }}>
                  {kycData.gst_number
                    ? kycData.gst_number.slice(0, 2) == "24"
                      ? `₹${((InvoiceAmount/1.18).toFixed(2) * 0.09).toFixed(2)}`
                      : `₹0`
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* 
                <div className='mt-5 text-sm'>
                    GST credit charged by the airline operator is only available against the invoice issued by the respective airline operator. If youare looking for the airline GST invoice, please check the airline website & download it from there.
                    This is not a valid travel document
                    The amount received as donation will be used for the specified charitable causes. MakeMyTrip will donate the collected
                    amount to MakeMyTrip Foundation (a public trust registered with charitable objects) or similar charitable organizations to helpcreate a social impact.
                </div> */}

        <div className="mt-5 text-sm">
          <h1 className="font-semibold text-xl ">
            ONE WHISTLE MARKETPLACE PRIVATE LIMITED
          </h1>
        </div>

        <div className="mt-5 text-sm flex justify-between">
          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">PAN</span>
            <span className="font-normal">AADCO5355J</span>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">HSN/SAC</span>
            <span className="font-normal">998319</span>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">
              SERVICE DESCRIPTION
            </span>
            <span className="font-normal">
              Vehicle tracking service without GPS
            </span>
          </div>
          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">GSTN</span>
            <span className="font-normal">24AADCO5355J1ZC</span>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">CIN</span>
            <span className="font-normal">U74999GJ2021PTC124744</span>
          </div>
        </div>

        <div className="mt-5 text-sm flex justify-between">
          <div className="flex flex-col mt-5">
            <p>ONEWHISTLE MARKETPLACE PRIVATE LIMITED</p>
            <p>A/514,Joyos Hubtown, Geeta Mandir Bus Stop</p>
            <p>Ahmedabad,Gujrat,India,380022</p>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-slate-700 font-semibold">
              Registered Office
            </span>
            <span>A/514,Joyos Hubtown, Geeta Mandir Bus Stop</span>
            <span className="font-normal">Ahmedabad,Gujrat,India,380022</span>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Invoice;
