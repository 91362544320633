import React, { useState, useEffect } from "react";
import TopSection from "../../TopSection";
import CommonTable from "../../CommonTable";
import { CSVLink } from "react-csv";
import Filter from "../../components/layouts/Filter/Filter";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import { useSelector, useDispatch } from 'react-redux'
import Footer from "../../components/layouts/Footer/Footer";
import { getPastJourney } from "../../apis/api";
import ls from 'localstorage-slim'
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addTollData } from "../../redux/slices/journeyTollData";
import axios from "axios";
import { Past_Journey_Top_Heading_Hn, Past_Journey_Top_Subheading_Hn, Past_Default_table_message_Hn } from '../../components/translation/Hn'
import { Past_Journey_Top_Heading_En, Past_Journey_Top_Subheading_En, Past_Default_table_message_En } from '../../components/translation/En'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MobilePastJourney = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const eng = useSelector((state) => state.translate.value);
    let tableData
    // get shorted and filter data
    const shortedFilterData4 = useSelector((state) => state.pastJourneyTable.value);
    const iLogin = ls.get('iLogin');
    let login_response_object, token_user, user_id;
    let [count, setCount] = useState(0);
    let [apiData, setApiData] = useState([]);
    const [isLoader, setIsLoader] = useState();
    const [showLoadMore, setShowLoadMore] = useState(true);


    if (iLogin) {
        login_response_object = ls.get("login_data_object", { decrypt: true });
        token_user = login_response_object.token;
        user_id = login_response_object.user._id;
    }
    // clickOnGoingRaw(e)
    function clickOnGoingRaw(e, _id) {
        console.log("clickOnGoingRaw called with _id:", _id);
        setIsLoader(true);
        axios.get(`https://lorrycare.com:5000/v1/trip-details/${_id}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            if (response.data.success) {
                dispatch(addTollData(response.data.data));
                ls.set("journeyPlan", response.data.data);
                ls.set('journeyTollData', response.data.data, { encrypt: true });
                setIsLoader(false)
                nav('/full-trip-list')
            }
            // console.log(response.data.data);
        }).catch(err => {
            console.log(err);
            setIsLoader(false)
        })
    }
    // get ongoing data...
    useEffect(() => {
        axios.get(`${getPastJourney}/${user_id}/${count}`, {
            headers: {
                Authorization: `Hello ${token_user}`
            }
        }).then(response => {
            console.log(response)
            if (response.data.success) {
                console.log(response.data.data)
                setApiData(response.data.data);
            }
        }).catch(err => {
            console.log(err)
        })

    }, [count])

    if (shortedFilterData4 === 0) {
        tableData = apiData;
    } else {
        tableData = shortedFilterData4;
    }

    // csvData
    const csvData = tableData;
    let latestCsv = [];
    tableData.forEach(elm => {
        console.log("elm16june====")
        console.log(elm)
        latestCsv.push({ Vehicle_no: elm.vehicle_no, Origin: elm.source[0].source, Destination: elm.destination[0].destination, Journey_Number: elm.trip_no, Start_Date: elm.start_date, End_Date: elm.end_date })
    })

    return (
        <>
            <div className="">
                <SecondNavBar active6={true} />
            </div>
            {/* top section Component */}
            <TopSection
                bigi={eng ? Past_Journey_Top_Heading_En : Past_Journey_Top_Heading_Hn}
                small={eng ? Past_Journey_Top_Subheading_En : Past_Journey_Top_Subheading_Hn}
            >
                <CSVLink
                    style={{
                        textDecoration: "none",
                        display: "inline-block",
                        background: "black",
                        color: "white",
                        borderRadius: "0.3rem",
                        padding: "0.4rem 2rem",
                        border: "none",
                        cursor: "pointer",
                    }}
                    data={latestCsv}
                    className={"download_scv"}
                >
                    Download All to CSV
                </CSVLink>
                <Filter tableData={tableData} />
            </TopSection>
            {/* top section Component END*/}

            {/* common table START */}
            <div>
                {Array.isArray(tableData) && tableData.length > 0 ? (
                    tableData.map((rowData, index) => (
                        console.log("tableDatatableData", tableData),
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">{rowData.vehicle_no}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="mobile-table">
                                    <table>
                                        <tbody>
                                            {[
                                                { property: 'vehicle_no', header: 'Vehicle Number' },
                                                { property: 'source', header: 'Origin' },
                                                { property: 'destination', header: 'Destination' },
                                                // { property: 'trip_no', header: 'Journey id' },
                                                { property: 'trip_no', header: 'Journey Number' },
                                                { property: 'start_date', header: 'Start Date' },
                                                { property: 'end_date', header: 'End Date' },

                                                // { property: 'button', header: '' },
                                                // { property: 'start_date', header: 'Credits' },

                                                // Updated property name
                                            ].map(({ property, header }) => (
                                                <tr key={property}>
                                                    <td>{header}</td>
                                                    {/* <td>{rowData[property]}</td> */}
                                                    <td onClick={(e) => clickOnGoingRaw(e, rowData._id)}
                                                        style={{ cursor: 'pointer' }}>
                                                        {property === 'source'
                                                            ? rowData[property][0].source
                                                            : property === 'destination'
                                                                ? rowData[property][0].destination
                                                                : property === 'start_date'
                                                                    ? new Date(rowData[property]).toLocaleDateString()
                                                                    : property === 'end_date'
                                                                        ? new Date(rowData[property]).toLocaleDateString()
                                                                        : rowData[property]}
                                                    </td>
                                                    {/* <td onClick={() => viewHistoryTracking(`/history-tracking/${rowData.id}`)}
                                                        style={{ cursor: 'pointer' }}>
                                                        {property === 'request_time' &&
                                                            (header === 'Date'
                                                                ? new Date(rowData[property]).toLocaleDateString()
                                                                : new Date(rowData[property]).toLocaleTimeString())}
                                                        {property !== 'request_time' && rowData[property]}
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <div>No data available</div>
                )}
            </div>

            {/* common table END */}
            {showLoadMore ?
                <div className="flex justify-center pb-8">
                    <Button variant="contained" style={(apiData.length < 5) ? { "display": "none" } : { "background": "#232323" }} onClick={() => { setCount(count + 5) }}>Load More</Button>
                </div> : <></>
            }

            <Footer />
        </>
    );
};

export default MobilePastJourney;
