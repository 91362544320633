import React, { useState, useEffect } from 'react';
import './Plans.css';
import SecondNavBar from '../../components/layouts/Navbar/SecondNavbar/SecondNavbar';
import TopSection from '../../TopSection';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/layouts/Footer/Footer';
import PlanCard2 from './PlanCard2';
import { ppcPlanGet, monthlyPlanGet } from "../../apis/api"
import axios from 'axios'
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { showAndToggle } from '../../redux/slices/choosePlan'
import { Link } from 'react-router-dom'
import ls from 'localstorage-slim'
import { PLAN_Top_Head_En, PLAN_Top_Sub_En, PLAN_Benifits_En } from '../../components/translation/En'
import { PLAN_Top_Head_Hn, PLAN_Top_Sub_Hn, PLAN_Benifits_Hn } from '../../components/translation/Hn'

function MainPlan() {
    const eng = useSelector((state) => state.translate.value);
    const dispatch = useDispatch();
    const [apiData, setApiData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const lastIndexOfMonthlyData = monthlyData.lastIndexOf();
    let confirmPop = useSelector((state) => state.choosePlan.value);
    let planName = useSelector((state) => state.choosePlan.planName);
    let Plantype = useSelector((state) => state.choosePlan.Plantype);
    const theme = ls.get("theme");

    useEffect(() => {
        axios.get(ppcPlanGet).then(resp => {
            console.log("resp" + JSON.stringify(resp))
            setApiData(resp.data.data)
        }).catch(err => {
            console.log(err)
        })

        axios.get(monthlyPlanGet).then(resp => {
            console.log("jjj" + JSON.stringify(resp.data.data))
            setMonthlyData(resp.data.data)
        }).catch(err => {
            console.log(err)
        })
    }, []);

    const nav = useNavigate();
    const sortedApiData = apiData.sort((a, b) => {
        if (a._id === "6557294d080c7eb033174307") return -1; // Move the specific _id to the beginning
        if (b._id === "6557294d080c7eb033174307") return 1; // Move other _ids to follow
        return 0; // Maintain the relative order of other _ids
    });
    return (
        <div>
            <div className="nav">
                <SecondNavBar active2={true} />
            </div>

            {/* top section Component */}
            <TopSection bigi={eng ? PLAN_Top_Head_En : PLAN_Top_Head_Hn}
                small={eng ? PLAN_Top_Sub_En : PLAN_Top_Sub_Hn}></TopSection>
            {/* top section Component END*/}

            <div className='w-[100%] m-auto h-auto plan_mai_warap'>
                {/* ppc */}
                <section className="plan_grid_wrapper w-[73.5%] m-auto" >
                    <div className='flex justify-between items-between'>
                        {ls.get('isGst') ? <Link to={"/profile-main"} className={"underline"}>Please add your gst detail</Link> : <></>}
                    </div>


                    <div className='benifits_section my-5'>
                        <div className='w-[100%] bg-white p-3 rounded-lg' style={{ "boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset" }}>
                            <h3 className='text-lg font-semibold font-Cardo'>Benefits of Plan</h3>
                            <ul className='in_bullets_wrapper list-disc pl-8 pt-3'>
                                <li>{eng ? PLAN_Benifits_En.line1 : PLAN_Benifits_Hn.line1}</li>
                                <li>{eng ? PLAN_Benifits_En.line2 : PLAN_Benifits_Hn.line2}</li>
                                <li>{eng ? PLAN_Benifits_En.line3 : PLAN_Benifits_Hn.line3}</li>
                                {/* <li> <b className='underline'>Special Introductory Offer:</b> view last 72 hours of toll crossing data at no extra cost</li> */}
                                <li>{eng ? PLAN_Benifits_En.line4 : PLAN_Benifits_Hn.line4}</li>
                                <li>{eng ? PLAN_Benifits_En.line5 : PLAN_Benifits_Hn.line5}</li>
                                <li>{eng ? PLAN_Benifits_En.line6 : PLAN_Benifits_Hn.line6}</li>
                                <li>{eng ? PLAN_Benifits_En.line7 : PLAN_Benifits_Hn.line7}</li>
                                <li>{eng ? PLAN_Benifits_En.line8 : PLAN_Benifits_Hn.line8}</li>
                                <li>{eng ? PLAN_Benifits_En.line9 : PLAN_Benifits_Hn.line9}</li>
                                <li>{eng ? PLAN_Benifits_En.line10 : PLAN_Benifits_Hn.line10}</li>
                                <li> <b className='underline'>{eng ? PLAN_Benifits_En.line11 : PLAN_Benifits_En.line11}</b>
                                    <ul className='list-decimal pl-4'>
                                        <li>{eng ? PLAN_Benifits_En.line12 : PLAN_Benifits_Hn.line12}</li>
                                        <li>{eng ? PLAN_Benifits_En.line13 : PLAN_Benifits_Hn.line13}</li>
                                        <li>{eng ? PLAN_Benifits_En.line14 : PLAN_Benifits_Hn.line14}</li>
                                    </ul>
                                </li>
                                <li> <b style={{ fontSize: "20px" }}>{eng ? PLAN_Benifits_En.line15 : PLAN_Benifits_Hn.line15}</b><br />
                                    {eng ? PLAN_Benifits_En.line16 : PLAN_Benifits_Hn.line16}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="ppc_grid flex flex-wrap mt-5 justify-between ffffsgsgsgsgsghdwsydxgy">
                        {/* <PlanCard2 planID={apiData[-1]._id} singleData={apiData[-1]} Main={`inr ${apiData[-1].amount}`} type={"Pay Per Click"} small={apiData[-1].credits} head={`Pack ${apiData[-1].amount}`} /> */}


                        {sortedApiData.map((elm) => (
                            <PlanCard2
                                key={elm._id}
                                planID={elm._id}
                                singleData={elm}
                                Main={`inr ${elm.amount}`}
                                type={"Pay Per Click"}
                                small={elm.credits}
                                head={`Pack ${elm.amount}`}
                            />
                        ))}

                        {/* <table id='price_table'>
                            <thead>
                                <tr>
                                    <td>Plan Name</td>
                                    <td>Plan Credits</td>
                                    <td>Price</td>
                                    <td>-</td>
                                </tr>
                            </thead>
                            <tbody>
                                {apiData.map(elm => {
                                    return <tr>
                                        <td>{`Pack ${elm.amount}`}</td>
                                        <td>{elm.credits}</td>
                                        <td>{elm.amount}</td>
                                        <td><button className='bg-black text-white text-sm rounded-3xl' style={{"padding":"4px"}}>Buy Credits</button></td>
                                    </tr>
                                })}
                            </tbody>
                        </table> */}
                    </div>

                </section>

                {/* monthly */}
                {/* <section className="plan_grid_wrapper w-[73.5%] m-auto my-10">
                    <h1 className='text-2xl'>Monthly Plans</h1>

                    <div className='benifits_section my-5'>

                        <div className='w-[100%] bg-white cursor-pointer p-3 rounded-lg mt-3' style={{"boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}}>
                            <h3 className='text-lg font-semibold font-Cardo'>Benifits of Monthly Plan</h3>
                            <ul className='in_bullets_wrapper list-disc pl-8 pt-3'>
                                <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                                <li>Find all toll plazas en route to plan travel expenses and rest stops</li>
                                <li>Estimate the distance and time to complete the journey</li>
                                <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed</li>
                                <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS</li>
                                <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles</li>
                                <li>Transfer tracking credits to other registered users</li>
                            </ul>
                        </div>

                    </div>

                    <div className="ppc_grid flex flex-wrap mt-5 justify-between ffffsgsgsgsgsghdwsydxgy">

                        {monthlyData.map((elm, ind) => {
                            return <PlanCard1 singleData={elm} Main={`inr ${elm.amount}`} type={"Monthly Subscription"} small={elm.creadit} head={`Pack ${elm.amount}`} />
                        })}

                    </div>
                </section> */}
            </div>

            {/* bottomer */}
            {/* <div
                className="flex justify-center"
                onClick={() => {
                    nav('/contact-us');
                }}>
                <div
                    className="rounded-tl-lg ncdbottom cursor-pointer rounded-tr-lg flex justify-center items-center"
                    style={{
                        width: '53.5%',
                        margin: 'auto',
                        height: '7rem',
                        background: '#FFFFFF',
                        "boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"
                    }}>
                    <p className="requst_bottom_para flex justify-center items-center" style={{}}>
                        <span style={{ "color": "black" }}>Contact us</span>{' '}
                        <span className="material-symbols-outlined ml-5 text-black">
                            arrow_forward
                        </span>
                    </p>
                </div>
            </div> */}

            <Footer />

        </div>
    );
}

export default MainPlan;
