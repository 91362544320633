import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const hoverMenu = createSlice({
    name: 'hoverMenu',
    initialState,
    reducers: {
        addBoolean: (state, action) => {
            state.value = (action.payload);
        },
    },
})

export const { addBoolean } = hoverMenu.actions

export default hoverMenu.reducer