import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value:0,
}

export const tableLegnth = createSlice({
    name: 'tableLegnth',
    initialState,
    reducers: {
        totalRows: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { totalRows } = tableLegnth.actions

export default tableLegnth.reducer