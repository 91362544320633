import React, { useState } from "react";
import Dot from "../../components/assets/svg/icons/Dot";
import Line from "../../components/assets/svg/Line";
import SecondNavBar from "../../components/layouts/Navbar/SecondNavbar/SecondNavbar";
import TopSection from "../../TopSection";
import Tick from "../../components/assets/svg/Tick"
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineArrowCircleRight } from "react-icons/md";
import ls from 'localstorage-slim'
import Footer from "../../components/layouts/Footer/Footer";

function VehicleModel() {

  // local states and variables
  const nav = useNavigate();
  const [data, setData] = useState(" ");
  const iLogin = ls.get('iLogin');

  // when user change input field then it function will be executed
  function inputChange(e) {
    setData(e.target.value);
  }

  // when user click on NextButton then it function will be execute
  function okNext(e) {
    ls.set('vehical_model', data, { encrypt: true });
    nav('/VehicleFuelType');
  }



  return (
    <>
      <div>
        <div className="">
          <SecondNavBar />
        </div>

        {/* top section Component */}
        <TopSection
          bigi={"Add My Vehicle"}
          small={""}
        >
        </TopSection>
        {/* top section Component END*/}

        {/* main section START  */}
        <div className="parent-elem relative">
          <div className="header"></div>
          <div className="py-5">
            <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
              <div style={{"boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}} className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
                <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>

                <div className="heading flex justify-between text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                  <h1>Let's Get to Know Your Vehicle!</h1>
                  <Link to={"/VehicleFuelType"} className={"text-sm flex justify-center items-center"}><p className="underline">Skip for now</p><svg className="ml-2" width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.992188 1.32591L4.52135 4.83991L0.992188 8.35449" stroke="black" strokeOpacity="0.8" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  </Link>
                </div>
                <div className="second-Section flex flex-col gap-7 w-[85%]">
                  <div className=" text-base font-Cardo font-normal lg:text-xl ">
                    <p>What's your vehicle Model?</p>
                  </div>
                  <div className="inputItem">
                    <input onChange={(e) => { inputChange(e) }}
                      type={"text"}
                      className=" border-none border-black rounded-md w-[100%] p-1 pl-2"
                      placeholder={"Example LP2516"}
                      style={{"boxShadow": "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}}
                    />
                  </div>
                  <div className="flex gap-4">
                    <div className="flex bg-black text-white cursor-pointer rounded-md px-10 py-1"  onClick={(e) => { iLogin?okNext(e):window.alert("please login first") }}>
                      <div className="font-Cardo font-normal text-xl pr-3">
                        Ok
                      </div>
                      <div className="flex justify-center items-center">
                        <Tick />
                      </div>
                    </div>
                    <div className="flex enter_wrpper">
                      <div className="flex justify-center items-center font-Inter font-light text-sm">
                        Press Enter
                      </div>
                      <div className="flex justify-center items-center">
                        <MdOutlineArrowCircleRight />
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] flex flex-col items-center justify-center mb-5">
          <div className="hidden h-[15vh] pt-3 lg:flex justify-center items-center w-[55%] rounded flex-col md:visible" style={{"boxShadow": "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px","background":"white"}}>
            <div className="line relative">
              <Line />
            </div>

            <div className="flex relative w-full justify-between pr-16 pl-20 bottom-[0.7rem]">
              <div className=" flex flex-col items-center">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 1</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <span className="material-symbols-outlined absolute top-0 ">
                  check
                </span>
                <p className="text-black mt-2">Step 2</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <p className="text-black mt-2">Step 3</p>
              </div>
              <div className=" flex flex-col items-center ">
                <Dot />
                <p className="text-black mt-2">Step 4</p>
              </div>
              <div className=" flex flex-col items-center">
                <Dot />
                <p className="text-black mt-2">Step 5</p>
              </div>

            </div>
          </div>
        </div>
        {/* MAIN SECTION END */}

      </div>
      <Footer />
    </>
  );
}

export default VehicleModel;
