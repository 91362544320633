import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: 0,
}

export const WalletBalance = createSlice({
    name: 'WalletBalance',
    initialState,
    reducers: {
        getWalletBalance: (state, action) => {
            state.value = (action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { getWalletBalance } = WalletBalance.actions

export default WalletBalance.reducer