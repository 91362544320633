import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Stroke1 from '../../components/assets/svg/Stroke1';
import Stroke2 from '../../components/assets/svg/Stroke2';
import Tick from '../../components/assets/svg/Tick';
import HeadWithUnderline from '../../components/layouts/HeadingUnderline/HeadWithUnderline';
import SecondNavbar from '../../components/layouts/Navbar/SecondNavbar/SecondNavbar';
import './Subscription.css';
import TopSection from '../../TopSection'
import Footer from '../../components/layouts/Footer/Footer';


function Subscription() {
  return (
    <>
      <div className="nav">
        <SecondNavbar />
      </div>
      {/* top section Component */}
      <TopSection
        bigi={"Profile"}
        small={""}
      >
      </TopSection>
      {/* top section Component END*/}
      <div className="ProfileNav w-[73%] m-auto bg-[#232323] rounded-xl">
        <ul className="font-Inter font-normal flex-wrap text-base flex justify-between items-center text-white p-5">
          <li className="zxcvbb">
            <NavLink to="/Profile" className="p-1 px-4 rounded-md ">
              Profile
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/ContactDetail" className="p-1 px-4 rounded-md">
              Contact Details
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/Password" className="p-1 px-4 rounded-md">
              Password
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/kyc" className="p-1 px-4 rounded-md">
              KYC
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/subscription" className="p-1 px-4 rounded-md">
              Subscription
            </NavLink>
          </li>
          <li className="zxcvbb">
            <NavLink to="/company" className="p-1 px-4 rounded-md">
              Company
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="parent-elem relative my-16">
        <div>
          <div className="main-Box-Vehicle bg-[#fafafa] flex flex-col justify-center items-center ">
            <div className="bg-white w-[90%] flex flex-col justify-center items-center Semi-Main-Box lg:w-[55%] gap-8 pb-4 rounded-md">
              <div className="h-2 bg-black w-full top-0 rounded-b-none rounded-t-md"></div>
              {/* 
              <div className="heading text-3xl font-Cardo font-bold lg:text-[40px] w-full px-8">
                <h1>You want to edit your details</h1>
              </div> */}

              <div className='w-[90%] '>

                {/* description */}
                {/* <div className='benifits_section my-5'>

                  <div className='w-[100%] bg-white cursor-pointer p-3 rounded-lg' style={{ "boxShadow": "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset" }}>
                    <h3 className='text-lg font-semibold font-Cardo'>Benifits of PPC Plan</h3>
                    <ul className='in_bullets_wrapper list-disc pl-8 pt-3'>
                      <li>Track a vehicle's last toll crossing data</li>
                      <li>Each toll data costs 1 credit</li>
                      <li>Share data with others</li>
                      <li>Special introductory offer: view last 72 hours of toll crossing data at no extra cost</li>
                      <li>Track multiple vehicles in real-time at 10 credits per vehicle per 24 hours</li>
                      <li>Find all toll plazas en route to plan travel expenses and rest stops</li>
                      <li>Estimate the distance and time to complete the journey</li>
                      <li>Modify the journey's starting or ending location while it's ongoing and extend the journey's duration as needed</li>
                      <li>Set pre-alerts for delays and diversions and receive notifications via email or SMS</li>
                      <li>Receive a customized summary of the journey upon completion, including expenses, and view a history of previously tracked vehicles</li>
                      <li>Transfer tracking credits to other registered users</li>
                    </ul>
                  </div>

                </div> */}

                {/* box */}
                <div className='sub_desc_box w-[33%] rounded-sm p-2 flex justify-between'>
                  <h1 className='text-2xl font-Cardo cursor-pointer'><div className='text-sm font-semibold'>Plan</div> <span>Pack 19</span></h1>
                  <h1 className='text-2xl font-Cardo cursor-pointer'><div className='text-sm font-semibold'>Total credits</div> <span>24 credits</span></h1>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Subscription;
