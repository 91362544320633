import React from "react";

function Line() {
  return (
    <div>
      <svg
        width="655"
        height="5"
        viewBox="0 0 655 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="0.00304872"
          y1="0.500009"
          x2="654.003"
          y2="4.48781"
          stroke="black"
          strokeDasharray="2 2"
        />
      </svg>
    </div>
  );
}

export default Line;
