import React from 'react'
import SecondNavbar from '../../components/layouts/Navbar/SecondNavbar/SecondNavbar'
import TopSection from '../../TopSection'
import "./blog.css";
import Carousel from "react-material-ui-carousel";
import BlogCard from './BlogCard';
import Footer from '../../components/layouts/Footer/Footer';

const BlogPage = () => {
    return (
        <>
            {/* navbar START*/}
            <SecondNavbar />
            {/* navbar END */}

            {/* top section Component */}
            <TopSection
                bigi={"Blog Archieves"}
                small={"You can read our blog"}
            >
            </TopSection>
            {/* top section Component END*/}

            <section className='blog'>

                {/* blog view */}
                {/* <section className="blog__viewing">
                    <p className='blog__viewing__para'>Viewing 03 out of 34</p>
                </section> */}

                {/* carousel of blog card START */}
                {/* <div className='flex justify-between flex-wrap'>
                    <BlogCard miniHeading={"Vehicle Tracking"}>
                        <p className='text-justify text-sm'>
                            Vehicle tracking is a key feature of Lorrycare app that helps fleet managers & logistics companies to track their vehicles. With this feature, users can view the location of their vehicles on a map and monitor their movements. This helps in better management of fleets, reducing delivery times, and improving overall efficiency.
                        </p>
                    </BlogCard>
                    <BlogCard miniHeading={"Trip sharing"}>
                        <p className='text-justify text-sm'>
                            Trip sharing is another useful feature of Lorrycare app that allows users to share their trip details with concerned clients or consignees or stakeholders. With the trip sharing feature users can easily share details such as the vehicle’s route, last location, expected arrival time at destination which can help consignee to arrange goods unloading without delays.
                        </p>
                    </BlogCard>
                    <BlogCard miniHeading={"Customized Trip Summary"}>
                        <p className='text-justify text-sm'>
                            The customized trip summary feature in the app provides users with a comprehensive summary of a single or multiple trips of a vehicle in a month or a particular timeframe, including details such as the route taken, distance travelled, time taken, and other important information. This feature can be particularly useful for fleet managers that need to keep track of their assets and analyze performance. With the customized trip summary feature, users can get a detailed overview of their trips and make informed decisions about their operations.
                        </p>
                    </BlogCard>
                    <BlogCard miniHeading={"Pre-alerts"}>
                        <p className='text-justify text-sm'>
                            The pre-alerts feature in the Lorrycare app allows users to set alerts for important events such as reaching a certain location, route deviation, delays, and other important events during a vehicle’s journey. These alerts are useful for logistics team to stay on top of their operations and ensure that everything is running smoothly. With the pre-alerts feature, users can stay updated on important events and take timely action as needed.
                        </p>
                    </BlogCard>
                </div> */}

                <h1>Coming Soon</h1>

            </section>

            <Footer />
        </>
    )
}

export default BlogPage